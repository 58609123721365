import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createRefereeFailure,
	createRefereeSuccess,
	deleteRefereeFailure,
	deleteRefereeSuccess,
	fetchRefereeFailure,
	fetchRefereesFailure,
	fetchRefereesSuccess,
	fetchRefereeSuccess,
	updateRefereeFailure,
	updateRefereeSuccess
} from './RefereeActions';
import { cutReferee, makeReferee, modifyReferee, takeReferee, takeReferees } from './RefereeApis';
import {
	CreateRefereeParams,
	FETCH_REFEREES,
	UPDATE_REFEREE,
	RefereeDetailsParams,
	RefereeFetchParams,
	FETCH_REFEREE_DETAILS,
	CREATE_REFEREE,
	REMOVE_REFEREE
} from './RefereeTypes';

//get referees
const getRefereesAsync = async (data: RefereeFetchParams) => await takeReferees(data);

function* getReferees(params: any) {
	const { payload } = params;
	try {
		const referees: AxiosResponse = yield call(getRefereesAsync, payload);
		if (referees) {
			yield put(fetchRefereesSuccess(referees.data));
		} else {
			yield put(fetchRefereesFailure('Could not get list of referees'));
			notification.error({
				message: 'Get Referee List',
				description: 'Could not get list of referees'
			});
		}
	} catch (error) {
		yield put(fetchRefereesFailure('Network Error while getting Referees'));
		notification.error({
			message: 'Get Referee List',
			description: 'Network Error while getting Referees'
		});
	}
}

function* watchGetReferees() {
	yield takeLatest(FETCH_REFEREES, getReferees);
}

//get referee details
const getRefereeAsync = async (data: RefereeDetailsParams) => await takeReferee(data);

function* getReferee(params: any) {
	const { payload } = params;
	try {
		const referees: AxiosResponse = yield call(getRefereeAsync, payload);
		if (referees) {
			yield put(fetchRefereeSuccess(referees.data));
		} else {
			yield put(fetchRefereeFailure('Could not get list of referees'));
			notification.error({
				message: 'Get Referee',
				description: 'Could not get list of referees'
			});
		}
	} catch (error) {
		yield put(fetchRefereeFailure('Network Error while getting Referees'));
		notification.error({
			message: 'Get Referee',
			description: 'Network Error while getting Referees'
		});
	}
}

function* watchGetReferee() {
	yield takeLatest(FETCH_REFEREE_DETAILS, getReferee);
}

//create Referee
const generateRefereeAsync = async (data: CreateRefereeParams) => await makeReferee(data);

function* generateReferee(params: any) {
	const { payload } = params;
	try {
		const referees: AxiosResponse = yield call(generateRefereeAsync, payload);
		if (referees) {
			yield put(createRefereeSuccess(referees.data));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Create Referee',
				description: 'Referee created successfully'
			});
		} else {
			const message = 'Could not create Referee';
			yield put(createRefereeFailure(message));
			notification.error({
				message: 'Create Referee',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Referee';
		yield put(createRefereeFailure(message));
		notification.error({
			message: 'Create Referee',
			description: message
		});
	}
}

function* watchGenerateReferee() {
	yield takeLatest(CREATE_REFEREE, generateReferee);
}

//update Referee
const amendRefereeAsync = async (data: CreateRefereeParams) => await modifyReferee(data);

function* amendReferee(params: any) {
	const { payload } = params;
	try {
		const referees: AxiosResponse = yield call(amendRefereeAsync, payload);
		if (referees) {
			yield put(updateRefereeSuccess(referees.data));
			yield put(setLayout({ form: false, data: false, update: false }));

			notification.success({
				message: 'Update Referee',
				description: 'Referee updated successfully'
			});
		} else {
			const message = 'Could not Update Referee';
			yield put(updateRefereeFailure(message));
			notification.error({
				message: 'Update Referee',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Referee';
		yield put(updateRefereeFailure(message));
		notification.error({
			message: 'Update Referee',
			description: message
		});
	}
}

function* watchAmendReferee() {
	yield takeLatest(UPDATE_REFEREE, amendReferee);
}

//Delete Referee
const removeRefereeAsync = async (data: RefereeDetailsParams) => await cutReferee(data);

function* removeReferee(params: any) {
	const { payload } = params;
	try {
		const referees: AxiosResponse = yield call(removeRefereeAsync, payload);
		if (referees) {
			yield put(deleteRefereeSuccess(referees.data));
			notification.success({
				message: 'Referee Deleted',
				description: 'Referee deleted successfully'
			});
		} else {
			const message = 'Could not delete Referee';
			yield put(deleteRefereeFailure(message));
			notification.error({
				message: 'Referee Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Referee';
		yield put(deleteRefereeFailure(message));
		notification.error({
			message: 'Referee Deleted',
			description: message
		});
	}
}

function* watchRemoveReferee() {
	yield takeLatest(REMOVE_REFEREE, removeReferee);
}

export default function* RefereeSagas() {
	yield all([
		fork(watchGetReferees),
		fork(watchGetReferee),
		fork(watchGenerateReferee),
		fork(watchAmendReferee),
		fork(watchRemoveReferee)
	]);
}
