import {
  EnvironmentOutlined,
  LoadingOutlined,
  MobileOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser, fetchUsers } from "Redux/UserRedux/UserActions";
import { Roles } from "Utils/RoleStore";
import qs from "qs";
import { createSchool, fetchSchools } from "Redux/SchoolRedux/SchoolActions";
import { createPlayer, updatePlayer } from "Redux/PlayerRedux/PlayerActions";
import { getUserId } from "Api/Api";
import { useForm } from "antd/lib/form/Form";
import DatePicker from "Components/DatePicker/DatePicker";
import dayjs from "dayjs";
import { uploadImage } from "Api/MediaApi";
import { useDebounce } from "use-debounce/lib";
import { GetUploadUrl } from "Utils/GetUploadResponse";
import FileUpload from "Components/FileUpload/FileUpload";
import { AccessControl, IsSelfOnly } from "Utils/AccessControl";
import { CoachesOnly, SelfCoaches } from "Utils/ComplexQueries";
import AddressData from "Utils/AddressData";
const { Option } = Select;

export const PlayerForm = (props: any) => {
  const dispatch = useDispatch();
  const layout = useSelector((state: any) => state.LayoutReducer);
  const players = useSelector((state: any) => state.PlayerReducer);
  const school = useSelector((state: any) => state.SchoolReducer);
  const user = useSelector((state: any) => state.UserReducer);
  const [imageUrl, setImageUrl] = useState("");

  const { data } = layout;
  const handleSubmit = (values: any) => {
    if (values?.iStateDistrict) {
      values.iDistrict = values?.iStateDistrict?.[1];
      values.iProvince = values?.iStateDistrict?.[0];
    }
    if (layout?.update) {
      dispatch(updatePlayer({ ...values, id: data?.id }));
    } else {
      dispatch(
        createPlayer({ ...values, coach: values?.coach || getUserId() })
      );
    }
  };

  const [form] = useForm();

  const complexQuery = {
    _where: {
      _or: [
        [{ "trainings.id_contains": layout?.data?.trainings }], // implicit AND
        // [{ category: 'province_associations' }], // implicit AND
      ],
    },
  };

  useEffect(() => {
    // dispatch(fetchUsers({ _where: qs.stringify(complexQuery) }));
    form.setFieldsValue({ dob: dayjs("2015/01/01") });
    if (!school?.loaded) {
      dispatch(fetchSchools({}));
    }
    if (!user?.loaded) {
      dispatch(fetchUsers(CoachesOnly("")));
    }
  }, []);

  useEffect(() => {
    if (data) setImageUrl(GetUploadUrl([data?.image]));
    if (data)
      form.setFieldsValue({
        ...data,
        coach: data?.coach?.id,
        dob: dayjs(data?.dob),
        trainings: data?.trainings,
        iStateDistrict: [data?.iProvince, data?.iDistrict],
      });
    else form.setFieldsValue({ dob: dayjs("2015/01/01") });
  }, [layout.data]);

  const handleOnUpload = (e: any) => {
    form.setFieldsValue({ image: e });
  };

  function filter(inputValue: any, path: any) {
    return path.some(
      (option: any) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <Content>
      <Typography.Title level={4}>
        {!layout?.update ? "ADD" : "UPDATE"} PLAYER
      </Typography.Title>
      <Divider />
      <Form
        // {...layout}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        form={form}
        // onFinishFailed={onFinishFailed}
      >
        <Typography.Title level={5}>GENERAL DETAILS</Typography.Title>
        <Divider />
        <Row gutter={[40, 0]}>
          <Col lg={18}>
            <Form.Item name="image">
              <Input hidden />
              <FileUpload
                onUpload={(e: any) => handleOnUpload(e)}
                url={imageUrl}
              />
            </Form.Item>
          </Col>
          <Col lg={18}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item name="trainings"></Form.Item>
          <Col lg={12}>
            <Form.Item
              label="Street Address"
              name="address"
              rules={[{ required: true, message: "Address is Required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="State/District"
              name="iStateDistrict"
              rules={[{ required: true, message: "asas is Required!" }]}
            >
              <Cascader
                autoComplete="off"
                options={AddressData}
                // onChange={onChange}
                placeholder="Please select Address"
                showSearch={{ filter }}
                // onSearch={(value: any) => console.log(value)}
              />
            </Form.Item>
          </Col>
          {!layout?.update && (
            <Col lg={12}>
              <Form.Item
                label="Mobile"
                name="mobile"
                // rules={[{ required: true, message: 'Mobile Password is Required!' }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          )}

          <Col lg={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                { required: true, message: "Gender Password is Required!" },
              ]}
            >
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Email"
              name="email"
              // rules={[{ required: true, message: 'Email is Required!' }]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Facebook Profile"
              name="facebook"
              // rules={[{ required: true, message: 'Facebook is Required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              label="DOB"
              name="dob"
              initialValue={dayjs("2015/01/01")}
              rules={[
                { required: true, message: "Date of Birth is Required!" },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              hidden={!AccessControl("coach").list}
              label="Coach"
              name="coach"
              // rules={[{ required: true, message: 'Coach is Required!' }]}
            >
              <Select showSearch>
                {user?.list?.map((us: any) => {
                  return (
                    <Option value={us?.id}>
                      {us?.fullName || us?.username}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              label="Player From"
              name="playerFrom"
              // rules={[{ required: true, message: 'Gender Password is Required!' }]}
            >
              <Radio.Group name="radiogroup">
                <Radio value="school">School</Radio>
                <Radio value="district">District</Radio>
                <Radio value="club">Club</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <Form.Item
                    label={
                      <Typography.Text style={{ textTransform: "capitalize" }}>
                        {form.getFieldsValue()?.playerFrom}
                      </Typography.Text>
                    }
                    name={form.getFieldsValue()?.playerFrom}
                    // rules={[ { required: true, message: 'Class Level is Required!' } ]}
                  >
                    {form.getFieldsValue()?.playerFrom === "school" ? (
                      <Select allowClear>
                        {school?.list?.map((sc: any) => {
                          return <Option value={sc?.id}>{sc?.name}</Option>;
                        })}
                      </Select>
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5}>EMERGENCY CONTACT</Typography.Title>
        <Divider />
        <Row gutter={[20, 0]}>
          <Col lg={12}>
            <Form.Item
              label="Name"
              name="emergencyContactName"
              // rules={[{ required: true, message: 'Name is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Relation"
              name="emergencyContactRelation"
              // rules={[{ required: true, message: 'Relation is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Address"
              name="emergencyContactAddress"
              // rules={[{ required: true, message: 'Address is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {!layout?.update && (
            <Col lg={12}>
              <Form.Item
                label="Telephone"
                name="emergencyContactTelephone"
                // rules={[{ required: true, message: 'Telephone is required!' }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          )}
          {!layout?.update && (
            <Col lg={12}>
              <Form.Item
                label="Mobile"
                name="emergencyContactMobile"
                // rules={[{ required: true, message: 'Mobile is required!' }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Typography.Title level={5}>
          HEALTH AND FITNESS ASSESMENT{" "}
        </Typography.Title>
        <Divider />
        <Row gutter={[20, 0]}>
          <Col lg={12}>
            <Form.Item
              label="In which other sports / physical activities are you involved?"
              name="otherSports"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col lg={10}>
						<Form.Item
							label="How many hours per week do you train?"
							name="trainingHrsPerWeek"
							rules={[{ required: true, message: 'Relation is required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col> */}
          <Col lg={12}>
            <Form.Item
              label="Do you have any health issue/injury/allergy/accident"
              name="previousHealth"
              // rules={[{ required: true, message: 'Address is required!' }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Have you played Rugby before?"
              name="playedRugbyBefore"
              // rules={[{ required: true, message: 'Address is required!' }]}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col lg={8}>
            <Form.Item
              label="Height (Centemeter)"
              name="height"
              // rules={[{ required: true, message: 'Telephone is required!' }]}
            >
              <Input type="number" placeholder="CM" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Weight"
              name="weight"
              // rules={[{ required: true, message: 'Mobile is required!' }]}
            >
              <Input type="number" placeholder="KG" />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={players.creating || players.updating}
              >
                SUBMIT
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default PlayerForm;
