import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LayoutReducers from './LayoutReducers';
import AuthReducers from './AuthReducer';
import { FormReducer } from 'Redux/FormRedux/FormReducer';
import { ReportReducer } from 'Redux/ReportRedux/ReportReducer';
import { GireventReducer } from 'Redux/GIREventRedux/GireventReducer';
import { GirreportReducer } from 'Redux/GIRReportRedux/GirreportReducer';
import { UserReducer } from 'Redux/UserRedux/UserReducer';
import { SchoolReducer } from 'Redux/SchoolRedux/SchoolReducer';
import { PlayerReducer } from 'Redux/PlayerRedux/PlayerReducer';
import { EventReducer } from 'Redux/EventRedux/EventReducer';
import { SessionReducer } from 'Redux/SessionRedux/SessionReducer';
import { AttendanceReducer } from 'Redux/AttendanceRedux/AttendanceReducer';
import { PerformanceReducer } from 'Redux/PerformanceRedux/PerformanceReducer';
import { AnalysisReducer } from 'Redux/AnalysisRedux/AnalysisReducer';
import { ActivityReducer } from 'Redux/ActivityRedux/ActivityReducer';
import { TournamentReducer } from 'Redux/TournamentRedux/TournamentReducer';
import { MatchReducer } from 'Redux/MatchRedux/MatchReducer';
import { WorkshopReducer } from 'Redux/WorkshopRedux/WorkshopReducer';
import { RefereeReducer } from 'Redux/RefereeRedux/RefereeReducer';
export const rootReducers = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		UserReducer: UserReducer,
		LayoutReducer: LayoutReducers,
		AuthReducer: AuthReducers,
		FormReducer: FormReducer,
		ReportReducer: ReportReducer,
		GireventReducer: GireventReducer,
		GirreportReducer: GirreportReducer,
		SchoolReducer: SchoolReducer,
		PlayerReducer: PlayerReducer,
		EventReducer: EventReducer,
		SessionReducer: SessionReducer,
		AttendanceReducer: AttendanceReducer,
		PerformanceReducer: PerformanceReducer,
		AnalysisReducer: AnalysisReducer,
		ActivityReducer: ActivityReducer,
		TournamentReducer: TournamentReducer,
		MatchReducer: MatchReducer,
		WorkshopReducer: WorkshopReducer,
		RefereeReducer: RefereeReducer
	});

export type RootState = ReturnType<typeof rootReducers>;
