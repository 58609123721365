import MainContainer from 'Container/MainContainer/MainContainer';
import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import SchoolDetails from 'Views/Schools/SchoolDetails';
import Schools from 'Views/Schools/Schools';
import UserDetails from 'Views/Users/UserDetails';
import Users from 'Views/Users/Users';
export const SchoolRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer>
      <Switch>
        <Route path={`${props.match?.path}/list`} exact component={Schools} />
        <Route path={`${props.match?.path}/:schoolId`} component={SchoolDetails} />
        <Route component={Schools} />
      </Switch>
    </MainContainer>
  )
}

export default SchoolRoute;