export const toDataURL = (url: any) => {
    return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
}

export const  downloadImage =async (url: any, name: string) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = name || "myFile.png";
    document.body.appendChild(a);
    a.click(); 
    document.body.removeChild(a);
}