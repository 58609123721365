import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/charts';
import MyContent from 'Components/Content/Content';
import { useSelector } from 'react-redux';
import { PieConfig } from '@ant-design/charts/es/pie';

const AgeGroupChart: React.FC = () => {
	const data = useSelector((state: any) => state.AnalysisReducer);

	var config: PieConfig = {
		appendPadding: 10,
		data: data?.ageVsProvince,
		angleField: 'value',
		colorField: 'type',
		radius: 1,
		innerRadius: 0.64,
		meta: {
			value: {
				formatter: function formatter(v: any) {
					return `${v}%`;
				}
			}
		},
		label: {
			type: 'inner',
			offset: '-50%',
			style: { textAlign: 'center' },
			autoRotate: false,
			content: '{value}'
		},
		interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
		statistic: {
			title: false,
			content: {
				style: {
					whiteSpace: 'pre-wrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					fontSize: '14px'
				},
				formatter: function formatter(v: any) {
					return `<Typography.Text>${v?.type || 'All'}\n\n${v?.value || ''}%</Typography.Text>`;
				},
			},
		},
	};
	// 	var config: PieConfig = {
	//     appendPadding: 10,
	//     data: data?.ageVsProvince,
	//     angleField: 'value',
	//     colorField: 'type',
	//     radius: 1,
	//     innerRadius: 0.6,
	//     label: {
	//       type: 'inner',
	//       offset: '-50%',
	//       content: '{value}',
	//       style: {
	//         textAlign: 'center',
	//         fontSize: 14,
	//       },
	//     },
	//     interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
	//     statistic: {
	//       title: false,
	//       content: {
	//         style: {
	//           whiteSpace: 'pre-wrap',
	//           overflow: 'hidden',
	//           textOverflow: 'ellipsis',
	//         },
	//         formatter: function formatter(v: any) {
	//           return `${v}%`;
	//         },
	//       },
	//     },
	//   };
	return (
		<MyContent>
			<Pie {...config} />
		</MyContent>
	);
};

export default AgeGroupChart;
