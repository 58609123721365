import API from 'Api/Api';
import { CreateFormParams, FormDetailsParams, FormFetchParams } from './FormTypes';

//get Form
export const takeForms = (data: FormFetchParams) => {
	return API({
		url: '/forms',
		method: 'GET',
		params: data
	});
};

//get Form Details
export const takeForm = (data: FormDetailsParams) => {
	return API({
		url: `/forms/${data.id}`,
		method: 'GET'
	});
};

//create Form Details
export const makeForm = (data: CreateFormParams) => {
	return API({
		url: '/forms',
		method: 'POST',
		data: data
	});
};

//modify Form
export const modifyForm = (data: CreateFormParams) => {
	return API({
		url: `/forms/${data.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Form
export const cutForm = (data: FormDetailsParams) => {
	return API({
		url: '/form',
		method: 'DELETE',
		params: data
	});
};

export const submitReportData = (data: any) => {
	return API({
		url: '/report-data',
		method: 'POST',
		data: data
	});
};

export const updateReportData = (data: any) => {
	const uri = `/report-data/${data.id}`;
	const tempData = data;
	delete tempData.id;
	return API({
		url: uri,
		method: 'PUT',
		data: tempData
	});
};
