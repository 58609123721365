import { Col, Row } from 'antd';
import MyContent from 'Components/Content/Content';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchActivitys } from 'Redux/ActivityRedux/ActivityActions';
import { fetchSchools } from 'Redux/SchoolRedux/SchoolActions';
import { fetchUsers } from 'Redux/UserRedux/UserActions';
import ActivityLogList from './ActivityLogList';

export const ActivityLog = (props: any) => {
	// const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(fetchActivitys({}));
	// }, []);
	return (
		<Row gutter={[ 20, 20 ]}>
			<Col lg={24}>
				<ActivityLogList />
			</Col>
		</Row>
	);
};

export default ActivityLog;
