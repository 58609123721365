import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createEventFailure,
	createEventSuccess,
	deleteEventFailure,
	deleteEventSuccess,
	fetchEventFailure,
	fetchEventsFailure,
	fetchEventsSuccess,
	fetchEventSuccess,
	updateEventFailure,
	updateEventSuccess
} from './EventActions';
import { cutEvent, makeEvent, modifyEvent, takeEvent, takeEvents } from './EventApis';
import {
	CreateEventParams,
	FETCH_EVENTS,
	UPDATE_EVENT,
	EventDetailsParams,
	EventFetchParams,
	FETCH_EVENT_DETAILS,
	CREATE_EVENT,
	REMOVE_EVENT
} from './EventTypes';

//get events
const getEventsAsync = async (data: EventFetchParams) => await takeEvents(data);

function* getEvents(params: any) {
	const { payload } = params;
	try {
		const events = yield call(getEventsAsync, payload);
		if (events) {
			yield put(fetchEventsSuccess(events.data));
		} else {
			yield put(fetchEventsFailure('Could not get list of events'));
			notification.error({
				message: 'Get Event List',
				description: 'Could not get list of events'
			});
		}
	} catch (error) {
		yield put(fetchEventsFailure('Network Error while getting Events'));
		notification.error({
			message: 'Get Event List',
			description: 'Network Error while getting Events'
		});
	}
}

function* watchGetEvents() {
	yield takeLatest(FETCH_EVENTS, getEvents);
}

//get event details
const getEventAsync = async (data: EventDetailsParams) => await takeEvent(data);

function* getEvent(params: any) {
	const { payload } = params;
	try {
		const events = yield call(getEventAsync, payload);
		if (events) {
			yield put(fetchEventSuccess(events.data));
		} else {
			yield put(fetchEventFailure('Could not get list of events'));
			notification.error({
				message: 'Get Event',
				description: 'Could not get list of events'
			});
		}
	} catch (error) {
		yield put(fetchEventFailure('Network Error while getting Events'));
		notification.error({
			message: 'Get Event',
			description: 'Network Error while getting Events'
		});
	}
}

function* watchGetEvent() {
	yield takeLatest(FETCH_EVENT_DETAILS, getEvent);
}

//create Event
const generateEventAsync = async (data: CreateEventParams) => await makeEvent(data);

function* generateEvent(params: any) {
	const { payload } = params;
	try {
		const events = yield call(generateEventAsync, payload);
		if (events) {
			yield put(createEventSuccess(events.data));
			yield put(setLayout({ form: '', update: false, data: false }));
			notification.success({
				message: 'Create Event',
				description: 'Event created successfully'
			});
		} else {
			const message = 'Could not create Event';
			yield put(createEventFailure(message));
			notification.error({
				message: 'Create Event',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Event';
		yield put(createEventFailure(message));
		notification.error({
			message: 'Create Event',
			description: message
		});
	}
}

function* watchGenerateEvent() {
	yield takeLatest(CREATE_EVENT, generateEvent);
}

//update Event
const amendEventAsync = async (data: CreateEventParams) => await modifyEvent(data);

function* amendEvent(params: any) {
	const { payload } = params;
	try {
		const events = yield call(amendEventAsync, payload);
		if (events) {
			yield put(updateEventSuccess(events.data));
			yield put(setLayout({ form: '', update: false, data: false }));

			notification.success({
				message: 'Update Event',
				description: 'Event updated successfully'
			});
		} else {
			const message = 'Could not Update Event';
			yield put(updateEventFailure(message));
			notification.error({
				message: 'Update Event',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Event';
		yield put(updateEventFailure(message));
		notification.error({
			message: 'Update Event',
			description: message
		});
	}
}

function* watchAmendEvent() {
	yield takeLatest(UPDATE_EVENT, amendEvent);
}

//Delete Event
const removeEventAsync = async (data: EventDetailsParams) => await cutEvent(data);

function* removeEvent(params: any) {
	const { payload } = params;
	try {
		const events = yield call(removeEventAsync, payload);
		if (events) {
			yield put(deleteEventSuccess(events.data));
			notification.success({
				message: 'Event Deleted',
				description: 'Event deleted successfully'
			});
		} else {
			const message = 'Could not delete Event';
			yield put(deleteEventFailure(message));
			notification.error({
				message: 'Event Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Event';
		yield put(deleteEventFailure(message));
		notification.error({
			message: 'Event Deleted',
			description: message
		});
	}
}

function* watchRemoveEvent() {
	yield takeLatest(REMOVE_EVENT, removeEvent);
}

export default function* EventSagas() {
	yield all([
		fork(watchGetEvents),
		fork(watchGetEvent),
		fork(watchGenerateEvent),
		fork(watchAmendEvent),
		fork(watchRemoveEvent)
	]);
}
