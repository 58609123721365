import { Button, Divider, Col, Form, Input, Row, Space, Typography, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';
import { getUserId } from 'Api/Api';
import dayjs from 'dayjs';
import { createReferee, updateReferee } from 'Redux/RefereeRedux/RefereeActions';

const { Option } = Select;
export const RefereeForm = (props: any) => {
	const dispatch = useDispatch();
	const schools = useSelector((state: any) => state.RefereeReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [imageData, setImageData] = useState<any[]>([]);

	const [participants, setParticipants] = useState<any[]>([]);
	const [documents, setDocuments] = useState<any[]>([]);

	const handleOnUploadDocument = (e: any, i: any) => {
		let tempTeams = form.getFieldsValue()?.documents;
		let currentTeam: any = tempTeams[i];
		currentTeam = { ...currentTeam, file: e?.url }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ documents: tempTeams })
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateReferee({ ...values, id: layout?.data?.id, media: imageData }));
			// console.log({ ...values, media: imageData});

		}
		else {
			dispatch(createReferee({ ...values, submittedBy: getUserId(), media: imageData }));

		}
	};
	useEffect(() => {
		// if (layout?.data) setImageUrl(layout?.data?.media?.map((d: any, i: number) => GetUploadUrl([d])))
		// if (layout?.data) setImageData(layout?.data?.media);
		form.setFieldsValue({ ...layout?.data, date: dayjs(layout?.data?.date) })
		setParticipants(layout?.data?.participants || [])
		setDocuments(layout?.data?.documents || [])
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>{layout?.update ? 'UPDATE' : 'ADD'} REFEREE</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
				className="custom__form"

			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={13}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: 'Name is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Address"
							name="address"
							rules={[{ required: true, message: 'Address is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Email"
							name="email"
						// rules={[{ required: true, message: 'Address is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Phone"
							name="phone"
						// rules={[{ required: true, message: 'Address is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Certificate Level (Highest)"
							name="highestCertificate"
							rules={[{ required: true, message: 'Certificateion is Required!' }]}
						>
							<Select>


								Level 2 match officiating
								Level 3 match officiating
								<Option value="Law of the game( Online)  Pre level 1">Law of the game( Online)  Pre level 1</Option>
								<Option value="Level 1 match officiating">Level 1 match officiating</Option>
								<Option value="Level 2 match officiating">Level 2 match officiating</Option>
								<Option value="Level 3 match officiating">Level 3 match officiating</Option>

							</Select>
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							style={{ marginBottom: 0 }}
							name="profilePicture"
							label="Photo"
						>
							<FileUpload
								// url={documents.length > 0 && documents[index]?.file}
								onUpload={(e: any) => form.setFieldsValue({ profilePicture: e })} />
							<Input hidden />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Divider />
						<Typography.Title level={5}>Documents</Typography.Title>

						<Form.List name="documents">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field: any, index: number) => (
										<Row key={field.key} align="middle" gutter={[20, 20]} style={{ marginBottom: 20 }}>

											<Col lg={16}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}
													label={index > 0 ? false : 'Title'}
													name={[field.name, 'title']}
													fieldKey={[field.fieldKey, 'title']}
													rules={[{ required: true, message: 'Required' }]}
												>
													<Input />
												</Form.Item>
											</Col>

											<Col lg={8}>
												<Space>
													<Form.Item
														{...field}
														style={{ marginBottom: 0 }}

														label={index > 0 ? false : 'Document'}
														name={[field.name, 'file']}
														fieldKey={[field.fieldKey, 'file']}
													>
														<FileUpload type='file'
															url={documents.length > 0 && documents[index]?.file}
															onUpload={(e: any) => handleOnUploadDocument(e, index)} />
														<Input hidden />
													</Form.Item>

													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space>
											</Col>
										</Row>
									))}

									<Row>
										<Col lg={17}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Add Documents
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
						</Form.List>
					</Col>
					<Col lg={24}>
						<Form.Item>
							<Button loading={schools?.creating || schools?.updating} type="primary" htmlType="submit">
								{layout?.update ? 'Update' : 'SUBMIT'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default RefereeForm;
