import { Descriptions, Divider, Space, Typography } from "antd";
import Tag from "antd/es/tag";
import DataTable from "Components/Table/DataTable";
import dayjs from "dayjs";
import { TableColumn } from "react-data-table-component";
export const WorkshopDetails = (props: any) => {
    const { data } = props;

    const teamColumn: TableColumn[] = [
        {
            name: "Name",
            selector: "name",
            width: '200px',
            style: {
                fontWeight: 'bold',
                color: 'red'
            }
        },
        {
            name: "Details",
            selector: "file",
            cell: (row: any) => <Space direction="vertical">
                {row?.file?.map((file: any) => {
                    return <Typography.Link target='_blank' style={{ width: '100%' }} ellipsis download={true} href={file}>{file?.split('/').slice(-1).pop()}</Typography.Link>
                })}
            </Space>
        }
    ]


    const matchColumn: TableColumn[] = [
        {
            name: "#",
            cell: (row: any, index: number) => <Typography.Text>{index + 1}.</Typography.Text>,
            width: '70px'
        },
        {
            name: "Title",
            selector: "title",
            width: '180px',
        },
        {
            name: "File",
            selector: "file",
            cell: (row: any) => <Typography.Link target='_blank' style={{ width: '200px' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
        }
    ]
    return (
        <div>
            <Typography.Title level={5}>WORKSHOP DETAILS</Typography.Title>
            <Divider />
            <Typography.Title level={3}>{data?.title}</Typography.Title>
            <Typography.Paragraph>Location: {data?.location}</Typography.Paragraph>
            <Typography.Paragraph type="secondary">{data?.description}</Typography.Paragraph>

            <Space size={5}>
                <Tag color="red">{dayjs(data?.date).format('LL')}</Tag>
                <Typography.Text>to</Typography.Text>
                <Tag color="red">{dayjs(data?.endDate).format('LL')}</Tag>
            </Space>
            <Divider />
            <Descriptions title="Organizer">
                {data?.organizer && Object.keys(data?.organizer).map((d: any, i: number) => {
                    return <Descriptions.Item label={d}>{data?.organizer[d]}</Descriptions.Item>
                })}
            </Descriptions>
            <Divider />

            <Descriptions title="Trainer">
                {data?.trainer && Object.keys(data?.trainer).map((d: any, i: number) => {
                    return <Descriptions.Item label={d}>{data?.trainer[d]}</Descriptions.Item>
                })}
            </Descriptions>
            <Divider />

            <Typography.Title level={4}>Participants</Typography.Title>
            <DataTable
                data={data?.participants}
                columns={teamColumn}
                noHeader
            />

            <Divider />

            <Typography.Title level={4}>Documents</Typography.Title>
            <DataTable
                data={data?.documents}
                columns={matchColumn}
                noHeader
            />

        </div >
    )
}

export default WorkshopDetails;
