import { EnvironmentOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { Option } from 'antd/lib/mentions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchUsers } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { createSchool, updateSchool } from 'Redux/SchoolRedux/SchoolActions';
import { useForm } from 'antd/lib/form/Form';

export const SchoolForm = (props: any) => {
	const dispatch = useDispatch();
	const schools = useSelector((state: any) => state.SchoolReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateSchool({ ...values, sportLists: values?.sportLists.join(' ,'), id: layout?.data?.id }));

		}
		else {
			dispatch(createSchool({ ...values, sportLists: values?.sportLists.join(' ,') }));

		}
	};

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};

	useEffect(() => {
		dispatch(fetchUsers({ _where: qs.stringify(complexQuery) }));
	}, []);

	useEffect(() => {
		form.setFieldsValue({ ...layout?.data, sportLists: layout?.data?.sportLists?.split(',') })
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>ADD SCHOOL</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={24}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: 'Name is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Address"
							name="address"
							rules={[{ required: true, message: 'Address is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[{ required: true, message: 'Confirm Password is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Email"
							name="email"
							rules={[{ required: true, message: 'Email is Required!' }]}
						>
							<Input type="email" prefix={'@'} />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Website"
							name="website"
							rules={[{ required: true, message: 'Website is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Max. Level"
							name="classLevels"
							rules={[{ required: true, message: 'Class Level is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="Total Students"
							name="totalStudents"
							rules={[{ required: true, message: 'Students is Required!' }]}
						>
							<Input type="number" prefix={<UserOutlined />} />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Total Rugby Players"
							name="totalRugbyPlayers"
						// rules={[{ required: true, message: 'Students is Required!' }]}
						>
							<Input type="number" prefix={<UserOutlined />} />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Principal"
							name="principalName"
							rules={[{ required: true, message: 'Principal Name is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="ECA Incharge"
							name="inchargeECA"
							rules={[{ required: true, message: 'Incharge is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Sports Teacher"
							name="sportsTeacher"
							rules={[{ required: true, message: 'Sports Teacher is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Sports"
							name="sportLists"
							rules={[{ required: true, message: 'Class Level is Required!' }]}
						>
							<Select mode="tags" />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Ground Situation"
							name="groundSituation"
							rules={[{ required: true, message: 'Class Level is Required!' }]}
						>
							<Select>
								<Option value="No Ground">No Ground</Option>
								<Option value="Concrete Ground">Concrete Ground</Option>
								<Option value="Ground On Lease">Ground On Lease</Option>
								<Option value="Rough Surface">Rough Surface</Option>
								<Option value="Grass Ground">Grass Ground</Option>
							</Select>
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="Ground Size"
							name="groundSize"
						// rules={[{ required: true, message: 'Class Level is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					{/* <Col lg={12}>
						<Form.Item
							label="Role"
							name="role"
							rules={[{ required: true, message: 'Organization is Required!' }]}
						>
							<Select allowClear>
								{Roles?.map((role: any, index: number) => {
									return (
										<Option value={role.id}>{role.name}</Option>
									)
								})}
							</Select>
						</Form.Item>
					</Col> */}
					{/* <Col lg={12}>
						<Form.Item
							label="Parent"
							name="parent"
							// rules={[{ required: true, message: 'Organization is Required!' }]}
						>
							<Select allowClear>
								{users?.list && users?.list.map((user: any, index: number) => {
									return (
										<Option value={user?.id}>{ user?.fullName}</Option>

									)
								})}
							</Select>
						</Form.Item>
					</Col> */}
					{/* <Col lg={24}>
						<Form.Item
							label="Address"
							name="address"
							rules={[ { required: true, message: 'Address is Required!' } ]}
						>
							<Input type="number" prefix={<EnvironmentOutlined />} />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Remarks"
							name="remarks"
							// rules={[{ required: true, message: "Address is Required!" }]}
						>
							<TextArea />
						</Form.Item>
					</Col> */}
					{/* 
					<Col lg={12}>
						<Form.Item label="" name="forcePassword">
							<Checkbox>Forcefully change password</Checkbox>
						</Form.Item>
					</Col> */}

					<Col lg={24}>
						<Form.Item>
							<Button loading={schools?.creating || schools?.updating} type="primary" htmlType="submit">
								{layout?.update ? 'Update' : 'SUBMIT'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default SchoolForm;
