import { Reducer } from 'react';
import {
	CreateFormParams,
	CREATE_FORM,
	CREATE_FORM_FAILURE,
	CREATE_FORM_SUCCESS,
	FETCH_FORMS,
	FETCH_FORMS_FAILURE,
	FETCH_FORMS_SUCCESS,
	FETCH_FORM_DETAILS,
	FETCH_FORM_DETAILS_FAILURE,
	FETCH_FORM_DETAILS_SUCCESS,
	REMOVE_FORM,
	REMOVE_FORM_FAILURE,
	REMOVE_FORM_SUCCESS,
	UPDATE_FORM,
	UPDATE_FORM_FAILURE,
	UPDATE_FORM_SUCCESS,
	FormDetailsParams
} from './FormTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const FormReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Forms
		case FETCH_FORMS:
			return { ...state, loading: true };
		case FETCH_FORMS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_FORMS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Form Details
		case FETCH_FORM_DETAILS:
			return { ...state, detailing: true };
		case FETCH_FORM_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_FORM_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Form
		case CREATE_FORM:
			return { ...state, creating: true };
		case CREATE_FORM_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_FORM_FAILURE:
			return { ...state, creating: false };

		//update Form
		case UPDATE_FORM:
			return { ...state, updating: true };
		case UPDATE_FORM_SUCCESS:
			const tempList = state.list.filter((d: CreateFormParams) => d.id !== action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_FORM_FAILURE:
			return { ...state, updating: false };

		//remove Form
		case REMOVE_FORM:
			return { ...state, deleting: true };
		case REMOVE_FORM_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: FormDetailsParams) => d.id !== action.payload.id)
			};
		case REMOVE_FORM_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
