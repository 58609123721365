import API from 'Api/Api';

//get Analysis
export const takeAgeVsProvince = (data: any) => {
	return API({
		url: '/charts/agegroup',
		method: 'GET'
	});
};

//get Analysis
export const takeGenderVsProvinceVsPlayer = (data: any) => {
	return API({
		url: '/charts/playervsgendervsprovince',
		method: 'GET'
	});
};

//get Analysis
export const takeProvinceVsPlayer = (data: any) => {
	return API({
		url: '/charts/playervsprovince',
		method: 'GET'
	});
};

//get Analysis
export const takeProvinceVsTraining = (data: any) => {
	return API({
		url: '/charts/provincevstraining',
		method: 'GET'
	});
};
