import { Reducer } from 'react';
import {
	CreateAttendanceParams,
	CREATE_ATTENDANCE,
	CREATE_ATTENDANCE_FAILURE,
	CREATE_ATTENDANCE_SUCCESS,
	FETCH_ATTENDANCES,
	FETCH_ATTENDANCES_FAILURE,
	FETCH_ATTENDANCES_SUCCESS,
	FETCH_ATTENDANCE_DETAILS,
	FETCH_ATTENDANCE_DETAILS_FAILURE,
	FETCH_ATTENDANCE_DETAILS_SUCCESS,
	REMOVE_ATTENDANCE,
	REMOVE_ATTENDANCE_FAILURE,
	REMOVE_ATTENDANCE_SUCCESS,
	UPDATE_ATTENDANCE,
	UPDATE_ATTENDANCE_FAILURE,
	UPDATE_ATTENDANCE_SUCCESS,
	AttendanceDetailsParams
} from './AttendanceTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const AttendanceReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Attendances
		case FETCH_ATTENDANCES:
			return { ...state, loading: true };
		case FETCH_ATTENDANCES_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_ATTENDANCES_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Attendance Details
		case FETCH_ATTENDANCE_DETAILS:
			return { ...state, detailing: true };
		case FETCH_ATTENDANCE_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_ATTENDANCE_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Attendance
		case CREATE_ATTENDANCE:
			return { ...state, creating: true, created: false };
		case CREATE_ATTENDANCE_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false, created: true };
		case CREATE_ATTENDANCE_FAILURE:
			return { ...state, creating: false };

		//update Attendance
		case UPDATE_ATTENDANCE:
			return { ...state, updating: true };
		case UPDATE_ATTENDANCE_SUCCESS:
			const tempList = state.list.filter((d: CreateAttendanceParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_ATTENDANCE_FAILURE:
			return { ...state, updating: false };

		//remove Attendance
		case REMOVE_ATTENDANCE:
			return { ...state, deleting: true };
		case REMOVE_ATTENDANCE_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: AttendanceDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_ATTENDANCE_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
