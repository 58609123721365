import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { AccessControl } from 'Utils/AccessControl';
import UserList from './UserList';

export const Users = (props: any) => {
	const dispatch = useDispatch();
	return (
		<Row gutter={[10, 10]}>
			<Col lg={20}>
				{AccessControl('user')?.list && <UserList />}
			</Col>
		</Row>
	);
};

export default Users;
