import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createGirreportFailure,
	createGirreportSuccess,
	deleteGirreportFailure,
	deleteGirreportSuccess,
	fetchGirreportFailure,
	fetchGirreports,
	fetchGirreportsFailure,
	fetchGirreportsSuccess,
	fetchGirreportSuccess,
	updateGirreportFailure,
	updateGirreportSuccess
} from './GirreportActions';
import { cutGirreport, makeGirreport, modifyGirreport, takeGirreport, takeGirreports } from './GirreportApis';
import {
	CreateGirreportParams,
	FETCH_GIRREPORTS,
	UPDATE_GIRREPORT,
	GirreportDetailsParams,
	GirreportFetchParams,
	FETCH_GIRREPORT_DETAILS,
	CREATE_GIRREPORT,
	REMOVE_GIRREPORT
} from './GirreportTypes';

//get girreports
const getGirreportsAsync = async (data: GirreportFetchParams) => await takeGirreports(data);

function* getGirreports(params: any) {
	const { payload } = params;
	try {
		const girreports = yield call(getGirreportsAsync, payload);
		if (girreports) {
			yield put(fetchGirreportsSuccess(girreports.data));
		} else {
			yield put(fetchGirreportsFailure('Could not get list of girreports'));
			notification.error({
				message: 'Get Girreport List',
				description: 'Could not get list of girreports'
			});
		}
	} catch (error) {
		yield put(fetchGirreportsFailure('Network Error while getting Girreports'));
		notification.error({
			message: 'Get Girreport List',
			description: 'Network Error while getting Girreports'
		});
	}
}

function* watchGetGirreports() {
	yield takeLatest(FETCH_GIRREPORTS, getGirreports);
}

//get girreport details
const getGirreportAsync = async (data: GirreportDetailsParams) => await takeGirreport(data);

function* getGirreport(params: any) {
	const { payload } = params;
	try {
		const girreports = yield call(getGirreportAsync, payload);
		if (girreports) {
			yield put(fetchGirreportSuccess(girreports.data));
		} else {
			yield put(fetchGirreportFailure('Could not get list of girreports'));
			notification.error({
				message: 'Get Girreport',
				description: 'Could not get list of girreports'
			});
		}
	} catch (error) {
		yield put(fetchGirreportFailure('Network Error while getting Girreports'));
		notification.error({
			message: 'Get Girreport',
			description: 'Network Error while getting Girreports'
		});
	}
}

function* watchGetGirreport() {
	yield takeLatest(FETCH_GIRREPORT_DETAILS, getGirreport);
}

//create Girreport
const generateGirreportAsync = async (data: CreateGirreportParams) => await makeGirreport(data);

function* generateGirreport(params: any) {
	const { payload } = params;
	try {
		const girreports = yield call(generateGirreportAsync, payload);
		if (girreports) {
			yield put(createGirreportSuccess(girreports.data));
			yield put(fetchGirreports({}));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Create Girreport',
				description: 'Girreport created successfully'
			});
		} else {
			const message = 'Could not create Girreport';
			yield put(createGirreportFailure(message));
			notification.error({
				message: 'Create Girreport',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Girreport';
		yield put(createGirreportFailure(message));
		notification.error({
			message: 'Create Girreport',
			description: message
		});
	}
}

function* watchGenerateGirreport() {
	yield takeLatest(CREATE_GIRREPORT, generateGirreport);
}

//update Girreport
const amendGirreportAsync = async (data: CreateGirreportParams) => await modifyGirreport(data);

function* amendGirreport(params: any) {
	const { payload } = params;
	try {
		const girreports = yield call(amendGirreportAsync, payload);
		if (girreports) {
			yield put(updateGirreportSuccess(girreports.data));
			notification.success({
				message: 'Update Girreport',
				description: 'Girreport updated successfully'
			});
		} else {
			const message = 'Could not Update Girreport';
			yield put(updateGirreportFailure(message));
			notification.error({
				message: 'Update Girreport',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Girreport';
		yield put(updateGirreportFailure(message));
		notification.error({
			message: 'Update Girreport',
			description: message
		});
	}
}

function* watchAmendGirreport() {
	yield takeLatest(UPDATE_GIRREPORT, amendGirreport);
}

//Delete Girreport
const removeGirreportAsync = async (data: GirreportDetailsParams) => await cutGirreport(data);

function* removeGirreport(params: any) {
	const { payload } = params;
	try {
		const girreports = yield call(removeGirreportAsync, payload);
		if (girreports) {
			yield put(deleteGirreportSuccess(girreports.data));
			notification.success({
				message: 'Girreport Deleted',
				description: 'Girreport deleted successfully'
			});
		} else {
			const message = 'Could not delete Girreport';
			yield put(deleteGirreportFailure(message));
			notification.error({
				message: 'Girreport Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Girreport';
		yield put(deleteGirreportFailure(message));
		notification.error({
			message: 'Girreport Deleted',
			description: message
		});
	}
}

function* watchRemoveGirreport() {
	yield takeLatest(REMOVE_GIRREPORT, removeGirreport);
}

export default function* GirreportSagas() {
	yield all([
		fork(watchGetGirreports),
		fork(watchGetGirreport),
		fork(watchGenerateGirreport),
		fork(watchAmendGirreport),
		fork(watchRemoveGirreport)
	]);
}
