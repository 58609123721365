import MainContainer from 'Container/MainContainer/MainContainer';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import PlayerDetails from 'Views/Players/PlayerDetails';
import Players from 'Views/Players/Players';
export const PlayerRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer>
      <Switch>
        <Route path={`${props.match?.path}/list`} exact component={Players} />
        <Route path={`${props.match?.path}/:schoolId`} component={PlayerDetails} />
        <Route component={Players} />
      </Switch>
    </MainContainer>
  )
}

export default PlayerRoute;