import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Typography } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import { fetchForms } from "Redux/FormRedux/FormActions";

export const FormList = (props: any) => {
    // const { data } = props;
    const data = useSelector((state: any) => state.FormReducer)
    const dataList = data?.list || [];
    const history = useHistory();

    const dispatch = useDispatch();

    const [filterText, setFilterText] = React.useState('');
    const [perPageData, setPerPageData] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = dataList?.filter(
        (item: any) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState({});


    const subHeaderComponentMemo = React.useMemo(
        () => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <TableHeader
                    onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
                    onClear={handleClear}
                    // filterText={filterText}
                    title="Form List"
                    module="forms"
                />
            );
        },
        [filterText, resetPaginationToggle]
    );

    //Row and Per page change
    const handlePerRowsChange = (perPage: number, page: number) => {
        // dispatch(fetchUserList({ page: page, limit: perPage }));
        setPerPageData(perPage);
    };
    const handlePageChange = (page: number) => {
        // dispatch(fetchUserList({ page: page, limit: perPageData }));

    };

    const onRowClicked = (values: any) => {
        setShowDetails(true);
        setDetails(values)
    }

    const onEditHandler = (values: any) => {
        dispatch(setLayout({ form: 'forms', data: values, update: true }));
    }

    const onDeleteHandler = (id: number) => {
        // dispatch(deleteAsset(id))
    }
    const onAddHandler = (data: any) => {
        dispatch(setLayout({ form: 'reporting', data: data, update: false }))
        // dispatch(deleteAsset(id))
    }

    const assetColumns: TableColumn[] = [
        {
            name: 'Name',
            selector: 'name',
            cell: (row: any) => <Typography.Text>{row.name}</Typography.Text>

        },
        {
            name: 'Description',
            selector: 'description',
            cell: (row: any) => <Typography.Text style={{ width: 250 }} ellipsis> {row?.description}</Typography.Text >

        },
        {
            name: 'Created By',
            selector: 'createdBy',
            cell: (row: any) => <Typography.Link>{row?.created_by?.name}</Typography.Link>
        },
        {
            name: 'Last Updated',
            selector: 'updatedAt',
            cell: (row: any) => <Typography.Text disabled>{dayjs(row?.updatedAt).format('LL')}</Typography.Text>
        },
        {
            name: 'Action',
            selector: '',
            cell: (row: any) => <TableActions noDelete addData onReport={() => history.push(`/reports/${row.formId}`)} onAdd={() => onAddHandler(row)} onEdit={() => onEditHandler(row)} onDelete={() => onDeleteHandler(row.id)} />
        }
    ];

    useEffect(() => {
        dispatch(fetchForms({}))
    }, [])
    return (
        <MyContent>
            <DataTable
                title={subHeaderComponentMemo}
                columns={assetColumns}
                data={filteredItems}
                progressPending={data.loading}
                // noHeader
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                onRowClicked={onRowClicked}

            />
            {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
            {/* <DetailsView  data={details} show={showDetails} module="asset" onHide={() =>setShowDetails(false)}/> */}

        </MyContent>
    )
}

export default FormList;