import API from 'Api/Api';
import { CreateReportParams, ReportDetailsParams, ReportFetchParams } from './ReportTypes';

//get Report
export const takeReports = (data: ReportFetchParams) => {
	return API({
		url: '/report-data',
		method: 'GET',
		params: data
	});
};

//get Report Details
export const takeReport = (data: ReportDetailsParams) => {
	return API({
		url: '/report-data',
		method: 'GET',
		params: data
	});
};

//create Report Details
export const makeReport = (data: CreateReportParams) => {
	return API({
		url: '/report-data',
		method: 'POST',
		params: data
	});
};

//modify Report
export const modifyReport = (data: CreateReportParams) => {
	return API({
		url: '/report-data',
		method: 'PUT',
		params: data
	});
};

//delete Report
export const cutReport = (data: ReportDetailsParams) => {
	return API({
		url: '/report-data',
		method: 'DELETE',
		params: data
	});
};
