import { Col, Row } from 'antd';
import WorkshopList from './WorkshopList';

export const Workshop = (props: any) => {

	return (
		<Row gutter={[20, 20]}>
			<Col lg={24}>
				<WorkshopList />
			</Col>
		</Row>
	);
};

export default Workshop;
