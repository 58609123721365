import {
	CreateFormParams,
	CREATE_FORM,
	CREATE_FORM_FAILURE,
	CREATE_FORM_SUCCESS,
	FETCH_FORMS,
	FETCH_FORMS_FAILURE,
	FETCH_FORMS_SUCCESS,
	FETCH_FORM_DETAILS,
	FETCH_FORM_DETAILS_FAILURE,
	FETCH_FORM_DETAILS_SUCCESS,
	REMOVE_FORM,
	REMOVE_FORM_FAILURE,
	REMOVE_FORM_SUCCESS,
	UPDATE_FORM,
	UPDATE_FORM_FAILURE,
	UPDATE_FORM_SUCCESS,
	FormDetailsParams,
	FormFetchParams
} from './FormTypes';

//fetch Form List
export const fetchForms = (params: FormFetchParams) => ({
	type: FETCH_FORMS,
	payload: params
});

export const fetchFormsSuccess = (response: any) => ({
	type: FETCH_FORMS_SUCCESS,
	payload: response
});

export const fetchFormsFailure = (error: any) => ({
	type: FETCH_FORMS_FAILURE,
	payload: error
});

//fetch Form Details
export const fetchForm = (params: FormDetailsParams) => ({
	type: FETCH_FORM_DETAILS,
	payload: params
});

export const fetchFormSuccess = (response: any) => ({
	type: FETCH_FORM_DETAILS_SUCCESS,
	payload: response
});

export const fetchFormFailure = (error: any) => ({
	type: FETCH_FORM_DETAILS_FAILURE,
	payload: error
});

//create Form
export const createForm = (params: CreateFormParams) => ({
	type: CREATE_FORM,
	payload: params
});

export const createFormSuccess = (response: CreateFormParams) => ({
	type: CREATE_FORM_SUCCESS,
	payload: response
});
export const createFormFailure = (error: any) => ({
	type: CREATE_FORM_FAILURE,
	payload: error
});

//update Form
export const updateForm = (params: CreateFormParams) => ({
	type: UPDATE_FORM,
	payload: params
});

export const updateFormSuccess = (response: CreateFormParams) => ({
	type: UPDATE_FORM_SUCCESS,
	payload: response
});

export const updateFormFailure = (error: any) => ({
	type: UPDATE_FORM_FAILURE,
	payload: error
});

//delete Form
export const deleteForm = (params: FormDetailsParams) => ({
	type: REMOVE_FORM,
	payload: params
});
export const deleteFormSuccess = (response: FormDetailsParams) => ({
	type: REMOVE_FORM_SUCCESS,
	payload: response
});
export const deleteFormFailure = (error: any) => ({
	type: REMOVE_FORM_FAILURE,
	payload: error
});
