import { Reducer } from 'react';
import {
	CreateTournamentParams,
	CREATE_TOURNAMENT,
	CREATE_TOURNAMENT_FAILURE,
	CREATE_TOURNAMENT_SUCCESS,
	FETCH_TOURNAMENTS,
	FETCH_TOURNAMENTS_FAILURE,
	FETCH_TOURNAMENTS_SUCCESS,
	FETCH_TOURNAMENT_DETAILS,
	FETCH_TOURNAMENT_DETAILS_FAILURE,
	FETCH_TOURNAMENT_DETAILS_SUCCESS,
	REMOVE_TOURNAMENT,
	REMOVE_TOURNAMENT_FAILURE,
	REMOVE_TOURNAMENT_SUCCESS,
	UPDATE_TOURNAMENT,
	UPDATE_TOURNAMENT_FAILURE,
	UPDATE_TOURNAMENT_SUCCESS,
	TournamentDetailsParams
} from './TournamentTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const TournamentReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Tournaments
		case FETCH_TOURNAMENTS:
			return { ...state, loading: true };
		case FETCH_TOURNAMENTS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_TOURNAMENTS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Tournament Details
		case FETCH_TOURNAMENT_DETAILS:
			return { ...state, detailing: true };
		case FETCH_TOURNAMENT_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_TOURNAMENT_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Tournament
		case CREATE_TOURNAMENT:
			return { ...state, creating: true };
		case CREATE_TOURNAMENT_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_TOURNAMENT_FAILURE:
			return { ...state, creating: false };

		//update Tournament
		case UPDATE_TOURNAMENT:
			return { ...state, updating: true };
		case UPDATE_TOURNAMENT_SUCCESS:
			const tempList = state.list.filter((d: CreateTournamentParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_TOURNAMENT_FAILURE:
			return { ...state, updating: false };

		//remove Tournament
		case REMOVE_TOURNAMENT:
			return { ...state, deleting: true };
		case REMOVE_TOURNAMENT_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: TournamentDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_TOURNAMENT_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
