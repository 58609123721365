import { EnvironmentOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchUsers } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { createSchool, fetchSchools } from 'Redux/SchoolRedux/SchoolActions';
import { createPlayer } from 'Redux/PlayerRedux/PlayerActions';
import { getUserId } from 'Api/Api';
import { createEvent, updateEvent } from 'Redux/EventRedux/EventActions';
import { SelfCoaches } from 'Utils/ComplexQueries';
import { AccessControl } from 'Utils/AccessControl';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
const { Option } = Select;

export const EventForm = (props: any) => {
	const dispatch = useDispatch();
	const events = useSelector((state: any) => state.EventReducer);
	const user = useSelector((state: any) => state.UserReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const school = useSelector((state: any) => state.SchoolReducer);
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateEvent({
				...values,
				coaches: values?.coach ? [values.coach] : [getUserId()],
				id: layout?.data?.id
			}));
		}
		else {
			dispatch(createEvent({
				...values,
				coaches: values?.coach ? [values.coach] : [getUserId()]
			}));
		}
	};

	const [form] = useForm();

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};

	useEffect(() => {
		if (layout?.update && layout?.data) {
			form.setFieldsValue({
				...layout?.data, startDate: dayjs(layout?.data?.startDate), endDate: dayjs(layout?.data?.endDate), coach: layout?.data?.coaches[0]?.id
			})
		}
	}, [layout?.data])

	useEffect(() => {
		if (AccessControl('user').list) {
			dispatch(fetchUsers({ ...SelfCoaches('') }));
		}
		dispatch(fetchSchools({}));

	}, []);
	return (
		<Content>
			<Typography.Title level={4}>{layout?.update ? 'UDATE' : 'ADD'} TRAINING</Typography.Title>
			<Form
				// {...layout}
				layout="vertical"
				form={form}
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 0]}>
					<Col lg={24}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: 'Name is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Description"
							name="description"
							rules={[{ required: true, message: 'Description is Required!' }]}
						>
							<TextArea />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Total Sessions"
							name="totalSessions"
							rules={[{ required: true, message: 'Number of session is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Coach"
							name="coach"
						// rules={[ { required: true, message: 'End Date is Required!' } ]}
						>
							<Select>
								{user && user?.list?.map((d: any, i: number) => {
									return <Option value={d?.id}>{d?.fullName}</Option>
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col lg={8}>
						<Form.Item
							label="Start Date"
							name="startDate"
							rules={[{ required: true, message: 'Start Date is Required!' }]}
						>
							<DatePicker />
						</Form.Item>
					</Col>
					<Col lg={8}>
						<Form.Item
							label="End Date"
							name="endDate"
							rules={[{ required: true, message: 'End Date is Required!' }]}
						>
							<DatePicker />
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="School"
							name="school"
						// rules={[ { required: true, message: 'End Date is Required!' } ]}
						>
							<Select>
								{school && school?.list?.map((d: any, i: number) => {
									return <Option value={d?.id}>{d?.name}</Option>
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Club"
							name="club"
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="District"
							name="district"
						>
							<Input />
						</Form.Item>
					</Col>

					<Col lg={24}>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={events.creating || events.updating}>
								SUBMIT
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default EventForm;
