import { Col, Row } from 'antd';
import { getUserId } from 'Api/Api';
import MyContent from 'Components/Content/Content';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPlayers } from 'Redux/PlayerRedux/PlayerActions';
import { PlayerList } from './PlayerList';
import { IsSelfOnly } from '../../Utils/AccessControl';
import qs from 'qs';
import { SelfPlayers } from 'Utils/ComplexQueries';
export const Players = (props: any) => {
	const dispatch = useDispatch();

	return (
		<Row gutter={[10, 10]}>
			<Col lg={24}>
				<PlayerList />
			</Col>
			{/* <Col lg={6}>
				<MyContent>
					<div>Player List</div>
				</MyContent>
			</Col> */}
		</Row>
	);
};

export default Players;
