import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  Divider,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import FileUpload from "Components/FileUpload/FileUpload";
import { getUserId } from "Api/Api";
import {
  createTournament,
  updateTournament,
} from "Redux/TournamentRedux/TournamentActions";
import dayjs from "dayjs";

export const TournamentForm = (props: any) => {
  const dispatch = useDispatch();
  const tournament = useSelector((state: any) => state.TournamentReducer);
  const layout = useSelector((state: any) => state.LayoutReducer);

  const [teams, setTeams] = useState<any>([]);

  const handleOnUpload = (e: any, i: any, fileIndex: number) => {
    let tempTeams = form.getFieldsValue()?.teams;
    let currentTeam: any = tempTeams[i];
    let files = currentTeam?.file || [];
    if (fileIndex === 100) {
      files.push(e?.url);
    } else {
      files[fileIndex] = e?.url;
    }
    currentTeam = { ...currentTeam, file: files };
    tempTeams[i] = currentTeam;
    form.setFieldsValue({ teams: tempTeams });
    // form.setFieldsValue({ image: [...form.getFieldValue('media'),e]})
  };

  const [form] = useForm();
  const handleSubmit = (values: any) => {
    if (layout?.update) {
      console.log(values);
      dispatch(updateTournament({ ...values, id: layout?.data?.id }));
      // console.log({ ...values, media: imageData});
    } else {
      dispatch(createTournament({ ...values, submittedBy: getUserId() }));
    }
  };

  const printFrom = (props: any) => {
    // console.log(form.getFieldsValue())
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const complexQuery = {
    _where: {
      _or: [
        [{ "role.id_eq": "6076ad8e4fb3af290d64a746" }], // implicit AND
        // [{ category: 'province_associations' }], // implicit AND
      ],
    },
  };

  useEffect(() => {
    // dispatch(fetchUsers({ _where: qs.stringify(complexQuery) }));
  }, []);

  useEffect(() => {
    // if (layout?.data) setImageUrl(layout?.data?.media?.map((d: any, i: number) => GetUploadUrl([d])))
    if (layout?.data) setTeams(layout?.data?.teams);
    form.setFieldsValue({
      ...layout?.data,
      startDate: dayjs(layout?.data?.startDate),
      endDate: dayjs(layout?.data?.endDate),
    });
  }, [layout?.data]);
  return (
    <Content>
      <Typography.Title level={4}>
        {layout?.update ? "UPDATE" : "ADD"} TOURNAMENT
      </Typography.Title>
      <Form
        // {...layout}
        form={form}
        layout="vertical"
        className="custom__form"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        onChange={printFrom}
        // onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 20]}>
          <Col lg={13}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, message: "Type is required!" }]}
            >
              <Select>
                <Option value="knockout">Knockout</Option>
                <Option value="league">League</Option>
                <Option value="league_cum_knockout">League-Knockout</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Venue"
              name="location"
              rules={[{ required: true, message: "Venue is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is Required!" }]}
            >
              <TextArea />
            </Form.Item>
          </Col>

          <Col lg={13}>
            <Space size="large">
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Start Date is Required!" }]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "End Date is Required!" }]}
              >
                <DatePicker />
              </Form.Item>
            </Space>
          </Col>
          <Col lg={16}>
            <Divider />
            <Form.Item
              label={
                <Typography.Title level={5}>Organizer Details</Typography.Title>
              }
            >
              <Input.Group>
                <Row gutter={[20, 20]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Organizer"
                      name={["organizer", "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Organizer Name type is required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Email"
                      name={["organizer", "email"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Phone"
                      name={["organizer", "phone"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            <Divider />

            <Form.Item
              label={
                <Typography.Title level={5}>
                  Competition Manager
                </Typography.Title>
              }
            >
              <Input.Group>
                <Row gutter={[20, 20]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Name"
                      name={["cManager", "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Organizer Name type is required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Email"
                      name={["cManager", "email"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Phone"
                      name={["cManager", "phone"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col lg={16}>
            <Divider />

            <Form.Item
              label={
                <Typography.Title level={5}>
                  Technical Co-ordinator
                </Typography.Title>
              }
            >
              <Input.Group>
                <Row gutter={[20, 20]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Name"
                      name={["tCoordinator", "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Organizer Name type is required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Email"
                      name={["tCoordinator", "email"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      label="Phone"
                      name={["tCoordinator", "phone"]}
                      // rules={[{ required: true, message: 'Agenda type is required!' }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col lg={15}>
            <Divider />

            <Typography.Title level={5}>Participant Teams</Typography.Title>
            <Form.List name="teams">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field: any, index: number) => (
                    <Row
                      key={field.key}
                      align="top"
                      gutter={[20, 20]}
                      style={{ marginBottom: 20 }}
                    >
                      <Col lg={24}>
                        <Divider />
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          {...field}
                          label={index > 0 ? false : "Name"}
                          name={[field.name, "name"]}
                          fieldKey={[field.fieldKey, "name"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item shouldUpdate>
                          {() => {
                            const currentFiles =
                              form.getFieldsValue()?.teams[field.name]?.file ||
                              [];
                            console.log(currentFiles);
                            return (
                              <Row align="middle" justify="space-between">
                                <Col flex="auto">
                                  <Form.Item
                                    {...field}
                                    label={index > 0 ? false : "Document"}
                                    name={[field.name, "file"]}
                                    fieldKey={[field.fieldKey, "file"]}
                                  >
                                    <Space direction="vertical">
                                      {currentFiles &&
                                        currentFiles.map(
                                          (d: any, i: number) => {
                                            return (
                                              <FileUpload
                                                type="file"
                                                url={currentFiles[i]}
                                                onUpload={(e: any) =>
                                                  handleOnUpload(e, index, i)
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      <FileUpload
                                        type="file"
                                        onUpload={(e: any) =>
                                          handleOnUpload(e, index, 100)
                                        }
                                      />
                                    </Space>
                                    <Input hidden />
                                  </Form.Item>
                                </Col>

                                {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                                <Col>
                                  <Button
                                    danger
                                    onClick={() => remove(field.name)}
                                  >
                                    Remove Team
                                  </Button>
                                </Col>
                              </Row>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Team
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col lg={24}>
            <Form.Item>
              <Button
                loading={tournament?.creating || tournament?.updating}
                type="primary"
                htmlType="submit"
              >
                {layout?.update ? "Update" : "SUBMIT"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default TournamentForm;
