import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createTournamentFailure,
	createTournamentSuccess,
	deleteTournamentFailure,
	deleteTournamentSuccess,
	fetchTournamentFailure,
	fetchTournamentsFailure,
	fetchTournamentsSuccess,
	fetchTournamentSuccess,
	updateTournamentFailure,
	updateTournamentSuccess
} from './TournamentActions';
import { cutTournament, makeTournament, modifyTournament, takeTournament, takeTournaments } from './TournamentApis';
import {
	CreateTournamentParams,
	FETCH_TOURNAMENTS,
	UPDATE_TOURNAMENT,
	TournamentDetailsParams,
	TournamentFetchParams,
	FETCH_TOURNAMENT_DETAILS,
	CREATE_TOURNAMENT,
	REMOVE_TOURNAMENT
} from './TournamentTypes';

//get tournaments
const getTournamentsAsync = async (data: TournamentFetchParams) => await takeTournaments(data);

function* getTournaments(params: any) {
	const { payload } = params;
	try {
		const tournaments: AxiosResponse = yield call(getTournamentsAsync, payload);
		if (tournaments) {
			yield put(fetchTournamentsSuccess(tournaments.data));
		} else {
			yield put(fetchTournamentsFailure('Could not get list of tournaments'));
			notification.error({
				message: 'Get Tournament List',
				description: 'Could not get list of tournaments'
			});
		}
	} catch (error) {
		yield put(fetchTournamentsFailure('Network Error while getting Tournaments'));
		notification.error({
			message: 'Get Tournament List',
			description: 'Network Error while getting Tournaments'
		});
	}
}

function* watchGetTournaments() {
	yield takeLatest(FETCH_TOURNAMENTS, getTournaments);
}

//get tournament details
const getTournamentAsync = async (data: TournamentDetailsParams) => await takeTournament(data);

function* getTournament(params: any) {
	const { payload } = params;
	try {
		const tournaments = yield call(getTournamentAsync, payload);
		if (tournaments) {
			yield put(fetchTournamentSuccess(tournaments.data));
		} else {
			yield put(fetchTournamentFailure('Could not get list of tournaments'));
			notification.error({
				message: 'Get Tournament',
				description: 'Could not get list of tournaments'
			});
		}
	} catch (error) {
		yield put(fetchTournamentFailure('Network Error while getting Tournaments'));
		notification.error({
			message: 'Get Tournament',
			description: 'Network Error while getting Tournaments'
		});
	}
}

function* watchGetTournament() {
	yield takeLatest(FETCH_TOURNAMENT_DETAILS, getTournament);
}

//create Tournament
const generateTournamentAsync = async (data: CreateTournamentParams) => await makeTournament(data);

function* generateTournament(params: any) {
	const { payload } = params;
	try {
		const tournaments: AxiosResponse = yield call(generateTournamentAsync, payload);
		if (tournaments) {
			yield put(createTournamentSuccess(tournaments.data));
			yield put(setLayout({ form: false, data: false, update: false }));

			notification.success({
				message: 'Create Tournament',
				description: 'Tournament created successfully'
			});
		} else {
			const message = 'Could not create Tournament';
			yield put(createTournamentFailure(message));
			notification.error({
				message: 'Create Tournament',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Tournament';
		yield put(createTournamentFailure(message));
		notification.error({
			message: 'Create Tournament',
			description: message
		});
	}
}

function* watchGenerateTournament() {
	yield takeLatest(CREATE_TOURNAMENT, generateTournament);
}

//update Tournament
const amendTournamentAsync = async (data: CreateTournamentParams) => await modifyTournament(data);

function* amendTournament(params: any) {
	const { payload } = params;
	try {
		const tournaments: AxiosResponse = yield call(amendTournamentAsync, payload);
		if (tournaments) {
			yield put(updateTournamentSuccess(tournaments.data));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Update Tournament',
				description: 'Tournament updated successfully'
			});
		} else {
			const message = 'Could not Update Tournament';
			yield put(updateTournamentFailure(message));
			notification.error({
				message: 'Update Tournament',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Tournament';
		yield put(updateTournamentFailure(message));
		notification.error({
			message: 'Update Tournament',
			description: message
		});
	}
}

function* watchAmendTournament() {
	yield takeLatest(UPDATE_TOURNAMENT, amendTournament);
}

//Delete Tournament
const removeTournamentAsync = async (data: TournamentDetailsParams) => await cutTournament(data);

function* removeTournament(params: any) {
	const { payload } = params;
	try {
		const tournaments: AxiosResponse = yield call(removeTournamentAsync, payload);
		if (tournaments) {
			yield put(deleteTournamentSuccess(tournaments.data));
			notification.success({
				message: 'Tournament Deleted',
				description: 'Tournament deleted successfully'
			});
		} else {
			const message = 'Could not delete Tournament';
			yield put(deleteTournamentFailure(message));
			notification.error({
				message: 'Tournament Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Tournament';
		yield put(deleteTournamentFailure(message));
		notification.error({
			message: 'Tournament Deleted',
			description: message
		});
	}
}

function* watchRemoveTournament() {
	yield takeLatest(REMOVE_TOURNAMENT, removeTournament);
}

export default function* TournamentSagas() {
	yield all([
		fork(watchGetTournaments),
		fork(watchGetTournament),
		fork(watchGenerateTournament),
		fork(watchAmendTournament),
		fork(watchRemoveTournament)
	]);
}
