import API from 'Api/Api';
import { CreateGirreportParams, GirreportDetailsParams, GirreportFetchParams } from './GirreportTypes';

//get Girreport
export const takeGirreports = (data: GirreportFetchParams) => {
	return API({
		url: '/gir-reports',
		method: 'GET',
		params: data
	});
};

//get Girreport Details
export const takeGirreport = (data: GirreportDetailsParams) => {
	return API({
		url: '/gir-reports',
		method: 'GET',
		params: data
	});
};

//create Girreport Details
export const makeGirreport = (data: CreateGirreportParams) => {
	return API({
		url: '/gir-reports',
		method: 'POST',
		data: data
	});
};

//modify Girreport
export const modifyGirreport = (data: CreateGirreportParams) => {
	return API({
		url: '/gir-reports',
		method: 'PUT',
		params: data
	});
};

//delete Girreport
export const cutGirreport = (data: GirreportDetailsParams) => {
	return API({
		url: '/gir-reports',
		method: 'DELETE',
		params: data
	});
};
