import { LoginProps } from 'Types/Types';
import { AuthApi } from './Api';
export const requestLogin = (data: LoginProps) => {
	return AuthApi({
		method: 'POST',
		data: data,
		url: '/auth/local'
	});
};

export const changePassword = (data: LoginProps) => {
	return AuthApi({
		method: 'POST',
		data: data,
		url: '/auth/local'
	});
};
