
import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import DataTable from "Components/Table/DataTable";
import { Status } from "Components/Status/Status";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import MainContainer from "Container/MainContainer/MainContainer";
import { fetchGirreports } from "Redux/GIRReportRedux/GirreportActions";
import { Typography } from "antd";
import dayjs from "dayjs";
import DetailsView from "Components/DetailsView/DetailsView";

export const GIRReport = () => {
    const list = useSelector((state: any) => state.GirreportReducer);
    const dataList = list?.list || [];
    const [filterText, setFilterText] = React.useState('');
    const [perPageData, setPerPageData] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const dispatch = useDispatch();

    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState<any>(false);


    const subHeaderComponentMemo = React.useMemo(
        () => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <TableHeader
                    onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
                    onClear={handleClear}
                    // filterText={filterText}
                    title="GIR Report"
                    module="gir-report"
                    addLocation
                />
            );
        },
        [filterText, resetPaginationToggle]
    );

    //Row and Per page change
    const handlePerRowsChange = (perPage: number, page: number) => {
        // dispatch(fetchUserList({ page: page, limit: perPage }));
        setPerPageData(perPage);
    };
    const handlePageChange = (page: number) => {
        // dispatch(fetchUserList({ page: page, limit: perPageData }));

    };

    const onRowClicked = (values: any) => {
        // setShowDetails(true);
        setDetails(values)
    }

    const onEditHandler = (values: any) => {
        // dispatch(setLayout({form: 'asset', data: values, update: true}));
    }

    const onDeleteHandler = (id: number) => {
        // dispatch(deleteAsset(id))
    }

    const assetColumns: TableColumn[] = [
        {
            name: 'Event',
            selector: 'event.title',
            sortable: true
        },
        {
            name: 'Date',
            selector: 'eventDate',
            sortable: true,
            cell: (row: any) => <Typography.Text>{dayjs(row.eventDate).format('LL')}</Typography.Text>

        },
        {
            name: 'Boys',
            selector: 'boysParticipant',
        },
        {
            name: 'Girls',
            selector: 'girlsParticipant',
        },
        {
            name: 'Created By',
            selector: 'createdBy.fullName',
            cell: (row: any) => <Typography.Link>{row?.createdBy?.fullName}</Typography.Link>
        },
        // {
        //     name: 'Action',
        //     selector: '',
        //     cell: (row: any) => <TableActions noReport onEdit={() => onEditHandler(row)} onDelete={() => onDeleteHandler(row.id)} />
        // }
    ];

    useEffect(() => {
        dispatch(fetchGirreports({}))
    }, [0])
    return (
        <MainContainer>
            <MyContent>
                <DataTable
                    title={subHeaderComponentMemo}
                    columns={assetColumns}
                    data={list.list}
                    progressPending={list.loading}
                    // noHeader
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    pagination
                    onRowClicked={onRowClicked}

                />
                {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
                <DetailsView data={details} module="asset" onHide={() => setDetails(false)} />

            </MyContent>
        </MainContainer>
    )
}

export default GIRReport;