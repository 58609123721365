import { Reducer } from 'react';
import {
	CreateGirreportParams,
	CREATE_GIRREPORT,
	CREATE_GIRREPORT_FAILURE,
	CREATE_GIRREPORT_SUCCESS,
	FETCH_GIRREPORTS,
	FETCH_GIRREPORTS_FAILURE,
	FETCH_GIRREPORTS_SUCCESS,
	FETCH_GIRREPORT_DETAILS,
	FETCH_GIRREPORT_DETAILS_FAILURE,
	FETCH_GIRREPORT_DETAILS_SUCCESS,
	REMOVE_GIRREPORT,
	REMOVE_GIRREPORT_FAILURE,
	REMOVE_GIRREPORT_SUCCESS,
	UPDATE_GIRREPORT,
	UPDATE_GIRREPORT_FAILURE,
	UPDATE_GIRREPORT_SUCCESS,
	GirreportDetailsParams
} from './GirreportTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const GirreportReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Girreports
		case FETCH_GIRREPORTS:
			return { ...state, loading: true };
		case FETCH_GIRREPORTS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_GIRREPORTS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Girreport Details
		case FETCH_GIRREPORT_DETAILS:
			return { ...state, detailing: true };
		case FETCH_GIRREPORT_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_GIRREPORT_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Girreport
		case CREATE_GIRREPORT:
			return { ...state, creating: true };
		case CREATE_GIRREPORT_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_GIRREPORT_FAILURE:
			return { ...state, creating: false };

		//update Girreport
		case UPDATE_GIRREPORT:
			return { ...state, updating: true };
		case UPDATE_GIRREPORT_SUCCESS:
			const tempList = state.list.filter((d: CreateGirreportParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_GIRREPORT_FAILURE:
			return { ...state, updating: false };

		//remove Girreport
		case REMOVE_GIRREPORT:
			return { ...state, deleting: true };
		case REMOVE_GIRREPORT_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: GirreportDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_GIRREPORT_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
