export const FETCH_REFEREES = 'FETCH_REFEREES';
export const FETCH_REFEREES_SUCCESS = 'FETCH_REFEREES_SUCCESS';
export const FETCH_REFEREES_FAILURE = 'FETCH_REFEREES_FAILURE';

export const FETCH_REFEREE_DETAILS = 'FETCH_REFEREE_DETAILS';
export const FETCH_REFEREE_DETAILS_SUCCESS = 'FETCH_REFEREE_DETAILS_SUCCESS';
export const FETCH_REFEREE_DETAILS_FAILURE = 'FETCH_REFEREE_DETAILS_FAILURE';

export const CREATE_REFEREE = 'CREATE_REFEREE';
export const CREATE_REFEREE_SUCCESS = 'CREATE_REFEREE_SUCCESS';
export const CREATE_REFEREE_FAILURE = 'CREATE_REFEREE_FAILURE';

export const UPDATE_REFEREE = 'UPDATE_REFEREE';
export const UPDATE_REFEREE_SUCCESS = 'UPDATE_REFEREE_SUCCESS';
export const UPDATE_REFEREE_FAILURE = 'UPDATE_REFEREE_FAILURE';

export const REMOVE_REFEREE = 'REMOVE_REFEREE';
export const REMOVE_REFEREE_SUCCESS = 'REMOVE_REFEREE_SUCCESS';
export const REMOVE_REFEREE_FAILURE = 'REMOVE_REFEREE_FAILURE';

export interface RefereeFetchParams {
	limit?: number;
	page?: number;
}
export interface RefereeDetailsParams {
	id?: number;
}
export interface CreateRefereeParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
