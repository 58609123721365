import { Row, Spin } from 'antd';
import './Loading.scss';

export const Loading = (props: any) => {
	const { fullScreen, tip } = props;
	return (
		<Row justify="center" className={`${fullScreen ? 'full-screen' : ''}`} style={{ margin: '40px 0px' }}>
			<Spin tip={tip ? tip : 'Loading Data'} />
		</Row>
	);
};

export default Loading;
