export const FETCH_WORKSHOPS = 'FETCH_WORKSHOPS';
export const FETCH_WORKSHOPS_SUCCESS = 'FETCH_WORKSHOPS_SUCCESS';
export const FETCH_WORKSHOPS_FAILURE = 'FETCH_WORKSHOPS_FAILURE';

export const FETCH_WORKSHOP_DETAILS = 'FETCH_WORKSHOP_DETAILS';
export const FETCH_WORKSHOP_DETAILS_SUCCESS = 'FETCH_WORKSHOP_DETAILS_SUCCESS';
export const FETCH_WORKSHOP_DETAILS_FAILURE = 'FETCH_WORKSHOP_DETAILS_FAILURE';

export const CREATE_WORKSHOP = 'CREATE_WORKSHOP';
export const CREATE_WORKSHOP_SUCCESS = 'CREATE_WORKSHOP_SUCCESS';
export const CREATE_WORKSHOP_FAILURE = 'CREATE_WORKSHOP_FAILURE';

export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const UPDATE_WORKSHOP_SUCCESS = 'UPDATE_WORKSHOP_SUCCESS';
export const UPDATE_WORKSHOP_FAILURE = 'UPDATE_WORKSHOP_FAILURE';

export const REMOVE_WORKSHOP = 'REMOVE_WORKSHOP';
export const REMOVE_WORKSHOP_SUCCESS = 'REMOVE_WORKSHOP_SUCCESS';
export const REMOVE_WORKSHOP_FAILURE = 'REMOVE_WORKSHOP_FAILURE';

export interface WorkshopFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface WorkshopDetailsParams {
	id?: number;
}
export interface CreateWorkshopParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
