import API, { MediaApis } from './Api';

export const recordSessionDocument = (data: any) => {
	return API({
		url: '/session-documents',
		data: data,
		method: 'POST'
	});
};

export const getSessionDocument = (data: any) => {
	return API({
		url: '/session-documents',
		params: data,
		method: 'GET'
	});
};
