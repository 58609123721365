import {
	CreatePlayerParams,
	CREATE_PLAYER,
	CREATE_PLAYER_FAILURE,
	CREATE_PLAYER_SUCCESS,
	FETCH_PLAYERS,
	FETCH_PLAYERS_FAILURE,
	FETCH_PLAYERS_SUCCESS,
	FETCH_PLAYER_DETAILS,
	FETCH_PLAYER_DETAILS_FAILURE,
	FETCH_PLAYER_DETAILS_SUCCESS,
	REMOVE_PLAYER,
	REMOVE_PLAYER_FAILURE,
	REMOVE_PLAYER_SUCCESS,
	UPDATE_PLAYER,
	UPDATE_PLAYER_FAILURE,
	UPDATE_PLAYER_SUCCESS,
	PlayerDetailsParams,
	PlayerFetchParams
} from './PlayerTypes';

//fetch Player List
export const fetchPlayers = (params: PlayerFetchParams) => ({
	type: FETCH_PLAYERS,
	payload: params
});

export const fetchPlayersSuccess = (response: any) => ({
	type: FETCH_PLAYERS_SUCCESS,
	payload: response
});

export const fetchPlayersFailure = (error: any) => ({
	type: FETCH_PLAYERS_FAILURE,
	payload: error
});

//fetch Player Details
export const fetchPlayer = (params: PlayerDetailsParams) => ({
	type: FETCH_PLAYER_DETAILS,
	payload: params
});

export const fetchPlayerSuccess = (response: any) => ({
	type: FETCH_PLAYER_DETAILS_SUCCESS,
	payload: response
});

export const fetchPlayerFailure = (error: any) => ({
	type: FETCH_PLAYER_DETAILS_FAILURE,
	payload: error
});

//create Player
export const createPlayer = (params: CreatePlayerParams) => ({
	type: CREATE_PLAYER,
	payload: params
});

export const createPlayerSuccess = (response: any) => ({
	type: CREATE_PLAYER_SUCCESS,
	payload: response
});
export const createPlayerFailure = (error: any) => ({
	type: CREATE_PLAYER_FAILURE,
	payload: error
});

//update Player
export const updatePlayer = (params: any) => ({
	type: UPDATE_PLAYER,
	payload: params
});

export const updatePlayerSuccess = (response: CreatePlayerParams) => ({
	type: UPDATE_PLAYER_SUCCESS,
	payload: response
});

export const updatePlayerFailure = (error: any) => ({
	type: UPDATE_PLAYER_FAILURE,
	payload: error
});

//delete Player
export const deletePlayer = (params: PlayerDetailsParams) => ({
	type: REMOVE_PLAYER,
	payload: params
});
export const deletePlayerSuccess = (response: PlayerDetailsParams) => ({
	type: REMOVE_PLAYER_SUCCESS,
	payload: response
});
export const deletePlayerFailure = (error: any) => ({
	type: REMOVE_PLAYER_FAILURE,
	payload: error
});
