export const FETCH_AGE_GROUP_VS_PROVINCE = 'FETCH_AGE_GROUP_VS_PROVINCE';
export const FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS = 'FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS';
export const FETCH_AGE_GROUP_VS_PROVINCE_FAILURE = 'FETCH_AGE_GROUP_VS_PROVINCE_FAILURE';

export const FETCH_PLAYER_GENDER_PROVINCE = 'FETCH_PLAYER_GENDER_PROVINCE';
export const FETCH_PLAYER_GENDER_PROVINCE_SUCCESS = 'FETCH_PLAYER_GENDER_PROVINCE_SUCCESS';
export const FETCH_PLAYER_GENDER_PROVINCE_FAILURE = 'FETCH_PLAYER_GENDER_PROVINCE_FAILURE';

export const FETCH_PROVINCE_PLAYER = 'FETCH_PROVINCE_PLAYER';
export const FETCH_PROVINCE_PLAYER_SUCCESS = 'FETCH_PROVINCE_PLAYER_SUCCESS';
export const FETCH_PROVINCE_PLAYER_FAILURE = 'FETCH_PROVINCE_PLAYER_FAILURE';

export const FETCH_PROVINCE_TRAINING = 'FETCH_PROVINCE_TRAINING';
export const FETCH_PROVINCE_TRAINING_SUCCESS = 'FETCH_PROVINCE_TRAINING_SUCCESS';
export const FETCH_PROVINCE_TRAINING_FAILURE = 'FETCH_PROVINCE_TRAINING_FAILURE';
