import MainContainer from 'Container/MainContainer/MainContainer';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import TournamentDetails from 'Views/Tournament/TournamentDetails';
import Tournaments from 'Views/Tournament/Tournaments';
export const TournamentRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer>
      <Switch>
        <Route path={`${props.match?.path}/list`} exact component={Tournaments} />
        <Route path={`${props.match?.path}/:id`} component={TournamentDetails} />
        <Route component={Tournaments} />
      </Switch>
    </MainContainer>
  )
}

export default TournamentRoute;