import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createSessionFailure,
	createSessionSuccess,
	deleteSessionFailure,
	deleteSessionSuccess,
	fetchSessionFailure,
	fetchSessionsFailure,
	fetchSessionsSuccess,
	fetchSessionSuccess,
	updateSessionFailure,
	updateSessionSuccess
} from './SessionActions';
import { cutSession, makeSession, modifySession, takeSession, takeSessions } from './SessionApis';
import {
	CreateSessionParams,
	FETCH_SESSIONS,
	UPDATE_SESSION,
	SessionDetailsParams,
	SessionFetchParams,
	FETCH_SESSION_DETAILS,
	CREATE_SESSION,
	REMOVE_SESSION
} from './SessionTypes';

//get sessions
const getSessionsAsync = async (data: SessionFetchParams) => await takeSessions(data);

function* getSessions(params: any) {
	const { payload } = params;
	try {
		const sessions = yield call(getSessionsAsync, payload);
		if (sessions) {
			yield put(fetchSessionsSuccess(sessions.data));
		} else {
			yield put(fetchSessionsFailure('Could not get list of sessions'));
			notification.error({
				message: 'Get Session List',
				description: 'Could not get list of sessions'
			});
		}
	} catch (error) {
		yield put(fetchSessionsFailure('Network Error while getting Sessions'));
		notification.error({
			message: 'Get Session List',
			description: 'Network Error while getting Sessions'
		});
	}
}

function* watchGetSessions() {
	yield takeLatest(FETCH_SESSIONS, getSessions);
}

//get session details
const getSessionAsync = async (data: SessionDetailsParams) => await takeSession(data);

function* getSession(params: any) {
	const { payload } = params;
	try {
		const sessions = yield call(getSessionAsync, payload);
		if (sessions) {
			yield put(fetchSessionSuccess(sessions.data));
		} else {
			yield put(fetchSessionFailure('Could not get list of sessions'));
			notification.error({
				message: 'Get Session',
				description: 'Could not get list of sessions'
			});
		}
	} catch (error) {
		yield put(fetchSessionFailure('Network Error while getting Sessions'));
		notification.error({
			message: 'Get Session',
			description: 'Network Error while getting Sessions'
		});
	}
}

function* watchGetSession() {
	yield takeLatest(FETCH_SESSION_DETAILS, getSession);
}

//create Session
const generateSessionAsync = async (data: CreateSessionParams) => await makeSession(data);

function* generateSession(params: any) {
	const { payload } = params;
	try {
		const sessions = yield call(generateSessionAsync, payload);
		if (sessions) {
			yield put(createSessionSuccess(sessions.data));
			yield put(setLayout({ data: false, form: false, update: false }));
			notification.success({
				message: 'Create Session',
				description: 'Session created successfully'
			});
		} else {
			const message = 'Could not create Session';
			yield put(createSessionFailure(message));
			notification.error({
				message: 'Create Session',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Session';
		yield put(createSessionFailure(message));
		notification.error({
			message: 'Create Session',
			description: message
		});
	}
}

function* watchGenerateSession() {
	yield takeLatest(CREATE_SESSION, generateSession);
}

//update Session
const amendSessionAsync = async (data: CreateSessionParams) => await modifySession(data);

function* amendSession(params: any) {
	const { payload } = params;
	try {
		const sessions = yield call(amendSessionAsync, payload);
		if (sessions) {
			yield put(updateSessionSuccess(sessions.data));
			yield put(setLayout({ data: false, form: false, update: false }));

			notification.success({
				message: 'Update Session',
				description: 'Session updated successfully'
			});
		} else {
			const message = 'Could not Update Session';
			yield put(updateSessionFailure(message));
			notification.error({
				message: 'Update Session',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Session';
		yield put(updateSessionFailure(message));
		notification.error({
			message: 'Update Session',
			description: message
		});
	}
}

function* watchAmendSession() {
	yield takeLatest(UPDATE_SESSION, amendSession);
}

//Delete Session
const removeSessionAsync = async (data: SessionDetailsParams) => await cutSession(data);

function* removeSession(params: any) {
	const { payload } = params;
	try {
		const sessions = yield call(removeSessionAsync, payload);
		if (sessions) {
			yield put(deleteSessionSuccess(sessions.data));
			notification.success({
				message: 'Session Deleted',
				description: 'Session deleted successfully'
			});
		} else {
			const message = 'Could not delete Session';
			yield put(deleteSessionFailure(message));
			notification.error({
				message: 'Session Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Session';
		yield put(deleteSessionFailure(message));
		notification.error({
			message: 'Session Deleted',
			description: message
		});
	}
}

function* watchRemoveSession() {
	yield takeLatest(REMOVE_SESSION, removeSession);
}

export default function* SessionSagas() {
	yield all([
		fork(watchGetSessions),
		fork(watchGetSession),
		fork(watchGenerateSession),
		fork(watchAmendSession),
		fork(watchRemoveSession)
	]);
}
