import { Button, Form, Input, Row, Typography } from 'antd';
import { useHistory } from 'react-router';

export const LoginForm = (props: any) => {
	const history = useHistory();
	const onPasswordReset = () => {
		history.push('reset-password');
	};
	return (
		<Form layout="vertical" onFinish={props.loginHandler}>
			<Form.Item label="Email" name="identifier" rules={[ { required: true, message: 'Email is required' } ]}>
				<Input placeholder="Email" />
			</Form.Item>
			<Form.Item label="Password" name="password" rules={[ { required: true, message: 'Password is required' } ]}>
				<Input type="password" placeholder="Password" />
			</Form.Item>
			<Typography.Paragraph type="danger">{props.error}</Typography.Paragraph>
			<Form.Item>
				<Row justify="space-between">
					<Button loading={props.requesting} type="primary" htmlType="submit">
						Login
					</Button>
					<Typography.Link onClick={() => onPasswordReset()}>Forgot Password</Typography.Link>
				</Row>
			</Form.Item>
		</Form>
	);
};
