import { Divider, Tag, Typography } from 'antd';
import MyContent from 'Components/Content/Content';
import DataTable from 'Components/Table/DataTable';
import { useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchUser } from 'Redux/UserRedux/UserActions';
import UserList from './UserList';

export const UserDetails = (props: any) => {
	const { userId } = useParams<any>();
	const users = useSelector((state: any) => state.UserReducer)
	const userDetails = users?.details;
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchUser({id: userId}))
	}, [userId]);
	const history = useHistory();

	const dummyData = [{
		id: 1,
		name: 'Prakash Gharti',
		role: 'General Manager',
		parent: 'Sanjeev Rajthala',
		email: 'prakass.dev@gmail.com',
		phone: '9851112509'
	}]

	const assetColumns: TableColumn[] = [
		{
			name: 'Name',
			selector: 'name',
			cell: (row: any) => <Typography.Link onClick={() => history.push(`/users/${row.id}`)}>{row.name}</Typography.Link>

		},
		{
			name: 'Designation',
			selector: 'role',

		},
		{
			name: 'Created By',
			selector: 'createdBy',
			cell: (row: any) => <Typography.Link>{row?.created_by?.name}</Typography.Link>
		},
		{
			name: 'Parent',
			selector: 'parent',
			cell: (row: any) => <Typography.Link>{row?.parent}</Typography.Link>
		},
		// {
		//     name: 'Action',
		//     selector: '',
		//     cell: (row: any) => <TableActions noDelete addData onReport={() => history.push(`/users/${row.id}`)} onAdd={() => onAddHandler(row)} onEdit={() => onEditHandler(row)} onDelete={() => onDeleteHandler(row.id)} />
		// }
	];
	return (
		<>
			<MyContent style={{ padding: '40px' }} show={true}>
				<Typography.Title>{userDetails?.fullName}</Typography.Title>
				<Typography.Link href="mailto:prakass.dev@gmail.com">{userDetails?.email}</Typography.Link> | <Tag>{userDetails?.role?.name}</Tag>
				<Typography.Link href="tel:+9779851112509">9851112509</Typography.Link>
				<Divider />
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>RECENT REPORTS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={assetColumns}
					data={dummyData}
					progressPending={false}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
				// onRowClicked={onRowClicked}
				

				/>
				<Divider />
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>RECENT SESSIONS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={assetColumns}
					data={dummyData}
					progressPending={false}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
				// onRowClicked={onRowClicked}

				/>
				<Divider />
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>RECENT PLAYERS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={assetColumns}
					data={dummyData}
					progressPending={false}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
				// onRowClicked={onRowClicked}

				/>
			</MyContent>
		</>
	)
};

export default UserDetails;
