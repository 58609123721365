export const FETCH_TOURNAMENTS = 'FETCH_TOURNAMENTS';
export const FETCH_TOURNAMENTS_SUCCESS = 'FETCH_TOURNAMENTS_SUCCESS';
export const FETCH_TOURNAMENTS_FAILURE = 'FETCH_TOURNAMENTS_FAILURE';

export const FETCH_TOURNAMENT_DETAILS = 'FETCH_TOURNAMENT_DETAILS';
export const FETCH_TOURNAMENT_DETAILS_SUCCESS = 'FETCH_TOURNAMENT_DETAILS_SUCCESS';
export const FETCH_TOURNAMENT_DETAILS_FAILURE = 'FETCH_TOURNAMENT_DETAILS_FAILURE';

export const CREATE_TOURNAMENT = 'CREATE_TOURNAMENT';
export const CREATE_TOURNAMENT_SUCCESS = 'CREATE_TOURNAMENT_SUCCESS';
export const CREATE_TOURNAMENT_FAILURE = 'CREATE_TOURNAMENT_FAILURE';

export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT';
export const UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS';
export const UPDATE_TOURNAMENT_FAILURE = 'UPDATE_TOURNAMENT_FAILURE';

export const REMOVE_TOURNAMENT = 'REMOVE_TOURNAMENT';
export const REMOVE_TOURNAMENT_SUCCESS = 'REMOVE_TOURNAMENT_SUCCESS';
export const REMOVE_TOURNAMENT_FAILURE = 'REMOVE_TOURNAMENT_FAILURE';

export interface TournamentFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface TournamentDetailsParams {
	id?: number;
}
export interface CreateTournamentParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
