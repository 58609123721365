import MainContainer from "Container/MainContainer/MainContainer";
import { Route, RouteChildrenProps, Switch } from "react-router";
import Dashboard from "Views/Dashboard/Dashboard";
export const DashboardRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer lg={22}>
      <Switch>
        <Route path={`${props?.match?.path}/`} exact component={Dashboard} />
        <Route component={Dashboard} />
      </Switch>
    </MainContainer>
  );
};

export default DashboardRoute;
