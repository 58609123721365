import { Divider, Tag, Typography } from 'antd';
import MyContent from 'Components/Content/Content';
import DataTable from 'Components/Table/DataTable';
import { useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchSchool } from 'Redux/SchoolRedux/SchoolActions';
import { fetchUser } from 'Redux/UserRedux/UserActions';
import UserList from './PlayerList';

export const PlayerDetails = (props: any) => {
	const { schoolId } = useParams<any>();
	const users = useSelector((state: any) => state.SchoolReducer)
	const userDetails = users?.details;
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchSchool({ id: schoolId }))
	}, [schoolId]);
	const history = useHistory();

	const assetColumns: TableColumn[] = [
		{
			name: 'Name',
			selector: 'name',
			cell: (row: any) => <Typography.Link onClick={() => history.push(`/users/${row.id}`)}>{row.name}</Typography.Link>

		},
		{
			name: 'Designation',
			selector: 'role',

		},
		{
			name: 'Created By',
			selector: 'createdBy',
			cell: (row: any) => <Typography.Link>{row?.created_by?.name}</Typography.Link>
		},
		{
			name: 'Parent',
			selector: 'parent',
			cell: (row: any) => <Typography.Link>{row?.parent}</Typography.Link>
		},
		// {
		//     name: 'Action',
		//     selector: '',
		//     cell: (row: any) => <TableActions noDelete addData onReport={() => history.push(`/users/${row.id}`)} onAdd={() => onAddHandler(row)} onEdit={() => onEditHandler(row)} onDelete={() => onDeleteHandler(row.id)} />
		// }
	];
	return (
		<>
			<MyContent style={{ padding: '40px' }} show={true}>
				<Typography.Title>{userDetails?.name}</Typography.Title>
				<Typography.Link href="mailto:prakass.dev@gmail.com">{userDetails?.email}</Typography.Link>
				<Divider type="vertical" />
				<Typography.Link href="tel:+9779851112509">9851112509</Typography.Link>
				<Divider />
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>RECENT REPORTS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={assetColumns}
					data={[]}
					progressPending={false}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
				// onRowClicked={onRowClicked}


				/>
			</MyContent>
		</>
	)
};

export default PlayerDetails;
