import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Typography, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';
import { getUserId } from 'Api/Api';
import { createMatch, updateMatch } from 'Redux/MatchRedux/MatchActions';

const { Option } = Select;

export const MatchForm = (props: any) => {
	const dispatch = useDispatch();
	const schools = useSelector((state: any) => state.ActivityReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [imageUrl, setImageUrl] = useState<any[]>([]);
	const [imageData, setImageData] = useState<any[]>([]);
	const [teams, setTeams] = useState<any[]>([]);
	const [matches, setMatches] = useState<any[]>([]);


	const handleOnUpload = (e: any, i: any, fileIndex: number) => {
		let tempTeams = form.getFieldsValue()?.matches;
		let currentTeam: any = tempTeams[i];

		let files = currentTeam?.file || [];
		if (fileIndex === 100) {
			files.push(e?.url)
		}
		else {
			files[fileIndex] = e?.url;
		}
		currentTeam = { ...currentTeam, file: files }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ matches: tempTeams })
		// form.setFieldsValue({ image: [...form.getFieldValue('media'),e]})
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.data?.match) {
			dispatch(updateMatch({ ...values, id: layout?.data?.match?.id }));
			// console.log({ ...values, media: imageData});
		}
		else {
			dispatch(createMatch({ ...values, tournament: layout?.data?.id, submittedBy: getUserId() }));

		}
	};

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};

	useEffect(() => {
		// dispatch(fetchMatchs({ _where: qs.stringify(complexQuery) }));
	}, []);

	useEffect(() => {
		// if (layout?.data) setImageUrl(layout?.data?.media?.map((d: any, i: number) => GetUploadUrl([d])))
		// if (layout?.data) setImageData(layout?.data?.media);
		form.setFieldsValue({ matches: layout?.data?.match?.matches })
		setMatches(layout?.data?.match?.matches)
		setTeams(layout?.data?.teams)
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>{layout?.data?.match ? 'UPDATE' : 'ADD'} MATCH</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={24}>
						<Form.List name="matches">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field: any, index: number) => (
										<Row key={field.key} align="top" gutter={[20, 20]} style={{ marginBottom: 20 }}>
											<Col lg={1}>
												{index == 0 ? (
													<Space direction="vertical" size={15}>
														<Typography.Text>#</Typography.Text>
														<Typography.Text>1</Typography.Text>
													</Space>
												) : (
													<Typography.Text>{index + 2}.</Typography.Text>

												)}
											</Col>
											<Col lg={6}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}
													label={index > 0 ? false : 'Home Team'}
													name={[field.name, 'homeTeam']}
													fieldKey={[field.fieldKey, 'homeTeam']}
													rules={[{ required: true, message: 'Required' }]}
												>
													<Select>
														{teams?.map((t: any, i: number) => {
															return (
																<Option value={t?.name}>{t?.name}</Option>
															)
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col lg={2}>
												<Form.Item
													style={{ marginBottom: 0 }}

													{...field}
													label={index > 0 ? false : 'Score'}
													name={[field.name, 'homeScore']}
													fieldKey={[field.fieldKey, 'homeScore']}
													rules={[{ required: true, message: 'Required' }]}

												>
													<Input />
												</Form.Item>
											</Col>
											<Col lg={2}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}

													label={index > 0 ? false : 'Score'}
													name={[field.name, 'awayScore']}
													fieldKey={[field.fieldKey, 'awayScore']}
													rules={[{ required: true, message: 'Required' }]}

												>
													<Input />
												</Form.Item>
											</Col>
											<Col lg={6}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}

													label={index > 0 ? false : 'Away Team'}
													name={[field.name, 'awayTeam']}
													fieldKey={[field.fieldKey, 'awayTeam']}
													rules={[{ required: true, message: 'Required' }]}

												>
													<Select>
														{teams?.map((t: any, i: number) => {
															return (
																<Option value={t?.name}>{t?.name}</Option>
															)
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col lg={6}>
												<Form.Item shouldUpdate>
													{() => {
														const currentFiles = form.getFieldsValue()?.matches[field.name]?.file || [];
														console.log(currentFiles)
														return (
															<Row align="middle" justify="space-between">
																<Col flex="auto">
																	<Form.Item
																		{...field}
																		label={index > 0 ? false : 'Document'}
																		name={[field.name, 'file']}
																		fieldKey={[field.fieldKey, 'file']}
																	>
																		<Space direction="vertical">
																			{currentFiles && currentFiles.map((d: any, i: number) => {
																				return (
																					<FileUpload type='file' url={currentFiles[i]} onUpload={(e: any) => handleOnUpload(e, index, i)} />
																				)
																			})}
																			<FileUpload type='file' onUpload={(e: any) => handleOnUpload(e, index, 100)} />

																		</Space>
																		<Input hidden />
																	</Form.Item>
																</Col>

																{/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
																<Col>
																	<Button danger onClick={() => remove(field.name)}>Remove Match</Button>
																</Col>
															</Row>
														)
													}}
												</Form.Item>
												{/* <Space>
													<Form.Item
														{...field}
														style={{ marginBottom: 0 }}

														label={index > 0 ? false : 'Document'}
														name={[field.name, 'file']}
														fieldKey={[field.fieldKey, 'file']}
													>
														<FileUpload type='file'
															url={matches?.length > 0 && matches[index]?.file}
															onUpload={(e: any) => handleOnUpload(e, index)} />
														<Input hidden />
													</Form.Item>

													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space> */}
											</Col>
										</Row>
									))}

									<Row>
										<Col lg={17}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Add Match
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
						</Form.List>
					</Col>
					<Col lg={24}>
						<Form.Item>
							<Button loading={schools?.creating || schools?.updating} type="primary" htmlType="submit">
								{layout?.update ? 'Update' : 'SUBMIT'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default MatchForm;
