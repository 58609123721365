import {
	CreateEventParams,
	CREATE_EVENT,
	CREATE_EVENT_FAILURE,
	CREATE_EVENT_SUCCESS,
	FETCH_EVENTS,
	FETCH_EVENTS_FAILURE,
	FETCH_EVENTS_SUCCESS,
	FETCH_EVENT_DETAILS,
	FETCH_EVENT_DETAILS_FAILURE,
	FETCH_EVENT_DETAILS_SUCCESS,
	REMOVE_EVENT,
	REMOVE_EVENT_FAILURE,
	REMOVE_EVENT_SUCCESS,
	UPDATE_EVENT,
	UPDATE_EVENT_FAILURE,
	UPDATE_EVENT_SUCCESS,
	EventDetailsParams,
	EventFetchParams
} from './EventTypes';

//fetch Event List
export const fetchEvents = (params: EventFetchParams) => ({
	type: FETCH_EVENTS,
	payload: params
});

export const fetchEventsSuccess = (response: any) => ({
	type: FETCH_EVENTS_SUCCESS,
	payload: response
});

export const fetchEventsFailure = (error: any) => ({
	type: FETCH_EVENTS_FAILURE,
	payload: error
});

//fetch Event Details
export const fetchEvent = (params: EventDetailsParams) => ({
	type: FETCH_EVENT_DETAILS,
	payload: params
});

export const fetchEventSuccess = (response: any) => ({
	type: FETCH_EVENT_DETAILS_SUCCESS,
	payload: response
});

export const fetchEventFailure = (error: any) => ({
	type: FETCH_EVENT_DETAILS_FAILURE,
	payload: error
});

//create Event
export const createEvent = (params: CreateEventParams) => ({
	type: CREATE_EVENT,
	payload: params
});

export const createEventSuccess = (response: CreateEventParams) => ({
	type: CREATE_EVENT_SUCCESS,
	payload: response
});
export const createEventFailure = (error: any) => ({
	type: CREATE_EVENT_FAILURE,
	payload: error
});

//update Event
export const updateEvent = (params: CreateEventParams) => ({
	type: UPDATE_EVENT,
	payload: params
});

export const updateEventSuccess = (response: CreateEventParams) => ({
	type: UPDATE_EVENT_SUCCESS,
	payload: response
});

export const updateEventFailure = (error: any) => ({
	type: UPDATE_EVENT_FAILURE,
	payload: error
});

//delete Event
export const deleteEvent = (params: EventDetailsParams) => ({
	type: REMOVE_EVENT,
	payload: params
});
export const deleteEventSuccess = (response: EventDetailsParams) => ({
	type: REMOVE_EVENT_SUCCESS,
	payload: response
});
export const deleteEventFailure = (error: any) => ({
	type: REMOVE_EVENT_FAILURE,
	payload: error
});
