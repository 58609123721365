import { SnippetsTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Result, Row, Steps, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Content } from 'antd/lib/layout/layout';
import { getUserId } from 'Api/Api';
import FormSegment from 'Components/Reporting/FormSegment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { submitReportData, updateReportData } from 'Redux/FormRedux/FormApis';

const { Step } = Steps;
export const Reporting = (props: any) => {
	const [current, setCurrent] = useState(0);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const data = layout?.data;

	const [dataId, setDataId] = useState();
	const [existingData, setExistingData] = useState({})

	const dispatch = useDispatch();
	const [form] = useForm()

	const submitDataHandler = (values: any) => {
		if (current === 0 && !dataId) {
			submitReportData({ data: values, formId: data?.formId, createdBy: getUserId() }).then((data) => {
				setDataId(data.data.id)
				setExistingData(data.data.data)
				setCurrent(current + 1);
			});
		}
		else {
			updateReportData({ data: { ...existingData, ...values }, formId: data?.formId, id: dataId }).then((data) => {
				setCurrent(current + 1);
			});
		}
	}

	const next = () => {
		submitDataHandler(form.getFieldsValue())
		setCurrent(current + 1);
	};

	const prev = () => {
		// submitDataHandler(form.getFieldsValue())
		setCurrent(current - 1);
	};

	return (
		<Content>
			<Row justify="start" align="middle">
				<Col>
					<SnippetsTwoTone style={{ fontSize: 32, marginBottom: 20, marginTop: 20 }} />
					<Typography.Title level={5} style={{ textTransform: 'uppercase' }}>
						{data?.name}
					</Typography.Title>
				</Col>
				<Divider type="vertical" style={{ height: '100px', marginLeft: 40 }} />
				<Col offset={1}>
					<Typography.Text>
						{data?.description}
					</Typography.Text>
					<Typography.Paragraph type="danger">NOTE: These data are not editable. Submit data carefully.</Typography.Paragraph>
				</Col>
			</Row>
			<Divider />

			<Row justify="center" align="middle" style={{ background: '#f5f5f5', padding: '20px 0px' }}>
				<Col lg={18}>
					<Steps
						type="navigation"
						size="small"
						current={current}
						// onChange={(e) => setCurrent(e)}
						className="site-navigation-steps"
					>
						{data?.steps &&
							data?.steps.map((process: any, i: number) => {
								return (
									<Step
										title={process.name}
										// subTitle="00:00:05"
										status={current === i ? 'process' : current > i ? 'finish' : 'wait'}
									// description="This is a description."
									/>
								);
							})}
					</Steps>
				</Col>
			</Row>
			<Divider />
			<Form
				// {...layout}
				layout="vertical"
				name="basic"
				form={form}
				onFinish={submitDataHandler}
			// onFinishFailed={onFinishFailed}
			>
				<Row justify="center">
					<Col lg={12}>
						<FormSegment data={data && data?.steps?.length > 0 && data?.steps[current]} />
						{(data && data?.steps && data?.steps?.length === current) &&
							<Result
								status="success"
								title="Successfully Recorded"
								subTitle="Your entry have been recorded successfully"
							/>
						}
					</Col>
				</Row>
				<Row justify="center">
					<Col>
						<div className="steps-action">
							{current < data?.steps?.length - 1 && (
								<Button htmlType="submit" type="primary" onClick={() => next()}>
									Next
								</Button>
							)}
							{current === data?.steps?.length - 1 && (
								<Button htmlType="submit" type="primary" onClick={() => message.success('Processing complete!')}>
									Done
								</Button>
							)}
							{current > data?.steps?.length - 1 && (
								<Button htmlType="submit" type="primary" onClick={() => dispatch(setLayout({ form: false, update: false, data: false }))}>
									Done
								</Button>
							)}
							{current > 0 && (
								<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
									Previous
								</Button>
							)}
						</div>
					</Col>
				</Row>
			</Form>

		</Content>
	);
};

export default Reporting;
