
import MainContainer from "Container/MainContainer/MainContainer";
import { Route, RouteChildrenProps, Switch } from "react-router";
import ActivityLog from "Views/ActivityLog/ActivityLog";
import Dashboard from "Views/Dashboard/Dashboard";
import { Report } from "Views/Report/Report";
export const ActivityRoute = (props: RouteChildrenProps) => {
    return (
        <MainContainer>
            <Switch>
                <Route path={`${props?.match?.path}`} exact component={ActivityLog} />
                <Route component={ActivityLog} />
            </Switch>
        </MainContainer>
    );
};

export default ActivityRoute;
