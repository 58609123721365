import { name } from 'dayjs/locale/*';

export interface FinalFormProps {
	name: string;
	type: string;
	isActive: string;
	steps: FormStepProps[];
	formId?: string;
	createdBy?: string;
}

interface FormStepProps {
	name: string;
	content: string;
	fields: FormFieldProps[];
}

interface FormFieldOptionProps {
	name: string;
	value: string;
}

interface FormFieldProps {
	name: string;
	type: string;
	label: string;
	options?: FormFieldOptionProps[];
	isRequired: boolean;
}

const getOptions = (options: any) => {
	let fieldOptions: FormFieldOptionProps[] = [];
	options.map((option: any) => {
		fieldOptions.push({
			name: option,
			value: option
		});
	});
	return fieldOptions;
};

const getFieds = (fields: any) => {
	let formFields: FormFieldProps[] = [];

	fields?.map((field: any, i: number) => {
		if (field.options) {
			formFields.push({
				name: field.name,
				label: field.label,
				type: field.type,
				isRequired: field.isRequired || false,
				options: getOptions(field.options)
			});
		} else {
			formFields.push({
				name: field.name,
				label: field.label,
				type: field.type,
				isRequired: field.isRequired || false
			});
		}
	});

	return formFields;
};

const getFormSteps = ({ values, steps }: any) => {
	let allFormSteps: FormStepProps[] = [];
	steps.map((step: any, i: number) => {
		let formSteps: FormStepProps = {
			name: values[`step_${i + 1}_name`],
			content: values[`step_${i + 1}_content`],
			fields: getFieds(values[`step${i + 1}`])
		};
		allFormSteps.push(formSteps);
	});

	return allFormSteps;
};
export const GetCreateFormData = (initialValues: any) => {
	const { value, steps } = initialValues;
	let finalObject: FinalFormProps = {
		name: value.name,
		type: value.type,
		createdBy: initialValues.created_by,
		formId: value?.formId || value.name?.split(' ').join('-'),
		isActive: value.isActive || false,
		steps: getFormSteps({ values: value, steps: steps })
	};
	return finalObject;
};








export const formInitialValues = (responseValues: any) => {
	let initialValues = {
		name: responseValues.name,
		type: responseValues.type,
		isActive: responseValues.isActive,
		...getInitialSteps(responseValues.steps)
	}
	return initialValues;
}

const getInitialSteps = (data: any) => {
	let initialSteps: any = {};
	data?.map((st: any, i: number) => {
		initialSteps[`step_${i + 1}_name`] = st.name;
		initialSteps[`step_${i + 1}_content`] = st.content;
		initialSteps[`step${i + 1}`] = getInitialFieldsOptions(st.fields);
	})

	return initialSteps;
}

const getInitialFieldsOptions = (data: any) => {
	let fieldOptions: any = [];
	data.map((d: any, i: number) => {
		let field = d;
		let initOptions = field.options;
		let options: string[] = [];
		if (d.type === 'select' || d.type === 'checkbox') {
			initOptions?.map((op: any) => {
				options = options.concat(op?.value)
			})
			// field['options'] = options
		}
		fieldOptions = fieldOptions.concat({...field, options: options})
	})

	return fieldOptions

}

const createformdata = {
	name: 'asdasdassadd asdasd asdas ',
	formId: 'asdasdassadd-asdasd-asdas',
	type: 'District',
	isActive: true,
	steps: [
		{
			name: 'asdasd',
			content: 'asdasdasd',
			fields: [
				{
					name: 'asdasd',
					options: [
						{
							name: 'asdasd',
							value: 'asdasd',
							isRequired: true
						},
						{
							name: 'asdasd',
							value: 'asdasd',
							isRequired: true
						}
					],
					type: 'textarea',
					label: 'asdasdasd'
				}
			]
		},
		{
			fields: [
				{
					name: 'asdasd',
					type: 'text',
					label: 'adasdasd'
				}
			]
		}
	]
};

//output

const outputData = {
	name: 'asdasd',
	type: 'district',
	isActive: true,
	steps: [
		{
			name: 'asdasd',
			content: 'asdas',
			fields: [
				{
					name: 'asdasd',
					label: 'asdasd',
					type: 'number',
					isRequired: false
				},
				{
					name: 'asdasd',
					label: 'sadasd',
					type: 'select',
					isRequired: false,
					options: [
						{
							name: 'asdasd',
							value: 'asdasd'
						},
						{
							name: 'rewr',
							value: 'rewr'
						}
					]
				}
			]
		},
		{
			name: 'dsadas',
			content: 'dasdas',
			fields: [
				{
					name: 'asdsa',
					label: 'asdasd',
					type: 'checkbox',
					isRequired: false,
					options: [
						{
							name: 'asdasd',
							value: 'asdasd'
						}
					]
				},
				{
					name: 'asdasd',
					label: 'asdasd',
					type: 'password',
					isRequired: false
				},
				{
					name: 'sadasd',
					label: 'sadasd',
					type: 'textarea',
					isRequired: false
				}
			]
		}
	]
};



//
const rawData = {
    "name": "asdasd",
    "type": "Province",
    "isActive": true,
    "step_1_name": "sdasd",
    "step_1_content": "asdasdasd",
    "step1": [
        {
            "name": "asdasd",
            "label": "asdasd",
            "type": "number",
            "isRequired": true
        },
        {
            "name": "asdsad",
            "label": "asdasd",
            "type": "checkbox",
            "options": [
                "jkb",
                "sfsdf",
                "asd"
            ],
            "isRequired": true
        }
    ],
    "step_2_name": "asdasd",
    "step_2_content": "sadasd",
    "step2": [
        {
            "name": "adasd",
            "label": "asdasd",
            "type": "number"
        },
        {
            "name": "asdasd",
            "label": "asdasd",
            "type": "number",
            "isRequired": true
        }
    ]
}

const responseDataFromServer = {
    "name": "asdasd",
    "type": "Province",
    "formId": "asdasd",
    "isActive": true,
    "steps": [
        {
            "name": "sdasd",
            "content": "asdasdasd",
            "fields": [
                {
                    "name": "asdasd",
                    "label": "asdasd",
                    "type": "number",
                    "isRequired": true
                },
                {
                    "name": "asdsad",
                    "label": "asdasd",
                    "type": "checkbox",
                    "isRequired": true,
                    "options": [
                        {
                            "name": "jkb",
                            "value": "jkb"
                        },
                        {
                            "name": "sfsdf",
                            "value": "sfsdf"
                        },
                        {
                            "name": "asd",
                            "value": "asd"
                        }
                    ]
                }
            ]
        },
        {
            "name": "asdasd",
            "content": "sadasd",
            "fields": [
                {
                    "name": "adasd",
                    "label": "asdasd",
                    "type": "number",
                    "isRequired": false
                },
                {
                    "name": "asdasd",
                    "label": "asdasd",
                    "type": "number",
                    "isRequired": true
                }
            ]
        }
    ]
}
