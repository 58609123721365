export const FETCH_FORMS = 'FETCH_FORMS';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_FAILURE = 'FETCH_FORMS_FAILURE';

export const FETCH_FORM_DETAILS = 'FETCH_FORM_DETAILS';
export const FETCH_FORM_DETAILS_SUCCESS = 'FETCH_FORM_DETAILS_SUCCESS';
export const FETCH_FORM_DETAILS_FAILURE = 'FETCH_FORM_DETAILS_FAILURE';

export const CREATE_FORM = 'CREATE_FORM';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAILURE = 'CREATE_FORM_FAILURE';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_FAILURE = 'UPDATE_FORM_FAILURE';

export const REMOVE_FORM = 'REMOVE_FORM';
export const REMOVE_FORM_SUCCESS = 'REMOVE_FORM_SUCCESS';
export const REMOVE_FORM_FAILURE = 'REMOVE_FORM_FAILURE';

export interface FormFetchParams {
	limit?: number;
	page?: number;
	formId_eq?: string;
}
export interface FormDetailsParams {
	id?: string;
}
export interface CreateFormParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
