import API from 'Api/Api';
import { CreateUserParams, UserDetailsParams, UserFetchParams } from './UserTypes';

//get User
export const takeUsers = (data: UserFetchParams) => {
	// return API({
	// 	url: '/users',
	// 	method: 'GET',
	// 	params: data
	// });
	if (data._where) {
		return API({
			url: `/users?${data._where}`,
			method: 'GET',
			params: {_limit: data?.limit}
		});
	} else {
		return API({
			url: '/users',
			method: 'GET',
			params: data
		});
	}
};

//get User Details
export const takeUser = (data: UserDetailsParams) => {
	return API({
		url: `/users/${data?.id}`,
		method: 'GET',
		// params: data
	});
};

//create User Details
export const makeUser = (data: CreateUserParams) => {
	return API({
		url: '/users',
		method: 'POST',
		data: data
	});
};

//modify User
export const modifyUser = (data: CreateUserParams) => {
	return API({
		url: `/users/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete User
export const cutUser = (data: UserDetailsParams) => {
	return API({
		url: '/user',
		method: 'DELETE',
		params: data
	});
};
