import API from 'Api/Api';

//get Event
export const takeEventsCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/trainings/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/trainings/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takeSessionCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/sessions/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/sessions/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takePlayersCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/players/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/players/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takeUserCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/users/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/users/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takeCoachCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/users/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/users/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takeSchoolCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/schools/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/schools/count',
			method: 'GET',
			params: data
		});
	}
};

//get sessions
export const takeActivityCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/data-activity-logs/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/data-activity-logs/count',
			method: 'GET',
			params: data
		});
	}
};

//refree count
export const takeRefereeCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/data-referee/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/data-referees/count',
			method: 'GET',
			params: data
		});
	}
};

//workshop count
export const takeWorkshopCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/data-workshops/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/data-workshops/count',
			method: 'GET',
			params: data
		});
	}
};

//workshop count
export const takeTournamentCount = (data: any) => {
	if (data._where) {
		return API({
			url: `/tournaments/count?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/tournaments/count',
			method: 'GET',
			params: data
		});
	}
};
