
import MainContainer from "Container/MainContainer/MainContainer";
import { Route, RouteChildrenProps, Switch } from "react-router";
import ActivityLog from "Views/ActivityLog/ActivityLog";
import Workshop from "Views/Workshop/Workshop";
export const WorkshopRoute = (props: RouteChildrenProps) => {
    return (
        <MainContainer>
            <Switch>
                <Route path={`${props?.match?.path}`} exact component={Workshop} />
                <Route component={Workshop} />
            </Switch>
        </MainContainer>
    );
};

export default WorkshopRoute;
