import MainContainer from 'Container/MainContainer/MainContainer';
import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import UserDetails from 'Views/Users/UserDetails';
import Users from 'Views/Users/Users';
export const UserRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer>
      <Switch>
        <Route path={`${props.match?.path}/list`} exact component={Users} />
        <Route path={`${props.match?.path}/:userId`} component={UserDetails} />
        <Route component={Users} />
      </Switch>
    </MainContainer>
  )
}

export default UserRoute;