import {
	CreateActivityParams,
	CREATE_ACTIVITY,
	CREATE_ACTIVITY_FAILURE,
	CREATE_ACTIVITY_SUCCESS,
	FETCH_ACTIVITYS,
	FETCH_ACTIVITYS_FAILURE,
	FETCH_ACTIVITYS_SUCCESS,
	FETCH_ACTIVITY_DETAILS,
	FETCH_ACTIVITY_DETAILS_FAILURE,
	FETCH_ACTIVITY_DETAILS_SUCCESS,
	REMOVE_ACTIVITY,
	REMOVE_ACTIVITY_FAILURE,
	REMOVE_ACTIVITY_SUCCESS,
	UPDATE_ACTIVITY,
	UPDATE_ACTIVITY_FAILURE,
	UPDATE_ACTIVITY_SUCCESS,
	ActivityDetailsParams,
	ActivityFetchParams
} from './ActivityTypes';

//fetch Activity List
export const fetchActivitys = (params: ActivityFetchParams) => ({
	type: FETCH_ACTIVITYS,
	payload: params
});

export const fetchActivitysSuccess = (response: any) => ({
	type: FETCH_ACTIVITYS_SUCCESS,
	payload: response
});

export const fetchActivitysFailure = (error: any) => ({
	type: FETCH_ACTIVITYS_FAILURE,
	payload: error
});

//fetch Activity Details
export const fetchActivity = (params: ActivityDetailsParams) => ({
	type: FETCH_ACTIVITY_DETAILS,
	payload: params
});

export const fetchActivitySuccess = (response: any) => ({
	type: FETCH_ACTIVITY_DETAILS_SUCCESS,
	payload: response
});

export const fetchActivityFailure = (error: any) => ({
	type: FETCH_ACTIVITY_DETAILS_FAILURE,
	payload: error
});

//create Activity
export const createActivity = (params: CreateActivityParams) => ({
	type: CREATE_ACTIVITY,
	payload: params
});

export const createActivitySuccess = (response: CreateActivityParams) => ({
	type: CREATE_ACTIVITY_SUCCESS,
	payload: response
});
export const createActivityFailure = (error: any) => ({
	type: CREATE_ACTIVITY_FAILURE,
	payload: error
});

//update Activity
export const updateActivity = (params: CreateActivityParams) => ({
	type: UPDATE_ACTIVITY,
	payload: params
});

export const updateActivitySuccess = (response: CreateActivityParams) => ({
	type: UPDATE_ACTIVITY_SUCCESS,
	payload: response
});

export const updateActivityFailure = (error: any) => ({
	type: UPDATE_ACTIVITY_FAILURE,
	payload: error
});

//delete Activity
export const deleteActivity = (params: ActivityDetailsParams) => ({
	type: REMOVE_ACTIVITY,
	payload: params
});
export const deleteActivitySuccess = (response: ActivityDetailsParams) => ({
	type: REMOVE_ACTIVITY_SUCCESS,
	payload: response
});
export const deleteActivityFailure = (error: any) => ({
	type: REMOVE_ACTIVITY_FAILURE,
	payload: error
});
