import Emptyy from 'Components/Empty/Empty';
import Loading from 'Components/Loading/Loading';
import ReactDataTable, { createTheme, TableProps } from 'react-data-table-component';
export const DataTable = (props: TableProps) => {
	createTheme('solarizeder', {
		text: {
			primary: '#268bd2',
			secondary: '#2aa198'
		},
		background: {
			default: '#002b36'
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF'
		},
		divider: {
			default: '#073642'
		},
		action: {
			button: 'rgba(0,0,0,.54)',
			hover: 'rgba(0,0,0,.08)',
			disabled: 'rgba(0,0,0,.12)'
		}
	});
	return (
		<ReactDataTable
			noDataComponent={<Emptyy />}
			progressComponent={<Loading />}
			pointerOnHover
			highlightOnHover
			{...props}
		/>
	);
};

export default DataTable;
