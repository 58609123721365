import {
	CreateRefereeParams,
	CREATE_REFEREE,
	CREATE_REFEREE_FAILURE,
	CREATE_REFEREE_SUCCESS,
	FETCH_REFEREES,
	FETCH_REFEREES_FAILURE,
	FETCH_REFEREES_SUCCESS,
	FETCH_REFEREE_DETAILS,
	FETCH_REFEREE_DETAILS_FAILURE,
	FETCH_REFEREE_DETAILS_SUCCESS,
	REMOVE_REFEREE,
	REMOVE_REFEREE_FAILURE,
	REMOVE_REFEREE_SUCCESS,
	UPDATE_REFEREE,
	UPDATE_REFEREE_FAILURE,
	UPDATE_REFEREE_SUCCESS,
	RefereeDetailsParams,
	RefereeFetchParams
} from './RefereeTypes';

//fetch Referee List
export const fetchReferees = (params: RefereeFetchParams) => ({
	type: FETCH_REFEREES,
	payload: params
});

export const fetchRefereesSuccess = (response: any) => ({
	type: FETCH_REFEREES_SUCCESS,
	payload: response
});

export const fetchRefereesFailure = (error: any) => ({
	type: FETCH_REFEREES_FAILURE,
	payload: error
});

//fetch Referee Details
export const fetchReferee = (params: RefereeDetailsParams) => ({
	type: FETCH_REFEREE_DETAILS,
	payload: params
});

export const fetchRefereeSuccess = (response: any) => ({
	type: FETCH_REFEREE_DETAILS_SUCCESS,
	payload: response
});

export const fetchRefereeFailure = (error: any) => ({
	type: FETCH_REFEREE_DETAILS_FAILURE,
	payload: error
});

//create Referee
export const createReferee = (params: CreateRefereeParams) => ({
	type: CREATE_REFEREE,
	payload: params
});

export const createRefereeSuccess = (response: CreateRefereeParams) => ({
	type: CREATE_REFEREE_SUCCESS,
	payload: response
});
export const createRefereeFailure = (error: any) => ({
	type: CREATE_REFEREE_FAILURE,
	payload: error
});

//update Referee
export const updateReferee = (params: CreateRefereeParams) => ({
	type: UPDATE_REFEREE,
	payload: params
});

export const updateRefereeSuccess = (response: CreateRefereeParams) => ({
	type: UPDATE_REFEREE_SUCCESS,
	payload: response
});

export const updateRefereeFailure = (error: any) => ({
	type: UPDATE_REFEREE_FAILURE,
	payload: error
});

//delete Referee
export const deleteReferee = (params: RefereeDetailsParams) => ({
	type: REMOVE_REFEREE,
	payload: params
});
export const deleteRefereeSuccess = (response: RefereeDetailsParams) => ({
	type: REMOVE_REFEREE_SUCCESS,
	payload: response
});
export const deleteRefereeFailure = (error: any) => ({
	type: REMOVE_REFEREE_FAILURE,
	payload: error
});
