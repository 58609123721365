import {
	CreateSchoolParams,
	CREATE_SCHOOL,
	CREATE_SCHOOL_FAILURE,
	CREATE_SCHOOL_SUCCESS,
	FETCH_SCHOOLS,
	FETCH_SCHOOLS_FAILURE,
	FETCH_SCHOOLS_SUCCESS,
	FETCH_SCHOOL_DETAILS,
	FETCH_SCHOOL_DETAILS_FAILURE,
	FETCH_SCHOOL_DETAILS_SUCCESS,
	REMOVE_SCHOOL,
	REMOVE_SCHOOL_FAILURE,
	REMOVE_SCHOOL_SUCCESS,
	UPDATE_SCHOOL,
	UPDATE_SCHOOL_FAILURE,
	UPDATE_SCHOOL_SUCCESS,
	SchoolDetailsParams,
	SchoolFetchParams
} from './SchoolTypes';

//fetch School List
export const fetchSchools = (params: SchoolFetchParams) => ({
	type: FETCH_SCHOOLS,
	payload: params
});

export const fetchSchoolsSuccess = (response: any) => ({
	type: FETCH_SCHOOLS_SUCCESS,
	payload: response
});

export const fetchSchoolsFailure = (error: any) => ({
	type: FETCH_SCHOOLS_FAILURE,
	payload: error
});

//fetch School Details
export const fetchSchool = (params: SchoolDetailsParams) => ({
	type: FETCH_SCHOOL_DETAILS,
	payload: params
});

export const fetchSchoolSuccess = (response: any) => ({
	type: FETCH_SCHOOL_DETAILS_SUCCESS,
	payload: response
});

export const fetchSchoolFailure = (error: any) => ({
	type: FETCH_SCHOOL_DETAILS_FAILURE,
	payload: error
});

//create School
export const createSchool = (params: CreateSchoolParams) => ({
	type: CREATE_SCHOOL,
	payload: params
});

export const createSchoolSuccess = (response: CreateSchoolParams) => ({
	type: CREATE_SCHOOL_SUCCESS,
	payload: response
});
export const createSchoolFailure = (error: any) => ({
	type: CREATE_SCHOOL_FAILURE,
	payload: error
});

//update School
export const updateSchool = (params: CreateSchoolParams) => ({
	type: UPDATE_SCHOOL,
	payload: params
});

export const updateSchoolSuccess = (response: CreateSchoolParams) => ({
	type: UPDATE_SCHOOL_SUCCESS,
	payload: response
});

export const updateSchoolFailure = (error: any) => ({
	type: UPDATE_SCHOOL_FAILURE,
	payload: error
});

//delete School
export const deleteSchool = (params: SchoolDetailsParams) => ({
	type: REMOVE_SCHOOL,
	payload: params
});
export const deleteSchoolSuccess = (response: SchoolDetailsParams) => ({
	type: REMOVE_SCHOOL_SUCCESS,
	payload: response
});
export const deleteSchoolFailure = (error: any) => ({
	type: REMOVE_SCHOOL_FAILURE,
	payload: error
});
