import { PlusOutlined, MinusCircleOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Space, Col, Form, Input, Row, Select, Divider, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { Option } from 'antd/lib/mentions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, updateUser } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { takeUsers } from 'Redux/UserRedux/UserApis';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';


export const UserForm = (props: any) => {
	const dispatch = useDispatch();
	const users = useSelector((state: any) => state.UserReducer)
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [provinces, setProvinces] = useState<any>([]);

	const [documents, setDocuments] = useState<any[]>([])

	const [isCoach, setIsCoach] = useState(false);


	const handleOnUploadDocument = (e: any, i: any) => {
		let tempTeams = form.getFieldsValue()?.userDocuments;
		let currentTeam: any = tempTeams[i];
		currentTeam = { ...currentTeam, file: e?.url }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ userDocuments: tempTeams })
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateUser({ ...values, id: layout?.data?.id }))
		}
		else {
			dispatch(createUser({ ...values, confirmed: true }))
		}
	};

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	}

	const fetchUsers = (data: any) => {
		takeUsers(data).then((d: any) => {
			setProvinces(d?.data)
		}).catch((err: any) => {
			console.log('Error loading provinces')
		})
	}
	useEffect(() => {
		fetchUsers({ _where: qs.stringify(complexQuery) })
	}, [])

	useEffect(() => {
		form.setFieldsValue({
			...layout?.data,
			role: layout?.data?.role?.id,
			parent: layout?.data?.parent?.id,

		})

		setDocuments(layout?.data?.userDocuments || []);
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>ADD USER</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={12}>
						<Form.Item
							label="Full Name"
							name="fullName"
							rules={[{ required: true, message: 'Full Name is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Username"
							name="username"
							rules={[{ required: true, message: 'Last Name is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Email"
							name="email"
							rules={[{ required: true, message: 'Email is Required!' }]}
						>
							<Input type="email" />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Password"
							name="password"
							hidden={layout?.update}
							rules={[{ required: !layout?.update, message: 'Confirm Password is Required!' }]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Confirm Password"
							name="cpassword"
							hidden={layout?.update}
							rules={[{ required: !layout?.update, message: 'Confirm Password is Required!' }]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[{ required: true, message: 'Phone is Required!' }]}
						>
							<Input type="number" prefix={<MobileOutlined />} />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Role"
							name="role"
							rules={[{ required: true, message: 'Organization is Required!' }]}
						>
							<Select allowClear onChange={(e: any) => e === '6076adac4fb3af290d64a807' ? setIsCoach(true) : setIsCoach(false)}>
								{Roles?.map((role: any, index: number) => {
									return (
										<Option value={role.id}>{role.name}</Option>
									)
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col lg={12} hidden={!isCoach}>
						<Form.Item
							label="Parent"
							name="parent"
							rules={[{ required: isCoach, message: 'Organization is Required!' }]}
						>
							<Select allowClear >
								{provinces && provinces?.map((user: any, index: number) => {
									return (
										<Option value={user?.id}>{user?.fullName}</Option>

									)
								})}
							</Select>
						</Form.Item>
						<Typography.Text type="danger">{provinces?.length > 0 ? '' : 'Please add atleast one Province Technical Co Ordinator'}</Typography.Text>
					</Col>

					<Col lg={13}>
						<Divider />
						<Typography.Title level={5}>Documents</Typography.Title>

						<Form.List name="userDocuments">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field: any, index: number) => (
										<Row key={field.key} align="middle" gutter={[20, 20]} style={{ marginBottom: 20 }}>

											<Col lg={16}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}
													label={index > 0 ? false : 'Title'}
													name={[field.name, 'title']}
													fieldKey={[field.fieldKey, 'title']}
													rules={[{ required: true, message: 'Required' }]}
												>
													<Input />
												</Form.Item>
											</Col>

											<Col lg={8}>
												<Space>
													<Form.Item
														{...field}
														style={{ marginBottom: 0 }}

														label={index > 0 ? false : 'Document'}
														name={[field.name, 'file']}
														fieldKey={[field.fieldKey, 'file']}
													>
														<FileUpload type='file'
															url={documents.length > 0 && documents[index]?.file}
															onUpload={(e: any) => handleOnUploadDocument(e, index)} />
														<Input hidden />
													</Form.Item>

													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space>
											</Col>
										</Row>
									))}

									<Row>
										<Col lg={17}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Add Documents
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
						</Form.List>
					</Col>

					<Col lg={24}>
						<Form.Item>
							<Button loading={users.creating || users.updating} type="primary" htmlType="submit">
								SUBMIT
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default UserForm;
