import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createActivityFailure,
	createActivitySuccess,
	deleteActivityFailure,
	deleteActivitySuccess,
	fetchActivityFailure,
	fetchActivitysFailure,
	fetchActivitysSuccess,
	fetchActivitySuccess,
	updateActivityFailure,
	updateActivitySuccess
} from './ActivityActions';
import { cutActivity, makeActivity, modifyActivity, takeActivity, takeActivitys } from './ActivityApis';
import {
	CreateActivityParams,
	FETCH_ACTIVITYS,
	UPDATE_ACTIVITY,
	ActivityDetailsParams,
	ActivityFetchParams,
	FETCH_ACTIVITY_DETAILS,
	CREATE_ACTIVITY,
	REMOVE_ACTIVITY
} from './ActivityTypes';

//get activitys
const getActivitysAsync = async (data: ActivityFetchParams) => await takeActivitys(data);

function* getActivitys(params: any) {
	const { payload } = params;
	try {
		const activitys = yield call(getActivitysAsync, payload);
		if (activitys) {
			yield put(fetchActivitysSuccess(activitys.data));
		} else {
			yield put(fetchActivitysFailure('Could not get list of activitys'));
			notification.error({
				message: 'Get Activity List',
				description: 'Could not get list of activitys'
			});
		}
	} catch (error) {
		yield put(fetchActivitysFailure('Network Error while getting Activitys'));
		notification.error({
			message: 'Get Activity List',
			description: 'Network Error while getting Activitys'
		});
	}
}

function* watchGetActivitys() {
	yield takeLatest(FETCH_ACTIVITYS, getActivitys);
}

//get activity details
const getActivityAsync = async (data: ActivityDetailsParams) => await takeActivity(data);

function* getActivity(params: any) {
	const { payload } = params;
	try {
		const activitys = yield call(getActivityAsync, payload);
		if (activitys) {
			yield put(fetchActivitySuccess(activitys.data));
		} else {
			yield put(fetchActivityFailure('Could not get list of activitys'));
			notification.error({
				message: 'Get Activity',
				description: 'Could not get list of activitys'
			});
		}
	} catch (error) {
		yield put(fetchActivityFailure('Network Error while getting Activitys'));
		notification.error({
			message: 'Get Activity',
			description: 'Network Error while getting Activitys'
		});
	}
}

function* watchGetActivity() {
	yield takeLatest(FETCH_ACTIVITY_DETAILS, getActivity);
}

//create Activity
const generateActivityAsync = async (data: CreateActivityParams) => await makeActivity(data);

function* generateActivity(params: any) {
	const { payload } = params;
	try {
		const activitys = yield call(generateActivityAsync, payload);
		if (activitys) {
			yield put(createActivitySuccess(activitys.data));
			yield put(setLayout({ form: '', data: false, update: false }));
			notification.success({
				message: 'Create Activity',
				description: 'Activity created successfully'
			});
		} else {
			const message = 'Could not create Activity';
			yield put(createActivityFailure(message));
			notification.error({
				message: 'Create Activity',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Activity';
		yield put(createActivityFailure(message));
		notification.error({
			message: 'Create Activity',
			description: message
		});
	}
}

function* watchGenerateActivity() {
	yield takeLatest(CREATE_ACTIVITY, generateActivity);
}

//update Activity
const amendActivityAsync = async (data: CreateActivityParams) => await modifyActivity(data);

function* amendActivity(params: any) {
	const { payload } = params;
	try {
		const activitys = yield call(amendActivityAsync, payload);
		if (activitys) {
			yield put(updateActivitySuccess(activitys.data));
			yield put(setLayout({ form: '', data: false, update: false }));

			notification.success({
				message: 'Update Activity',
				description: 'Activity updated successfully'
			});
		} else {
			const message = 'Could not Update Activity';
			yield put(updateActivityFailure(message));
			notification.error({
				message: 'Update Activity',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Activity';
		yield put(updateActivityFailure(message));
		notification.error({
			message: 'Update Activity',
			description: message
		});
	}
}

function* watchAmendActivity() {
	yield takeLatest(UPDATE_ACTIVITY, amendActivity);
}

//Delete Activity
const removeActivityAsync = async (data: ActivityDetailsParams) => await cutActivity(data);

function* removeActivity(params: any) {
	const { payload } = params;
	try {
		const activitys = yield call(removeActivityAsync, payload);
		if (activitys) {
			yield put(deleteActivitySuccess(activitys.data));
			notification.success({
				message: 'Activity Deleted',
				description: 'Activity deleted successfully'
			});
		} else {
			const message = 'Could not delete Activity';
			yield put(deleteActivityFailure(message));
			notification.error({
				message: 'Activity Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Activity';
		yield put(deleteActivityFailure(message));
		notification.error({
			message: 'Activity Deleted',
			description: message
		});
	}
}

function* watchRemoveActivity() {
	yield takeLatest(REMOVE_ACTIVITY, removeActivity);
}

export default function* ActivitySagas() {
	yield all([
		fork(watchGetActivitys),
		fork(watchGetActivity),
		fork(watchGenerateActivity),
		fork(watchAmendActivity),
		fork(watchRemoveActivity)
	]);
}
