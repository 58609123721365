export const SchoolKeys: any[] = [
	{
		title: 'School Details',
		keys: [
			'name',
			'address',
			'contact',
			'email',
			'website',
			'classLevels',
			'totalStudents',
			'principalName',
			'inchargeECA',
			'sportsTeacher',
			'sportLists',
			'groundSituation',
			'groundSize',
			'totalRugbyPlayers'
		]
	}
];

export const activityKey: any[] = [
	{
		title: 'Activity Details',
		keys: [ 'title', 'description', 'media', 'type' ]
	}
];

export const PlayerKeys: any[] = [
	{
		title: 'Basic Details',
		keys: [
			'name',
			'address',
			// 'mobile',
			'gender',
			'email',
			'facebook',
			'dob',
			'club',
			'district',
			'province'
		]
	},
	// {
	// 	title: 'Emergency Contact',
	// 	keys: [
	// 		'emergencyContactName',
	// 		'emergencyContactAddress',
	// 		'emergencyContactRelation',
	// 		'emergencyContactTelephone',
	// 		'emergencyContactMobile'
	// 	]
	// },
	{
		title: 'Health and Fitness Assessment',
		keys: [ 'otherSports', 'trainingHrsPerWeek', 'playedRugbyBefore', 'pastPlaysDescription', 'height', 'weight' ]
	}
	// {
	// 	title: 'Signatures',
	// 	keys: [ 'profileCompletionDate', 'followUpDate' ]
	// }
];

export const SessionKeys: any[] = [
	{
		title: 'Tranining Session',
		keys: [
			'venue',
			'sessionDate',
			'startTime',
			'endTime',
			'group',
			'ageGroup',
			'noOfPlayers',
			'coach',
			'equipments',
			'assistantCoach',
			'outline'
		]
	}
];

export const PerformanceKeys: any[] = [
	{
		title: 'Attack',
		subTitles: [
			'Gaining Possession',
			'Going Forward',
			'Support',
			'Continuity in Rucks and Mauls',
			'Pressure-Possession Field Position and Pace',
			'Turnovers-Ball Lost By Your Team'
		]
	},
	{
		title: 'Defence',
		subTitles: [
			'Contesting Possession',
			'Going Forward',
			'Applying Pressure By Tackling',
			'Preventing Territorial Gain - Quality of Tackling',
			'Support',
			'Regaining Possession/ Turnovers-Ball Won By Your Team',
			'Counter Attack'
		]
	}
];

export const PerformanceTitleKeys: any = {
	gainingPossesion: 'Gaining Possession',
	goingForward: 'Going Forward',
	contInRuckMaul: 'Continuity in Rucks and Mauls',
	support: 'Support',
	pressurePositionField: 'Pressure-Possession Field Position and Pace',
	turnoverBallLost: 'Turnovers-Ball Lost By Your Team',
	contestingPossession: 'Contesting Possession',
	goingForwardd: 'Going Forward',
	applyingPressureByTackling: 'Applying Pressure By Tackling',
	preventingTerrirorialGain: 'Preventing Territorial Gain - Quality of Tackling',
	supportd: 'Support',
	regainingPossession: 'Regaining Possession/ Turnovers-Ball Won By Your Team',
	counterAttack: 'Counter Attack'
};


export const IPlayerKey = [
	{
		name: "District",
		key: "iDistrict",
	},
	{
		name: "Province",
		key: "iProvince",
	}
]