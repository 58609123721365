import { LeftSquareTwoTone, MinusCircleOutlined, PlusOutlined, RightSquareTwoTone } from "@ant-design/icons"
import { Avatar, Button, Carousel, Col, DatePicker, Divider, Form, Input, notification, Row, Select, Space, Switch, Typography } from "antd"
import { CarouselRef } from "antd/lib/carousel";
import form from "antd/lib/form"
import TextArea from "antd/lib/input/TextArea";
import { getUserId } from "Api/Api";
import AttendanceItem from "Components/AttendanceItem/AttendanceItem";
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { makeAttendance } from "Redux/AttendanceRedux/AttendanceApis";
import { createForm, updateForm } from "Redux/FormRedux/FormActions";
import { formInitialValues, GetCreateFormData } from "Utils/GetCreateFormData";
import './AttendanceForm.scss';
const { Option } = Select;
export const AttendanceForm = (props: any) => {
    const [steps, setSteps] = useState<any>(['step_1']);
    const [isOptions, setIsOptions] = useState<string[]>([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<any>(false);
    const ref = useRef<any>();
    const layout = useSelector((state: any) => state.LayoutReducer);
    const attendance = useSelector((state: any) => state.AttendanceReducer);
    const { players, session } = layout?.data;
    const [attData, setAttData] = useState<any>([])
    useEffect(() => {
        ref.current?.next();
    }, [])


    useEffect(() => {
        setAttData([])
        if (layout.data) {
            let steps: any = [];
            layout.data?.steps?.map((d: any, i: number) => {
                steps = steps.concat(`step_${i + 1}`);
            })
            setSteps(steps)
        }
    }, [layout])

    const handleAttendanceRecord = (params: any) => {
        let tempAtt = attData.filter((d: any) => d.id !== params.id);
        setAttData([...tempAtt, params]);
        ref.current?.next();
    }

    const checkAttendanceRecord = (id: any) => {
        return attData.filter((d: any) => d.id === id)[0]?.type;
    }

    const handleSubmit = (value: any) => {
        makeAttendance({ session: session, sessionId: session, list: attData, createdBy: getUserId(), date: value?.date }).then(() => {
            setLoading(false);
            dispatch(setLayout({ form: false, update: false, data: false }));
            notification.success({ message: 'Success', description: 'Attendance Submitted Successfully' })
        }).catch(() => {
            notification.error({ message: 'Failed', description: 'Attendance Could not be recorded' });

        })
    }

    useEffect(() => {
        if (attendance?.created) {
            dispatch(setLayout({ form: false, update: false, data: false }))
        }
    }, [attendance])
    return (
        <Row gutter={[20, 20]} justify="center">
            <Col lg={16}>
                <Carousel
                    className="attendance__slider"
                    ref={ref}
                    draggable
                    dots={false}
                    arrows
                    initialSlide={0}
                    infinite={false}
                    nextArrow={<RightSquareTwoTone />}
                    prevArrow={<LeftSquareTwoTone />}
                // afterChange={onChange}
                >

                    {players && players?.map((d: any, i: number) => {
                        return <AttendanceItem onClick={(d: any) => handleAttendanceRecord(d)} data={d} type={checkAttendanceRecord(d.id)} />
                    })}
                </Carousel>
            </Col>
            {attData?.length === players?.length &&
                <Col lg={12}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Form.Item name="date"
                            rules={[{ required: true, message: 'Date is Required!' }]}
                            label="Date">
                            <DatePicker />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" block loading={attendance?.creating}>SUBMIT</Button>

                        </Form.Item>
                    </Form>
                </Col>
            }
        </Row>
    )
}