import {
	CreateWorkshopParams,
	CREATE_WORKSHOP,
	CREATE_WORKSHOP_FAILURE,
	CREATE_WORKSHOP_SUCCESS,
	FETCH_WORKSHOPS,
	FETCH_WORKSHOPS_FAILURE,
	FETCH_WORKSHOPS_SUCCESS,
	FETCH_WORKSHOP_DETAILS,
	FETCH_WORKSHOP_DETAILS_FAILURE,
	FETCH_WORKSHOP_DETAILS_SUCCESS,
	REMOVE_WORKSHOP,
	REMOVE_WORKSHOP_FAILURE,
	REMOVE_WORKSHOP_SUCCESS,
	UPDATE_WORKSHOP,
	UPDATE_WORKSHOP_FAILURE,
	UPDATE_WORKSHOP_SUCCESS,
	WorkshopDetailsParams,
	WorkshopFetchParams
} from './WorkshopTypes';

//fetch Workshop List
export const fetchWorkshops = (params: WorkshopFetchParams) => ({
	type: FETCH_WORKSHOPS,
	payload: params
});

export const fetchWorkshopsSuccess = (response: any) => ({
	type: FETCH_WORKSHOPS_SUCCESS,
	payload: response
});

export const fetchWorkshopsFailure = (error: any) => ({
	type: FETCH_WORKSHOPS_FAILURE,
	payload: error
});

//fetch Workshop Details
export const fetchWorkshop = (params: WorkshopDetailsParams) => ({
	type: FETCH_WORKSHOP_DETAILS,
	payload: params
});

export const fetchWorkshopSuccess = (response: any) => ({
	type: FETCH_WORKSHOP_DETAILS_SUCCESS,
	payload: response
});

export const fetchWorkshopFailure = (error: any) => ({
	type: FETCH_WORKSHOP_DETAILS_FAILURE,
	payload: error
});

//create Workshop
export const createWorkshop = (params: CreateWorkshopParams) => ({
	type: CREATE_WORKSHOP,
	payload: params
});

export const createWorkshopSuccess = (response: CreateWorkshopParams) => ({
	type: CREATE_WORKSHOP_SUCCESS,
	payload: response
});
export const createWorkshopFailure = (error: any) => ({
	type: CREATE_WORKSHOP_FAILURE,
	payload: error
});

//update Workshop
export const updateWorkshop = (params: CreateWorkshopParams) => ({
	type: UPDATE_WORKSHOP,
	payload: params
});

export const updateWorkshopSuccess = (response: CreateWorkshopParams) => ({
	type: UPDATE_WORKSHOP_SUCCESS,
	payload: response
});

export const updateWorkshopFailure = (error: any) => ({
	type: UPDATE_WORKSHOP_FAILURE,
	payload: error
});

//delete Workshop
export const deleteWorkshop = (params: WorkshopDetailsParams) => ({
	type: REMOVE_WORKSHOP,
	payload: params
});
export const deleteWorkshopSuccess = (response: WorkshopDetailsParams) => ({
	type: REMOVE_WORKSHOP_SUCCESS,
	payload: response
});
export const deleteWorkshopFailure = (error: any) => ({
	type: REMOVE_WORKSHOP_FAILURE,
	payload: error
});
