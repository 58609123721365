import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import { takeTournamentCount } from "Api/CounteApi";
import { IsSelfOnly } from "Utils/AccessControl";
import { useDebounce } from "use-debounce/lib";
import { fetchTournaments } from "Redux/TournamentRedux/TournamentActions";
import DetailsView from "Components/DetailsView/DetailsView";
import { SelfSubmitted } from "Utils/ComplexQueries";

export const TournamentList = (props: any) => {
  const users = useSelector((state: any) => state.TournamentReducer);

  const dispatch = useDispatch();

  const [filterText, setFilterText] = React.useState("");

  const [searchText] = useDebounce(filterText, 500);

  const [perPageData, setPerPageData] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<any>(false);

  const [count, setCount] = useState(0);

  const getCount = async (params: any) => {
    await takeTournamentCount({
      ...params,
      name_contains: searchText.toLowerCase(),
    })
      .then((d) => {
        setCount(d?.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <TableHeader
        onFilter={(e: FormEvent<HTMLInputElement>) =>
          setFilterText(e.currentTarget.value)
        }
        onClear={handleClear}
        // filterText={filterText}
        title="TOURNAMENTS"
        module="tournament"
        search
      />
    );
  }, [filterText, resetPaginationToggle]);

  const fetchWithFilter = (data: any) => {
    dispatch(
      fetchTournaments({
        ...data,
        _sort: "createdAt:DESC",
        name_contains: filterText.toLowerCase(),
      })
    );
  };
  useEffect(() => {
    fetchWithFilter({ _limit: 10, ...(IsSelfOnly() ? SelfSubmitted() : {}) });
    getCount(IsSelfOnly() ? SelfSubmitted() : {});
  }, [searchText]);
  //Row and Per page change
  const handlePerRowsChange = (perPage: number, page: number) => {
    setPerPageData(perPage);
    fetchWithFilter({ _start: perPage * (page - 1), _limit: perPage });
  };
  const handlePageChange = (page: number) => {
    fetchWithFilter({ _start: perPageData * (page - 1), _limit: perPageData });
  };

  const onRowClicked = (values: any) => {
    setShowDetails(true);
    setDetails(values);
  };

  const onEditHandler = (values: any) => {
    dispatch(setLayout({ form: "tournament", data: values, update: true }));
  };

  const onDeleteHandler = (id: number) => {
    // dispatch(deleteAsset(id))
  };
  const onAddHandler = (data: any) => {
    dispatch(setLayout({ form: "match", data: data, update: false }));
    // dispatch(deleteAsset(id))
  };

  const assetColumns: TableColumn[] = [
    {
      name: "Name",
      selector: "name",
      cell: (row: any) => <Typography.Link>{row?.name}</Typography.Link>,
    },
    {
      name: "Location",
      selector: "location",
      cell: (row: any) => <Typography.Text>{row?.location}</Typography.Text>,
    },
    {
      name: "Type",
      selector: "type",
      cell: (row: any) => (
        <Typography.Text style={{ textTransform: "uppercase" }}>
          {row?.type}
        </Typography.Text>
      ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row: any) => (
        <TableActions
          addTitle="Add Matches"
          addData
          onAdd={() => onAddHandler(row)}
          noDelete
          noReport
          onEdit={() => onEditHandler(row)}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchWithFilter(IsSelfOnly() ? SelfSubmitted() : {});
    getCount(IsSelfOnly() ? SelfSubmitted() : {});
  }, [0]);

  return (
    <MyContent>
      <DataTable
        title={subHeaderComponentMemo}
        columns={assetColumns}
        data={users?.list}
        progressPending={users?.loading}
        // noHeader
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        pagination
        onRowClicked={onRowClicked}
      />
      {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
      <DetailsView
        data={details}
        show={showDetails}
        module="tournament"
        onHide={() => setDetails(false)}
      />
    </MyContent>
  );
};

export default TournamentList;
