import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Typography } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import DetailsView from "Components/DetailsView/DetailsView";
import { SchoolKeys } from "Models/KeyMapModels";
import { fetchSchools } from "Redux/SchoolRedux/SchoolActions";
import { takeSchoolCount } from "Api/CounteApi";
import { useDebounce } from "use-debounce/lib";

export const SchoolList = (props: any) => {
    const schools = useSelector((state: any) => state.SchoolReducer)
    const dataList = schools?.list || [];
    const history = useHistory();

    const dispatch = useDispatch();

    const [filterText, setFilterText] = React.useState('');
    const [searchText] = useDebounce(filterText, 500)

    const [perPageData, setPerPageData] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = dataList?.filter(
        (item: any) => item.fullName && item.fullName.toLowerCase().includes(filterText.toLowerCase())
    );

    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState<any>(false);



    const [count, setCount] = useState(0);

    const getCount = async () => {
        await takeSchoolCount({ name_contains: filterText.toLowerCase() }).then((d) => {
            setCount(d?.data)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const subHeaderComponentMemo = React.useMemo(
        () => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <TableHeader
                    onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
                    onClear={handleClear}
                    // filterText={filterText}
                    title="SCHOOL LIST"
                    module="school"
                    search
                />
            );
        },
        [filterText, resetPaginationToggle]
    );


    const fetchWithFilter = (data: any) => {
        dispatch(fetchSchools({ ...data, _sort: 'createdAt:DESC', name_contains: filterText.toLowerCase() }))
    }
    const handlePerRowsChange = (perPage: number, page: number) => {
        setPerPageData(perPage);
        fetchWithFilter({ _start: perPage * (page - 1), _limit: perPage })
    };
    const handlePageChange = (page: number) => {
        fetchWithFilter({ _start: perPageData * (page - 1), _limit: perPageData });
    };

    useEffect(() => {
        getCount();
        fetchWithFilter({})
    }, [searchText])

    const onRowClicked = (values: any) => {
        setDetails(values)
    }

    const onEditHandler = (values: any) => {
        dispatch(setLayout({ form: 'school', data: values, update: true }));
    }

    const onDeleteHandler = (id: number) => {
        // dispatch(deleteAsset(id))
    }
    const onAddHandler = (data: any) => {
        dispatch(setLayout({ form: 'reporting', data: data, update: false }))
        // dispatch(deleteAsset(id))
    }


    useEffect(() => {
        fetchWithFilter({ _limit: 10 })
        getCount();
    }, [0])
    const assetColumns: TableColumn[] = [
        {
            name: 'Name',
            selector: 'name',
            cell: (row: any) => <Typography.Link onClick={() => history.push(`/schools/${row.id}`)}>{row?.name}</Typography.Link>
        },
        {
            name: 'Address',
            selector: 'address',

        },
        {
            name: 'Email',
            selector: 'email',
            cell: (row: any) => <Typography.Link>{row?.email}</Typography.Link>
        },
        {
            name: 'Action',
            selector: '',
            cell: (row: any) => <TableActions noDelete noReport onEdit={() => onEditHandler(row)} />
        }
    ];
    return (
        <MyContent>
            <DataTable
                title={subHeaderComponentMemo}
                columns={assetColumns}
                data={schools?.list}
                progressPending={schools?.loading}
                // noHeader
                paginationServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                onRowClicked={onRowClicked}

            />
            {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
            <DetailsView module="school" labels={SchoolKeys} data={details} onHide={() => setDetails(false)} />

        </MyContent>
    )
}

export default SchoolList;