export const FETCH_ATTENDANCES = 'FETCH_ATTENDANCES';
export const FETCH_ATTENDANCES_SUCCESS = 'FETCH_ATTENDANCES_SUCCESS';
export const FETCH_ATTENDANCES_FAILURE = 'FETCH_ATTENDANCES_FAILURE';

export const FETCH_ATTENDANCE_DETAILS = 'FETCH_ATTENDANCE_DETAILS';
export const FETCH_ATTENDANCE_DETAILS_SUCCESS = 'FETCH_ATTENDANCE_DETAILS_SUCCESS';
export const FETCH_ATTENDANCE_DETAILS_FAILURE = 'FETCH_ATTENDANCE_DETAILS_FAILURE';

export const CREATE_ATTENDANCE = 'CREATE_ATTENDANCE';
export const CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS';
export const CREATE_ATTENDANCE_FAILURE = 'CREATE_ATTENDANCE_FAILURE';

export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS';
export const UPDATE_ATTENDANCE_FAILURE = 'UPDATE_ATTENDANCE_FAILURE';

export const REMOVE_ATTENDANCE = 'REMOVE_ATTENDANCE';
export const REMOVE_ATTENDANCE_SUCCESS = 'REMOVE_ATTENDANCE_SUCCESS';
export const REMOVE_ATTENDANCE_FAILURE = 'REMOVE_ATTENDANCE_FAILURE';

export interface AttendanceFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface AttendanceDetailsParams {
	id?: number;
}
export interface CreateAttendanceParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
	session?: string;
	list?: any;
	createdBy?: any;
	date?: any;
	sessionId?: any;
}
