export const FETCH_SCHOOLS = 'FETCH_SCHOOLS';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';

export const FETCH_SCHOOL_DETAILS = 'FETCH_SCHOOL_DETAILS';
export const FETCH_SCHOOL_DETAILS_SUCCESS = 'FETCH_SCHOOL_DETAILS_SUCCESS';
export const FETCH_SCHOOL_DETAILS_FAILURE = 'FETCH_SCHOOL_DETAILS_FAILURE';

export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAILURE = 'CREATE_SCHOOL_FAILURE';

export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_FAILURE = 'UPDATE_SCHOOL_FAILURE';

export const REMOVE_SCHOOL = 'REMOVE_SCHOOL';
export const REMOVE_SCHOOL_SUCCESS = 'REMOVE_SCHOOL_SUCCESS';
export const REMOVE_SCHOOL_FAILURE = 'REMOVE_SCHOOL_FAILURE';

export interface SchoolFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface SchoolDetailsParams {
	id?: number;
}
export interface CreateSchoolParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
