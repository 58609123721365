import { PlusOutlined, MinusCircleOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Space, Col, Form, Input, Row, Select, Divider, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { Option } from 'antd/lib/mentions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, updateUser } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { takeUsers } from 'Redux/UserRedux/UserApis';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';


export const UserForm = (props: any) => {
	const dispatch = useDispatch();
	const users = useSelector((state: any) => state.UserReducer)
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [provinces, setProvinces] = useState<any>([]);

	const [documents, setDocuments] = useState<any[]>([])

	const [isCoach, setIsCoach] = useState(false);


	const handleOnUploadDocument = (e: any, i: any) => {
		let tempTeams = form.getFieldsValue()?.userDocuments;
		let currentTeam: any = tempTeams[i];
		currentTeam = { ...currentTeam, file: e?.url }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ userDocuments: tempTeams })
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateUser({ ...values, id: layout?.data?.id }))
		}
		else {
			dispatch(createUser(values))
		}
	};

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	}

	const fetchUsers = (data: any) => {
		takeUsers(data).then((d: any) => {
			setProvinces(d?.data)
		}).catch((err: any) => {
			console.log('Error loading provinces')
		})
	}
	useEffect(() => {
		fetchUsers({ _where: qs.stringify(complexQuery) })
	}, [])

	useEffect(() => {
		form.setFieldsValue({
			...layout?.data,
			role: layout?.data?.role?.id,
			parent: layout?.data?.parent?.id,

		})

		setDocuments(layout?.data?.userDocuments || []);
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>CHANGE PASSWORD</Typography.Title>
			<Typography.Title level={5}>{layout?.data?.fullName}</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>

					<Col lg={13}>
						<Form.Item
							label="Password"
							name="password"
							rules={[{ required: true, message: 'Confirm Password is Required!' }]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Confirm Password"
							name="password"
							// hidden={layout?.update}
							rules={[{ required: true, message: 'Confirm Password is Required!' }]}
						>
							<Input.Password />
						</Form.Item>
					</Col>


					<Col lg={24}>
						<Form.Item>
							<Button loading={users.creating || users.updating} type="primary" htmlType="submit">
								SUBMIT
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default UserForm;
