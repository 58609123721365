import { EnvironmentOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { Option } from 'antd/lib/mentions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchUsers } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { createSchool, updateSchool } from 'Redux/SchoolRedux/SchoolActions';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';
import { createActivity, updateActivity } from 'Redux/ActivityRedux/ActivityActions';
import { GetUploadUrl } from 'Utils/GetUploadResponse';
import { getUserId } from 'Api/Api';

export const ActivityForm = (props: any) => {
	const dispatch = useDispatch();
	const schools = useSelector((state: any) => state.ActivityReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [imageUrl, setImageUrl] = useState<any[]>([]);
	const [imageData, setImageData] = useState<any[]>([]);


	const handleOnUpload = (e: any, i: any) => {
		let tempUrl = imageUrl;
		tempUrl[i] = e;
		imageData[i] = e;
		setImageUrl(tempUrl)

		// form.setFieldsValue({ image: [...form.getFieldValue('media'),e]})
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateActivity({ ...values, id: layout?.data?.id, media: imageData }));
			// console.log({ ...values, media: imageData});

		}
		else {
			dispatch(createActivity({ ...values, submitedBy: getUserId(), media: imageData }));

		}
	};

	const complexQuery = {
		_where: {
			_or: [
				[{ 'role.id_eq': '6076ad8e4fb3af290d64a746' }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};

	useEffect(() => {
		dispatch(fetchUsers({ _where: qs.stringify(complexQuery) }));
	}, []);

	useEffect(() => {
		if (layout?.data) setImageUrl(layout?.data?.media?.map((d: any, i: number) => GetUploadUrl([d])))
		if (layout?.data) setImageData(layout?.data?.media);
		form.setFieldsValue({ ...layout?.data, sportLists: layout?.data?.sportLists?.split(',') })
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>{layout?.update ? 'UPDATE' : 'ADD'} ACTIVITY</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={24}>
						<Form.Item
							label="Title"
							name="title"
							rules={[{ required: true, message: 'Title is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Form.Item name="submitedBy" hidden={true}>
						<Input value={getUserId()} />
					</Form.Item>
					<Col lg={12}>
						<Form.Item
							label="Description"
							name="description"
							rules={[{ required: true, message: 'Description is Required!' }]}
						>
							<TextArea />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Type"
							name="type"
							rules={[{ required: true, message: 'Type Level is Required!' }]}
						>
							<Select>
								<Option value="Daily Activity">Daily Activity</Option>
								<Option value="School Activity">School Activity</Option>
								<Option value="Monthly Activity">Monthly Activity</Option>
								<Option value="Club Activity">Club Activity</Option>
								<Option value="Other Activity">Other Activity</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Media"
							name="media"
						// rules={[ { required: true, message: 'Type Level is Required!' } ]}
						>
							{imageUrl?.map((d: any, i: number) => {
								return <FileUpload key={i} url={d} onUpload={(e: any) => handleOnUpload(e, i)} />
							})}
							<FileUpload onUpload={(e: any) => handleOnUpload(e, imageUrl.length)} />
							<Input hidden />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item>
							<Button loading={schools?.creating || schools?.updating} type="primary" htmlType="submit">
								{layout?.update ? 'Update' : 'SUBMIT'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default ActivityForm;
