import API from 'Api/Api';
import { CreatePerformanceParams, PerformanceDetailsParams, PerformanceFetchParams } from './PerformanceTypes';

//get Performance
export const takePerformances = (data: PerformanceFetchParams) => {

		if (data._where) {
		return API({
			url: `/performances?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/performances',
			method: 'GET',
			params: data
		});
	}
};

//get Performance Details
export const takePerformance = (data: PerformanceDetailsParams) => {
	return API({
		url: '/performances',
		method: 'GET',
		params: data
	});
};

//create Performance Details
export const makePerformance = (data: CreatePerformanceParams) => {
	return API({
		url: '/performances',
		method: 'POST',
		data: data
	});
};

//modify Performance
export const modifyPerformance = (data: CreatePerformanceParams) => {
	return API({
		url: `/performances/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Performance
export const cutPerformance = (data: PerformanceDetailsParams) => {
	return API({
		url: '/performances',
		method: 'DELETE',
		params: data
	});
};
