import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/charts';
import { BarConfig } from '@ant-design/charts/es/bar';
import MyContent from 'Components/Content/Content';
import { Divider, Typography } from 'antd';
import { useSelector } from 'react-redux';

export const ProvinceVsTraining: React.FC = () => {
	const data = useSelector((state: any) => state.AnalysisReducer)
	
	var config: BarConfig = {
		data: data?.trainingVsProvince,
		isGroup: true,
		xField: 'value',
		yField: 'label',
		seriesField: 'type',
		marginRatio: 0,
		label: {
			position: 'middle',
			layout: [
				{ type: 'interval-adjust-position' },
				{ type: 'interval-hide-overlap' },
				{ type: 'adjust-color' }
			]
		},
		color: ['#fa8334', '#271033'],
		style: {
			height: 400
		}
	};
	return (
		<MyContent style={{ height: '100%' }}>
			<Typography.Title level={3}>Province vs Trainings</Typography.Title>
			<Divider />
			<Bar {...config} />
		</MyContent>
	);
};

export default ProvinceVsTraining;
