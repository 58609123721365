import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createMatchFailure,
	createMatchSuccess,
	deleteMatchFailure,
	deleteMatchSuccess,
	fetchMatchFailure,
	fetchMatchsFailure,
	fetchMatchsSuccess,
	fetchMatchSuccess,
	updateMatchFailure,
	updateMatchSuccess
} from './MatchActions';
import { cutMatch, makeMatch, modifyMatch, takeMatch, takeMatchs } from './MatchApis';
import {
	CreateMatchParams,
	FETCH_MATCHS,
	UPDATE_MATCH,
	MatchDetailsParams,
	MatchFetchParams,
	FETCH_MATCH_DETAILS,
	CREATE_MATCH,
	REMOVE_MATCH
} from './MatchTypes';

//get matchs
const getMatchsAsync = async (data: MatchFetchParams) => await takeMatchs(data);

function* getMatchs(params: any) {
	const { payload } = params;
	try {
		const matchs: AxiosResponse = yield call(getMatchsAsync, payload);
		if (matchs) {
			yield put(fetchMatchsSuccess(matchs.data));
		} else {
			yield put(fetchMatchsFailure('Could not get list of matchs'));
			notification.error({
				message: 'Get Match List',
				description: 'Could not get list of matchs'
			});
		}
	} catch (error) {
		yield put(fetchMatchsFailure('Network Error while getting Matchs'));
		notification.error({
			message: 'Get Match List',
			description: 'Network Error while getting Matchs'
		});
	}
}

function* watchGetMatchs() {
	yield takeLatest(FETCH_MATCHS, getMatchs);
}

//get match details
const getMatchAsync = async (data: MatchDetailsParams) => await takeMatch(data);

function* getMatch(params: any) {
	const { payload } = params;
	try {
		const matchs: AxiosResponse = yield call(getMatchAsync, payload);
		if (matchs) {
			yield put(fetchMatchSuccess(matchs.data));
		} else {
			yield put(fetchMatchFailure('Could not get list of matchs'));
			notification.error({
				message: 'Get Match',
				description: 'Could not get list of matchs'
			});
		}
	} catch (error) {
		yield put(fetchMatchFailure('Network Error while getting Matchs'));
		notification.error({
			message: 'Get Match',
			description: 'Network Error while getting Matchs'
		});
	}
}

function* watchGetMatch() {
	yield takeLatest(FETCH_MATCH_DETAILS, getMatch);
}

//create Match
const generateMatchAsync = async (data: CreateMatchParams) => await makeMatch(data);

function* generateMatch(params: any) {
	const { payload } = params;
	try {
		const matchs: AxiosResponse = yield call(generateMatchAsync, payload);
		if (matchs) {
			yield put(createMatchSuccess(matchs.data));
			notification.success({
				message: 'Create Match',
				description: 'Match created successfully'
			});
		} else {
			const message = 'Could not create Match';
			yield put(createMatchFailure(message));
			notification.error({
				message: 'Create Match',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Match';
		yield put(createMatchFailure(message));
		notification.error({
			message: 'Create Match',
			description: message
		});
	}
}

function* watchGenerateMatch() {
	yield takeLatest(CREATE_MATCH, generateMatch);
}

//update Match
const amendMatchAsync = async (data: CreateMatchParams) => await modifyMatch(data);

function* amendMatch(params: any) {
	const { payload } = params;
	try {
		const matchs: AxiosResponse = yield call(amendMatchAsync, payload);
		if (matchs) {
			yield put(updateMatchSuccess(matchs.data));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Update Match',
				description: 'Match updated successfully'
			});
		} else {
			const message = 'Could not Update Match';
			yield put(updateMatchFailure(message));
			notification.error({
				message: 'Update Match',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Match';
		yield put(updateMatchFailure(message));
		notification.error({
			message: 'Update Match',
			description: message
		});
	}
}

function* watchAmendMatch() {
	yield takeLatest(UPDATE_MATCH, amendMatch);
}

//Delete Match
const removeMatchAsync = async (data: MatchDetailsParams) => await cutMatch(data);

function* removeMatch(params: any) {
	const { payload } = params;
	try {
		const matchs: AxiosResponse = yield call(removeMatchAsync, payload);
		if (matchs) {
			yield put(deleteMatchSuccess(matchs.data));
			notification.success({
				message: 'Match Deleted',
				description: 'Match deleted successfully'
			});
		} else {
			const message = 'Could not delete Match';
			yield put(deleteMatchFailure(message));
			notification.error({
				message: 'Match Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Match';
		yield put(deleteMatchFailure(message));
		notification.error({
			message: 'Match Deleted',
			description: message
		});
	}
}

function* watchRemoveMatch() {
	yield takeLatest(REMOVE_MATCH, removeMatch);
}

export default function* MatchSagas() {
	yield all([
		fork(watchGetMatchs),
		fork(watchGetMatch),
		fork(watchGenerateMatch),
		fork(watchAmendMatch),
		fork(watchRemoveMatch)
	]);
}
