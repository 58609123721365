import { Avatar, Col, Row, Typography } from 'antd';
import React from 'react';

export const AttendanceItem = (props: any) => {
	const { data, type } = props;
	return (
		<div>
			<div style={{ background: '#cccccc', borderRadius: 10, padding: '50px 0px' }}>
				<Typography.Title level={5} style={{ textAlign: 'center' }} disabled>
					Training 2 / Session 5
				</Typography.Title>
				<Typography.Title style={{ textAlign: 'center', marginTop: 10, marginBottom: 50 }}>
					{data?.name}
				</Typography.Title>
				<Row gutter={[20, 20]} align="middle" justify="center">
					<Col>
						<a onClick={() => props.onClick({ id: data?.id, type: 'X', name: data?.name })}>
							<Avatar size={70} style={{ border: '2px solid white', background: type === 'X' ? 'red' : 'transparent' }}>
								X
							</Avatar>
						</a>
					</Col>
					<Col>
						<a onClick={() => props.onClick({ id: data?.id, type: 'A', name: data?.name })}>
							<Avatar size={70} style={{ border: '2px solid white', background: type === 'A' ? 'gray' : 'transparent' }}>
								A
							</Avatar>
						</a>
					</Col>
					<Col>
						<a onClick={() => props.onClick({ id: data?.id, type: 'I', name: data?.name })}>
							<Avatar size={70} style={{ border: '2px solid white', background: type === 'I' ? 'orange' : 'transparent' }}>
								I
							</Avatar>
						</a>
					</Col>
					<Col>
						<a onClick={() => props.onClick({ id: data?.id, type: 'P', name: data?.name })}>
							<Avatar size={70} style={{ border: '2px solid white', background: type === 'P' ? 'green' : 'transparent' }}>
								P
							</Avatar>
						</a>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default AttendanceItem;
