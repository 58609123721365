import API from 'Api/Api';
import { CreateMatchParams, MatchDetailsParams, MatchFetchParams } from './MatchTypes';

//get Match
export const takeMatchs = (data: MatchFetchParams) => {
	if(data?._where){		
	return API({
			url: `/data-matches?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/data-matches',
			method: 'GET',
			params: data
		});
	}
};

//get Match Details
export const takeMatch = (data: MatchDetailsParams) => {
	return API({
		url: '/data-matches',
		method: 'GET',
		params: data
	});
};

//create Match Details
export const makeMatch = (data: CreateMatchParams) => {
	return API({
		url: '/data-matches',
		method: 'POST',
		data: data
	});
};

//modify Match
export const modifyMatch = (data: CreateMatchParams) => {
	return API({
		url: `/data-matches/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Match
export const cutMatch = (data: MatchDetailsParams) => {
	return API({
		url: '/data-matches',
		method: 'DELETE',
		params: data
	});
};
