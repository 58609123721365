import { Reducer } from 'react';
import {
	CreateWorkshopParams,
	CREATE_WORKSHOP,
	CREATE_WORKSHOP_FAILURE,
	CREATE_WORKSHOP_SUCCESS,
	FETCH_WORKSHOPS,
	FETCH_WORKSHOPS_FAILURE,
	FETCH_WORKSHOPS_SUCCESS,
	FETCH_WORKSHOP_DETAILS,
	FETCH_WORKSHOP_DETAILS_FAILURE,
	FETCH_WORKSHOP_DETAILS_SUCCESS,
	REMOVE_WORKSHOP,
	REMOVE_WORKSHOP_FAILURE,
	REMOVE_WORKSHOP_SUCCESS,
	UPDATE_WORKSHOP,
	UPDATE_WORKSHOP_FAILURE,
	UPDATE_WORKSHOP_SUCCESS,
	WorkshopDetailsParams
} from './WorkshopTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const WorkshopReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Workshops
		case FETCH_WORKSHOPS:
			return { ...state, loading: true };
		case FETCH_WORKSHOPS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_WORKSHOPS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Workshop Details
		case FETCH_WORKSHOP_DETAILS:
			return { ...state, detailing: true };
		case FETCH_WORKSHOP_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_WORKSHOP_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Workshop
		case CREATE_WORKSHOP:
			return { ...state, creating: true };
		case CREATE_WORKSHOP_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_WORKSHOP_FAILURE:
			return { ...state, creating: false };

		//update Workshop
		case UPDATE_WORKSHOP:
			return { ...state, updating: true };
		case UPDATE_WORKSHOP_SUCCESS:
			const tempList = state.list.filter((d: CreateWorkshopParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_WORKSHOP_FAILURE:
			return { ...state, updating: false };

		//remove Workshop
		case REMOVE_WORKSHOP:
			return { ...state, deleting: true };
		case REMOVE_WORKSHOP_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: WorkshopDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_WORKSHOP_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
