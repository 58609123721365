import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { defaultFunction } from 'Redux/Constants';

export interface TableHeaderProps {
	title: string;
	module?: string;
	onFilter?: (e: any) => void;
	onClear?: () => void;
	noAddFeature?: boolean;
	addLocation?: boolean;
	data?: any;
	search?: boolean;
	rightExtra?: any;
	extraBottom?: any
}

export const TableHeader: React.FC<TableHeaderProps> = ({
	title,
	noAddFeature,
	module,
	onFilter = defaultFunction,
	addLocation,
	data,
	search,
	rightExtra,
	extraBottom
}) => {
	const dispatch = useDispatch();
	return (
		<Content>
			<Space
				align="center"
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%'
				}}
			>
				<Row gutter={[15, 15]} align="middle">
					<Col>
						<Typography.Title style={{ margin: 0, textTransform: 'uppercase' }} level={5}>
							{title}
						</Typography.Title>
					</Col>
					<Col>
						{!noAddFeature && (
							<Button
								type="primary"
								size="small"
								icon={<PlusOutlined />}
								style={{ display: 'block' }}
								onClick={() => dispatch(setLayout({ form: module, data: data }))}
							/>
						)}
					</Col>
					<Col>
						{addLocation && (
							<Button
								type="primary"
								size="middle"
								icon={<PlusOutlined />}
								style={{ display: 'block' }}
								onClick={() => dispatch(setLayout({ form: 'gir-events' }))}
							>
								Add Location/Event
							</Button>
						)}
					</Col>

				</Row>
				{rightExtra && rightExtra}
				{search && (
					<Input
						size="large"
						placeholder="Search ..."
						className="rounded-input"
						prefix={<SearchOutlined />}
						onChange={(e) => onFilter(e)}
					/>
				)}
			</Space>
			<Row>
				<Col lg={24}>
					{extraBottom}
				</Col>
			</Row>
			<Divider />
		</Content>
	);
};
