export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const FETCH_EVENT_DETAILS = 'FETCH_EVENT_DETAILS';
export const FETCH_EVENT_DETAILS_SUCCESS = 'FETCH_EVENT_DETAILS_SUCCESS';
export const FETCH_EVENT_DETAILS_FAILURE = 'FETCH_EVENT_DETAILS_FAILURE';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const REMOVE_EVENT = 'REMOVE_EVENT';
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS';
export const REMOVE_EVENT_FAILURE = 'REMOVE_EVENT_FAILURE';

export interface EventFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
	_limit?: number;
	_start?: number;
	_sort?: any;
}
export interface EventDetailsParams {
	id?: number;
}
export interface CreateEventParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
