import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es/column';
import MyContent from 'Components/Content/Content';
import { useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';

export const PlayerVsGenderVsProvince: React.FC = () => {
	const data = useSelector((state: any) => state.AnalysisReducer);
	
	var config: ColumnConfig = {
		data: data?.playerVsProvinceVsGender,
		isGroup: true,
		xField: 'label',
		yField: 'value',
		seriesField: 'type',
		label: {
			position: 'middle',
			layout: [
				{ type: 'interval-adjust-position' },
				{ type: 'interval-hide-overlap' },
				{ type: 'adjust-color' }
			]
		},
		color: ['#92140C', '#00798C'],
		style: {
			height: 400
		}
	};
	return (
		<MyContent style={{height: '100%'}}>
			<Typography.Title level={3}>Players vs Province vs Gender</Typography.Title>
			<Divider/>
			<Column {...config} />
		</MyContent>
	);
};

export default PlayerVsGenderVsProvince;
