import { Button, Divider, Col, Form, Input, Row, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from 'Components/FileUpload/FileUpload';
import { getUserId } from 'Api/Api';
import { createWorkshop, updateWorkshop } from 'Redux/WorkshopRedux/WorkshopActions';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';

export const WorkshopForm = (props: any) => {
	const dispatch = useDispatch();
	const schools = useSelector((state: any) => state.WorkshopReducer);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const [imageUrl, setImageUrl] = useState<any[]>([]);
	const [imageData, setImageData] = useState<any[]>([]);

	const [participants, setParticipants] = useState<any[]>([]);
	const [documents, setDocuments] = useState<any[]>([]);


	const handleOnUpload = (e: any, i: any, fileIndex: number) => {
		let tempTeams = form.getFieldsValue()?.participants;
		let currentTeam: any = tempTeams[i];

		let files = currentTeam?.file || [];
		if (fileIndex === 100) {
			files.push(e?.url)
		}
		else {
			files[fileIndex] = e?.url;
		}
		currentTeam = { ...currentTeam, file: files }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ participants: tempTeams })
	}

	const handleOnUploadDocument = (e: any, i: any) => {
		let tempTeams = form.getFieldsValue()?.documents;
		let currentTeam: any = tempTeams[i];
		currentTeam = { ...currentTeam, file: e?.url }
		tempTeams[i] = currentTeam;
		form.setFieldsValue({ documents: tempTeams })
	}

	const [form] = useForm();
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateWorkshop({ ...values, id: layout?.data?.id, media: imageData }));
			// console.log({ ...values, media: imageData});

		}
		else {
			dispatch(createWorkshop({ ...values, submittedBy: getUserId(), media: imageData }));

		}
	};
	useEffect(() => {
		// if (layout?.data) setImageUrl(layout?.data?.media?.map((d: any, i: number) => GetUploadUrl([d])))
		// if (layout?.data) setImageData(layout?.data?.media);
		form.setFieldsValue({ ...layout?.data, date: dayjs(layout?.data?.date), endDate: dayjs(layout?.data?.endDate) })
		setParticipants(layout?.data?.participants || [])
		setDocuments(layout?.data?.documents || [])
	}, [layout?.data])
	return (
		<Content>
			<Typography.Title level={4}>{layout?.update ? 'UPDATE' : 'ADD'} WORKSHOP</Typography.Title>
			<Form
				// {...layout}
				form={form}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
				className="custom__form"

			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]}>
					<Col lg={13}>
						<Form.Item
							label="Title"
							name="title"
							rules={[{ required: true, message: 'Title is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Location"
							name="location"
							rules={[{ required: true, message: 'Location is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Form.Item
							label="Description"
							name="description"
							rules={[{ required: true, message: 'Description is Required!' }]}
						>
							<TextArea />
						</Form.Item>
					</Col>
					<Col lg={13}>
						<Space size={35}>
							<Form.Item
								label="End Date"
								name="date"
								rules={[{ required: true, message: 'Date is Required!' }]}
							>
								<DatePicker />
							</Form.Item>

							<Form.Item
								label="Start Date"
								name="endDate"
							// rules={[{ required: true, message: 'End is Required!' }]}
							>
								<DatePicker />
							</Form.Item>
						</Space>
					</Col>



					<Col lg={16}>
						<Divider />
						<Form.Item label={<Typography.Title level={5}>Organizer Details</Typography.Title>}>
							<Input.Group>
								<Row gutter={[20, 20]}>
									<Col lg={12}>
										<Form.Item
											label="Name"
											name={['organizer', 'name']}
											rules={[{ required: true, message: 'Organizer Name type is required!' }]}
										>
											<Input />
										</Form.Item>

									</Col>
									<Col lg={12}>
										<Form.Item
											label="Email"
											name={['organizer', 'email']}
										// rules={[{ required: true, message: 'Agenda type is required!' }]}
										>
											<Input type="email" />
										</Form.Item>

									</Col>
									<Col lg={12}>
										<Form.Item
											label="Phone"
											name={['organizer', 'phone']}
										// rules={[{ required: true, message: 'Agenda type is required!' }]}
										>
											<Input type="number" />
										</Form.Item>

									</Col>
								</Row>
							</Input.Group>
						</Form.Item>
					</Col>

					<Col lg={16}>
						<Divider />
						<Form.Item label={<Typography.Title level={5}>Trainer Details</Typography.Title>}>
							<Input.Group>
								<Row gutter={[20, 20]}>
									<Col lg={12}>
										<Form.Item
											label="Name"
											name={['trainer', 'name']}
											rules={[{ required: true, message: 'Organizer Name type is required!' }]}
										>
											<Input />
										</Form.Item>

									</Col>
									<Col lg={12}>
										<Form.Item
											label="Email"
											name={['trainer', 'email']}
										// rules={[{ required: true, message: 'Agenda type is required!' }]}
										>
											<Input type="email" />
										</Form.Item>

									</Col>
									<Col lg={12}>
										<Form.Item
											label="Phone"
											name={['trainer', 'phone']}
										// rules={[{ required: true, message: 'Agenda type is required!' }]}
										>
											<Input type="number" />
										</Form.Item>

									</Col>
								</Row>
							</Input.Group>
						</Form.Item>
					</Col>


					<Col lg={18}>
						<Divider />
						<Typography.Title level={5}>Participants</Typography.Title>

						<Form.List name="participants">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field: any, index: number) => (
										<Row key={field.key} align="top" gutter={[20, 20]} style={{ marginBottom: 20 }}>

											<Col lg={12}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}
													label={index > 0 ? false : 'Name'}
													name={[field.name, 'name']}
													fieldKey={[field.fieldKey, 'name']}
													rules={[{ required: true, message: 'Required' }]}
												>
													<Input />
												</Form.Item>
											</Col>

											<Col lg={12}>

												<Form.Item shouldUpdate>
													{() => {
														const currentFiles = form.getFieldsValue()?.participants[field.name]?.file || [];
														console.log(currentFiles)
														return (
															<Row align="middle" justify="space-between">
																<Col flex="auto">
																	<Form.Item
																		{...field}
																		label={index > 0 ? false : 'Document'}
																		name={[field.name, 'file']}
																		fieldKey={[field.fieldKey, 'file']}
																	>
																		<Space direction="vertical">
																			{currentFiles && currentFiles.map((d: any, i: number) => {
																				return (
																					<FileUpload type='file' url={currentFiles[i]} onUpload={(e: any) => handleOnUpload(e, index, i)} />
																				)
																			})}
																			<FileUpload type='file' onUpload={(e: any) => handleOnUpload(e, index, 100)} />

																		</Space>
																		<Input hidden />
																	</Form.Item>
																</Col>

																{/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
																<Col>
																	<Button danger onClick={() => remove(field.name)}>Remove Participant</Button>
																</Col>
															</Row>
														)
													}}
												</Form.Item>
												{/* <Space>
													<Form.Item
														{...field}
														style={{ marginBottom: 0 }}

														label={index > 0 ? false : 'Document'}
														name={[field.name, 'file']}
														fieldKey={[field.fieldKey, 'file']}
													>
														<FileUpload type='file'
															url={participants.length > 0 && participants[index]?.file}
															onUpload={(e: any) => handleOnUpload(e, index)} />
														<Input hidden />
													</Form.Item>

													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space> */}
											</Col>
											<Col lg={24}>
												<Divider />
											</Col>
										</Row>
									))}

									<Row>
										<Col lg={17}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Add Participant
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
						</Form.List>
					</Col>
					<Col lg={13}>
						<Divider />
						<Typography.Title level={5}>Documents</Typography.Title>

						<Form.List name="documents">
							{(fields, { add, remove }) => (
								<>
									{fields.map((field: any, index: number) => (
										<Row key={field.key} align="middle" gutter={[20, 20]} style={{ marginBottom: 20 }}>

											<Col lg={16}>
												<Form.Item
													{...field}
													style={{ marginBottom: 0 }}
													label={index > 0 ? false : 'Title'}
													name={[field.name, 'title']}
													fieldKey={[field.fieldKey, 'title']}
													rules={[{ required: true, message: 'Required' }]}
												>
													<Input />
												</Form.Item>
											</Col>

											<Col lg={8}>
												<Space>
													<Form.Item
														{...field}
														style={{ marginBottom: 0 }}

														label={index > 0 ? false : 'Document'}
														name={[field.name, 'file']}
														fieldKey={[field.fieldKey, 'file']}
													>
														<FileUpload type='file'
															url={documents.length > 0 && documents[index]?.file}
															onUpload={(e: any) => handleOnUploadDocument(e, index)} />
														<Input hidden />
													</Form.Item>

													<MinusCircleOutlined onClick={() => remove(field.name)} />
												</Space>
											</Col>
										</Row>
									))}

									<Row>
										<Col lg={17}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
													Add Documents
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
						</Form.List>
					</Col>
					<Col lg={24}>
						<Form.Item>
							<Button loading={schools?.creating || schools?.updating} type="primary" htmlType="submit">
								{layout?.update ? 'Update' : 'SUBMIT'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default WorkshopForm;
