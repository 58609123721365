import {
	ApartmentOutlined,
	BankOutlined,
	BookFilled,
	DashboardFilled,
	SubnodeOutlined,
	CarryOutOutlined,
	TeamOutlined,
	UsergroupAddOutlined,
	ContactsOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useHistory } from 'react-router';
import { NavigationAccess } from 'Utils/AccessControl';

export const Sidebar = () => {
	const history = useHistory();
	const onMenuClick = (value: any) => {
		history.push('/' + value.keyPath.reverse().join('/'));
	};

	return (
		<Menu theme="light" mode="horizontal" defaultSelectedKeys={['dashboard']} onClick={onMenuClick}>
			<Menu.Item
				hidden={!NavigationAccess('dashboard')}
				key="dashboard"
				icon={<DashboardFilled />}
				title="Dashboard"
			>
				Dashboard
			</Menu.Item>
			{/* <SubMenu key="users" icon={<UsergroupAddOutlined />} title="Users"> */}
			{/* <Menu.Item key="list">Users</Menu.Item> */}
			{/* <Menu.Item key="roles-list">Roles</Menu.Item> */}
			{/* </SubMenu> */}
			<Menu.Item hidden={!NavigationAccess('user')} key="users" icon={<UsergroupAddOutlined />}>
				Users
			</Menu.Item>
			<Menu.Item hidden={!NavigationAccess('school')} key="schools" icon={<BankOutlined />}>
				Schools
			</Menu.Item>
			<Menu.Item hidden={!NavigationAccess('player')} key="players" icon={<TeamOutlined />}>
				Players
			</Menu.Item>


			<Menu.Item hidden={!NavigationAccess('form')} key="forms" icon={<BookFilled />} title="Forms">
				Forms
			</Menu.Item>
			<Menu.Item hidden={!NavigationAccess('gir')} key="gir-report" icon={<BookFilled />} title="GIR">
				GIR
			</Menu.Item>
			<Menu.Item hidden={!NavigationAccess('referee')} key="referee" icon={<BookFilled />} title="Referee">
				Referee
			</Menu.Item>

			<SubMenu
				key="activity"
				icon={<SubnodeOutlined />}
				title="Activity"
			>
				<Menu.Item hidden={!NavigationAccess('event')} key="events" icon={<CarryOutOutlined />}>
					Trainings
				</Menu.Item>
				<Menu.Item hidden={!NavigationAccess('tournament')} key="tournaments" icon={<ApartmentOutlined />}>
					Tournaments
				</Menu.Item>
				{/* <Menu.Item key="activity" icon={<BookFilled />} title="Activity Log">
					Activity Log
				</Menu.Item> */}
				<Menu.Item key="workshop" icon={<ContactsOutlined />} title="Workshop">
					Workshop
				</Menu.Item>
			</SubMenu>
		</Menu>
	);
};

export default Sidebar;
