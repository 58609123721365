import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import ResetEmail from './ResetEmail';
import NewPassword from './NewPassword';
import CheckEmail from './CheckEmail';

const ResetPassword = (props: RouteChildrenProps) => {
	const [authState, setAuthState] = useState('email');
	const [email, setEmail] = useState('');
	const onSuccessHandle = (e: any) => {
		setAuthState('success');
		setEmail(e);
	};
	switch (authState) {
		case 'eamil':
			return <ResetEmail onSuccess={(e) => onSuccessHandle(e)} />;
		case 'success':
			return <CheckEmail email={email} />;
		case 'password':
			return <NewPassword />;
		default:
			return <ResetEmail onSuccess={onSuccessHandle} />;
	}
};

export default ResetPassword;
