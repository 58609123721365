import {
	DeleteTwoTone,
	EditTwoTone,
	ExclamationCircleOutlined,
	FilePdfTwoTone,
	PlusCircleOutlined,
	SyncOutlined
} from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { defaultFunction } from 'Redux/Constants';

export interface TableActionProps {
	onDelete?: (e: any) => void;
	onEdit?: (e: any) => void;
	onReport?: (e: any) => void;
	onPassword?: (e: any) => void;
	noReport?: boolean;
	module?: string;
	noDelete?: boolean;
	addData?: boolean;
	onAdd?: (e: any) => void;
	noEdit?: boolean;
	addTitle?: string;
}

export const TableActions: React.FC<TableActionProps> = ({
	onEdit = defaultFunction,
	onDelete = defaultFunction,
	onReport = defaultFunction,
	onPassword = defaultFunction,
	noEdit,
	noReport,
	module,
	noDelete,
	addData,
	addTitle,
	onAdd = defaultFunction
}) => {
	const confirm = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure want to delete',
			okText: 'Confirm Delete',
			okButtonProps: { type: 'primary', danger: true },
			cancelText: 'Cancel',
			onOk: onDelete
		});
	};
	return (
		<Content>
			{!noEdit && <EditTwoTone onClick={onEdit} style={{ fontSize: 20, cursor: 'pointer' }} />}
			{addData && (
				<Tooltip title={addTitle || "Add Performance Data"}>
					<PlusCircleOutlined
						onClick={onAdd}
						style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
						twoToneColor="#722ed1"
					/>
				</Tooltip>
			)}
			{!noDelete &&
				onDelete && (
					<DeleteTwoTone
						onClick={() => confirm()}
						style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
						twoToneColor="red"
					/>
				)}
			{!noReport && (
				<Tooltip title="Add Addendance">
					<FilePdfTwoTone
						onClick={onReport}
						style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
						twoToneColor="#722ed1"
					/>
				</Tooltip>
			)}

			{module === 'user' && (
				<Tooltip title="Reset Password">
					<SyncOutlined
						onClick={onPassword}
						style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
						twoToneColor="#722ed1"
					/>
				</Tooltip>
			)}
		</Content>
	);
};

export default TableActions;
