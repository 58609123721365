import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createAttendanceFailure,
	createAttendanceSuccess,
	deleteAttendanceFailure,
	deleteAttendanceSuccess,
	fetchAttendanceFailure,
	fetchAttendancesFailure,
	fetchAttendancesSuccess,
	fetchAttendanceSuccess,
	updateAttendanceFailure,
	updateAttendanceSuccess
} from './AttendanceActions';
import { cutAttendance, makeAttendance, modifyAttendance, takeAttendance, takeAttendances } from './AttendanceApis';
import {
	CreateAttendanceParams,
	FETCH_ATTENDANCES,
	UPDATE_ATTENDANCE,
	AttendanceDetailsParams,
	AttendanceFetchParams,
	FETCH_ATTENDANCE_DETAILS,
	CREATE_ATTENDANCE,
	REMOVE_ATTENDANCE
} from './AttendanceTypes';

//get attendances
const getAttendancesAsync = async (data: AttendanceFetchParams) => await takeAttendances(data);

function* getAttendances(params: any) {
	const { payload } = params;
	try {
		const attendances = yield call(getAttendancesAsync, payload);
		if (attendances) {
			yield put(fetchAttendancesSuccess(attendances.data));
		} else {
			yield put(fetchAttendancesFailure('Could not get list of attendances'));
			notification.error({
				message: 'Get Attendance List',
				description: 'Could not get list of attendances'
			});
		}
	} catch (error) {
		yield put(fetchAttendancesFailure('Network Error while getting Attendances'));
		notification.error({
			message: 'Get Attendance List',
			description: 'Network Error while getting Attendances'
		});
	}
}

function* watchGetAttendances() {
	yield takeLatest(FETCH_ATTENDANCES, getAttendances);
}

//get attendance details
const getAttendanceAsync = async (data: AttendanceDetailsParams) => await takeAttendance(data);

function* getAttendance(params: any) {
	const { payload } = params;
	try {
		const attendances = yield call(getAttendanceAsync, payload);
		if (attendances) {
			yield put(fetchAttendanceSuccess(attendances.data));
		} else {
			yield put(fetchAttendanceFailure('Could not get list of attendances'));
			notification.error({
				message: 'Get Attendance',
				description: 'Could not get list of attendances'
			});
		}
	} catch (error) {
		yield put(fetchAttendanceFailure('Network Error while getting Attendances'));
		notification.error({
			message: 'Get Attendance',
			description: 'Network Error while getting Attendances'
		});
	}
}

function* watchGetAttendance() {
	yield takeLatest(FETCH_ATTENDANCE_DETAILS, getAttendance);
}

//create Attendance
const generateAttendanceAsync = async (data: CreateAttendanceParams) => await makeAttendance(data);

function* generateAttendance(params: any) {
	const { payload } = params;
	try {
		const attendances = yield call(generateAttendanceAsync, payload);
		if (attendances) {
			yield put(createAttendanceSuccess(attendances.data));
			yield put(setLayout({ form: '', update: false, data: false }));
			notification.success({
				message: 'Create Attendance',
				description: 'Attendance created successfully'
			});
		} else {
			const message = 'Could not create Attendance';
			yield put(createAttendanceFailure(message));
			notification.error({
				message: 'Create Attendance',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Attendance';
		yield put(createAttendanceFailure(message));
		notification.error({
			message: 'Create Attendance',
			description: message
		});
	}
}

function* watchGenerateAttendance() {
	yield takeLatest(CREATE_ATTENDANCE, generateAttendance);
}

//update Attendance
const amendAttendanceAsync = async (data: CreateAttendanceParams) => await modifyAttendance(data);

function* amendAttendance(params: any) {
	const { payload } = params;
	try {
		const attendances = yield call(amendAttendanceAsync, payload);
		if (attendances) {
			yield put(updateAttendanceSuccess(attendances.data));
			notification.success({
				message: 'Update Attendance',
				description: 'Attendance updated successfully'
			});
		} else {
			const message = 'Could not Update Attendance';
			yield put(updateAttendanceFailure(message));
			notification.error({
				message: 'Update Attendance',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Attendance';
		yield put(updateAttendanceFailure(message));
		notification.error({
			message: 'Update Attendance',
			description: message
		});
	}
}

function* watchAmendAttendance() {
	yield takeLatest(UPDATE_ATTENDANCE, amendAttendance);
}

//Delete Attendance
const removeAttendanceAsync = async (data: AttendanceDetailsParams) => await cutAttendance(data);

function* removeAttendance(params: any) {
	const { payload } = params;
	try {
		const attendances = yield call(removeAttendanceAsync, payload);
		if (attendances) {
			yield put(deleteAttendanceSuccess(attendances.data));
			notification.success({
				message: 'Attendance Deleted',
				description: 'Attendance deleted successfully'
			});
		} else {
			const message = 'Could not delete Attendance';
			yield put(deleteAttendanceFailure(message));
			notification.error({
				message: 'Attendance Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Attendance';
		yield put(deleteAttendanceFailure(message));
		notification.error({
			message: 'Attendance Deleted',
			description: message
		});
	}
}

function* watchRemoveAttendance() {
	yield takeLatest(REMOVE_ATTENDANCE, removeAttendance);
}

export default function* AttendanceSagas() {
	yield all([
		fork(watchGetAttendances),
		fork(watchGetAttendance),
		fork(watchGenerateAttendance),
		fork(watchAmendAttendance),
		fork(watchRemoveAttendance)
	]);
}
