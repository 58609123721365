import {
	CreateMatchParams,
	CREATE_MATCH,
	CREATE_MATCH_FAILURE,
	CREATE_MATCH_SUCCESS,
	FETCH_MATCHS,
	FETCH_MATCHS_FAILURE,
	FETCH_MATCHS_SUCCESS,
	FETCH_MATCH_DETAILS,
	FETCH_MATCH_DETAILS_FAILURE,
	FETCH_MATCH_DETAILS_SUCCESS,
	REMOVE_MATCH,
	REMOVE_MATCH_FAILURE,
	REMOVE_MATCH_SUCCESS,
	UPDATE_MATCH,
	UPDATE_MATCH_FAILURE,
	UPDATE_MATCH_SUCCESS,
	MatchDetailsParams,
	MatchFetchParams
} from './MatchTypes';

//fetch Match List
export const fetchMatchs = (params: MatchFetchParams) => ({
	type: FETCH_MATCHS,
	payload: params
});

export const fetchMatchsSuccess = (response: any) => ({
	type: FETCH_MATCHS_SUCCESS,
	payload: response
});

export const fetchMatchsFailure = (error: any) => ({
	type: FETCH_MATCHS_FAILURE,
	payload: error
});

//fetch Match Details
export const fetchMatch = (params: MatchDetailsParams) => ({
	type: FETCH_MATCH_DETAILS,
	payload: params
});

export const fetchMatchSuccess = (response: any) => ({
	type: FETCH_MATCH_DETAILS_SUCCESS,
	payload: response
});

export const fetchMatchFailure = (error: any) => ({
	type: FETCH_MATCH_DETAILS_FAILURE,
	payload: error
});

//create Match
export const createMatch = (params: CreateMatchParams) => ({
	type: CREATE_MATCH,
	payload: params
});

export const createMatchSuccess = (response: CreateMatchParams) => ({
	type: CREATE_MATCH_SUCCESS,
	payload: response
});
export const createMatchFailure = (error: any) => ({
	type: CREATE_MATCH_FAILURE,
	payload: error
});

//update Match
export const updateMatch = (params: CreateMatchParams) => ({
	type: UPDATE_MATCH,
	payload: params
});

export const updateMatchSuccess = (response: CreateMatchParams) => ({
	type: UPDATE_MATCH_SUCCESS,
	payload: response
});

export const updateMatchFailure = (error: any) => ({
	type: UPDATE_MATCH_FAILURE,
	payload: error
});

//delete Match
export const deleteMatch = (params: MatchDetailsParams) => ({
	type: REMOVE_MATCH,
	payload: params
});
export const deleteMatchSuccess = (response: MatchDetailsParams) => ({
	type: REMOVE_MATCH_SUCCESS,
	payload: response
});
export const deleteMatchFailure = (error: any) => ({
	type: REMOVE_MATCH_FAILURE,
	payload: error
});
