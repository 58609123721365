import { Col, Descriptions, Divider, Row, Space, Typography } from "antd";
import Tag from "antd/es/tag";
import DataTable from "Components/Table/DataTable";
import dayjs from "dayjs";
import { TableColumn } from "react-data-table-component";
import { GetUploadUrl } from "Utils/GetUploadResponse";
export const Refereedetails = (props: any) => {
    const { data } = props;

    const teamColumn: TableColumn[] = [
        {
            name: "Name",
            selector: "name",
            width: '200px',
            style: {
                fontWeight: 'bold',
                color: 'red'
            }
        },
        {
            name: "Details",
            selector: "file",
            cell: (row: any) => <Typography.Link target='_blank' style={{ width: '100%' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
        }
    ]


    const matchColumn: TableColumn[] = [
        {
            name: "#",
            cell: (row: any, index: number) => <Typography.Text>{index + 1}.</Typography.Text>,
            width: '70px'
        },
        {
            name: "Title",
            selector: "title",
            width: '180px',
        },
        {
            name: "File",
            selector: "file",
            cell: (row: any) => <Typography.Link target='_blank' style={{ width: '200px' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
        }
    ]
    return (
        <div>
            {/* <Typography.Title level={5}>WORKSHOP DETAILS</Typography.Title> */}
            <Row align="middle" justify="space-between" style={{ marginTop: 40 }}>
                <Col>
                    <Typography.Title level={3}>{data?.name}</Typography.Title>
                </Col>
                <Col>
                    <img width="200px" src={GetUploadUrl([data?.profilePicture])} alt="Profile" />
                </Col>
            </Row>
            <Divider />

            <Descriptions layout="vertical" column={2} contentStyle={{ fontWeight: 'bold' }} labelStyle={{ color: '#353535b0' }}>
                {/* <Descriptions.Item label="Full Name">{data?.fullName}</Descriptions.Item> */}
                <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
                <Descriptions.Item label="Email"><Typography.Link>{data?.email || 'N/A'}</Typography.Link></Descriptions.Item>
                <Descriptions.Item label="Phone">{data?.phone || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="highestCertificate"><Tag color="blue">{data?.highestCertificate}</Tag></Descriptions.Item>
            </Descriptions>
            <Divider />

            <Typography.Title level={4}>Documents</Typography.Title>
            <DataTable
                data={data?.documents}
                columns={matchColumn}
                noHeader
            />

        </div >
    )
}

export default Refereedetails;
