import API from 'Api/Api';
import { CreateWorkshopParams, WorkshopDetailsParams, WorkshopFetchParams } from './WorkshopTypes';

//get Workshop
export const takeWorkshops = (data: WorkshopFetchParams) => {


		const params = { ...data };
	delete params?._where

	if (data._where) {
		return API({
			url: `/data-workshops?${data._where}`,
			method: 'GET',
			params: params
		});
	} else {
		return API({
			url: '/data-workshops',
			method: 'GET',
			params: data
		});
	}
};

//get Workshop Details
export const takeWorkshop = (data: WorkshopDetailsParams) => {
	return API({
		url: '/data-workshops',
		method: 'GET',
		params: data
	});
};

//create Workshop Details
export const makeWorkshop = (data: CreateWorkshopParams) => {
	return API({
		url: '/data-workshops',
		method: 'POST',
		data: data
	});
};

//modify Workshop
export const modifyWorkshop = (data: CreateWorkshopParams) => {
	return API({
		url: `/data-workshops/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Workshop
export const cutWorkshop = (data: WorkshopDetailsParams) => {
	return API({
		url: `/data-workshops/${data?.id}`,
		method: 'DELETE',
		params: data
	});
};
