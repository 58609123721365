import React from 'react';
import MainRoute from 'Routes/MainRoute';
import 'Themes/App.scss';
import 'Themes/Customization.scss';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
const App = () => {
	dayjs.extend(localizedFormat);
	dayjs.extend(CustomParseFormat);
	const flame = String.fromCodePoint(0x1f525);
	// console.log('%c Auth ', 'color: white; background-color: #2274A5');
	console.log(`%c Don't ${flame} me, bro`, `color: red`);
	return <MainRoute />;
};

export default App;
