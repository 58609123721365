import { Reducer } from 'react';
import {
	CreatePerformanceParams,
	CREATE_PERFORMANCE,
	CREATE_PERFORMANCE_FAILURE,
	CREATE_PERFORMANCE_SUCCESS,
	FETCH_PERFORMANCES,
	FETCH_PERFORMANCES_FAILURE,
	FETCH_PERFORMANCES_SUCCESS,
	FETCH_PERFORMANCE_DETAILS,
	FETCH_PERFORMANCE_DETAILS_FAILURE,
	FETCH_PERFORMANCE_DETAILS_SUCCESS,
	REMOVE_PERFORMANCE,
	REMOVE_PERFORMANCE_FAILURE,
	REMOVE_PERFORMANCE_SUCCESS,
	UPDATE_PERFORMANCE,
	UPDATE_PERFORMANCE_FAILURE,
	UPDATE_PERFORMANCE_SUCCESS,
	PerformanceDetailsParams
} from './PerformanceTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const PerformanceReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Performances
		case FETCH_PERFORMANCES:
			return { ...state, loading: true };
		case FETCH_PERFORMANCES_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_PERFORMANCES_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Performance Details
		case FETCH_PERFORMANCE_DETAILS:
			return { ...state, detailing: true };
		case FETCH_PERFORMANCE_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_PERFORMANCE_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Performance
		case CREATE_PERFORMANCE:
			return { ...state, creating: true };
		case CREATE_PERFORMANCE_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_PERFORMANCE_FAILURE:
			return { ...state, creating: false };

		//update Performance
		case UPDATE_PERFORMANCE:
			return { ...state, updating: true };
		case UPDATE_PERFORMANCE_SUCCESS:
			const tempList = state.list.filter((d: CreatePerformanceParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_PERFORMANCE_FAILURE:
			return { ...state, updating: false };

		//remove Performance
		case REMOVE_PERFORMANCE:
			return { ...state, deleting: true };
		case REMOVE_PERFORMANCE_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: PerformanceDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_PERFORMANCE_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
