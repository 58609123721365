import API from 'Api/Api';
import { CreateRefereeParams, RefereeDetailsParams, RefereeFetchParams } from './RefereeTypes';

//get Referee
export const takeReferees = (data: RefereeFetchParams) => {
	return API({
		url: '/data-referees',
		method: 'GET',
		params: data
	});
};

//get Referee Details
export const takeReferee = (data: RefereeDetailsParams) => {
	return API({
		url: '/data-referees',
		method: 'GET',
		params: data
	});
};

//create Referee Details
export const makeReferee = (data: CreateRefereeParams) => {
	return API({
		url: '/data-referees',
		method: 'POST',
		data: data
	});
};

//modify Referee
export const modifyReferee = (data: CreateRefereeParams) => {
	return API({
		url: `/data-referees/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Referee
export const cutReferee = (data: RefereeDetailsParams) => {
	return API({
		url: `/data-referees/${data?.id}`,
		method: 'DELETE',
		params: data
	});
};
