import { Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import DatePicker from 'Components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { useState } from 'react';
import EventList from 'Views/Events/EventList';
import PlayerList from 'Views/Players/PlayerList';
import './Widgets.scss';
export const Widgets = (props: any) => {
	const [dates, setDates] = useState<any>([]);
	const disabledDate = (current: any) => {
		if (!dates || dates.length === 0) {
			return dayjs().endOf('day') < current && current;
		}
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
		const afterToday = dayjs().endOf('day') < current && current;
		return tooEarly || tooLate || afterToday;
		// return current && current < dayjs().endOf('day');
	};
	return (
		<Content style={{ marginTop: 20 }}>
			<Row gutter={[20, 20]}>
				{/* <Col lg={24}>
					<DatePicker.RangePicker
						disabledDate={disabledDate}
						onCalendarChange={(val) => setDates(val)}
						defaultValue={[ dayjs().subtract(30, 'd'), dayjs() ]}
					/>
				</Col> */}
				<Col lg={9}>
					<PlayerList dashboard />
				</Col>
				<Col lg={15}>
					<EventList dashboard />
				</Col>
			</Row>
		</Content>
	);
};

export default Widgets;
