import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import './Content.scss';

export const MyContent: React.FC<any> = ({ style, shadow, ...rest }: any) => {
	const defaultStyle: React.CSSProperties = {
		boxShadow: `0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)`,
		borderRadius: 20
	};
	return (
		// <Content className="site-content">
		<Content className="site-content-background" style={{ ...defaultStyle, ...style }} {...rest}>
			{rest.children}
		</Content>
		// </Content>
	);
};

export default MyContent;
