import API from 'Api/Api';
import { CreateAttendanceParams, AttendanceDetailsParams, AttendanceFetchParams } from './AttendanceTypes';

//get Attendance
export const takeAttendances = (data: AttendanceFetchParams) => {
	// return API({
	// 	url: '/attendances',
	// 	method: 'GET',
	// 	params: data
	// });

	if (data._where) {
		return API({
			url: `/data-attendances?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/data-attendances',
			method: 'GET',
			params: data
		});
	}
};

//get Attendance Details
export const takeAttendance = (data: AttendanceDetailsParams) => {
	return API({
		url: '/data-attendances',
		method: 'GET',
		params: data
	});
};

//create Attendance Details
export const makeAttendance = (data: CreateAttendanceParams) => {
	return API({
		url: '/data-attendances',
		method: 'POST',
		data: data
	});
};

//modify Attendance
export const modifyAttendance = (data: CreateAttendanceParams) => {
	return API({
		url: '/data-attendances',
		method: 'PUT',
		params: data
	});
};

//delete Attendance
export const cutAttendance = (data: AttendanceDetailsParams) => {
	return API({
		url: '/data-attendances',
		method: 'DELETE',
		params: data
	});
};
