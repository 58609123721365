import { Affix, Col, Layout, Row, Typography } from 'antd';
import Sidebar from './Sidebars/SideBar';
import Form from 'Views/Forms/Forms';
import MyHeader from 'Components/MyHeader/MyHeader';
import Animate from 'rc-animate';
import Logo from 'Assets/logo.png';
import MainContainer from 'Container/MainContainer/MainContainer';

const { Header, Content } = Layout;

export const ContentLayout = (props: any) => {
	return (
		<Animate animate>
			<Layout>
				<Form />
				<Affix>
					<Header
						className="site-layout-background"
						style={{ background: 'white ', boxShadow: '0 2px 6px 0 rgb(29 29 30 / 12%)' }}
					>
						<MainContainer>
							<Row justify="space-between">
								<Col>
									<img src={Logo} alt="Rugby Nepal" height={60} />
								</Col>

								<Col>
									<Sidebar />
								</Col>
								<Col>
									<MyHeader />
								</Col>
							</Row>
						</MainContainer>
					</Header>
				</Affix>
				<Layout
					className="site-layout"
					style={{
						background: '#fafafa'
					}}
				>
					<Content
						className="site-layout"
						style={{
							// margin: "24px 16px",
							padding: '10px 10px',
							minHeight: '80vh',
							background: '#fafafa'
						}}
					>
						{props.children}
					</Content>
					{/* <Row justify="center">
						<Col style={{ padding: 20, marginTop: 20 }}>
							Powered By: <Typography.Link>Beeaver</Typography.Link>
						</Col>
					</Row> */}
				</Layout>
			</Layout>
		</Animate>
	);
};

export default ContentLayout;
