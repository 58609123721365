import { Descriptions, Divider, Space, Typography } from "antd";
import Tag from "antd/es/tag";
import DataTable from "Components/Table/DataTable";
import dayjs from "dayjs";
import { TableColumn } from "react-data-table-component";
export const UserDetails = (props: any) => {
    const { data } = props;

    const teamColumn: TableColumn[] = [
        {
            name: "Name",
            selector: "name",
            width: '200px',
            style: {
                fontWeight: 'bold',
                color: 'red'
            }
        },
        {
            name: "Details",
            selector: "file",
            cell: (row: any) => <Typography.Link target='_blank' style={{ width: '100%' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
        }
    ]


    const matchColumn: TableColumn[] = [
        {
            name: "#",
            cell: (row: any, index: number) => <Typography.Text>{index + 1}.</Typography.Text>,
            width: '70px'
        },
        {
            name: "Title",
            selector: "title",
            width: '180px',
        },
        {
            name: "File",
            selector: "file",
            cell: (row: any) => <Typography.Link target='_blank' style={{ width: '200px' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
        }
    ]
    return (
        <div>
            <Typography.Title level={5}>USER DETAILS</Typography.Title>
            <Divider />
            <Descriptions layout="vertical" column={2} contentStyle={{ fontWeight: 'bold' }} labelStyle={{ color: '#353535b0' }}>
                <Descriptions.Item label="Full Name">{data?.fullName}</Descriptions.Item>
                <Descriptions.Item label="Username">{data?.username}</Descriptions.Item>
                <Descriptions.Item label="Email"><Typography.Link>{data?.email}</Typography.Link></Descriptions.Item>
                <Descriptions.Item label="Roles"><Tag>{data?.role?.name}</Tag></Descriptions.Item>
            </Descriptions>
            <Divider />
            <Typography.Title level={4}>Documents</Typography.Title>
            <DataTable
                data={data?.userDocuments}
                columns={matchColumn}
                noHeader
            />

        </div >
    )
}

export default UserDetails;
