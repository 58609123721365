import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Tag, Typography } from 'antd';
import { getUserId } from 'Api/Api';
import MyContent from 'Components/Content/Content';
import DataTable from 'Components/Table/DataTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { fetchEvent } from 'Redux/EventRedux/EventActions';
import { fetchPlayers } from 'Redux/PlayerRedux/PlayerActions';
import { fetchSchool } from 'Redux/SchoolRedux/SchoolActions';
import { fetchUser } from 'Redux/UserRedux/UserActions';
import UserList from './EventList';
import qs from 'qs';
import { fetchSessions } from 'Redux/SessionRedux/SessionActions';
import { EventAttendances, EventPlayers, EventSessions } from 'Utils/ComplexQueries';
import TableActions from 'Components/TableAction/TableAction';
import { fetchAttendances } from 'Redux/AttendanceRedux/AttendanceActions';
import { AttendanceMapping } from 'Utils/AttendanceMapping';
import DetailsView from 'Components/DetailsView/DetailsView';
import { PlayerKeys, SessionKeys } from 'Models/KeyMapModels';
import { isReportCompleted } from 'Utils/ReportingConditions';
import { ShadowContent } from 'Components/ShadowContent/ShadowContent';

export const EventDetails = (props: any) => {
	const { eventId } = useParams<any>();
	const events = useSelector((state: any) => state.EventReducer)
	const players = useSelector((state: any) => state.PlayerReducer)
	const sessions = useSelector((state: any) => state.SessionReducer)
	const attendances = useSelector((state: any) => state.AttendanceReducer)
	const eventDetails = events?.details;
	const [details, setDetails] = useState<any>(false);
	const [sessionDetails, setSessionDetails] = useState<any>(false);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchEvent({ id: eventId }))
		dispatch(fetchPlayers(EventPlayers(eventId)));
		dispatch(fetchSessions(EventSessions(eventId)));
		dispatch(fetchAttendances(EventAttendances(eventId)));

	}, [eventId]);
	const history = useHistory();
	const handleAttendance = (sessionId: any) => {
		dispatch(setLayout({ form: 'attendance', data: { players: players?.list, session: sessionId } }))
	}

	const handleSessionEdit = (data: any) => [
		dispatch(setLayout({ form: 'session', data: { ...data, coach: getUserId() }, update: true }))
	]

	const handleAddPerformance = (data: any) => {
		dispatch(setLayout({ form: 'performance', data: { ...data, sessionId: data?.id }, update: true }))
	}

	const sessionColumn: TableColumn[] = [
		{
			name: 'Date',
			selector: 'sessionDate',
			// cell: (row: any) => <Typography.Link onClick={() => history.push(`/users/${row.id}`)}>{row.name}</Typography.Link>
			cell: (row: any) => <Typography.Link onClick={() => history.push(`/session/${row?.id}`)}>{dayjs(row?.sessionDate).format('LL')}</Typography.Link>

		},
		{
			name: 'Start Time',
			selector: 'startTime',
		},
		{
			name: 'End Time',
			selector: 'endTime',
		},

		{
			name: 'Venue',
			selector: 'venue',

		},
		{
			name: 'Action',
			selector: '',
			cell: (row: any) => <TableActions addData={row?.attendance && !isReportCompleted(row?.attendance, row?.performances)} onAdd={() => handleAddPerformance(row)} noDelete onEdit={() => handleSessionEdit(row)} noReport={row?.attendance} onReport={() => handleAttendance(row?.id)} />
		}
	];
	const playerColumn: TableColumn[] = [
		{
			name: 'Name',
			selector: 'name',
			cell: (row: any) => <Typography.Link
			// onClick={() => history.push(`/users/${row.id}`)}
			>{row.name}</Typography.Link>
			// cell: (row: any) => <Typography.Text>{ dayjs(row?.sessionDate).format('LT')}</Typography.Text>

		},
		{

			name: 'Address',
			selector: 'address',
		},

		{
			name: 'Mobile',
			selector: 'mobile',

		},
		{
			name: 'Email',
			selector: 'email',
			cell: (row: any) => <Typography.Link>{row?.email}</Typography.Link>
		},

	];

	const getColumns = () => {
		let columns: TableColumn[] = [];
		sessions?.list?.map((sess: any) => {
			let key = dayjs(sess?.sessionDate).format('DD/MM');
			columns = [...columns, { name: key, selector: key, width: '60px', style: { textAlign: 'center' } }]
		})
		return columns

		// attendanceTable.concat(columns);
	}

	const attendanceTable: TableColumn[] = [
		{
			name: 'Name',
			selector: 'name',
			width: '200px',
			cell: (row: any) => <Typography.Link
			// onClick={() => history.push(`/users/${row.id}`)}
			>{row.name}</Typography.Link>
			// cell: (row: any) => <Typography.Text>{ dayjs(row?.sessionDate).format('LT')}</Typography.Text>

		},
		...getColumns()
	];

	const onRowClicked = (values: any) => {
		setDetails(values)
	}

	const onSessionClicked = (values: any) => {
		setSessionDetails(values)
	}

	return (
		<>
			<MyContent style={{ padding: '40px' }} show={true}>
				<Typography.Title level={3} style={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>{eventDetails?.name}
					<Button
						type="primary"
						size="small"
						icon={<PlusOutlined />}
						style={{ display: 'block', marginLeft: 20 }}
						onClick={() => dispatch(setLayout({ form: 'player', data: { trainings: [eventId], coach: getUserId() } }))}
					>ADD PLAYER</Button>
					<Button
						type="primary"
						size="small"
						icon={<PlusOutlined />}
						style={{ display: 'block', marginLeft: 20 }}
						onClick={() => dispatch(setLayout({ form: 'session', data: { training: [eventId], coach: getUserId() } }))}
					>ADD SESSION</Button>
				</Typography.Title>
				<Tag color="green">{dayjs(eventDetails?.startDate).format('LLL')}</Tag> - To - <Tag color="red">{dayjs(eventDetails?.endDate).format('LLL')}</Tag>
				{/* <Typography.Link href="mailto:prakass.dev@gmail.com">{dayjs(eventDetails?.startDate).format('LLL')}</Typography.Link> */}
				<Divider type="vertical" />
				<Divider />
			</MyContent>

			<ShadowContent>
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>SESSIONS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={sessionColumn}
					data={sessions?.list}
					progressPending={sessions?.loading}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={true}
					onRowClicked={onSessionClicked}


				/>
			</ShadowContent>

			<Divider />
			<ShadowContent>

				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>PLAYERS</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={playerColumn}
					data={players?.list}
					progressPending={players?.loading}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
					onRowClicked={onRowClicked}


				/>
			</ShadowContent>
			<Divider />
			<ShadowContent>
				<Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>ATTENDANCE</Typography.Title>
				<DataTable
					// title={'SUB DIVISIONS'}
					title={false}
					noHeader
					columns={attendanceTable}
					data={AttendanceMapping({ players: players?.list, sessions: sessions?.list })}
					progressPending={players?.loading}
					// noHeader
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					pagination={false}
				// onRowClicked={onRowClicked}


				/>
			</ShadowContent>


			<DetailsView module="player" labels={PlayerKeys} data={details} onHide={() => setDetails(false)} />
			<DetailsView module="session" labels={SessionKeys} data={sessionDetails} onHide={() => setSessionDetails(false)} />

		</>
	)
};

export default EventDetails;
