import { Reducer } from 'react';
import {
	CreateReportParams,
	CREATE_REPORT,
	CREATE_REPORT_FAILURE,
	CREATE_REPORT_SUCCESS,
	FETCH_REPORTS,
	FETCH_REPORTS_FAILURE,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORT_DETAILS,
	FETCH_REPORT_DETAILS_FAILURE,
	FETCH_REPORT_DETAILS_SUCCESS,
	REMOVE_REPORT,
	REMOVE_REPORT_FAILURE,
	REMOVE_REPORT_SUCCESS,
	UPDATE_REPORT,
	UPDATE_REPORT_FAILURE,
	UPDATE_REPORT_SUCCESS,
	ReportDetailsParams
} from './ReportTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const ReportReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Reports
		case FETCH_REPORTS:
			return { ...state, loading: true };
		case FETCH_REPORTS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_REPORTS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Report Details
		case FETCH_REPORT_DETAILS:
			return { ...state, detailing: true };
		case FETCH_REPORT_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_REPORT_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Report
		case CREATE_REPORT:
			return { ...state, creating: true };
		case CREATE_REPORT_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_REPORT_FAILURE:
			return { ...state, creating: false };

		//update Report
		case UPDATE_REPORT:
			return { ...state, updating: true };
		case UPDATE_REPORT_SUCCESS:
			const tempList = state.list.filter((d: CreateReportParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_REPORT_FAILURE:
			return { ...state, updating: false };

		//remove Report
		case REMOVE_REPORT:
			return { ...state, deleting: true };
		case REMOVE_REPORT_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: ReportDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_REPORT_FAILURE:
			return { ...state, deleting: false };

		default:
			return { ...state };
	}
};
