import { SnippetsTwoTone } from '@ant-design/icons';
import { Button, Carousel, Col, Divider, Form, Input, message, Result, Row, Steps, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Content } from 'antd/lib/layout/layout';
import { getUserId } from 'Api/Api';
import Loading from 'Components/Loading/Loading';
import FormSegment from 'Components/Reporting/FormSegment';
import PerformanceFormSegment from 'Components/Reporting/PerformanceFormSegment/PerformanceFormSegment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { submitReportData, takeForm, updateReportData } from 'Redux/FormRedux/FormApis';
import { createPerformance, updatePerformance } from 'Redux/PerformanceRedux/PerformanceActions';
import { makePerformance, modifyPerformance } from 'Redux/PerformanceRedux/PerformanceApis';

const { Step } = Steps;
export const PerformanceForm = (props: any) => {
	const [current, setCurrent] = useState(0);
	const layout = useSelector((state: any) => state.LayoutReducer);
	const data = layout?.data;

	const [loading, setLoading] = useState(false);

	const [formLoading, setFormLoading] = useState(false);

	const [players, setPlayers] = useState<any>(false);
	const [currentPlayer, setCurrentPlayer] = useState<any>(false);

	const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

	const [isCompleted, setIsCompleted] = useState(false);

	const [formData, setFormData] = useState<any>(false);

	const [dataId, setDataId] = useState<any>(false);
	const [existingData, setExistingData] = useState({})

	const dispatch = useDispatch();
	const [form] = useForm()

	const submitDataHandler = async (values: any) => {
		setLoading(true);
		const newValues = { [formData?.steps[current]?.name?.split(' ').join('_')]: values }
		if (current === 0 && !dataId) {
			await makePerformance({ reportData: newValues, session: data?.sessionId, player: currentPlayer?.id }).then((data) => {
				setDataId(data.data.id)
				setExistingData(data.data.reportData)
				setCurrent(current + 1);
				setLoading(false)
			});
		}
		else {
			await modifyPerformance({ reportData: { ...existingData, ...newValues }, id: dataId }).then((data) => {
				setCurrent(current + 1);
				setLoading(false)
			});
		}
		// setCurrent(current + 1)
		// if(current < (formData?.steps.length - 1)) setCurrentPlayerIndex(currentPlayerIndex + 1);
	}

	const handleGetFormData = () => {
		setFormLoading(true);
		takeForm({ id: '60826526e358010aae507ac1' }).then((response: any) => {
			setFormData(response?.data);
			setFormLoading(false);
		})
	}

	const next = () => {
		submitDataHandler(form.getFieldsValue())
		setCurrent(current + 1);
	};

	const prev = () => {
		// submitDataHandler(form.getFieldsValue())
		setCurrent(current - 1);
	};

	useEffect(() => {
		handleGetFormData()
	}, [])

	useEffect(() => {
		if (currentPlayerIndex <= (players.length - 1)) {
			setCurrentPlayer(players[currentPlayerIndex]);
			setIsCompleted(false);
		}
		else {
			setCurrent(0);
			setIsCompleted(true);
		}

	}, [currentPlayerIndex, players])

	useEffect(() => {
		if (current === formData?.steps?.length) handleChangeCurrentPlayer(currentPlayerIndex + 1);
	}, [current])

	const handleChangeCurrentPlayer = (index: number) => {
		setCurrent(0)
		setDataId(false);
		form.resetFields();
		setCurrentPlayerIndex(index);
	}

	useEffect(() => {
		if (data) {
			const presentPlayer = data?.attendance?.list?.filter((at: any) => at.type === 'P');
			setPlayers(presentPlayer)
			setCurrentPlayer(presentPlayer && presentPlayer[0])
		}
	}, [data])

	const handleCloseForm = () => {
		dispatch(setLayout({ form: false, update: false, data: false }));
		setCurrent(0);
		setFormData(false);
		setCurrentPlayerIndex(0);
		setCurrentPlayer(false);
		setIsCompleted(false)
	}
	return (
		<Content>
			{loading &&
				<Loading tip="Recording report" fullScreen />
			}
			{formLoading && <Loading tip="Getting Performance Form" fullScreen />}
			{!isCompleted &&
				<>
					<Row justify="start" align="middle">
						<Col>
							<SnippetsTwoTone style={{ fontSize: 32, marginBottom: 20, marginTop: 20 }} />
							<Typography.Title level={5} style={{ textTransform: 'uppercase' }}>
								{currentPlayer && currentPlayer?.name}
							</Typography.Title>
						</Col>
						<Divider type="vertical" style={{ height: '100px', marginLeft: 40 }} />
						<Col offset={1}>
							<Typography.Text>
								{data?.description || `Performance Record of ${currentPlayer && currentPlayer.name}`}
							</Typography.Text>
							<Typography.Paragraph type="danger">NOTE: These data are not editable. Submit data carefully.</Typography.Paragraph>
						</Col>
					</Row>
					<Divider />

					<Row justify="center" align="middle" style={{ background: '#f5f5f5', padding: '20px 0px' }}>
						<Col lg={18}>
							<Steps
								type="navigation"
								size="small"
								current={current}
								// onChange={(e) => setCurrent(e)}
								className="site-navigation-steps"
							>
								{formData && formData?.steps &&
									formData?.steps.map((process: any, i: number) => {
										return (
											<Step
												title={process.name}
												// subTitle="00:00:05"
												status={current === i ? 'process' : current > i ? 'finish' : 'wait'}
											// description="This is a description."
											/>
										);
									})}
							</Steps>
						</Col>
					</Row>
				</>
			}
			<Divider />
			<Row justify="center">
				<Col lg={18}>
					{isCompleted &&
						<Result
							status="success"
							title="Successfully Recorded"
							subTitle="Your entry have been recorded successfully"
							extra={[
								<Button type="primary" onClick={() => handleCloseForm()}>Close</Button>
							]}
						/>
					}
				</Col>
			</Row>
			{!isCompleted && currentPlayer &&
				<Form
					// {...layout}
					layout="vertical"
					name="basic"
					form={form}
					onFinish={submitDataHandler}
				// onFinishFailed={onFinishFailed}
				>
					<Row justify="center">
						<Col lg={18}>
							<PerformanceFormSegment data={formData && formData?.steps?.length > 0 && formData?.steps[current]} />
						</Col>
					</Row>
					<Row justify="center">
						<Col>
							<div className="steps-action">
								{current < formData?.steps?.length - 1 && (
									<Button htmlType="submit" type="primary">
										Next
									</Button>
								)}
								{current === formData?.steps?.length - 1 && (
									<Button htmlType="submit" type="primary" onClick={() => message.success('Processing complete!')}>
										Next Player
									</Button>
								)}
								{/* {current > formData?.steps?.length - 1 && (
										<Button htmlType="submit" type="primary" onClick={() => setCurrent(current+1)}>
											Done
										</Button>
									)} */}
								{current > 0 && (
									<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
										Previous
									</Button>
								)}
							</div>
						</Col>
					</Row>
				</Form>
			}

		</Content>
	);
};

export default PerformanceForm;
