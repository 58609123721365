import {
	CreateTournamentParams,
	CREATE_TOURNAMENT,
	CREATE_TOURNAMENT_FAILURE,
	CREATE_TOURNAMENT_SUCCESS,
	FETCH_TOURNAMENTS,
	FETCH_TOURNAMENTS_FAILURE,
	FETCH_TOURNAMENTS_SUCCESS,
	FETCH_TOURNAMENT_DETAILS,
	FETCH_TOURNAMENT_DETAILS_FAILURE,
	FETCH_TOURNAMENT_DETAILS_SUCCESS,
	REMOVE_TOURNAMENT,
	REMOVE_TOURNAMENT_FAILURE,
	REMOVE_TOURNAMENT_SUCCESS,
	UPDATE_TOURNAMENT,
	UPDATE_TOURNAMENT_FAILURE,
	UPDATE_TOURNAMENT_SUCCESS,
	TournamentDetailsParams,
	TournamentFetchParams
} from './TournamentTypes';

//fetch Tournament List
export const fetchTournaments = (params: TournamentFetchParams) => ({
	type: FETCH_TOURNAMENTS,
	payload: params
});

export const fetchTournamentsSuccess = (response: any) => ({
	type: FETCH_TOURNAMENTS_SUCCESS,
	payload: response
});

export const fetchTournamentsFailure = (error: any) => ({
	type: FETCH_TOURNAMENTS_FAILURE,
	payload: error
});

//fetch Tournament Details
export const fetchTournament = (params: TournamentDetailsParams) => ({
	type: FETCH_TOURNAMENT_DETAILS,
	payload: params
});

export const fetchTournamentSuccess = (response: any) => ({
	type: FETCH_TOURNAMENT_DETAILS_SUCCESS,
	payload: response
});

export const fetchTournamentFailure = (error: any) => ({
	type: FETCH_TOURNAMENT_DETAILS_FAILURE,
	payload: error
});

//create Tournament
export const createTournament = (params: CreateTournamentParams) => ({
	type: CREATE_TOURNAMENT,
	payload: params
});

export const createTournamentSuccess = (response: CreateTournamentParams) => ({
	type: CREATE_TOURNAMENT_SUCCESS,
	payload: response
});
export const createTournamentFailure = (error: any) => ({
	type: CREATE_TOURNAMENT_FAILURE,
	payload: error
});

//update Tournament
export const updateTournament = (params: CreateTournamentParams) => ({
	type: UPDATE_TOURNAMENT,
	payload: params
});

export const updateTournamentSuccess = (response: CreateTournamentParams) => ({
	type: UPDATE_TOURNAMENT_SUCCESS,
	payload: response
});

export const updateTournamentFailure = (error: any) => ({
	type: UPDATE_TOURNAMENT_FAILURE,
	payload: error
});

//delete Tournament
export const deleteTournament = (params: TournamentDetailsParams) => ({
	type: REMOVE_TOURNAMENT,
	payload: params
});
export const deleteTournamentSuccess = (response: TournamentDetailsParams) => ({
	type: REMOVE_TOURNAMENT_SUCCESS,
	payload: response
});
export const deleteTournamentFailure = (error: any) => ({
	type: REMOVE_TOURNAMENT_FAILURE,
	payload: error
});
