import { all } from 'redux-saga/effects';
import ActivitySagas from 'Redux/ActivityRedux/ActivitySagas';
import AnalysisSagas from 'Redux/AnalysisRedux/AnalysisSagas';
import AttendanceSagas from 'Redux/AttendanceRedux/AttendanceSagas';
import EventSagas from 'Redux/EventRedux/EventSagas';
import FormSagas from 'Redux/FormRedux/FormSagas';
import GireventSagas from 'Redux/GIREventRedux/GireventSagas';
import GirreportSagas from 'Redux/GIRReportRedux/GirreportSagas';
import MatchSagas from 'Redux/MatchRedux/MatchSagas';
import PerformanceSagas from 'Redux/PerformanceRedux/PerformanceSagas';
import PlayerSagas from 'Redux/PlayerRedux/PlayerSagas';
import RefereeSagas from 'Redux/RefereeRedux/RefereeSagas';
import ReportSagas from 'Redux/ReportRedux/ReportSagas';
import SchoolSagas from 'Redux/SchoolRedux/SchoolSagas';
import SessionSagas from 'Redux/SessionRedux/SessionSagas';
import TournamentSagas from 'Redux/TournamentRedux/TournamentSagas';
import UserSagas from 'Redux/UserRedux/UserSagas';
import WorkshopSagas from 'Redux/WorkshopRedux/WorkshopSagas';

export default function* rootSagas() {
	yield all([
		UserSagas(),
		FormSagas(),
		ReportSagas(),
		GireventSagas(),
		GirreportSagas(),
		SchoolSagas(),
		PlayerSagas(),
		EventSagas(),
		SessionSagas(),
		AttendanceSagas(),
		PerformanceSagas(),
		AnalysisSagas(),
		ActivitySagas(),
		TournamentSagas(),
		MatchSagas(),
		WorkshopSagas(),
		RefereeSagas()
	]);
}
