import { Reducer } from 'react';
import {
	CreateEventParams,
	CREATE_EVENT,
	CREATE_EVENT_FAILURE,
	CREATE_EVENT_SUCCESS,
	FETCH_EVENTS,
	FETCH_EVENTS_FAILURE,
	FETCH_EVENTS_SUCCESS,
	FETCH_EVENT_DETAILS,
	FETCH_EVENT_DETAILS_FAILURE,
	FETCH_EVENT_DETAILS_SUCCESS,
	REMOVE_EVENT,
	REMOVE_EVENT_FAILURE,
	REMOVE_EVENT_SUCCESS,
	UPDATE_EVENT,
	UPDATE_EVENT_FAILURE,
	UPDATE_EVENT_SUCCESS,
	EventDetailsParams
} from './EventTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const EventReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Events
		case FETCH_EVENTS:
			return { ...state, loading: true };
		case FETCH_EVENTS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_EVENTS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Event Details
		case FETCH_EVENT_DETAILS:
			return { ...state, detailing: true };
		case FETCH_EVENT_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_EVENT_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Event
		case CREATE_EVENT:
			return { ...state, creating: true };
		case CREATE_EVENT_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_EVENT_FAILURE:
			return { ...state, creating: false };

		//update Event
		case UPDATE_EVENT:
			return { ...state, updating: true };
		case UPDATE_EVENT_SUCCESS:
			const tempList = state.list.filter((d: CreateEventParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_EVENT_FAILURE:
			return { ...state, updating: false };

		//remove Event
		case REMOVE_EVENT:
			return { ...state, deleting: true };
		case REMOVE_EVENT_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: EventDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_EVENT_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
