export const Roles = [
	{
		_id: '6076ad6e4fb3af290d64a685',
		name: 'Authenticated',
		description: 'Assistant Manager',
		type: 'assistance_manage',
		__v: 0,
		id: '6076ad6e4fb3af290d64a685',
		nb_users: 0
	},
	// {
	// 	_id: '6027ac8901193ffb58073ce3',
	// 	name: 'Authenticated',
	// 	description: 'Default role given to authenticated user.',
	// 	type: 'authenticated',
	// 	__v: 0,
	// 	id: '6027ac8901193ffb58073ce3',
	// 	nb_users: 1
	// },
	{
		_id: '6076adac4fb3af290d64a807',
		name: 'Coach',
		description: 'Coaches',
		type: 'coach',
		__v: 0,
		id: '6076adac4fb3af290d64a807',
		nb_users: 0
	},
	{
		_id: '605a0add77b21e55ac61b478',
		name: 'Admin',
		description: 'General Manager',
		type: 'general_manager',
		__v: 0,
		id: '605a0add77b21e55ac61b478',
		nb_users: 1
	},
	{
		_id: '6076ad8e4fb3af290d64a746',
		name: 'Province Technical Co Ordinator',
		description: 'Province Co Ordinator\n',
		type: 'province_technical_co_ordinator',
		__v: 0,
		id: '6076ad8e4fb3af290d64a746',
		nb_users: 0
	},
	// {
	// 	_id: '6027ac8a01193ffb58073ce4',
	// 	name: 'Public',
	// 	description: 'Default role given to unauthenticated user.',
	// 	type: 'public',
	// 	__v: 0,
	// 	id: '6027ac8a01193ffb58073ce4',
	// 	nb_users: 0
	// },
	{
		_id: '6076ad254fb3af290d64a5c4',
		name: 'TNE Manager',
		description: 'Training and Assistance Manager\n',
		type: 'tne_manager',
		__v: 0,
		id: '6076ad254fb3af290d64a5c4',
		nb_users: 0
	}
];
