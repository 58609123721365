import { Reducer } from 'react';
import {
	CreateUserParams,
	CREATE_USER,
	CREATE_USER_FAILURE,
	CREATE_USER_SUCCESS,
	FETCH_USERS,
	FETCH_USERS_FAILURE,
	FETCH_USERS_SUCCESS,
	FETCH_USER_DETAILS,
	FETCH_USER_DETAILS_FAILURE,
	FETCH_USER_DETAILS_SUCCESS,
	REMOVE_USER,
	REMOVE_USER_FAILURE,
	REMOVE_USER_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_FAILURE,
	UPDATE_USER_SUCCESS,
	UserDetailsParams
} from './UserTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const UserReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Users
		case FETCH_USERS:
			return { ...state, loading: true };
		case FETCH_USERS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_USERS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch User Details
		case FETCH_USER_DETAILS:
			return { ...state, detailing: true };
		case FETCH_USER_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_USER_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create User
		case CREATE_USER:
			return { ...state, creating: true };
		case CREATE_USER_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_USER_FAILURE:
			return { ...state, creating: false };

		//update User
		case UPDATE_USER:
			return { ...state, updating: true };
		case UPDATE_USER_SUCCESS:
			const tempList = state.list.filter((d: CreateUserParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_USER_FAILURE:
			return { ...state, updating: false };

		//remove User
		case REMOVE_USER:
			return { ...state, deleting: true };
		case REMOVE_USER_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: UserDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_USER_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
