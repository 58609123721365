import {
	CreateAttendanceParams,
	CREATE_ATTENDANCE,
	CREATE_ATTENDANCE_FAILURE,
	CREATE_ATTENDANCE_SUCCESS,
	FETCH_ATTENDANCES,
	FETCH_ATTENDANCES_FAILURE,
	FETCH_ATTENDANCES_SUCCESS,
	FETCH_ATTENDANCE_DETAILS,
	FETCH_ATTENDANCE_DETAILS_FAILURE,
	FETCH_ATTENDANCE_DETAILS_SUCCESS,
	REMOVE_ATTENDANCE,
	REMOVE_ATTENDANCE_FAILURE,
	REMOVE_ATTENDANCE_SUCCESS,
	UPDATE_ATTENDANCE,
	UPDATE_ATTENDANCE_FAILURE,
	UPDATE_ATTENDANCE_SUCCESS,
	AttendanceDetailsParams,
	AttendanceFetchParams
} from './AttendanceTypes';

//fetch Attendance List
export const fetchAttendances = (params: AttendanceFetchParams) => ({
	type: FETCH_ATTENDANCES,
	payload: params
});

export const fetchAttendancesSuccess = (response: any) => ({
	type: FETCH_ATTENDANCES_SUCCESS,
	payload: response
});

export const fetchAttendancesFailure = (error: any) => ({
	type: FETCH_ATTENDANCES_FAILURE,
	payload: error
});

//fetch Attendance Details
export const fetchAttendance = (params: AttendanceDetailsParams) => ({
	type: FETCH_ATTENDANCE_DETAILS,
	payload: params
});

export const fetchAttendanceSuccess = (response: any) => ({
	type: FETCH_ATTENDANCE_DETAILS_SUCCESS,
	payload: response
});

export const fetchAttendanceFailure = (error: any) => ({
	type: FETCH_ATTENDANCE_DETAILS_FAILURE,
	payload: error
});

//create Attendance
export const createAttendance = (params: CreateAttendanceParams) => ({
	type: CREATE_ATTENDANCE,
	payload: params
});

export const createAttendanceSuccess = (response: CreateAttendanceParams) => ({
	type: CREATE_ATTENDANCE_SUCCESS,
	payload: response
});
export const createAttendanceFailure = (error: any) => ({
	type: CREATE_ATTENDANCE_FAILURE,
	payload: error
});

//update Attendance
export const updateAttendance = (params: CreateAttendanceParams) => ({
	type: UPDATE_ATTENDANCE,
	payload: params
});

export const updateAttendanceSuccess = (response: CreateAttendanceParams) => ({
	type: UPDATE_ATTENDANCE_SUCCESS,
	payload: response
});

export const updateAttendanceFailure = (error: any) => ({
	type: UPDATE_ATTENDANCE_FAILURE,
	payload: error
});

//delete Attendance
export const deleteAttendance = (params: AttendanceDetailsParams) => ({
	type: REMOVE_ATTENDANCE,
	payload: params
});
export const deleteAttendanceSuccess = (response: AttendanceDetailsParams) => ({
	type: REMOVE_ATTENDANCE_SUCCESS,
	payload: response
});
export const deleteAttendanceFailure = (error: any) => ({
	type: REMOVE_ATTENDANCE_FAILURE,
	payload: error
});
