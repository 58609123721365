import { DownloadOutlined } from "@ant-design/icons";
import { Col, Descriptions, Divider, Row, Space, Typography } from "antd";
import Tag from "antd/es/tag";
import DataTable from "Components/Table/DataTable";
import dayjs from "dayjs";
import { TableColumn } from "react-data-table-component";
import { downloadImage } from "Utils/DownloadImage";
export const TournamentDetail = (props: any) => {
  const { data } = props;

  const teamColumn: TableColumn[] = [
    {
      name: "Name",
      selector: "name",
      width: "200px",
      style: {
        fontWeight: "bold",
        color: "red",
      },
      cell: (row: any) => (
        <Typography.Text
          style={{
            fontWeight: "bold",
            color: "red",
          }}
        >
          {row?.name || "N/A"}
        </Typography.Text>
      ),
    },
    {
      name: "Details",
      selector: "file",
      cell: (row: any) => (
        <Space direction="vertical">
          {row?.file?.map((file: any) => {
            return (
              <Row gutter={[20, 20]} justify="space-between">
                <Col>
                  <Typography.Link
                    target="_blank"
                    style={{ minWidth: "200px" }}
                    ellipsis
                    download={true}
                    href={file}
                  >
                    {file?.split("/").slice(-1).pop()}
                  </Typography.Link>
                </Col>
                <Col>
                  <a
                    onClick={
                      () =>
                        downloadImage(file, file?.split("/").slice(-1).pop())
                      // FileSaver.saveAs(file, file?.split("/").slice(-1).pop())
                    }
                  >
                    <DownloadOutlined />
                  </a>
                </Col>
              </Row>
            );
          })}
        </Space>
      ),
    },
  ];

  const matchColumn: TableColumn[] = [
    {
      name: "#",
      cell: (row: any, index: number) => (
        <Typography.Text>{index + 1}.</Typography.Text>
      ),
      width: "70px",
    },
    {
      name: "Home Team",
      selector: "homeTeam",
      width: "180px",
      style: {
        fontWeight: "bold",
        color: "red",
      },
    },
    {
      name: "Score",
      style: {
        fontWeight: "bold",
        color: "red",
      },
      width: "100px",
      cell: (row: any) => (
        <Typography.Text strong>
          {row?.homeScore} - {row?.awayScore}
        </Typography.Text>
      ),
    },
    {
      name: "Away Team",
      selector: "awayTeam",
      width: "180px",
      style: {
        fontWeight: "bold",
        color: "red",
      },
    },
    {
      name: "Details",
      selector: "file",
      cell: (row: any) => (
        <Space direction="vertical">
          {row?.file?.map((file: any) => {
            return (
              <Typography.Link
                target="_blank"
                style={{ width: "200px" }}
                ellipsis
                download={true}
                href={file}
              >
                {file?.split("/").slice(-1).pop()}
              </Typography.Link>
            );
          })}
        </Space>
      ),

      // <Typography.Link target='_blank' style={{ width: '200px' }} ellipsis download={true} href={row?.file}>{row?.file?.split('/').slice(-1).pop()}</Typography.Link>
    },
  ];
  return (
    <div>
      <Typography.Title level={3}>{data?.name}</Typography.Title>
      <Tag>{data?.type}</Tag>
      <br />
      <br />
      <Typography.Paragraph>Venue: {data?.location}</Typography.Paragraph>
      <Space size={5}>
        <Tag color="red">{dayjs(data?.startDate).format("LL")}</Tag>{" "}
        <Typography.Text>to</Typography.Text>{" "}
        <Tag color="red">{dayjs(data?.endDate).format("LL")}</Tag>
      </Space>
      <Divider />
      <Typography.Paragraph>{data?.description}</Typography.Paragraph>

      <Descriptions
        layout="vertical"
        labelStyle={{ textTransform: "capitalize", color: "#ff0000" }}
        title="Organizer"
      >
        {data?.organizer &&
          Object.keys(data?.organizer).map((d: any, i: number) => {
            return (
              <Descriptions.Item label={d}>
                {data?.organizer[d]}
              </Descriptions.Item>
            );
          })}
      </Descriptions>
      <Divider />
      <Descriptions
        layout="vertical"
        labelStyle={{ textTransform: "capitalize", color: "#ff0000" }}
        title="Competition Manager"
      >
        {data?.cManager &&
          Object.keys(data?.cManager).map((d: any, i: number) => {
            return (
              <Descriptions.Item label={d}>
                {data?.cManager[d]}
              </Descriptions.Item>
            );
          })}
      </Descriptions>
      <Divider />

      <Descriptions
        layout="vertical"
        labelStyle={{ textTransform: "capitalize", color: "#ff0000" }}
        title="Technical Coordinator"
      >
        {data?.tCoordinator &&
          Object.keys(data?.tCoordinator).map((d: any, i: number) => {
            return (
              <Descriptions.Item label={d}>
                {data?.tCoordinator[d]}
              </Descriptions.Item>
            );
          })}
      </Descriptions>
      <Divider />

      <Typography.Title level={4}>Participants</Typography.Title>
      <DataTable data={data?.teams} columns={teamColumn} noHeader />

      <Divider />

      <Typography.Title level={4}>Matches</Typography.Title>
      <DataTable data={data?.match?.matches} columns={matchColumn} noHeader />
    </div>
  );
};

export default TournamentDetail;
