import API from 'Api/Api';
import { CreateGireventParams, GireventDetailsParams, GireventFetchParams } from './GireventTypes';

//get Girevent
export const takeGirevents = (data: GireventFetchParams) => {
	return API({
		url: '/gir-events',
		method: 'GET',
		params: data
	});
};

//get Girevent Details
export const takeGirevent = (data: GireventDetailsParams) => {
	return API({
		url: '/gir-events',
		method: 'GET',
		params: data
	});
};

//create Girevent Details
export const makeGirevent = (data: CreateGireventParams) => {
	return API({
		url: '/gir-events',
		method: 'POST',
		data: data
	});
};

//modify Girevent
export const modifyGirevent = (data: CreateGireventParams) => {
	return API({
		url: `/gir-events${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Girevent
export const cutGirevent = (data: GireventDetailsParams) => {
	return API({
		url: '/girevent',
		method: 'DELETE',
		params: data
	});
};
