import { FileZipFilled } from '@ant-design/icons';
import { Card, Spin, Typography } from 'antd';
import React from 'react';
import './DashboardCard.scss';

export interface DashboardCardProps {
	title?: string;
	count?: number;
	color?: string;
	loading?: boolean;
	icon?: any;
}

export const DashboardCard = (props: DashboardCardProps) => {
	const { count, title, color, loading, icon } = props;
	return (
		<Card className="dashboard__card__wrapper">
			{icon ? icon : <FileZipFilled className="icon" style={{ fontSize: 34, color: color || 'maroon' }} />}
			<div style={{ height: 70 }}>{loading ? <Spin /> : <Typography.Title>{count || 0}</Typography.Title>}</div>
			<Typography.Title style={{ color: color || 'maroon' }} level={5}>
				{title || 'Title'}
			</Typography.Title>
		</Card>
	);
};

export default DashboardCard;
