import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createGireventFailure,
	createGireventSuccess,
	deleteGireventFailure,
	deleteGireventSuccess,
	fetchGireventFailure,
	fetchGirevents,
	fetchGireventsFailure,
	fetchGireventsSuccess,
	fetchGireventSuccess,
	updateGireventFailure,
	updateGireventSuccess
} from './GireventActions';
import { cutGirevent, makeGirevent, modifyGirevent, takeGirevent, takeGirevents } from './GireventApis';
import {
	CreateGireventParams,
	FETCH_GIREVENTS,
	UPDATE_GIREVENT,
	GireventDetailsParams,
	GireventFetchParams,
	FETCH_GIREVENT_DETAILS,
	CREATE_GIREVENT,
	REMOVE_GIREVENT
} from './GireventTypes';

//get girevents
const getGireventsAsync = async (data: GireventFetchParams) => await takeGirevents(data);

function* getGirevents(params: any) {
	const { payload } = params;
	try {
		const girevents = yield call(getGireventsAsync, payload);
		if (girevents) {
			yield put(fetchGireventsSuccess(girevents.data));
		} else {
			yield put(fetchGireventsFailure('Could not get list of girevents'));
			notification.error({
				message: 'Get Girevent List',
				description: 'Could not get list of girevents'
			});
		}
	} catch (error) {
		yield put(fetchGireventsFailure('Network Error while getting Girevents'));
		notification.error({
			message: 'Get Girevent List',
			description: 'Network Error while getting Girevents'
		});
	}
}

function* watchGetGirevents() {
	yield takeLatest(FETCH_GIREVENTS, getGirevents);
}

//get girevent details
const getGireventAsync = async (data: GireventDetailsParams) => await takeGirevent(data);

function* getGirevent(params: any) {
	const { payload } = params;
	try {
		const girevents = yield call(getGireventAsync, payload);
		if (girevents) {
			yield put(fetchGireventSuccess(girevents.data));
		} else {
			yield put(fetchGireventFailure('Could not get list of girevents'));
			notification.error({
				message: 'Get Girevent',
				description: 'Could not get list of girevents'
			});
		}
	} catch (error) {
		yield put(fetchGireventFailure('Network Error while getting Girevents'));
		notification.error({
			message: 'Get Girevent',
			description: 'Network Error while getting Girevents'
		});
	}
}

function* watchGetGirevent() {
	yield takeLatest(FETCH_GIREVENT_DETAILS, getGirevent);
}

//create Girevent
const generateGireventAsync = async (data: CreateGireventParams) => await makeGirevent(data);

function* generateGirevent(params: any) {
	const { payload } = params;
	try {
		const girevents = yield call(generateGireventAsync, payload);
		if (girevents) {
			yield put(createGireventSuccess(girevents.data));
			yield put(fetchGirevents({}));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Create Girevent',
				description: 'Girevent created successfully'
			});
		} else {
			const message = 'Could not create Girevent';
			yield put(createGireventFailure(message));
			notification.error({
				message: 'Create Girevent',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Girevent';
		yield put(createGireventFailure(message));
		notification.error({
			message: 'Create Girevent',
			description: message
		});
	}
}

function* watchGenerateGirevent() {
	yield takeLatest(CREATE_GIREVENT, generateGirevent);
}

//update Girevent
const amendGireventAsync = async (data: CreateGireventParams) => await modifyGirevent(data);

function* amendGirevent(params: any) {
	const { payload } = params;
	try {
		const girevents = yield call(amendGireventAsync, payload);
		if (girevents) {
			yield put(updateGireventSuccess(girevents.data));
			notification.success({
				message: 'Update Girevent',
				description: 'Girevent updated successfully'
			});
		} else {
			const message = 'Could not Update Girevent';
			yield put(updateGireventFailure(message));
			notification.error({
				message: 'Update Girevent',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Girevent';
		yield put(updateGireventFailure(message));
		notification.error({
			message: 'Update Girevent',
			description: message
		});
	}
}

function* watchAmendGirevent() {
	yield takeLatest(UPDATE_GIREVENT, amendGirevent);
}

//Delete Girevent
const removeGireventAsync = async (data: GireventDetailsParams) => await cutGirevent(data);

function* removeGirevent(params: any) {
	const { payload } = params;
	try {
		const girevents = yield call(removeGireventAsync, payload);
		if (girevents) {
			yield put(deleteGireventSuccess(girevents.data));
			notification.success({
				message: 'Girevent Deleted',
				description: 'Girevent deleted successfully'
			});
		} else {
			const message = 'Could not delete Girevent';
			yield put(deleteGireventFailure(message));
			notification.error({
				message: 'Girevent Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Girevent';
		yield put(deleteGireventFailure(message));
		notification.error({
			message: 'Girevent Deleted',
			description: message
		});
	}
}

function* watchRemoveGirevent() {
	yield takeLatest(REMOVE_GIREVENT, removeGirevent);
}

export default function* GireventSagas() {
	yield all([
		fork(watchGetGirevents),
		fork(watchGetGirevent),
		fork(watchGenerateGirevent),
		fork(watchAmendGirevent),
		fork(watchRemoveGirevent)
	]);
}
