import { Col, Divider, Radio, Row, Tooltip, Typography } from 'antd';
import MyContent from 'Components/Content/Content';
import Loading from 'Components/Loading/Loading';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchSession, fetchSessions } from 'Redux/SessionRedux/SessionActions';
import { SessionDetailsQuery } from 'Utils/ComplexQueries';
import './Session.scss';
import { PerformanceTitleKeys } from 'Models/KeyMapModels'
import { ShadowContent } from 'Components/ShadowContent/ShadowContent';
import FileUpload from 'Components/FileUpload/FileUpload';
import { recordSessionDocument, getSessionDocument } from 'Api/MiscApi';
import { GetUploadUrl } from 'Utils/GetUploadResponse';

export const SessionDetails = (props: any) => {
	const { sessionId } = useParams<any>();
	const sessions = useSelector((state: any) => state.SessionReducer)
	const { details } = sessions;

	const [documents, setDocuments] = useState<any[]>();
	const dispatch = useDispatch();
	const [upload, setUpload] = useState(true);

	const [currentData, setCurrentData] = useState<any>(false);
	const [currentPlayer, setCurrentPlayer] = useState<any>(false);
	useEffect(() => {
		dispatch(fetchSession({ id: sessionId }))
		// dispatch(fetchSessions(SessionDetailsQuery(sessionId)))
		getSessionDocument({ session_eq: sessionId }).then((data: any) => {
			setDocuments(data?.data);
		})
	}, [sessionId]);

	useEffect(() => {
		const temp = details?.performances && details?.performances[0]?.reportData;
		setCurrentData(temp);
	}, [details])

	const presentPlayer = details?.attendance?.list?.filter((at: any) => at.type === 'P');

	useEffect(() => {
		setCurrentPlayer(presentPlayer ? presentPlayer[0]?.id : false)
	}, [])

	useEffect(() => {
		let player = details?.performances?.filter((d: any) => d.player === currentPlayer)[0]?.reportData
		setCurrentData(player);
	}, [currentPlayer])


	const handleUpload = (e: any) => {
		setUpload(false);
		recordSessionDocument({ file: e, session: sessionId }).then(() => {
			getSessionDocument({ session_eq: sessionId }).then((data: any) => {
				setDocuments(data?.data);
				setUpload(true);

			}).catch(() => {
				setUpload(true);
			})
		})
	}

	return (
		<>

			{sessions?.detailing && <Loading fullScreen />}
			<MyContent style={{ padding: '40px 40px 40px 40px' }} show={true}>
				<Typography.Title level={3} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{details?.training?.name}</Typography.Title>
				<Typography.Link>{dayjs(details?.sessionDate).format('LL')}</Typography.Link><br />
				<Typography.Text disabled>{details?.startTime} - {details?.endTime}</Typography.Text>
			</MyContent>
			<MyContent style={{ padding: '40px 40px 40px 40px', marginTop: '20px' }} show={true}>
				<Typography.Title level={5} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>DOCUMENTS</Typography.Title>
				<Row align="middle" gutter={[20, 20]}>
					{documents && documents?.map((doc: any) => {
						return <Col>
							<img style={{ maxHeight: 100 }} alt="Open in new tab to view" src={GetUploadUrl([doc?.file])} />
						</Col>
					})}
					<Col>
						{upload && <FileUpload onUpload={(e) => handleUpload(e)} />}
					</Col>
				</Row>
			</MyContent>

			<Row style={{ paddingLeft: 20 }} gutter={[20, 20]}>
				<Divider />
				<Col >
					<Typography.Title level={4}>Players Statistics</Typography.Title>
					<Typography.Text disabled>Click on player name to see details</Typography.Text>

				</Col>
				<Col lg={24}>
					<Radio.Group onChange={(e: any) => setCurrentPlayer(e.target.value)} value={currentPlayer} buttonStyle="solid">
						{presentPlayer?.map((key: any) => {
							return (
								<Radio.Button value={key.id}>{key.name}</Radio.Button>
							)
						})}
					</Radio.Group>
				</Col>
			</Row>
			<Row gutter={[20, 20]} align="stretch">
				<Col lg={12}>
					<ShadowContent>
						<Typography.Title level={4} style={{ textTransform: 'uppercase' }}>Attack</Typography.Title>
						<Divider />
						<Row>
							<Col lg={24}>
								<Row justify="space-between" gutter={[10, 10]} style={{ padding: '10px 20px' }}>
									<Col lg={10}>
										<b>Title</b>
									</Col>
									<Col lg={14}>
										<Row justify="space-between" gutter={[10, 10]}>
											<Col><b>Strength</b></Col>
											<Col><b>Weakness</b></Col>
										</Row>
									</Col>
								</Row>
								<Divider style={{ margin: 2 }} />
								{currentData?.Attack && Object.keys(currentData?.Attack)?.map((key: any) => {
									return (
										<Row justify="space-between" gutter={[10, 10]} style={{ padding: '10px 20px' }} className="hoverable">
											<Col lg={10}>
												<Tooltip title={PerformanceTitleKeys[key]}>
													<Typography.Paragraph style={{ margin: 0 }} ellipsis title={PerformanceTitleKeys[key]}>{PerformanceTitleKeys[key]}</Typography.Paragraph>

												</Tooltip>
											</Col>
											<Col lg={14}>
												<Row justify="space-between" gutter={[10, 10]}>
													<Col lg={12}>
														<Typography.Paragraph style={{ color: 'green' }}>
															{currentData?.Attack[key]?.strength}
														</Typography.Paragraph>
													</Col>
													<Col lg={12}>
														<Typography.Paragraph style={{ color: 'red' }}>
															{currentData?.Attack[key]?.weakness}

														</Typography.Paragraph>
													</Col>
												</Row>
											</Col>
										</Row>
									)
								})}
							</Col>
						</Row>
					</ShadowContent>


				</Col>
				<Col lg={12}>
					<ShadowContent>
						<Typography.Title level={4} style={{ textTransform: 'uppercase' }}>Defence</Typography.Title>
						<Divider />

						<Row>
							<Col lg={24}>
								<Row justify="space-between" gutter={[10, 10]} style={{ padding: '10px 20px' }}>
									<Col lg={10}>
										<b>Title</b>
									</Col>
									<Col lg={14}>
										<Row justify="space-between" gutter={[10, 10]}>
											<Col lg={12}><b>Strength</b></Col>
											<Col lg={12}><b>Weakness</b></Col>
										</Row>
									</Col>
								</Row>
								<Divider style={{ margin: 2 }} />
								{currentData?.Defence && Object.keys(currentData?.Defence)?.map((key: any) => {
									return (
										<Row justify="space-between" style={{ padding: '10px 20px' }} className="hoverable">
											<Col lg={10}>
												<Tooltip title={PerformanceTitleKeys[key]}>
													<Typography.Paragraph style={{ margin: 0 }} ellipsis title={PerformanceTitleKeys[key]}>{PerformanceTitleKeys[key]}</Typography.Paragraph>

												</Tooltip>
											</Col>
											<Col lg={14}>
												<Row justify="space-between">
													<Col lg={12}>
														<Typography.Paragraph style={{ color: 'green' }}>
															{currentData?.Defence[key]?.strength}
														</Typography.Paragraph>
													</Col>
													<Col lg={12}>
														<Typography.Paragraph style={{ color: 'red' }}>
															{currentData?.Defence[key]?.weakness}

														</Typography.Paragraph>
													</Col>
												</Row>
											</Col>
										</Row>
									)
								})}
							</Col>
						</Row>
					</ShadowContent>
				</Col>
			</Row>

		</>
	)
};

export default SessionDetails;
