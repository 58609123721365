import { Reducer } from 'react';
import {
	CreateActivityParams,
	CREATE_ACTIVITY,
	CREATE_ACTIVITY_FAILURE,
	CREATE_ACTIVITY_SUCCESS,
	FETCH_ACTIVITYS,
	FETCH_ACTIVITYS_FAILURE,
	FETCH_ACTIVITYS_SUCCESS,
	FETCH_ACTIVITY_DETAILS,
	FETCH_ACTIVITY_DETAILS_FAILURE,
	FETCH_ACTIVITY_DETAILS_SUCCESS,
	REMOVE_ACTIVITY,
	REMOVE_ACTIVITY_FAILURE,
	REMOVE_ACTIVITY_SUCCESS,
	UPDATE_ACTIVITY,
	UPDATE_ACTIVITY_FAILURE,
	UPDATE_ACTIVITY_SUCCESS,
	ActivityDetailsParams
} from './ActivityTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const ActivityReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Activitys
		case FETCH_ACTIVITYS:
			return { ...state, loading: true };
		case FETCH_ACTIVITYS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_ACTIVITYS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Activity Details
		case FETCH_ACTIVITY_DETAILS:
			return { ...state, detailing: true };
		case FETCH_ACTIVITY_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_ACTIVITY_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Activity
		case CREATE_ACTIVITY:
			return { ...state, creating: true };
		case CREATE_ACTIVITY_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_ACTIVITY_FAILURE:
			return { ...state, creating: false };

		//update Activity
		case UPDATE_ACTIVITY:
			return { ...state, updating: true };
		case UPDATE_ACTIVITY_SUCCESS:
			const tempList = state.list.filter((d: CreateActivityParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_ACTIVITY_FAILURE:
			return { ...state, updating: false };

		//remove Activity
		case REMOVE_ACTIVITY:
			return { ...state, deleting: true };
		case REMOVE_ACTIVITY_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: ActivityDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_ACTIVITY_FAILURE:
			return { ...state, deleting: false };

		default:
			return { ...state };
	}
};
