
import MainContainer from "Container/MainContainer/MainContainer";
import { Route, RouteChildrenProps, Switch } from "react-router";
import Referee from "Views/Referee/Referee";
export const RefereeRoute = (props: RouteChildrenProps) => {
    return (
        <MainContainer>
            <Switch>
                <Route path={`${props?.match?.path}`} exact component={Referee} />
                <Route component={Referee} />
            </Switch>
        </MainContainer>
    );
};

export default RefereeRoute;
