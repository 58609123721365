export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export const FETCH_REPORT_DETAILS = 'FETCH_REPORT_DETAILS';
export const FETCH_REPORT_DETAILS_SUCCESS = 'FETCH_REPORT_DETAILS_SUCCESS';
export const FETCH_REPORT_DETAILS_FAILURE = 'FETCH_REPORT_DETAILS_FAILURE';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';

export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_FAILURE';

export const REMOVE_REPORT = 'REMOVE_REPORT';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';
export const REMOVE_REPORT_FAILURE = 'REMOVE_REPORT_FAILURE';

export interface ReportFetchParams {
	limit?: number;
	page?: number;
	formId_eq?: string;
}
export interface ReportDetailsParams {
	id?: number;
}
export interface CreateReportParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
