import {
	FETCH_AGE_GROUP_VS_PROVINCE,
	FETCH_AGE_GROUP_VS_PROVINCE_FAILURE,
	FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS,
	FETCH_PLAYER_GENDER_PROVINCE,
	FETCH_PLAYER_GENDER_PROVINCE_FAILURE,
	FETCH_PLAYER_GENDER_PROVINCE_SUCCESS,
	FETCH_PROVINCE_PLAYER,
	FETCH_PROVINCE_PLAYER_FAILURE,
	FETCH_PROVINCE_PLAYER_SUCCESS,
	FETCH_PROVINCE_TRAINING,
	FETCH_PROVINCE_TRAINING_FAILURE,
	FETCH_PROVINCE_TRAINING_SUCCESS
} from './AnalysisTypes';

//fetch Analysis List
export const fetchAgeVsProvince = (params: any) => ({
	type: FETCH_AGE_GROUP_VS_PROVINCE,
	payload: params
});

export const fetchAgeVsProvinceSuccess = (response: any) => ({
	type: FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS,
	payload: response
});

export const fetchAgeVsProvinceFailure = (error: any) => ({
	type: FETCH_AGE_GROUP_VS_PROVINCE_FAILURE,
	payload: error
});

//fetch Analysis List
export const fetchPlayerVsProvinceVsGender = (params: any) => ({
	type: FETCH_PLAYER_GENDER_PROVINCE,
	payload: params
});

export const fetchPlayerVsProvinceVsGenderSuccess = (response: any) => ({
	type: FETCH_PLAYER_GENDER_PROVINCE_SUCCESS,
	payload: response
});

export const fetchPlayerVsProvinceVsGenderFailure = (error: any) => ({
	type: FETCH_PLAYER_GENDER_PROVINCE_FAILURE,
	payload: error
});

//fetch Analysis List
export const fetchPlayerVsProvince = (params: any) => ({
	type: FETCH_PROVINCE_PLAYER,
	payload: params
});

export const fetchPlayerVsProvinceSuccess = (response: any) => ({
	type: FETCH_PROVINCE_PLAYER_SUCCESS,
	payload: response
});

export const fetchPlayerVsProvinceFailure = (error: any) => ({
	type: FETCH_PROVINCE_PLAYER_FAILURE,
	payload: error
});

//fetch Analysis List
export const fetchTrainingVsProvince = (params: any) => ({
	type: FETCH_PROVINCE_TRAINING,
	payload: params
});

export const fetchTrainingVsProvinceSuccess = (response: any) => ({
	type: FETCH_PROVINCE_TRAINING_SUCCESS,
	payload: response
});

export const fetchTrainingVsProvinceFailure = (error: any) => ({
	type: FETCH_PROVINCE_TRAINING_FAILURE,
	payload: error
});
