import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createPlayerFailure,
	createPlayerSuccess,
	deletePlayerFailure,
	deletePlayerSuccess,
	fetchPlayerFailure,
	fetchPlayersFailure,
	fetchPlayersSuccess,
	fetchPlayerSuccess,
	updatePlayerFailure,
	updatePlayerSuccess
} from './PlayerActions';
import { cutPlayer, makePlayer, modifyPlayer, takePlayer, takePlayers } from './PlayerApis';
import {
	CreatePlayerParams,
	FETCH_PLAYERS,
	UPDATE_PLAYER,
	PlayerDetailsParams,
	PlayerFetchParams,
	FETCH_PLAYER_DETAILS,
	CREATE_PLAYER,
	REMOVE_PLAYER
} from './PlayerTypes';

//get players
const getPlayersAsync = async (data: PlayerFetchParams) => await takePlayers(data);

function* getPlayers(params: any) {
	const { payload } = params;
	try {
		const players = yield call(getPlayersAsync, payload);
		if (players) {
			yield put(fetchPlayersSuccess(players.data));
		} else {
			yield put(fetchPlayersFailure('Could not get list of players'));
			notification.error({
				message: 'Get Player List',
				description: 'Could not get list of players'
			});
		}
	} catch (error) {
		yield put(fetchPlayersFailure('Network Error while getting Players'));
		notification.error({
			message: 'Get Player List',
			description: 'Network Error while getting Players'
		});
	}
}

function* watchGetPlayers() {
	yield takeLatest(FETCH_PLAYERS, getPlayers);
}

//get player details
const getPlayerAsync = async (data: PlayerDetailsParams) => await takePlayer(data);

function* getPlayer(params: any) {
	const { payload } = params;
	try {
		const players = yield call(getPlayerAsync, payload);
		if (players) {
			yield put(fetchPlayerSuccess(players.data));
		} else {
			yield put(fetchPlayerFailure('Could not get list of players'));
			notification.error({
				message: 'Get Player',
				description: 'Could not get list of players'
			});
		}
	} catch (error) {
		yield put(fetchPlayerFailure('Network Error while getting Players'));
		notification.error({
			message: 'Get Player',
			description: 'Network Error while getting Players'
		});
	}
}

function* watchGetPlayer() {
	yield takeLatest(FETCH_PLAYER_DETAILS, getPlayer);
}

//create Player
const generatePlayerAsync = async (data: CreatePlayerParams) => await makePlayer(data);

function* generatePlayer(params: any) {
	const { payload } = params;
	try {
		const players = yield call(generatePlayerAsync, payload);
		if (players) {
			yield put(createPlayerSuccess(players.data));
			yield put(setLayout({ form: '', update: false, data: false }));
			notification.success({
				message: 'Create Player',
				description: 'Player created successfully'
			});
		} else {
			const message = 'Could not create Player';
			yield put(createPlayerFailure(message));
			notification.error({
				message: 'Create Player',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Player';
		yield put(createPlayerFailure(message));
		notification.error({
			message: 'Create Player',
			description: message
		});
	}
}

function* watchGeneratePlayer() {
	yield takeLatest(CREATE_PLAYER, generatePlayer);
}

//update Player
const amendPlayerAsync = async (data: CreatePlayerParams) => await modifyPlayer(data);

function* amendPlayer(params: any) {
	const { payload } = params;
	try {
		const players = yield call(amendPlayerAsync, payload);
		if (players) {
			yield put(updatePlayerSuccess(players.data));
			yield put(setLayout({ form: '', update: false, data: false }));

			notification.success({
				message: 'Update Player',
				description: 'Player updated successfully'
			});
		} else {
			const message = 'Could not Update Player';
			yield put(updatePlayerFailure(message));
			notification.error({
				message: 'Update Player',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Player';
		yield put(updatePlayerFailure(message));
		notification.error({
			message: 'Update Player',
			description: message
		});
	}
}

function* watchAmendPlayer() {
	yield takeLatest(UPDATE_PLAYER, amendPlayer);
}

//Delete Player
const removePlayerAsync = async (data: PlayerDetailsParams) => await cutPlayer(data);

function* removePlayer(params: any) {
	const { payload } = params;
	try {
		const players = yield call(removePlayerAsync, payload);
		if (players) {
			yield put(deletePlayerSuccess(players.data));
			notification.success({
				message: 'Player Deleted',
				description: 'Player deleted successfully'
			});
		} else {
			const message = 'Could not delete Player';
			yield put(deletePlayerFailure(message));
			notification.error({
				message: 'Player Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Player';
		yield put(deletePlayerFailure(message));
		notification.error({
			message: 'Player Deleted',
			description: message
		});
	}
}

function* watchRemovePlayer() {
	yield takeLatest(REMOVE_PLAYER, removePlayer);
}

export default function* PlayerSagas() {
	yield all([
		fork(watchGetPlayers),
		fork(watchGetPlayer),
		fork(watchGeneratePlayer),
		fork(watchAmendPlayer),
		fork(watchRemovePlayer)
	]);
}
