import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Form, Input, Row, Select, Space, Switch, Typography } from "antd"
import form from "antd/lib/form"
import TextArea from "antd/lib/input/TextArea";
import { getUserId } from "Api/Api";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { createForm, updateForm } from "Redux/FormRedux/FormActions";
import { formInitialValues, GetCreateFormData } from "Utils/GetCreateFormData";
const { Option } = Select;
export const CreateForm = (props: any) => {
    const [steps, setSteps] = useState<any>(['step_1']);
    const [isOptions, setIsOptions] = useState<string[]>([]);
    const dispatch = useDispatch();

    const layout = useSelector((state: any) => state.LayoutReducer);

    const handleAddStep = () => {
        let tempData = steps || [];
        setSteps(tempData.concat(`step_${tempData.length + 1}`))

    }

    const handleTypeChange = (props: any) => {
        let tempOption = isOptions;
        let filterData;
        if (props.value === 'checkbox' || props.value === 'select') {
            setIsOptions(tempOption.concat(`step_${props?.index}_field${props.fieldKey}`))
        }
        else {
            let filterData = tempOption.filter((d: any) => d !== `step_${props?.index}_field${props.fieldKey}`)
            setIsOptions(filterData);
        }
    }


    const handleSubmit = (formValues: any) => {
        if (!layout.update) {
            dispatch(createForm(GetCreateFormData({ value: formValues, steps: steps, created_by: getUserId() })))
            // console.log(createForm(GetCreateFormData({ value: formValues, steps: steps, created_by: getUserId() })))
        }
        else {
            dispatch(updateForm({ ...GetCreateFormData({ value: formValues, steps: steps, formId: layout?.data?.formId, }), id: layout?.data?.id }))
        }
    }


    useEffect(() => {
        if (layout.data) {
            let steps: any = [];
            layout.data?.steps?.map((d: any, i: number) => {
                steps = steps.concat(`step_${i + 1}`);
            })
            setSteps(steps)
        }
    }, [layout])
    return (
        <Form layout='vertical' onFinish={handleSubmit} initialValues={formInitialValues(layout?.data)}>
            <Typography.Title level={3}>{layout.update ? 'UPDATE' : 'CREATE'} DATA FORM</Typography.Title>
            <Divider />
            <Row gutter={[20, 20]}>
                <Col lg={4}>
                    <Form.Item label='Form Name' name='name'>
                        <Input placeholder='Form Name' />
                    </Form.Item>
                </Col>
                <Col lg={4}>
                    <Form.Item label='Type' name='type'>
                        <Select>
                            <Option value="District">District</Option>
                            <Option value="Province">Province</Option>
                            <Option value="National">National</Option>
                            <Option value="International">International</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={4}>
                    <Form.Item initialValue={true} label='Is Active?' name='isActive'>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col lg={4}></Col>


            </Row>

            <Row gutter={[20, 20]}>
                <Col lg={24}>
                    {steps && steps?.map((d: any, i: number) => {
                        return (
                            <div style={{ background: i % 2 === 0 ? '#f2f2f3' : 'white', padding: '10px 20px', border: '1px solid gray', marginBottom: 10 }}>

                                <Typography.Title level={4} style={{ textTransform: 'uppercase' }}>{d?.split('_').join(' ')}</Typography.Title>
                                <Divider />
                                <Row gutter={[20, 20]}>
                                    <Col lg={4}>
                                        <Form.Item label='Title' name={`step_${i + 1}_name`}>
                                            <Input placeholder='Form Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Item label='Description' name={`step_${i + 1}_content`}>
                                            <TextArea />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Typography.Title level={5} style={{ textTransform: 'uppercase' }}>FIELDS:</Typography.Title>
                                <Form.List name={`step${i + 1}`}>
                                    {(fields, { add, remove }) => (
                                        <>

                                            {fields.map(field => (
                                                <Row key={field.key} align="middle" gutter={[20, 20]}>

                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label={field.key == 0 ? 'Name' : false}
                                                            name={[field.name, 'name']}
                                                            fieldKey={[field.fieldKey, 'name']}
                                                            rules={[{ required: true, message: 'Missing Name' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label={field.key == 0 ? 'Label' : false}

                                                            name={[field.name, 'label']}
                                                            fieldKey={[field.fieldKey, 'label']}
                                                            rules={[{ required: true, message: 'Missing Label' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label={field.key == 0 ? 'Type' : false}

                                                            name={[field.name, 'type']}
                                                            fieldKey={[field.fieldKey, 'type']}
                                                            rules={[{ required: true, message: 'Missing Type' }]}
                                                        >
                                                            <Select onChange={(e) => handleTypeChange({ value: e, fieldKey: field.key, index: i })}>
                                                                <Option value="text">Text</Option>
                                                                <Option value="textarea">Text Area</Option>
                                                                <Option value="password">Password</Option>
                                                                <Option value="select">Select</Option>
                                                                <Option value="checkbox">Checkbox</Option>
                                                                <Option value="number">Number</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    {/* {isOptions && isOptions.includes(`step_${i}_field${field.key}`) && */}
                                                    <Col lg={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label={field.key == 0 ? 'Options' : false}

                                                            name={[field.name, 'options']}
                                                            fieldKey={[field.fieldKey, 'options']}
                                                        // rules={[{ required: true, message: 'Missing Option' }]}
                                                        >
                                                            <Select mode='tags' />

                                                        </Form.Item>
                                                    </Col>
                                                    {/* } */}
                                                    <Col lg={4}>
                                                        <Form.Item {...field}
                                                            label={field.key == 0 ? 'Is Required?' : false}

                                                            name={[field.name, 'isRequired']}
                                                            fieldKey={[field.fieldKey, 'isRequired']}>
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={1}>
                                                        <MinusCircleOutlined style={{ marginBottom: field.key === 0 ? '0px' : 24 }} onClick={() => remove(field.name)} />
                                                    </Col>
                                                </Row>
                                            ))}

                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                    Add Field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>

                        )
                    })}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => handleAddStep()} danger>ADD FORM STEP</Button>
                </Col>
                <Divider />
            </Row>

            <Form.Item>
                <Button type="primary" htmlType="submit">{layout.update ? 'UPDATE' : 'SUBMIT'}</Button>
            </Form.Item>
        </Form>
    )
}