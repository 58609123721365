import { Reducer } from 'react';
import {
	CreateGireventParams,
	CREATE_GIREVENT,
	CREATE_GIREVENT_FAILURE,
	CREATE_GIREVENT_SUCCESS,
	FETCH_GIREVENTS,
	FETCH_GIREVENTS_FAILURE,
	FETCH_GIREVENTS_SUCCESS,
	FETCH_GIREVENT_DETAILS,
	FETCH_GIREVENT_DETAILS_FAILURE,
	FETCH_GIREVENT_DETAILS_SUCCESS,
	REMOVE_GIREVENT,
	REMOVE_GIREVENT_FAILURE,
	REMOVE_GIREVENT_SUCCESS,
	UPDATE_GIREVENT,
	UPDATE_GIREVENT_FAILURE,
	UPDATE_GIREVENT_SUCCESS,
	GireventDetailsParams
} from './GireventTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const GireventReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Girevents
		case FETCH_GIREVENTS:
			return { ...state, loading: true };
		case FETCH_GIREVENTS_SUCCESS:
			return { ...state, loading: false, list: action.payload, loaded: true };
		case FETCH_GIREVENTS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Girevent Details
		case FETCH_GIREVENT_DETAILS:
			return { ...state, detailing: true };
		case FETCH_GIREVENT_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_GIREVENT_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Girevent
		case CREATE_GIREVENT:
			return { ...state, creating: true };
		case CREATE_GIREVENT_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_GIREVENT_FAILURE:
			return { ...state, creating: false };

		//update Girevent
		case UPDATE_GIREVENT:
			return { ...state, updating: true };
		case UPDATE_GIREVENT_SUCCESS:
			const tempList = state.list.filter((d: CreateGireventParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_GIREVENT_FAILURE:
			return { ...state, updating: false };

		//remove Girevent
		case REMOVE_GIREVENT:
			return { ...state, deleting: true };
		case REMOVE_GIREVENT_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: GireventDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_GIREVENT_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
