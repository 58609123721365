export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAILURE = 'FETCH_SESSIONS_FAILURE';

export const FETCH_SESSION_DETAILS = 'FETCH_SESSION_DETAILS';
export const FETCH_SESSION_DETAILS_SUCCESS = 'FETCH_SESSION_DETAILS_SUCCESS';
export const FETCH_SESSION_DETAILS_FAILURE = 'FETCH_SESSION_DETAILS_FAILURE';

export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';

export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';

export const REMOVE_SESSION = 'REMOVE_SESSION';
export const REMOVE_SESSION_SUCCESS = 'REMOVE_SESSION_SUCCESS';
export const REMOVE_SESSION_FAILURE = 'REMOVE_SESSION_FAILURE';

export interface SessionFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface SessionDetailsParams {
	id?: number;
}
export interface CreateSessionParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
