import {
	CreatePerformanceParams,
	CREATE_PERFORMANCE,
	CREATE_PERFORMANCE_FAILURE,
	CREATE_PERFORMANCE_SUCCESS,
	FETCH_PERFORMANCES,
	FETCH_PERFORMANCES_FAILURE,
	FETCH_PERFORMANCES_SUCCESS,
	FETCH_PERFORMANCE_DETAILS,
	FETCH_PERFORMANCE_DETAILS_FAILURE,
	FETCH_PERFORMANCE_DETAILS_SUCCESS,
	REMOVE_PERFORMANCE,
	REMOVE_PERFORMANCE_FAILURE,
	REMOVE_PERFORMANCE_SUCCESS,
	UPDATE_PERFORMANCE,
	UPDATE_PERFORMANCE_FAILURE,
	UPDATE_PERFORMANCE_SUCCESS,
	PerformanceDetailsParams,
	PerformanceFetchParams
} from './PerformanceTypes';

//fetch Performance List
export const fetchPerformances = (params: PerformanceFetchParams) => ({
	type: FETCH_PERFORMANCES,
	payload: params
});

export const fetchPerformancesSuccess = (response: any) => ({
	type: FETCH_PERFORMANCES_SUCCESS,
	payload: response
});

export const fetchPerformancesFailure = (error: any) => ({
	type: FETCH_PERFORMANCES_FAILURE,
	payload: error
});

//fetch Performance Details
export const fetchPerformance = (params: PerformanceDetailsParams) => ({
	type: FETCH_PERFORMANCE_DETAILS,
	payload: params
});

export const fetchPerformanceSuccess = (response: any) => ({
	type: FETCH_PERFORMANCE_DETAILS_SUCCESS,
	payload: response
});

export const fetchPerformanceFailure = (error: any) => ({
	type: FETCH_PERFORMANCE_DETAILS_FAILURE,
	payload: error
});

//create Performance
export const createPerformance = (params: CreatePerformanceParams) => ({
	type: CREATE_PERFORMANCE,
	payload: params
});

export const createPerformanceSuccess = (response: CreatePerformanceParams) => ({
	type: CREATE_PERFORMANCE_SUCCESS,
	payload: response
});
export const createPerformanceFailure = (error: any) => ({
	type: CREATE_PERFORMANCE_FAILURE,
	payload: error
});

//update Performance
export const updatePerformance = (params: CreatePerformanceParams) => ({
	type: UPDATE_PERFORMANCE,
	payload: params
});

export const updatePerformanceSuccess = (response: CreatePerformanceParams) => ({
	type: UPDATE_PERFORMANCE_SUCCESS,
	payload: response
});

export const updatePerformanceFailure = (error: any) => ({
	type: UPDATE_PERFORMANCE_FAILURE,
	payload: error
});

//delete Performance
export const deletePerformance = (params: PerformanceDetailsParams) => ({
	type: REMOVE_PERFORMANCE,
	payload: params
});
export const deletePerformanceSuccess = (response: PerformanceDetailsParams) => ({
	type: REMOVE_PERFORMANCE_SUCCESS,
	payload: response
});
export const deletePerformanceFailure = (error: any) => ({
	type: REMOVE_PERFORMANCE_FAILURE,
	payload: error
});
