import { Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
const { Option } = Select;

const getField = (data: any) => {
    switch (data.type) {
        case 'text':
            return <Input />
        case 'textarea':
            return <TextArea />
        case 'select':
            return <GenerateSelect options={data?.options} />
        case 'checkbox':
            return <GenerateSelect multi options={data?.options} />
        case 'number':
            return <Input type="number" />
    }
}

const GenerateSelect = (props: any) => {
    const { options, multi } = props;
    if (multi) {
        return (
            <Select mode="multiple">
                {options?.map((op: any) => {
                    return <Option value={op.value}>{op.name}</Option>
                })}
            </Select>
        )
    }
    else {
        return (
            <Select>
                {options?.map((op: any) => {
                    return <Option value={op.value}>{op.name}</Option>
                })}
            </Select>
        )
    }
}

export const FormSegment = (props: any) => {
    const { data } = props;
    return (
        <Row gutter={[20, 20]}>
            {data && data?.fields?.map((fie: any, i: number) => {
                return (
                    <Col lg={12}>
                        <Form.Item name={fie.name} label={fie.label}>
                            {getField(fie)}
                        </Form.Item>
                    </Col>
                )
            })}
            {/* <Col lg={24} style={{ textAlign: 'center' }}>
                <Button htmlType="submit" danger>SUBMIT</Button>
            </Col> */}
        </Row>
    );
};

export default FormSegment;
