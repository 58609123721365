import { Reducer } from 'react';
import {
	FETCH_AGE_GROUP_VS_PROVINCE,
	FETCH_AGE_GROUP_VS_PROVINCE_FAILURE,
	FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS,
	FETCH_PLAYER_GENDER_PROVINCE,
	FETCH_PLAYER_GENDER_PROVINCE_FAILURE,
	FETCH_PLAYER_GENDER_PROVINCE_SUCCESS,
	FETCH_PROVINCE_PLAYER,
	FETCH_PROVINCE_PLAYER_FAILURE,
	FETCH_PROVINCE_PLAYER_SUCCESS,
	FETCH_PROVINCE_TRAINING,
	FETCH_PROVINCE_TRAINING_FAILURE,
	FETCH_PROVINCE_TRAINING_SUCCESS
} from './AnalysisTypes';

const INIT_STATE = {
	ageVsProvince: [],
	ageVsProvinceLoading: false,

	playerVsProvinceVsGender: [],
	playerVsProvinceVsGenderLoading: false,

	playerVsProvince: [],
	playerVsProvinceLoading: false,

	trainingVsProvince: [],
	trainingVsProvinceLoading: false,

	error: '',
	loading: false
};

export const AnalysisReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Analysiss
		case FETCH_AGE_GROUP_VS_PROVINCE:
			return { ...state, ageVsProvinceLoading: true };
		case FETCH_AGE_GROUP_VS_PROVINCE_SUCCESS:
			return { ...state, ageVsProvinceLoading: false, ageVsProvince: action.payload };
		case FETCH_AGE_GROUP_VS_PROVINCE_FAILURE:
			return { ...state, ageVsProvinceLoading: false, error: action.payload };

		case FETCH_PLAYER_GENDER_PROVINCE:
			return { ...state, playerVsProvinceVsGenderLoading: true };
		case FETCH_PLAYER_GENDER_PROVINCE_SUCCESS:
			return { ...state, playerVsProvinceVsGenderLoading: false, playerVsProvinceVsGender: action.payload };
		case FETCH_PLAYER_GENDER_PROVINCE_FAILURE:
			return { ...state, playerVsProvinceVsGenderLoading: false, error: action.payload };

		case FETCH_PROVINCE_PLAYER:
			return { ...state, playerVsProvinceLoading: true };
		case FETCH_PROVINCE_PLAYER_SUCCESS:
			return { ...state, playerVsProvinceLoading: false, playerVsProvince: action.payload };
		case FETCH_PROVINCE_PLAYER_FAILURE:
			return { ...state, playerVsProvinceLoading: false, error: action.payload };

		case FETCH_PROVINCE_TRAINING:
			return { ...state, trainingVsProvinceLoading: true };
		case FETCH_PROVINCE_TRAINING_SUCCESS:
			return { ...state, trainingVsProvinceLoading: false, trainingVsProvince: action.payload };
		case FETCH_PROVINCE_TRAINING_FAILURE:
			return { ...state, trainingVsProvinceLoading: false, error: action.payload };

		default:
			return { ...state };
	}
};
