import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createWorkshopFailure,
	createWorkshopSuccess,
	deleteWorkshopFailure,
	deleteWorkshopSuccess,
	fetchWorkshopFailure,
	fetchWorkshopsFailure,
	fetchWorkshopsSuccess,
	fetchWorkshopSuccess,
	updateWorkshopFailure,
	updateWorkshopSuccess
} from './WorkshopActions';
import { cutWorkshop, makeWorkshop, modifyWorkshop, takeWorkshop, takeWorkshops } from './WorkshopApis';
import {
	CreateWorkshopParams,
	CREATE_WORKSHOP,
	FETCH_WORKSHOPS,
	FETCH_WORKSHOP_DETAILS,
	REMOVE_WORKSHOP,
	UPDATE_WORKSHOP,
	WorkshopDetailsParams,
	WorkshopFetchParams
} from './WorkshopTypes';

//get workshops
const getWorkshopsAsync = async (data: WorkshopFetchParams) => await takeWorkshops(data);

function* getWorkshops(params: any) {
	const { payload } = params;
	try {
		const workshops: AxiosResponse = yield call(getWorkshopsAsync, payload);
		if (workshops) {
			yield put(fetchWorkshopsSuccess(workshops.data));
		} else {
			yield put(fetchWorkshopsFailure('Could not get list of workshops'));
			notification.error({
				message: 'Get Workshop List',
				description: 'Could not get list of workshops'
			});
		}
	} catch (error) {
		yield put(fetchWorkshopsFailure('Network Error while getting Workshops'));
		notification.error({
			message: 'Get Workshop List',
			description: 'Network Error while getting Workshops'
		});
	}
}

function* watchGetWorkshops() {
	yield takeLatest(FETCH_WORKSHOPS, getWorkshops);
}

//get workshop details
const getWorkshopAsync = async (data: WorkshopDetailsParams) => await takeWorkshop(data);

function* getWorkshop(params: any) {
	const { payload } = params;
	try {
		const workshops: AxiosResponse = yield call(getWorkshopAsync, payload);
		if (workshops) {
			yield put(fetchWorkshopSuccess(workshops.data));
		} else {
			yield put(fetchWorkshopFailure('Could not get list of workshops'));
			notification.error({
				message: 'Get Workshop',
				description: 'Could not get list of workshops'
			});
		}
	} catch (error) {
		yield put(fetchWorkshopFailure('Network Error while getting Workshops'));
		notification.error({
			message: 'Get Workshop',
			description: 'Network Error while getting Workshops'
		});
	}
}

function* watchGetWorkshop() {
	yield takeLatest(FETCH_WORKSHOP_DETAILS, getWorkshop);
}

//create Workshop
const generateWorkshopAsync = async (data: CreateWorkshopParams) => await makeWorkshop(data);

function* generateWorkshop(params: any) {
	const { payload } = params;
	try {
		const workshops: AxiosResponse = yield call(generateWorkshopAsync, payload);
		if (workshops) {
			yield put(createWorkshopSuccess(workshops.data));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Create Workshop',
				description: 'Workshop created successfully'
			});
		} else {
			const message = 'Could not create Workshop';
			yield put(createWorkshopFailure(message));
			notification.error({
				message: 'Create Workshop',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Workshop';
		yield put(createWorkshopFailure(message));
		notification.error({
			message: 'Create Workshop',
			description: message
		});
	}
}

function* watchGenerateWorkshop() {
	yield takeLatest(CREATE_WORKSHOP, generateWorkshop);
}

//update Workshop
const amendWorkshopAsync = async (data: CreateWorkshopParams) => await modifyWorkshop(data);

function* amendWorkshop(params: any) {
	const { payload } = params;
	try {
		const workshops: AxiosResponse = yield call(amendWorkshopAsync, payload);
		if (workshops) {
			yield put(updateWorkshopSuccess(workshops.data));
			yield put(setLayout({ form: false, data: false, update: false }));

			notification.success({
				message: 'Update Workshop',
				description: 'Workshop updated successfully'
			});
		} else {
			const message = 'Could not Update Workshop';
			yield put(updateWorkshopFailure(message));
			notification.error({
				message: 'Update Workshop',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Workshop';
		yield put(updateWorkshopFailure(message));
		notification.error({
			message: 'Update Workshop',
			description: message
		});
	}
}

function* watchAmendWorkshop() {
	yield takeLatest(UPDATE_WORKSHOP, amendWorkshop);
}

//Delete Workshop
const removeWorkshopAsync = async (data: WorkshopDetailsParams) => await cutWorkshop(data);

function* removeWorkshop(params: any) {
	const { payload } = params;
	try {
		const workshops: AxiosResponse = yield call(removeWorkshopAsync, payload);
		if (workshops) {
			yield put(deleteWorkshopSuccess(workshops.data));
			notification.success({
				message: 'Workshop Deleted',
				description: 'Workshop deleted successfully'
			});
		} else {
			const message = 'Could not delete Workshop';
			yield put(deleteWorkshopFailure(message));
			notification.error({
				message: 'Workshop Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Workshop';
		yield put(deleteWorkshopFailure(message));
		notification.error({
			message: 'Workshop Deleted',
			description: message
		});
	}
}

function* watchRemoveWorkshop() {
	yield takeLatest(REMOVE_WORKSHOP, removeWorkshop);
}

export default function* WorkshopSagas() {
	yield all([
		fork(watchGetWorkshops),
		fork(watchGetWorkshop),
		fork(watchGenerateWorkshop),
		fork(watchAmendWorkshop),
		fork(watchRemoveWorkshop)
	]);
}
