import { Col, Row } from 'antd';
import MyContent from 'Components/Content/Content';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEvents } from 'Redux/EventRedux/EventActions';
import { IsSelfOnly } from 'Utils/AccessControl';
import { SelfEvents } from 'Utils/ComplexQueries';
import EventList from './EventList';

export const Events = (props: any) => {
	const dispatch = useDispatch();

	return (
		<Row gutter={[ 10, 10 ]}>
			<Col lg={24}>
				<EventList />
			</Col>
			{/* <Col lg={6}>
				<MyContent>
					<div>Event List</div>
				</MyContent>
			</Col> */}
		</Row>
	);
};

export default Events;
