import { Col, Row } from "antd"

export const MainContainer = ({ children, ...rest }: any) => {
    return (
        <Row justify="center">
            <Col lg={20} {...rest}>
                {children}
            </Col>
        </Row>
    )
}

export default MainContainer;