export const FETCH_PLAYERS = 'FETCH_PLAYERS';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAILURE = 'FETCH_PLAYERS_FAILURE';

export const FETCH_PLAYER_DETAILS = 'FETCH_PLAYER_DETAILS';
export const FETCH_PLAYER_DETAILS_SUCCESS = 'FETCH_PLAYER_DETAILS_SUCCESS';
export const FETCH_PLAYER_DETAILS_FAILURE = 'FETCH_PLAYER_DETAILS_FAILURE';

export const CREATE_PLAYER = 'CREATE_PLAYER';
export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export const CREATE_PLAYER_FAILURE = 'CREATE_PLAYER_FAILURE';

export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAILURE = 'UPDATE_PLAYER_FAILURE';

export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const REMOVE_PLAYER_SUCCESS = 'REMOVE_PLAYER_SUCCESS';
export const REMOVE_PLAYER_FAILURE = 'REMOVE_PLAYER_FAILURE';

export interface PlayerFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface PlayerDetailsParams {
	id?: number;
}
export interface CreatePlayerParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
