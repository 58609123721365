import API from 'Api/Api';
import { CreateEventParams, EventDetailsParams, EventFetchParams } from './EventTypes';

//get Event
export const takeEvents = (data: EventFetchParams) => {
	// return API({
	// 	url: '/event',
	// 	method: 'GET',
	// 	params: data
	// });
	if (data._where) {
		return API({
			url: `/trainings?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/trainings',
			method: 'GET',
			params: data
		});
	}
};

//get Event Details
export const takeEvent = (data: EventDetailsParams) => {
	return API({
		url:`/trainings/${data?.id}`,
		method: 'GET',
	});
};

//create Event Details
export const makeEvent = (data: CreateEventParams) => {
	return API({
		url: '/trainings',
		method: 'POST',
		data: data
	});
};

//modify Event
export const modifyEvent = (data: CreateEventParams) => {
	return API({
		url: `/trainings/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Event
export const cutEvent = (data: EventDetailsParams) => {
	return API({
		url: '/trainings',
		method: 'DELETE',
		params: data
	});
};
