import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/charts';
import { LineConfig } from '@ant-design/charts/es/line';
import MyContent from 'Components/Content/Content';
import { useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';

const PlayersVsProvinces: React.FC = () => {
	const data = useSelector((state: any) => state.AnalysisReducer)
	
	var config: LineConfig = {
		data: data?.playerVsProvince,
		xField: 'month',
		yField: 'players',
		seriesField: 'province',
		// yAxis: {
		// 	label: {
		// 		formatter: function formatter(v: any) {
		// 			return ''.concat((v / 1000000000).toFixed(1), ' B');
		// 		}
		// 	}
		// },
		legend: { position: 'top' },
		smooth: true,
		animation: {
			appear: {
				animation: 'path-in',
				duration: 5000
			}
		},
		lineStyle: {
			lineWidth: 3
		},
		style: {
			height: 300
		}
	};
	return (
		<MyContent style={{height: '100%'}}>
			<Typography.Title level={3}>Players vs Province</Typography.Title>
			<Divider/>
			<Line {...config} />
		</MyContent>
	);
};

export default PlayersVsProvinces;
