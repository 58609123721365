import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import UserList, { TournamentList } from './TournamentList';

export const Tournaments = (props: any) => {
	const dispatch = useDispatch();
	return (
		<Row gutter={[ 10, 10 ]}>
			<Col lg={20}>
				<TournamentList />
			</Col>
			{/* <Col lg={6}>
				<MyContent>
					<div>User List</div>
				</MyContent>
			</Col> */}
		</Row>
	);
};

export default Tournaments;
