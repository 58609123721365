export const FETCH_MATCHS = 'FETCH_MATCHS';
export const FETCH_MATCHS_SUCCESS = 'FETCH_MATCHS_SUCCESS';
export const FETCH_MATCHS_FAILURE = 'FETCH_MATCHS_FAILURE';

export const FETCH_MATCH_DETAILS = 'FETCH_MATCH_DETAILS';
export const FETCH_MATCH_DETAILS_SUCCESS = 'FETCH_MATCH_DETAILS_SUCCESS';
export const FETCH_MATCH_DETAILS_FAILURE = 'FETCH_MATCH_DETAILS_FAILURE';

export const CREATE_MATCH = 'CREATE_MATCH';
export const CREATE_MATCH_SUCCESS = 'CREATE_MATCH_SUCCESS';
export const CREATE_MATCH_FAILURE = 'CREATE_MATCH_FAILURE';

export const UPDATE_MATCH = 'UPDATE_MATCH';
export const UPDATE_MATCH_SUCCESS = 'UPDATE_MATCH_SUCCESS';
export const UPDATE_MATCH_FAILURE = 'UPDATE_MATCH_FAILURE';

export const REMOVE_MATCH = 'REMOVE_MATCH';
export const REMOVE_MATCH_SUCCESS = 'REMOVE_MATCH_SUCCESS';
export const REMOVE_MATCH_FAILURE = 'REMOVE_MATCH_FAILURE';

export interface MatchFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface MatchDetailsParams {
	id?: number;
}
export interface CreateMatchParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
