export const FETCH_GIRREPORTS = 'FETCH_GIRREPORTS';
export const FETCH_GIRREPORTS_SUCCESS = 'FETCH_GIRREPORTS_SUCCESS';
export const FETCH_GIRREPORTS_FAILURE = 'FETCH_GIRREPORTS_FAILURE';

export const FETCH_GIRREPORT_DETAILS = 'FETCH_GIRREPORT_DETAILS';
export const FETCH_GIRREPORT_DETAILS_SUCCESS = 'FETCH_GIRREPORT_DETAILS_SUCCESS';
export const FETCH_GIRREPORT_DETAILS_FAILURE = 'FETCH_GIRREPORT_DETAILS_FAILURE';

export const CREATE_GIRREPORT = 'CREATE_GIRREPORT';
export const CREATE_GIRREPORT_SUCCESS = 'CREATE_GIRREPORT_SUCCESS';
export const CREATE_GIRREPORT_FAILURE = 'CREATE_GIRREPORT_FAILURE';

export const UPDATE_GIRREPORT = 'UPDATE_GIRREPORT';
export const UPDATE_GIRREPORT_SUCCESS = 'UPDATE_GIRREPORT_SUCCESS';
export const UPDATE_GIRREPORT_FAILURE = 'UPDATE_GIRREPORT_FAILURE';

export const REMOVE_GIRREPORT = 'REMOVE_GIRREPORT';
export const REMOVE_GIRREPORT_SUCCESS = 'REMOVE_GIRREPORT_SUCCESS';
export const REMOVE_GIRREPORT_FAILURE = 'REMOVE_GIRREPORT_FAILURE';

export interface GirreportFetchParams {
	limit?: number;
	page?: number;
}
export interface GirreportDetailsParams {
	id?: number;
}
export interface CreateGirreportParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
