import { getRole } from 'Api/Api';


//module access control
const modulesAccess: any = {
	user: {
		create: [ 'tne_manager' ],
		edit: [ 'tne_manager', 'province_technical_co_ordinator' ],
		delete: [ 'tne_manager' ],
		view: [ 'general_manager', 'tne_manager' ],
		list: [ 'general_manager', 'tne_manager','province_technical_co_ordinator' ]
	},
	player: {
		create: ['player', 'sessions', 'training', 'general_manager'],
		edit: ['player', 'sessions', 'training'],
		delete: [],
		list: [ 'training', 'player', 'session']
	},
	stats: {
		list: [ 'general_manager', 'tne_manager']
	},
	coach: {
		list: [ 'general_manager', 'tne_manager','province_technical_co_ordinator' ]
	}
};


export const AccessControl = (module: string) => {
	const roleData: any = getRole();
	let role = roleData?.type;
	return { create: modulesAccess[module]?.create?.includes(role), delete: modulesAccess[module]?.delete?.includes(role), edit: modulesAccess[module]?.edit?.includes(role), view: modulesAccess[module]?.view?.includes(role), list: modulesAccess[module]?.list?.includes(role) };
};

//control for navigation

const navigationAccess: any = {
	general_manager: ['dashboard', 'user', 'school', 'player', 'event', 'statistics', 'tournament', 'referee'],
	province_technical_co_ordinator: ['dashboard','tournament', 'school', 'player', 'event', 'user'],
	tne_manager: ['dashboard', 'user', 'school', 'player', 'event', 'referee', 'tournament'],
	assistance_manage: ['dashboard', 'referee', 'workshop'],
	coach: ['dashboard', 'player', 'event', 'tournament']
};



export const NavigationAccess = (module: string) => {
	const roleData: any = getRole();
	let role = roleData?.type;
	return navigationAccess[role]?.includes(module);
};


//self data only control
export const IsSelfOnly: any = () => {
	const roleData: any = getRole();
	let role = roleData?.type;
	return role === 'coach' || role === 'province_technical_co_ordinator';
}
