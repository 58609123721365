import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Progress, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import DetailsView from "Components/DetailsView/DetailsView";
import { PlayerKeys } from "Models/KeyMapModels";
import { fetchEvents } from "Redux/EventRedux/EventActions";
import { SelfEvents } from "Utils/ComplexQueries";
import { IsSelfOnly } from "Utils/AccessControl";
import { takeEventsCount } from "Api/CounteApi";
import { useDebounce } from "use-debounce/lib";

interface EventListprops {
    dashboard?: boolean
}

export const EventList = ({ dashboard }: EventListprops) => {
    const events = useSelector((state: any) => state.EventReducer)
    const dataList = events?.list || [];
    const history = useHistory();

    const dispatch = useDispatch();

    const [filterText, setFilterText] = React.useState('');
    const [searchText] = useDebounce(filterText, 500)



    const [perPageData, setPerPageData] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = dataList?.filter(
        (item: any) => item.fullName && item.fullName.toLowerCase().includes(filterText.toLowerCase())
    );

    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState<any>(false);





    const [count, setCount] = useState(0);

    const getCount = async () => {
        await takeEventsCount({ ...(IsSelfOnly() ? SelfEvents() : {}), name_contains: filterText.toLowerCase() }).then((d) => {
            setCount(d?.data)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const fetchWithFilter = (data: any) => {
        dispatch(fetchEvents({ ...data, ...(IsSelfOnly() ? SelfEvents() : {}), _sort: 'startDate:DESC', name_contains: filterText.toLowerCase() }));
    }


    //Row and Per page change
    const handlePerRowsChange = (perPage: number, page: number) => {
        setPerPageData(perPage);
        fetchWithFilter({ _start: perPage * (page - 1), _limit: perPage })
    };
    const handlePageChange = (page: number) => {
        fetchWithFilter({ _start: perPageData * (page - 1), _limit: perPageData });
    };

    useEffect(() => {
        fetchWithFilter({ _limit: 10, _start: 0 });
        getCount();
    }, [searchText])






    const subHeaderComponentMemo = React.useMemo(
        () => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <TableHeader
                    onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
                    onClear={handleClear}
                    // filterText={filterText}
                    title={dashboard ? 'Recent Trainings' : 'TRAINING LIST'}
                    module="event"
                    noAddFeature={dashboard}
                    search
                />
            );
        },
        [filterText, resetPaginationToggle]
    );




    const onRowClicked = (values: any) => {
        setDetails(values)
    }

    const onEditHandler = (values: any) => {
        dispatch(setLayout({ form: 'event', data: values, update: true }));
    }

    const onDeleteHandler = (id: number) => {
        // dispatch(deleteAsset(id))
    }
    const onAddHandler = (data: any) => {
        dispatch(setLayout({ form: 'event', data: data, update: false }))
        // dispatch(deleteAsset(id))
    }

    const daysEllapsed = (startDate: any, endDate: any) => {
        let step = dayjs(endDate).diff(dayjs(startDate), 'days');
        let completed = dayjs().diff(dayjs(startDate), 'days');
        // console.log(completed, step)
        return { step: step, completed: (completed / step) * 100 }
    }
    const assetColumns: TableColumn[] = [
        {
            name: 'Name',
            selector: 'name',
            cell: (row: any) => <Typography.Link onClick={() => history.push(`/activity/events/${row.id}`)}>{row?.name}</Typography.Link>
        },
        {
            name: 'Start Date',
            selector: 'startDate',
            width: '160px',
            sortable: true,
            cell: (row: any) => <Typography.Text style={{ color: 'green' }}>{dayjs(row.startDate).format('LL')}</Typography.Text>

        },
        {
            name: 'End Date',
            selector: 'endDate',
            width: '160px',
            sortable: true,
            cell: (row: any) => <Typography.Text style={{ color: 'red' }}>{dayjs(row.endDate).format('LL')}</Typography.Text>
        },
        {
            name: 'Players',
            selector: 'players',
            center: true,
            width: '70px',
            sortable: true,
            cell: (row: any) => <Typography.Text>{row?.players?.length || 0}</Typography.Text>
        },
        {
            name: 'Sessions',
            selector: 'sessions',
            center: true,
            width: '70px',
            sortable: true,
            cell: (row: any) => <Typography.Text>{row?.sessions?.length || 0}</Typography.Text>
        },
        {
            name: 'Coach',
            selector: 'coaches',
            width: '150px',
            omit: IsSelfOnly() || dashboard,
            cell: (row: any) => <Typography.Text style={{ textTransform: 'capitalize' }} strong>{row?.coaches?.length > 0 ? row?.coaches?.map((coach: any, index: number) => coach?.fullName) : '-'}</Typography.Text>
        },
        {
            name: 'Days Elapsed',
            omit: dashboard,
            width: '90px',
            cell: (row: any) =>
                <Progress
                    type="circle"
                    strokeWidth={15}
                    strokeColor={daysEllapsed(row.startDate, row.endDate).completed > 70 ? 'green' : ''}
                    trailColor="#d9d9d9"
                    width={20}
                    percent={daysEllapsed(row.startDate, row.endDate).completed} format={percent => ``}
                />
            // <Typography.Text>
            //     {dayjs().diff(dayjs(row.startDate), 'days')}/{dayjs(row.startDate).diff(dayjs(row.endDate), 'days')}
            // </Typography.Text>
            // <Typography.Text style={{ overflowX: 'auto' }}>{dayjs().diff(dayjs(row.startDate), 'days') > -1 ?
            //     <Progress strokeColor={daysEllapsed(row.startDate, row.endDate).completed > 70 ? 'green' : ''} trailColor="#d9d9d9" showInfo={false} percent={daysEllapsed(row.startDate, row.endDate).completed} steps={daysEllapsed(row.startDate, row.endDate).step} />
            //     : <Tag style={{ background: '#ffa500' }}>PENDING</Tag>}
            // </Typography.Text>
        },
        {
            name: 'Action',
            selector: '',
            width: '80px',
            cell: (row: any) => <TableActions noReport noDelete onEdit={() => onEditHandler(row)} />
        }
    ];


    return (
        <MyContent>
            <DataTable
                title={subHeaderComponentMemo}
                columns={assetColumns}
                data={events?.list}
                progressPending={events?.loading}
                // noHeader
                paginationServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination={!dashboard}
                onRowClicked={onRowClicked}

            />
            {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
            {/* <DetailsView module="event" labels={PlayerKeys} data={details} onHide={() => setDetails(false)}/> */}

        </MyContent>
    )
}

export default EventList;