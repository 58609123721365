import { EnvironmentOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { Option } from 'antd/lib/mentions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchUsers } from 'Redux/UserRedux/UserActions';
import { Roles } from 'Utils/RoleStore';
import qs from 'qs';
import { createSchool } from 'Redux/SchoolRedux/SchoolActions';
import { createPlayer } from 'Redux/PlayerRedux/PlayerActions';
import { getUserId } from 'Api/Api';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { createSession, updateSession } from 'Redux/SessionRedux/SessionActions';
import { DatePicker, TimePicker, Calendar } from 'Components/DatePicker';


export const SessionForm = (props: any) => {
	const dispatch = useDispatch();
	const layout = useSelector((state: any) => state.LayoutReducer);
	const { data } = layout;
	const sessions = useSelector((state: any) => state.SessionReducer);
	const handleSubmit = (values: any) => {
		if (layout?.update) {
			dispatch(updateSession({ ...values, id: data?._id, coach: getUserId(), startTime: dayjs(values.startTime).format('LT'), endTime: dayjs(values.endTime).format('LT') }));

		}
		else {
			dispatch(createSession({ ...values, coach: getUserId(), startTime: dayjs(values.startTime).format('LT'), endTime: dayjs(values.endTime).format('LT') }));
		}
	};

	const [form] = useForm();

	const complexQuery = {
		_where: {
			_or: [
				[{ 'trainings.id_contains': layout?.data?.trainings }] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};

	useEffect(() => {
		// dispatch(fetchUsers({ _where: qs.stringify(complexQuery) }));
	}, []);

	useEffect(() => {
		if (layout?.update) {
			form.setFieldsValue({ ...data, startTime: dayjs(data?.startTime, 'HH:mm'), endTime: dayjs(data?.endTime, 'HH:mm'), sessionDate: dayjs(data?.sessionDate) });
		}
		else {
			form.setFieldsValue({ ...data, sessionDate: dayjs(data?.sessionDate) });
		}
	}, [layout.data])
	return (
		<Content>
			<Typography.Title level={4}>ADD SESSION</Typography.Title>
			<Form
				// {...layout}
				layout="vertical"
				name="basic"
				initialValues={{ remember: true }}
				onFinish={handleSubmit}
				form={form}
			// onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 0]}>
					<Col lg={16}>
						<Form.Item
							label="Session Date"
							name="sessionDate"
							rules={[{ required: true, message: 'Date is required!' }]}
						>
							<DatePicker defaultValue={dayjs()} />
						</Form.Item>
					</Col>
					<Col lg={4}>
						<Form.Item
							label="Start Time"
							name="startTime"
							rules={[{ required: true, message: 'Start time is required!' }]}
						>
							<TimePicker format='HH:mm' minuteStep={15} />
						</Form.Item>
					</Col>
					<Col lg={4}>
						<Form.Item
							label="End Time"
							name="endTime"
							rules={[{ required: true, message: 'End Time is required!' }]}
						>
							<TimePicker format='HH:mm' minuteStep={15} />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Venue"
							name="venue"
							rules={[{ required: true, message: 'Venue is required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={24}>
						<Form.Item
							label="Age Group"
							name="ageGroup"
							rules={[{ required: true, message: 'Age group is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="Group"
							name="group"
							rules={[{ required: true, message: 'Group is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Number of Players"
							name="noOfPlayers"
							rules={[{ required: true, message: 'Number of players is Required!' }]}
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Coach"
							name="coach"
							hidden={true}
							rules={[{ required: true, message: 'Facebook is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							label="Training"
							name="training"
							hidden={true}
							rules={[{ required: true, message: 'Facebook is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col lg={12}
						hidden={true}

					>
						<Form.Item
							label="Assistant Coach"
							name="assistantCoach"
						// rules={[ { required: true, message: 'Date of Birth is Required!' } ]}
						>
							<Input />
						</Form.Item>
					</Col>

					<Col lg={12}>
						<Form.Item
							label="Equipments"
							name="equipments"
						// rules={[ { required: true, message: 'Training is Required!' } ]}
						>
							<TextArea />
						</Form.Item>
					</Col>
					<Col lg={12}>
						<Form.Item
							hidden={layout?.data?.coach}
							label="Coach"
							name="coach"
							rules={[{ required: true, message: 'Coach is Required!' }]}
						>
							<Input />
						</Form.Item>
					</Col>

					{/* <Col lg={12}>
						<Form.Item
							label="Ground Situation"
							name="groundSituation"
							rules={[ { required: true, message: 'Class Level is Required!' } ]}
						>
							<Select>
								<Option value="good">Good</Option>
								<Option value="bad">Bad</Option>
								<Option value="excellent">Excellent</Option>
								<Option value="worst">Worst</Option>
								<Option value="no">Doesn't Exist</Option>
							</Select>
						</Form.Item>
					</Col> */}

					{/* <Col lg={24}>
						<Divider />

						<Typography.Title level={4}>TRAINING PLAN</Typography.Title>
						<Divider />
					</Col> */}
					<Col lg={24}>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={sessions.creating || sessions.updating}>
								SUBMIT
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Content>
	);
};

export default SessionForm;
