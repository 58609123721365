import API from 'Api/Api';
import { CreateSchoolParams, SchoolDetailsParams, SchoolFetchParams } from './SchoolTypes';

//get School
export const takeSchools = (data: SchoolFetchParams) => {
	// return API({
	// 	url: '/schools',
	// 	method: 'GET',
	// 	params: data
	// });
	if (data._where) {
		return API({
			url: `/schools?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/schools',
			method: 'GET',
			params: data
		});
	}
};

//get School Details
export const takeSchool = (data: SchoolDetailsParams) => {
	return API({
		url: `/schools/${data?.id}`,
		method: 'GET',
		// params: data
	});
};

//create School Details
export const makeSchool = (data: CreateSchoolParams) => {
	return API({
		url: '/schools',
		method: 'POST',
		data: data
	});
};

//modify School
export const modifySchool = (data: CreateSchoolParams) => {
	return API({
		url: `/schools/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete School
export const cutSchool = (data: SchoolDetailsParams) => {
	return API({
		url: '/schools',
		method: 'DELETE',
		params: data
	});
};
