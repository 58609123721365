export const FETCH_PERFORMANCES = 'FETCH_PERFORMANCES';
export const FETCH_PERFORMANCES_SUCCESS = 'FETCH_PERFORMANCES_SUCCESS';
export const FETCH_PERFORMANCES_FAILURE = 'FETCH_PERFORMANCES_FAILURE';

export const FETCH_PERFORMANCE_DETAILS = 'FETCH_PERFORMANCE_DETAILS';
export const FETCH_PERFORMANCE_DETAILS_SUCCESS = 'FETCH_PERFORMANCE_DETAILS_SUCCESS';
export const FETCH_PERFORMANCE_DETAILS_FAILURE = 'FETCH_PERFORMANCE_DETAILS_FAILURE';

export const CREATE_PERFORMANCE = 'CREATE_PERFORMANCE';
export const CREATE_PERFORMANCE_SUCCESS = 'CREATE_PERFORMANCE_SUCCESS';
export const CREATE_PERFORMANCE_FAILURE = 'CREATE_PERFORMANCE_FAILURE';

export const UPDATE_PERFORMANCE = 'UPDATE_PERFORMANCE';
export const UPDATE_PERFORMANCE_SUCCESS = 'UPDATE_PERFORMANCE_SUCCESS';
export const UPDATE_PERFORMANCE_FAILURE = 'UPDATE_PERFORMANCE_FAILURE';

export const REMOVE_PERFORMANCE = 'REMOVE_PERFORMANCE';
export const REMOVE_PERFORMANCE_SUCCESS = 'REMOVE_PERFORMANCE_SUCCESS';
export const REMOVE_PERFORMANCE_FAILURE = 'REMOVE_PERFORMANCE_FAILURE';

export interface PerformanceFetchParams {
	limit?: number;
	page?: number;
	_where?: any;
}
export interface PerformanceDetailsParams {
	id?: number;
}
export interface CreatePerformanceParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
	reportData?: any;
	session?: any;
	player?: string;
}
