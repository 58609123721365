import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createUserFailure,
	createUserSuccess,
	deleteUserFailure,
	deleteUserSuccess,
	fetchUserFailure,
	fetchUsersFailure,
	fetchUsersSuccess,
	fetchUserSuccess,
	updateUserFailure,
	updateUserSuccess
} from './UserActions';
import { cutUser, makeUser, modifyUser, takeUser, takeUsers } from './UserApis';
import {
	CreateUserParams,
	CREATE_USER,
	FETCH_USERS,
	FETCH_USER_DETAILS,
	REMOVE_USER,
	UPDATE_USER,
	UserDetailsParams,
	UserFetchParams
} from './UserTypes';

//get users
const getUsersAsync = async (data: UserFetchParams) => await takeUsers(data);

function* getUsers(params: any) {
	const { payload } = params;
	try {
		const users = yield call(getUsersAsync, payload);
		if (users) {
			yield put(fetchUsersSuccess(users.data));
		} else {
			yield put(fetchUsersFailure('Could not get list of users'));
			notification.error({
				message: 'Get User List',
				description: 'Could not get list of users'
			});
		}
	} catch (error) {
		yield put(fetchUsersFailure('Network Error while getting Users'));
		notification.error({
			message: 'Get User List',
			description: 'Network Error while getting Users'
		});
	}
}

function* watchGetUsers() {
	yield takeLatest(FETCH_USERS, getUsers);
}

//get user details
const getUserAsync = async (data: UserDetailsParams) => await takeUser(data);

function* getUser(params: any) {
	const { payload } = params;
	try {
		const users = yield call(getUserAsync, payload);
		if (users) {
			yield put(fetchUserSuccess(users.data));
		} else {
			yield put(fetchUserFailure('Could not get list of users'));
			notification.error({
				message: 'Get User',
				description: 'Could not get list of users'
			});
		}
	} catch (error) {
		yield put(fetchUserFailure('Network Error while getting Users'));
		notification.error({
			message: 'Get User',
			description: 'Network Error while getting Users'
		});
	}
}

function* watchGetUser() {
	yield takeLatest(FETCH_USER_DETAILS, getUser);
}

//create User
const generateUserAsync = async (data: CreateUserParams) => await makeUser(data);

function* generateUser(params: any) {
	const { payload } = params;
	try {
		const users = yield call(generateUserAsync, payload);
		if (users) {
			yield put(createUserSuccess(users.data));
			yield put(setLayout({ form: '', update: false, data: false }));
			notification.success({
				message: 'Create User',
				description: 'User created successfully'
			});
		} else {
			const message = 'Could not create User';
			yield put(createUserFailure(message));
			notification.error({
				message: 'Create User',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating User';
		yield put(createUserFailure(message));
		notification.error({
			message: 'Create User',
			description: message
		});
	}
}

function* watchGenerateUser() {
	yield takeLatest(CREATE_USER, generateUser);
}

//update User
const amendUserAsync = async (data: CreateUserParams) => await modifyUser(data);

function* amendUser(params: any) {
	const { payload } = params;
	try {
		const users = yield call(amendUserAsync, payload);
		if (users) {
			yield put(updateUserSuccess(users.data));
			yield put(setLayout({ form: '', update: false, data: false }));

			notification.success({
				message: 'Update User',
				description: 'User updated successfully'
			});
		} else {
			const message = 'Could not Update User';
			yield put(updateUserFailure(message));
			notification.error({
				message: 'Update User',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating User';
		yield put(updateUserFailure(message));
		notification.error({
			message: 'Update User',
			description: message
		});
	}
}

function* watchAmendUser() {
	yield takeLatest(UPDATE_USER, amendUser);
}

//Delete User
const removeUserAsync = async (data: UserDetailsParams) => await cutUser(data);

function* removeUser(params: any) {
	const { payload } = params;
	try {
		const users = yield call(removeUserAsync, payload);
		if (users) {
			yield put(deleteUserSuccess(users.data));
			notification.success({
				message: 'User Deleted',
				description: 'User deleted successfully'
			});
		} else {
			const message = 'Could not delete User';
			yield put(deleteUserFailure(message));
			notification.error({
				message: 'User Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting User';
		yield put(deleteUserFailure(message));
		notification.error({
			message: 'User Deleted',
			description: message
		});
	}
}

function* watchRemoveUser() {
	yield takeLatest(REMOVE_USER, removeUser);
}

export default function* UserSagas() {
	yield all([
		fork(watchGetUsers),
		fork(watchGetUser),
		fork(watchGenerateUser),
		fork(watchAmendUser),
		fork(watchRemoveUser)
	]);
}
