import {
	CreateGireventParams,
	CREATE_GIREVENT,
	CREATE_GIREVENT_FAILURE,
	CREATE_GIREVENT_SUCCESS,
	FETCH_GIREVENTS,
	FETCH_GIREVENTS_FAILURE,
	FETCH_GIREVENTS_SUCCESS,
	FETCH_GIREVENT_DETAILS,
	FETCH_GIREVENT_DETAILS_FAILURE,
	FETCH_GIREVENT_DETAILS_SUCCESS,
	REMOVE_GIREVENT,
	REMOVE_GIREVENT_FAILURE,
	REMOVE_GIREVENT_SUCCESS,
	UPDATE_GIREVENT,
	UPDATE_GIREVENT_FAILURE,
	UPDATE_GIREVENT_SUCCESS,
	GireventDetailsParams,
	GireventFetchParams
} from './GireventTypes';

//fetch Girevent List
export const fetchGirevents = (params: GireventFetchParams) => ({
	type: FETCH_GIREVENTS,
	payload: params
});

export const fetchGireventsSuccess = (response: any) => ({
	type: FETCH_GIREVENTS_SUCCESS,
	payload: response
});

export const fetchGireventsFailure = (error: any) => ({
	type: FETCH_GIREVENTS_FAILURE,
	payload: error
});

//fetch Girevent Details
export const fetchGirevent = (params: GireventDetailsParams) => ({
	type: FETCH_GIREVENT_DETAILS,
	payload: params
});

export const fetchGireventSuccess = (response: any) => ({
	type: FETCH_GIREVENT_DETAILS_SUCCESS,
	payload: response
});

export const fetchGireventFailure = (error: any) => ({
	type: FETCH_GIREVENT_DETAILS_FAILURE,
	payload: error
});

//create Girevent
export const createGirevent = (params: CreateGireventParams) => ({
	type: CREATE_GIREVENT,
	payload: params
});

export const createGireventSuccess = (response: CreateGireventParams) => ({
	type: CREATE_GIREVENT_SUCCESS,
	payload: response
});
export const createGireventFailure = (error: any) => ({
	type: CREATE_GIREVENT_FAILURE,
	payload: error
});

//update Girevent
export const updateGirevent = (params: CreateGireventParams) => ({
	type: UPDATE_GIREVENT,
	payload: params
});

export const updateGireventSuccess = (response: CreateGireventParams) => ({
	type: UPDATE_GIREVENT_SUCCESS,
	payload: response
});

export const updateGireventFailure = (error: any) => ({
	type: UPDATE_GIREVENT_FAILURE,
	payload: error
});

//delete Girevent
export const deleteGirevent = (params: GireventDetailsParams) => ({
	type: REMOVE_GIREVENT,
	payload: params
});
export const deleteGireventSuccess = (response: GireventDetailsParams) => ({
	type: REMOVE_GIREVENT_SUCCESS,
	payload: response
});
export const deleteGireventFailure = (error: any) => ({
	type: REMOVE_GIREVENT_FAILURE,
	payload: error
});
