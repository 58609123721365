import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Typography, Radio } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import { fetchUsers } from "Redux/UserRedux/UserActions";
import { takeUserCount } from "Api/CounteApi";
import { IsSelfOnly } from "Utils/AccessControl";
import { useDebounce } from "use-debounce/lib";
import DetailsView from "Components/DetailsView/DetailsView";
import { CoachesOnly, SelfCoaches } from "Utils/ComplexQueries";

export const UserList = (props: any) => {
    const users = useSelector((state: any) => state.UserReducer)
    const dataList = users?.list || [];

    const dispatch = useDispatch();

    const [filterText, setFilterText] = React.useState('');

    const [searchText] = useDebounce(filterText, 500);
    const [coachOnly, setCoachOnly] = useState(false);

    const [perPageData, setPerPageData] = useState(10);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = dataList?.filter(
        (item: any) => item.fullName && item.fullName.toLowerCase().includes(searchText.toLowerCase())
    );

    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState<any>(false);



    const [count, setCount] = useState(0);

    const getCount = async () => {
        await takeUserCount(coachOnly ? (IsSelfOnly ? SelfCoaches(searchText) : CoachesOnly(searchText)) : { fullName_contains: searchText }).then((d) => {
            setCount(d?.data)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const FilterButton = () => {
        return <Radio.Group defaultValue="" onChange={(e: any) => setCoachOnly(e?.target?.value === 'coach')} buttonStyle="solid">
            <Radio.Button value="">All</Radio.Button>
            <Radio.Button value="coach">Coaches Only</Radio.Button>
        </Radio.Group>
    }

    const subHeaderComponentMemo = React.useMemo(
        () => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <TableHeader
                    onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
                    onClear={handleClear}
                    // filterText={filterText}
                    rightExtra={<FilterButton />}
                    title="USER LIST"
                    module="user"
                    search
                />
            );
        },
        [filterText, resetPaginationToggle]
    );

    const fetchWithFilter = (data: any) => {
        // dispatch(fetchUsers({ ...data, _sort: 'createdAt:DESC', fullName_contains: filterText.toLowerCase() }))
        const allUser = { ...data, _sort: 'createdAt:DESC', fullName_contains: filterText.toLowerCase() };
        dispatch(fetchUsers(!coachOnly ? allUser : CoachesOnly(searchText.toLowerCase())))
    }
    useEffect(() => {
        fetchWithFilter({ _limit: 10 })
        getCount();
    }, [searchText, coachOnly])
    //Row and Per page change
    const handlePerRowsChange = (perPage: number, page: number) => {
        setPerPageData(perPage);
        fetchWithFilter({ _start: perPage * (page - 1), _limit: perPage })
    };
    const handlePageChange = (page: number) => {
        fetchWithFilter({ _start: perPageData * (page - 1), _limit: perPageData });
    };

    const onRowClicked = (values: any) => {
        setShowDetails(true);
        setDetails(values)
    }

    const onEditHandler = (values: any) => {
        dispatch(setLayout({ form: 'user', data: values, update: true }));
    }

    const onDeleteHandler = (id: number) => {
        // dispatch(deleteAsset(id))
    }
    const onAddHandler = (data: any) => {
        dispatch(setLayout({ form: 'reporting', data: data, update: false }))
        // dispatch(deleteAsset(id))
    }

    const onPasswordHandler = (values: any) => {
        dispatch(setLayout({ form: 'password', data: values, update: true }));
    }

    const assetColumns: TableColumn[] = [
        {
            name: 'Name',
            selector: 'name',
            cell: (row: any) => <Typography.Link style={{ textTransform: 'capitalize' }}>{row?.fullName}</Typography.Link>
        }, {
            name: 'Email',
            selector: 'email',
            cell: (row: any) => <Typography.Link href={`mailto:${row.email}`}>{row?.email}</Typography.Link>
        },
        {
            name: 'Designation',
            selector: 'role.name',

        },
        // {
        //     name: 'Created By',
        //     selector: 'createdBy',
        //     cell: (row: any) => <Typography.Link>{row?.created_by?.name}</Typography.Link>
        // },
        {
            name: 'Parent',
            selector: 'parent.fullName',
            cell: (row: any) => <Typography.Link>{row?.parent?.fullName}</Typography.Link>
        },
        {
            name: 'User Since',
            selector: 'createdAt',
            cell: (row: any) => <Typography.Text>{dayjs(row?.createdAt).format('LL')}</Typography.Text>
        },
        {
            name: 'Action',
            selector: '',
            cell: (row: any) => <TableActions onPassword={() => onPasswordHandler(row)} module="user" noDelete noReport onEdit={() => onEditHandler(row)} />
        }
    ];

    useEffect(() => {
        fetchWithFilter({});
        getCount();
    }, [0])

    return (
        <MyContent>

            <DataTable
                title={subHeaderComponentMemo}
                columns={assetColumns}
                data={users?.list}
                progressPending={users?.loading}
                // noHeader
                paginationServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                onRowClicked={onRowClicked}

            />
            {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
            <DetailsView data={details} show={details} module="user" onHide={() => setDetails(false)} />

        </MyContent>
    )
}

export default UserList;