import { Drawer, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { CreateForm } from './Forms/CreateForm';
import GIRForm from './Forms/GIRForm';
import Reporting from './Forms/Reporting';
import UserForm from './Forms/UserForm';
import GIREventForm from './Forms/GIREventForm';
import { AttendanceForm } from './Forms/AttendanceForm';
import SchoolForm from './Forms/SchoolForm';
import PlayerForm from './Forms/PlayerForm';
import EventForm from './Forms/EventForm';
import SessionForm from './Forms/SessionForm';
import PerformanceForm from './Forms/PerformanceForm';
import ActivityForm from './Forms/ActivityForm';
import TournamentForm from './Forms/TournamentForm';
import MatchForm from './Forms/MatchForm';
import WorkshopForm from './Forms/WorkshopForm';
import RefereeForm from './Forms/RefereeForm';
import PasswordForm from './Forms/PasswordForm';

const SelectForm = (form: string) => {
	switch (form) {
		case 'user':
			return <UserForm />;
		case 'reporting':
			return <Reporting />;
		case 'forms':
			return <CreateForm />;
		case 'gir-report':
			return <GIRForm />;
		case 'gir-events':
			return <GIREventForm />;
		case 'attendance':
			return <AttendanceForm />;
		case 'school':
			return <SchoolForm />;
		case 'player':
			return <PlayerForm />;
		case 'event':
			return <EventForm />;
		case 'session':
			return <SessionForm />;
		case 'performance':
			return <PerformanceForm />;
		case 'activity':
			return <ActivityForm />;
		case 'tournament':
			return <TournamentForm />;
		case 'match':
			return <MatchForm />;
		case 'workshop':
			return <WorkshopForm />;
		case 'referee':
			return <RefereeForm />;
		case 'password':
			return <PasswordForm />;
	}
};

export const Form = () => {
	const layout = useSelector((state: any) => state.LayoutReducer);
	const dispatch = useDispatch();
	return (
		<div>
			{layout?.form === 'tournament' || layout?.form === 'match' ? (
				<Drawer
					width='60vw'
					visible={layout.form}
					onClose={() => dispatch(setLayout({ data: false, update: false, form: false }))}

				>
					{SelectForm(layout.form)}

				</Drawer>
			) : (
				<Modal
					className="form__modal"
					destroyOnClose
					visible={layout.form}
					width={
						layout.form === 'gir-report' ||
							layout.form === 'school' ||
							layout.form === 'user' ||
							layout.form === 'player' ||
							layout.form === 'event' ||
							layout.form === 'session' ||
							layout.form === 'gir-events' ||
							layout.form === 'activity' ||
							layout.form === 'referee' ||
							layout.form === 'attendance' ? (
							'50vw'
						) : (
							'50vw'
						)
					}
					onCancel={() => dispatch(setLayout({ data: false, update: false, form: false }))}
					footer={null}
				>
					{SelectForm(layout.form)}
				</Modal>
			)}
		</div>
	);
};

export default Form;
