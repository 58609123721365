import API from 'Api/Api';
import { CreateTournamentParams, TournamentDetailsParams, TournamentFetchParams } from './TournamentTypes';

//get Tournament
export const takeTournaments = (data: TournamentFetchParams) => {
	// return API({
	// 	url: '/tournaments',
	// 	method: 'GET',
	// 	params: data
	// });
	const params = { ...data };
	delete params?._where

	if (data._where) {
		return API({
			url: `/tournaments?${data._where}`,
			method: 'GET',
			params: params
		});
	} else {
		return API({
			url: '/tournaments',
			method: 'GET',
			params: data
		});
	}
};

//get Tournament Details
export const takeTournament = (data: TournamentDetailsParams) => {
	return API({
		url: '/tournaments',
		method: 'GET',
		params: data
	});
};

//create Tournament Details
export const makeTournament = (data: CreateTournamentParams) => {
	return API({
		url: '/tournaments',
		method: 'POST',
		data: data
	});
};

//modify Tournament
export const modifyTournament = (data: CreateTournamentParams) => {
	return API({
		url: `/tournaments/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Tournament
export const cutTournament = (data: TournamentDetailsParams) => {
	return API({
		url: '/tournaments',
		method: 'DELETE',
		params: data
	});
};
