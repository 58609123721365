import axios from 'axios';
// export const baseUrl = 'https://advantech.mules.cloud';
// export const baseUrl = 'http://65.1.114.116';
// export const baseUrl = 'http://65.1.114.116';
export const prodBaseUrl = 'https://api.nepalrugby.com';
export const devBaseUrl = 'https://api.nepalrugby.com';
// export const devBaseUrl = 'http://localhost:1337';
export const WebsiteUrl = 'https://rugbynepal.com';
// export const devBaseUrl = "http://localhost:1337";

export const baseUrl = process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl;

export const getToken = () => {
	let token: any = false;
	let auths = localStorage.getItem('auths') || '';
	const tokens = JSON.parse(auths);
	try {
		token = 'Bearer ' + tokens?.jwt;
	} catch (error) {
		console.log(error);
		localStorage.setItem('loggedIn', 'false');
	}
	return token;
};
export const getUserId = () => {
	let id: any = false;
	try {
		id = JSON.parse(localStorage.getItem('auths') || '')?.user?.id;
	} catch (error) {
		console.log(error);
	}
	return id;
};

export const getRole = () => {
	let role: any = false;
	try {
		role = JSON.parse(localStorage.getItem('auths') || '')?.user?.role;
	} catch (error) {
		console.log(error);
	}
	return role;
};

export const getUserDetails = () => {
	let details: any = false;
	try {
		details = JSON.parse(localStorage.getItem('auths') || '')?.user;
	} catch (error) {
		console.log(error);
	}
	return details;
}


//apis definitions

export const API = axios.create({
	baseURL: `${baseUrl}/`
});

export const AuthApi = axios.create({
	baseURL: baseUrl
});

export const MediaApis = axios.create({
	baseURL: baseUrl
});


//api request interceptors

API.interceptors.request.use(
	async (config) => {
		config.headers = {
			Authorization: getToken(),
			'Content-Type': 'application/json'
		};

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

//API for media upload
MediaApis.interceptors.request.use(
	async (config) => {
		config.headers = {
			Authorization: getToken(),
			'Content-Type': 'multipart/form-data'

		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);





//response interceptors

API.interceptors.response.use(
	(response: any) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem('auths');
			localStorage.removeItem('loggedIn');
			window.location.reload();
		}
		Promise.reject(error);
	}
)




export default API;
