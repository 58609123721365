import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createSchoolFailure,
	createSchoolSuccess,
	deleteSchoolFailure,
	deleteSchoolSuccess,
	fetchSchoolFailure,
	fetchSchoolsFailure,
	fetchSchoolsSuccess,
	fetchSchoolSuccess,
	updateSchoolFailure,
	updateSchoolSuccess
} from './SchoolActions';
import { cutSchool, makeSchool, modifySchool, takeSchool, takeSchools } from './SchoolApis';
import {
	CreateSchoolParams,
	FETCH_SCHOOLS,
	UPDATE_SCHOOL,
	SchoolDetailsParams,
	SchoolFetchParams,
	FETCH_SCHOOL_DETAILS,
	REMOVE_SCHOOL,
	CREATE_SCHOOL
} from './SchoolTypes';

//get schools
const getSchoolsAsync = async (data: SchoolFetchParams) => await takeSchools(data);

function* getSchools(params: any) {
	const { payload } = params;
	try {
		const schools = yield call(getSchoolsAsync, payload);
		if (schools) {
			yield put(fetchSchoolsSuccess(schools.data));
		} else {
			yield put(fetchSchoolsFailure('Could not get list of schools'));
			notification.error({
				message: 'Get School List',
				description: 'Could not get list of schools'
			});
		}
	} catch (error) {
		yield put(fetchSchoolsFailure('Network Error while getting Schools'));
		notification.error({
			message: 'Get School List',
			description: 'Network Error while getting Schools'
		});
	}
}

function* watchGetSchools() {
	yield takeLatest(FETCH_SCHOOLS, getSchools);
}

//get school details
const getSchoolAsync = async (data: SchoolDetailsParams) => await takeSchool(data);

function* getSchool(params: any) {
	const { payload } = params;
	try {
		const schools = yield call(getSchoolAsync, payload);
		if (schools) {
			yield put(fetchSchoolSuccess(schools.data));
		} else {
			yield put(fetchSchoolFailure('Could not get list of schools'));
			notification.error({
				message: 'Get School',
				description: 'Could not get list of schools'
			});
		}
	} catch (error) {
		yield put(fetchSchoolFailure('Network Error while getting Schools'));
		notification.error({
			message: 'Get School',
			description: 'Network Error while getting Schools'
		});
	}
}

function* watchGetSchool() {
	yield takeLatest(FETCH_SCHOOL_DETAILS, getSchool);
}

//create School
const generateSchoolAsync = async (data: CreateSchoolParams) => await makeSchool(data);

function* generateSchool(params: any) {
	const { payload } = params;
	try {
		const schools = yield call(generateSchoolAsync, payload);
		if (schools) {
			yield put(createSchoolSuccess(schools.data));
			yield put(setLayout({ form: '', data: false, update: false }));
			notification.success({
				message: 'Create School',
				description: 'School created successfully'
			});
		} else {
			const message = 'Could not create School';
			yield put(createSchoolFailure(message));
			notification.error({
				message: 'Create School',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating School';
		yield put(createSchoolFailure(message));
		notification.error({
			message: 'Create School',
			description: message
		});
	}
}

function* watchGenerateSchool() {
	yield takeLatest(CREATE_SCHOOL, generateSchool);
}

//update School
const amendSchoolAsync = async (data: CreateSchoolParams) => await modifySchool(data);

function* amendSchool(params: any) {
	const { payload } = params;
	try {
		const schools = yield call(amendSchoolAsync, payload);
		if (schools) {
			yield put(updateSchoolSuccess(schools.data));
			yield put(setLayout({ form: '', data: false, update: false }));

			notification.success({
				message: 'Update School',
				description: 'School updated successfully'
			});
		} else {
			const message = 'Could not Update School';
			yield put(updateSchoolFailure(message));
			notification.error({
				message: 'Update School',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating School';
		yield put(updateSchoolFailure(message));
		notification.error({
			message: 'Update School',
			description: message
		});
	}
}

function* watchAmendSchool() {
	yield takeLatest(UPDATE_SCHOOL, amendSchool);
}

//Delete School
const removeSchoolAsync = async (data: SchoolDetailsParams) => await cutSchool(data);

function* removeSchool(params: any) {
	const { payload } = params;
	try {
		const schools = yield call(removeSchoolAsync, payload);
		if (schools) {
			yield put(deleteSchoolSuccess(schools.data));
			notification.success({
				message: 'School Deleted',
				description: 'School deleted successfully'
			});
		} else {
			const message = 'Could not delete School';
			yield put(deleteSchoolFailure(message));
			notification.error({
				message: 'School Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting School';
		yield put(deleteSchoolFailure(message));
		notification.error({
			message: 'School Deleted',
			description: message
		});
	}
}

function* watchRemoveSchool() {
	yield takeLatest(REMOVE_SCHOOL, removeSchool);
}

export default function* SchoolSagas() {
	yield all([
		fork(watchGetSchools),
		fork(watchGetSchool),
		fork(watchGenerateSchool),
		fork(watchAmendSchool),
		fork(watchRemoveSchool)
	]);
}
