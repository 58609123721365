import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
const { Option } = Select;

const getField = (data: any) => {
    switch (data.type) {
        case 'text':
            return <Input />
        case 'textarea':
            return <TextArea style={{ marginBottom: 0 }}/>
        case 'select':
            return <GenerateSelect options={data?.options} />
        case 'checkbox':
            return <GenerateSelect multi options={data?.options} />
        case 'number':
            return <Input type="number" />
    }
}

const GenerateSelect = (props: any) => {
    const { options, multi } = props;
    if (multi) {
        return (
            <Select mode="multiple">
                {options?.map((op: any) => {
                    return <Option value={op.value}>{op.name}</Option>
                })}
            </Select>
        )
    }
    else {
        return (
            <Select>
                {options?.map((op: any) => {
                    return <Option value={op.value}>{op.name}</Option>
                })}
            </Select>
        )
    }
}

export const PerformanceFormSegment = (props: any) => {
    const { data } = props;
    return (
        <Row gutter={[20, 20]} align="middle">
            {data && data?.fields?.map((fie: any, i: number) => {
                return (
                    <>
                    <Col lg={8}>
                        <Typography.Title level={5}>{fie.label}</Typography.Title>
                    </Col>
                    <Col lg={16}>
                        {/* <Form.Item label={fie.label}> */}
                        
                        <Input.Group>
                            <Row gutter={[20, 20]}>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Strength"
                                        name={[fie.name, 'strength']}
                                        // rules={[{ required: true, message: 'This is required!' }]}
                                    >
                                        {getField(fie)}
                                    </Form.Item>

                                    </Col>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Weakness"
                                        name={[fie.name, 'weakness']}
                                        // rules={[{ required: true, message: 'This is required!' }]}
                                    >
                                        {getField(fie)}
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Input.Group>
                    {/* </Form.Item> */}
                        </Col>
                        <Divider/>
                    </>

                )
            })}
            {/* <Col lg={24} style={{ textAlign: 'center' }}>
                <Button htmlType="submit" danger>SUBMIT</Button>
            </Col> */}
        </Row>
    );
};

export default PerformanceFormSegment;
