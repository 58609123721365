import MainContainer from 'Container/MainContainer/MainContainer';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import EventDetails from 'Views/Events/EventDetails';
import Events from 'Views/Events/Events';
export const EventRoute = (props: RouteChildrenProps) => {
  return (
    <MainContainer>
      <Switch>
        <Route path={`${props.match?.path}/list`} exact component={Events} />
        <Route path={`${props.match?.path}/:eventId`} component={EventDetails} />
        <Route component={Events} />
      </Switch>
    </MainContainer>
  )
}

export default EventRoute;