export const FETCH_GIREVENTS = 'FETCH_GIREVENTS';
export const FETCH_GIREVENTS_SUCCESS = 'FETCH_GIREVENTS_SUCCESS';
export const FETCH_GIREVENTS_FAILURE = 'FETCH_GIREVENTS_FAILURE';

export const FETCH_GIREVENT_DETAILS = 'FETCH_GIREVENT_DETAILS';
export const FETCH_GIREVENT_DETAILS_SUCCESS = 'FETCH_GIREVENT_DETAILS_SUCCESS';
export const FETCH_GIREVENT_DETAILS_FAILURE = 'FETCH_GIREVENT_DETAILS_FAILURE';

export const CREATE_GIREVENT = 'CREATE_GIREVENT';
export const CREATE_GIREVENT_SUCCESS = 'CREATE_GIREVENT_SUCCESS';
export const CREATE_GIREVENT_FAILURE = 'CREATE_GIREVENT_FAILURE';

export const UPDATE_GIREVENT = 'UPDATE_GIREVENT';
export const UPDATE_GIREVENT_SUCCESS = 'UPDATE_GIREVENT_SUCCESS';
export const UPDATE_GIREVENT_FAILURE = 'UPDATE_GIREVENT_FAILURE';

export const REMOVE_GIREVENT = 'REMOVE_GIREVENT';
export const REMOVE_GIREVENT_SUCCESS = 'REMOVE_GIREVENT_SUCCESS';
export const REMOVE_GIREVENT_FAILURE = 'REMOVE_GIREVENT_FAILURE';

export interface GireventFetchParams {
	limit?: number;
	page?: number;
}
export interface GireventDetailsParams {
	id?: number;
}
export interface CreateGireventParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
