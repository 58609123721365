import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import React from "react";
import DataTable from "Components/Table/DataTable";
import TableActions from "Components/TableAction/TableAction";
import MyContent from "Components/Content/Content";
import { Typography, Radio, Space, Button } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import { useHistory } from "react-router";
import DetailsView from "Components/DetailsView/DetailsView";
import { PlayerKeys } from "Models/KeyMapModels";
import { getRole, getUserId } from "Api/Api";
import { AccessControl, IsSelfOnly } from "Utils/AccessControl";
import { takePlayersCount } from "Api/CounteApi";
import { SelfPlayers } from "Utils/ComplexQueries";
import { fetchPlayers } from "Redux/PlayerRedux/PlayerActions";
import { useDebounce } from "use-debounce/lib";
import { ExportOutlined } from "@ant-design/icons";
import exportFromJSON from "export-from-json";

interface PlayerListProps {
  dashboard?: boolean;
}

export const PlayerList = ({ dashboard }: PlayerListProps) => {
  const schools = useSelector((state: any) => state.PlayerReducer);
  const dataList = schools?.list || [];
  const history = useHistory();

  const [gender, setGener] = useState("");

  const dispatch = useDispatch();

  const [filterText, setFilterText] = React.useState("");
  const [searchText] = useDebounce(filterText, 500);

  const [perPageData, setPerPageData] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = dataList?.filter(
    (item: any) =>
      item.fullName &&
      item.fullName.toLowerCase().includes(filterText.toLowerCase())
  );

  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<any>(false);

  const [count, setCount] = useState(0);

  const getCount = async (params: any) => {
    await takePlayersCount({
      ...(IsSelfOnly() ? SelfPlayers() : {}),
      name_contains: filterText.toLocaleLowerCase(),
      ...params,
    })
      .then((d) => {
        setCount(d?.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const fetchWithFilter = (data: any) => {
    dispatch(
      fetchPlayers({
        ...data,
        ...(IsSelfOnly() ? SelfPlayers() : {}),
        _sort: "createdAt:DESC",
        name_contains: filterText.toLowerCase(),
      })
    );
  };
  useEffect(() => {
    const params = { gender_contains: gender };
    fetchWithFilter({ _limit: 10, ...params });
    getCount({ ...params });
  }, [searchText, gender]);

  //Row and Per page change
  const handlePerRowsChange = (perPage: number, page: number) => {
    setPerPageData(perPage);
    fetchWithFilter({ _start: perPage * (page - 1), _limit: perPage });
  };
  const handlePageChange = (page: number) => {
    fetchWithFilter({ _start: perPageData * (page - 1), _limit: perPageData });
  };

  useEffect(() => {
    fetchWithFilter({ _limit: 10, _start: 0 });
    getCount({});
  }, [0]);

  const FilterButton = (props: any) => {
    return (
      <Space align="center">
        <Radio.Group
          style={{ marginTop: 40 }}
          defaultValue=""
          onChange={(e: any) => setGener(e?.target?.value)}
          buttonStyle="solid"
        >
          <Radio.Button value="">All</Radio.Button>
          <Radio.Button value="Male">Men</Radio.Button>
          <Radio.Button value="Female">Women</Radio.Button>
        </Radio.Group>
        <Button
          onClick={props?.onExport}
          icon={<ExportOutlined />}
          style={{ marginTop: 40 }}
          type="primary"
          hidden={getRole()?.type !== "general_manager"}
        >
          Export Data
        </Button>
      </Space>
    );
  };

  const onExport = () => {
    exportFromJSON({
      data: schools?.list?.map((pl: any) => {
        return {
          ...pl,
          dob: pl?.dob ? dayjs(pl?.dob).format("LL") : "N/A",
        };
      }),
      fileName: "players",
      fields: [
        "name",
        "gender",
        "email",
        "mobile",
        "address",
        "dob",
        "club",
        "district",
        "height",
        "weight",
      ],
      exportType: exportFromJSON.types.csv,
    });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <TableHeader
        onFilter={(e: FormEvent<HTMLInputElement>) =>
          setFilterText(e.currentTarget.value)
        }
        onClear={handleClear}
        // filterText={filterText}
        title={dashboard ? "RECENT PLAYERS" : "PLAYER LIST"}
        module="player"
        data={{ coach: getUserId() }}
        noAddFeature={!AccessControl("player") || dashboard}
        search
        extraBottom={<FilterButton onExport={onExport} />}
      />
    );
  }, [filterText, resetPaginationToggle, dataList]);

  const onRowClicked = (values: any) => {
    setDetails({
      ...values,
      district: values?.iDistrict || values?.district,
      province: values?.iProvince,
    });
  };

  const onEditHandler = (values: any) => {
    dispatch(setLayout({ form: "player", data: values, update: true }));
  };

  const onDeleteHandler = (id: number) => {
    // dispatch(deleteAsset(id))
  };
  const onAddHandler = (data: any) => {
    dispatch(setLayout({ form: "reporting", data: data, update: false }));
    // dispatch(deleteAsset(id))
  };
  const assetColumns: TableColumn[] = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row: any) => (
        <Typography.Link
          style={{ textTransform: "capitalize" }}
          // onClick={() => history.push(`/schools/${row.id}`)}
        >
          {row?.name}
        </Typography.Link>
      ),
    },
    {
      name: "Address",
      selector: "address",
      omit: dashboard,
    },
    // {
    //     name: 'Phone',
    //     selector: 'mobile',
    //     omit: dashboard

    // },
    {
      name: "Email",
      selector: "email",
      omit: dashboard,
      cell: (row: any) => <Typography.Link>{row?.email}</Typography.Link>,
    },
    {
      name: "Coach",
      selector: "coach",
      cell: (row: any) => (
        <Typography.Text>{row?.coach?.fullName}</Typography.Text>
      ),
    },
    {
      name: "Join From",
      selector: "createdAt",
      omit: dashboard,
      cell: (row: any) => (
        <Typography.Text>{dayjs(row?.createdAt).format("LL")}</Typography.Text>
      ),
    },
    {
      name: "Action",
      omit: dashboard,
      selector: "",
      cell: (row: any) => (
        <TableActions noDelete noReport onEdit={() => onEditHandler(row)} />
      ),
    },
  ];

  return (
    <MyContent>
      <DataTable
        title={subHeaderComponentMemo}
        columns={assetColumns}
        data={schools?.list}
        progressPending={schools?.loading}
        // noHeader
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        pagination={!dashboard}
        paginationRowsPerPageOptions={[20, 50, 100, 200, 500]}
        onRowClicked={onRowClicked}
      />
      {/* <DataTable columns={problemColumns} data={filteredItems} /> */}
      <DetailsView
        module="player"
        labels={PlayerKeys}
        data={details}
        onHide={() => setDetails(false)}
      />
    </MyContent>
  );
};

export default PlayerList;
