import { Row, Col } from 'antd';
import { RouteChildrenProps } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import DashboardCard from 'Components/DashboardCard/DashboardCard';
import { useEffect, useState } from 'react';
import { takeCoachCount, takeEventsCount, takePlayersCount, takeSessionCount } from 'Api/CounteApi';
import { CalendarFilled, PlayCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { AccessControl, IsSelfOnly } from 'Utils/AccessControl';
import { SelfEvents, SelfSessions, SelfPlayers, SelfCoaches, CoachesOnly } from 'Utils/ComplexQueries';
import Widgets from './Widgets/Widgets';
import { useDispatch } from 'react-redux';
import { ReactComponent as PlayerIcon } from 'Assets/Icons/player.svg';
import Stats from './Stats/Stats';

const Dashboard = (props: RouteChildrenProps) => {
	const [loading, setLoading] = useState<any>(false);
	const [data, setData] = useState<any>([])
	const dispatch = useDispatch();
	const fetchcount = async () => {
		setLoading(true)
		let countData: any = [];
		await takeEventsCount(IsSelfOnly() ? { ...SelfEvents() } : {}).then((d) => {
			countData = [...countData, { module: 'event', count: d?.data }];
		}).catch((err: any) => {
			console.log(err)
		})

		await takeSessionCount(IsSelfOnly() ? { ...SelfSessions() } : {}).then((d) => {
			countData = [...countData, { module: 'session', count: d?.data }];
		}).catch((err: any) => {
			console.log(err)
		})

		await takePlayersCount(IsSelfOnly() ? { ...SelfPlayers() } : {}).then((d) => {
			countData = [...countData, { module: 'player', count: d?.data }];
		}).catch((err: any) => {
			console.log(err)
		})

		await takeCoachCount(IsSelfOnly() ? { ...SelfCoaches('') } : CoachesOnly('')).then((d) => {
			countData = [...countData, { module: 'coach', count: d?.data }];
		}).catch((err: any) => {
			console.log(err)
		})
		setLoading(false)
		setData(countData);
	}

	useEffect(() => {
		fetchcount();

	}, [])
	return (
		<Content>
			<Row gutter={[20, 20]} align='stretch' style={{ marginBottom: 20 }}>
				<Col lg={24}>
					<Row gutter={[20, 20]}>
						<Col lg={6}>
							<DashboardCard icon={<CalendarFilled className="icon" style={{ fontSize: 34, color: 'green' }} />} loading={loading} color="green" count={data.filter((d: any) => d.module == 'event')[0]?.count} title="Events" />
						</Col>
						<Col lg={6}>
							<DashboardCard icon={<PlayCircleOutlined className="icon" style={{ fontSize: 34, color: 'maroon' }} />} loading={loading} count={data.filter((d: any) => d.module == 'session')[0]?.count} title="Sessions" />
						</Col>
						<Col lg={6}>
							<DashboardCard icon={<PlayerIcon className="icon" fill="green" style={{ width: 40, color: 'green' }} />} color="green" loading={loading} count={data.filter((d: any) => d.module == 'player')[0]?.count} title="Players" />
						</Col>
						<Col lg={6} hidden={!AccessControl('coach').list}>
							<DashboardCard icon={<UsergroupAddOutlined className="icon" style={{ fontSize: 34, color: 'blue' }} />} color="blue" loading={loading} count={data.filter((d: any) => d.module == 'coach')[0]?.count} title="Coaches" />
						</Col>
						{/* <Col lg={6}>
							<DashboardCard count={2} title="Inomplete plans" />
						</Col>
						<Col lg={6}>
							<DashboardCard color="orange" count={4} title="all time schools" />
						</Col> */}
					</Row>

				</Col>

			</Row>
			{AccessControl('stats').list && <Stats />}

			<Widgets />
		</Content>
	);
};

export default Dashboard;
