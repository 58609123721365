export const FETCH_ACTIVITYS = 'FETCH_ACTIVITYS';
export const FETCH_ACTIVITYS_SUCCESS = 'FETCH_ACTIVITYS_SUCCESS';
export const FETCH_ACTIVITYS_FAILURE = 'FETCH_ACTIVITYS_FAILURE';

export const FETCH_ACTIVITY_DETAILS = 'FETCH_ACTIVITY_DETAILS';
export const FETCH_ACTIVITY_DETAILS_SUCCESS = 'FETCH_ACTIVITY_DETAILS_SUCCESS';
export const FETCH_ACTIVITY_DETAILS_FAILURE = 'FETCH_ACTIVITY_DETAILS_FAILURE';

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const REMOVE_ACTIVITY_SUCCESS = 'REMOVE_ACTIVITY_SUCCESS';
export const REMOVE_ACTIVITY_FAILURE = 'REMOVE_ACTIVITY_FAILURE';

export interface ActivityFetchParams {
	limit?: number;
	page?: number;
	_where?: string;
}
export interface ActivityDetailsParams {
	id?: number;
}
export interface CreateActivityParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
