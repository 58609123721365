import {
	CreateGirreportParams,
	CREATE_GIRREPORT,
	CREATE_GIRREPORT_FAILURE,
	CREATE_GIRREPORT_SUCCESS,
	FETCH_GIRREPORTS,
	FETCH_GIRREPORTS_FAILURE,
	FETCH_GIRREPORTS_SUCCESS,
	FETCH_GIRREPORT_DETAILS,
	FETCH_GIRREPORT_DETAILS_FAILURE,
	FETCH_GIRREPORT_DETAILS_SUCCESS,
	REMOVE_GIRREPORT,
	REMOVE_GIRREPORT_FAILURE,
	REMOVE_GIRREPORT_SUCCESS,
	UPDATE_GIRREPORT,
	UPDATE_GIRREPORT_FAILURE,
	UPDATE_GIRREPORT_SUCCESS,
	GirreportDetailsParams,
	GirreportFetchParams
} from './GirreportTypes';

//fetch Girreport List
export const fetchGirreports = (params: GirreportFetchParams) => ({
	type: FETCH_GIRREPORTS,
	payload: params
});

export const fetchGirreportsSuccess = (response: any) => ({
	type: FETCH_GIRREPORTS_SUCCESS,
	payload: response
});

export const fetchGirreportsFailure = (error: any) => ({
	type: FETCH_GIRREPORTS_FAILURE,
	payload: error
});

//fetch Girreport Details
export const fetchGirreport = (params: GirreportDetailsParams) => ({
	type: FETCH_GIRREPORT_DETAILS,
	payload: params
});

export const fetchGirreportSuccess = (response: any) => ({
	type: FETCH_GIRREPORT_DETAILS_SUCCESS,
	payload: response
});

export const fetchGirreportFailure = (error: any) => ({
	type: FETCH_GIRREPORT_DETAILS_FAILURE,
	payload: error
});

//create Girreport
export const createGirreport = (params: CreateGirreportParams) => ({
	type: CREATE_GIRREPORT,
	payload: params
});

export const createGirreportSuccess = (response: CreateGirreportParams) => ({
	type: CREATE_GIRREPORT_SUCCESS,
	payload: response
});
export const createGirreportFailure = (error: any) => ({
	type: CREATE_GIRREPORT_FAILURE,
	payload: error
});

//update Girreport
export const updateGirreport = (params: CreateGirreportParams) => ({
	type: UPDATE_GIRREPORT,
	payload: params
});

export const updateGirreportSuccess = (response: CreateGirreportParams) => ({
	type: UPDATE_GIRREPORT_SUCCESS,
	payload: response
});

export const updateGirreportFailure = (error: any) => ({
	type: UPDATE_GIRREPORT_FAILURE,
	payload: error
});

//delete Girreport
export const deleteGirreport = (params: GirreportDetailsParams) => ({
	type: REMOVE_GIRREPORT,
	payload: params
});
export const deleteGirreportSuccess = (response: GirreportDetailsParams) => ({
	type: REMOVE_GIRREPORT_SUCCESS,
	payload: response
});
export const deleteGirreportFailure = (error: any) => ({
	type: REMOVE_GIRREPORT_FAILURE,
	payload: error
});
