import { Reducer } from 'react';
import {
	CreateMatchParams,
	CREATE_MATCH,
	CREATE_MATCH_FAILURE,
	CREATE_MATCH_SUCCESS,
	FETCH_MATCHS,
	FETCH_MATCHS_FAILURE,
	FETCH_MATCHS_SUCCESS,
	FETCH_MATCH_DETAILS,
	FETCH_MATCH_DETAILS_FAILURE,
	FETCH_MATCH_DETAILS_SUCCESS,
	REMOVE_MATCH,
	REMOVE_MATCH_FAILURE,
	REMOVE_MATCH_SUCCESS,
	UPDATE_MATCH,
	UPDATE_MATCH_FAILURE,
	UPDATE_MATCH_SUCCESS,
	MatchDetailsParams
} from './MatchTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const MatchReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Matchs
		case FETCH_MATCHS:
			return { ...state, loading: true };
		case FETCH_MATCHS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_MATCHS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Match Details
		case FETCH_MATCH_DETAILS:
			return { ...state, detailing: true };
		case FETCH_MATCH_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_MATCH_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Match
		case CREATE_MATCH:
			return { ...state, creating: true };
		case CREATE_MATCH_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_MATCH_FAILURE:
			return { ...state, creating: false };

		//update Match
		case UPDATE_MATCH:
			return { ...state, updating: true };
		case UPDATE_MATCH_SUCCESS:
			const tempList = state.list.filter((d: CreateMatchParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_MATCH_FAILURE:
			return { ...state, updating: false };

		//remove Match
		case REMOVE_MATCH:
			return { ...state, deleting: true };
		case REMOVE_MATCH_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: MatchDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_MATCH_FAILURE:
			return { ...state, deleting: false };

		default:
			return { ...state };
	}
};
