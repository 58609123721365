import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Button, Typography } from "antd";
import form from "antd/lib/form";
import { Content } from "antd/lib/layout/layout";
import { uploadImage } from "Api/MediaApi";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import { GetUploadUrl } from "Utils/GetUploadResponse";
import "./FileUpload.scss";

interface UploadFileProps {
  onUpload: (e: any) => void;
  url?: string;
  type?: "file";
}
export const FileUpload = ({ onUpload, url, type }: UploadFileProps) => {
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [debounceImage] = useDebounce(image, 1000);

  const beforeUpload = async ({ file }: any) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //     message.error('You can only upload JPG/PNG file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //     message.error('Image must smaller than 2MB!');
    // }

    // setImageUrl(URL.createObjectURL(file?.originFileObj));
    // setImageUrl(file?.originFileObj)
    setImage(file);

    return true;
  };

  const uploadFile = async (file: any) => {
    setLoading(true);
    setError("");
    let formData = new FormData();
    formData.append("files", file?.originFileObj);
    await uploadImage(formData)
      .then((data: any) => {
        setLoading(false);

        setImageUrl(GetUploadUrl(data?.data));
        onUpload(data?.data[0]);
      })
      .catch((err: any) => {
        setLoading(false);
        message.error("It looks like you are trying to upload too large file.");
        console.log(err);
        setError("It looks like you are trying to upload too large file.");
      });
  };

  useEffect(() => {
    if (debounceImage) uploadFile(debounceImage);
  }, [debounceImage]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <React.Fragment>
      {type === "file" ? (
        <Upload
          name="image"
          listType="text"
          className="avatar-uploader"
          showUploadList={false}
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={beforeUpload}
        >
          {url || imageUrl ? (
            <Typography.Link
              style={{ maxWidth: 150 }}
              ellipsis
              download
              onClick={(e: any) => e?.preventDefault()}
              href={imageUrl || url}
            >
              {(imageUrl || url)?.split("/").slice(-1).pop()}
            </Typography.Link>
          ) : (
            <Button loading={loading}>Upload</Button>
          )}
          {error && <Typography.Text type="danger">{error}</Typography.Text>}
        </Upload>
      ) : (
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={beforeUpload}
        >
          {url || imageUrl ? (
            <img src={imageUrl || url} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </React.Fragment>
  );
};

export default FileUpload;
