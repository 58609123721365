import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const RouteGuard = (props: any) => {
    const history = useHistory();
    const auth = useSelector((state: any) => state.AuthReducer);
    if (!auth.loggedIn) {
        history.push('/auth/login')
    }
    return (
        <>
        {auth.loggedIn ? props.children : ''}
        </>
    )
};
