import {
	CreateReportParams,
	CREATE_REPORT,
	CREATE_REPORT_FAILURE,
	CREATE_REPORT_SUCCESS,
	FETCH_REPORTS,
	FETCH_REPORTS_FAILURE,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORT_DETAILS,
	FETCH_REPORT_DETAILS_FAILURE,
	FETCH_REPORT_DETAILS_SUCCESS,
	REMOVE_REPORT,
	REMOVE_REPORT_FAILURE,
	REMOVE_REPORT_SUCCESS,
	UPDATE_REPORT,
	UPDATE_REPORT_FAILURE,
	UPDATE_REPORT_SUCCESS,
	ReportDetailsParams,
	ReportFetchParams
} from './ReportTypes';

//fetch Report List
export const fetchReports = (params: ReportFetchParams) => ({
	type: FETCH_REPORTS,
	payload: params
});

export const fetchReportsSuccess = (response: any) => ({
	type: FETCH_REPORTS_SUCCESS,
	payload: response
});

export const fetchReportsFailure = (error: any) => ({
	type: FETCH_REPORTS_FAILURE,
	payload: error
});

//fetch Report Details
export const fetchReport = (params: ReportDetailsParams) => ({
	type: FETCH_REPORT_DETAILS,
	payload: params
});

export const fetchReportSuccess = (response: any) => ({
	type: FETCH_REPORT_DETAILS_SUCCESS,
	payload: response
});

export const fetchReportFailure = (error: any) => ({
	type: FETCH_REPORT_DETAILS_FAILURE,
	payload: error
});

//create Report
export const createReport = (params: CreateReportParams) => ({
	type: CREATE_REPORT,
	payload: params
});

export const createReportSuccess = (response: CreateReportParams) => ({
	type: CREATE_REPORT_SUCCESS,
	payload: response
});
export const createReportFailure = (error: any) => ({
	type: CREATE_REPORT_FAILURE,
	payload: error
});

//update Report
export const updateReport = (params: CreateReportParams) => ({
	type: UPDATE_REPORT,
	payload: params
});

export const updateReportSuccess = (response: CreateReportParams) => ({
	type: UPDATE_REPORT_SUCCESS,
	payload: response
});

export const updateReportFailure = (error: any) => ({
	type: UPDATE_REPORT_FAILURE,
	payload: error
});

//delete Report
export const deleteReport = (params: ReportDetailsParams) => ({
	type: REMOVE_REPORT,
	payload: params
});
export const deleteReportSuccess = (response: ReportDetailsParams) => ({
	type: REMOVE_REPORT_SUCCESS,
	payload: response
});
export const deleteReportFailure = (error: any) => ({
	type: REMOVE_REPORT_FAILURE,
	payload: error
});
