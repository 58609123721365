import { Col, Row } from 'antd';
import RefereeList from './RefereeList';

export const Referee = (props: any) => {

	return (
		<Row gutter={[20, 20]}>
			<Col lg={24}>
				<RefereeList />
			</Col>
		</Row>
	);
};

export default Referee;
