import { Reducer } from 'react';
import {
	CreatePlayerParams,
	CREATE_PLAYER,
	CREATE_PLAYER_FAILURE,
	CREATE_PLAYER_SUCCESS,
	FETCH_PLAYERS,
	FETCH_PLAYERS_FAILURE,
	FETCH_PLAYERS_SUCCESS,
	FETCH_PLAYER_DETAILS,
	FETCH_PLAYER_DETAILS_FAILURE,
	FETCH_PLAYER_DETAILS_SUCCESS,
	REMOVE_PLAYER,
	REMOVE_PLAYER_FAILURE,
	REMOVE_PLAYER_SUCCESS,
	UPDATE_PLAYER,
	UPDATE_PLAYER_FAILURE,
	UPDATE_PLAYER_SUCCESS,
	PlayerDetailsParams
} from './PlayerTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const PlayerReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Players
		case FETCH_PLAYERS:
			return { ...state, loading: true };
		case FETCH_PLAYERS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_PLAYERS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Player Details
		case FETCH_PLAYER_DETAILS:
			return { ...state, detailing: true };
		case FETCH_PLAYER_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_PLAYER_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Player
		case CREATE_PLAYER:
			return { ...state, creating: true };
		case CREATE_PLAYER_SUCCESS:
			const tempData = state.list;
			tempData.concat([ action.payload ]);
			return { ...state, creating: false, list: tempData };
		case CREATE_PLAYER_FAILURE:
			return { ...state, creating: false };

		//update Player
		case UPDATE_PLAYER:
			return { ...state, updating: true };
		case UPDATE_PLAYER_SUCCESS:
			const tempList = state.list.filter((d: any) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_PLAYER_FAILURE:
			return { ...state, updating: false };

		//remove Player
		case REMOVE_PLAYER:
			return { ...state, deleting: true };
		case REMOVE_PLAYER_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: PlayerDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_PLAYER_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
