import { Reducer } from 'react';
import {
	CreateRefereeParams,
	CREATE_REFEREE,
	CREATE_REFEREE_FAILURE,
	CREATE_REFEREE_SUCCESS,
	FETCH_REFEREES,
	FETCH_REFEREES_FAILURE,
	FETCH_REFEREES_SUCCESS,
	FETCH_REFEREE_DETAILS,
	FETCH_REFEREE_DETAILS_FAILURE,
	FETCH_REFEREE_DETAILS_SUCCESS,
	REMOVE_REFEREE,
	REMOVE_REFEREE_FAILURE,
	REMOVE_REFEREE_SUCCESS,
	UPDATE_REFEREE,
	UPDATE_REFEREE_FAILURE,
	UPDATE_REFEREE_SUCCESS,
	RefereeDetailsParams
} from './RefereeTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const RefereeReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Referees
		case FETCH_REFEREES:
			return { ...state, loading: true };
		case FETCH_REFEREES_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_REFEREES_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Referee Details
		case FETCH_REFEREE_DETAILS:
			return { ...state, detailing: true };
		case FETCH_REFEREE_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_REFEREE_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Referee
		case CREATE_REFEREE:
			return { ...state, creating: true };
		case CREATE_REFEREE_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_REFEREE_FAILURE:
			return { ...state, creating: false };

		//update Referee
		case UPDATE_REFEREE:
			return { ...state, updating: true };
		case UPDATE_REFEREE_SUCCESS:
			const tempList = state.list.filter((d: CreateRefereeParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_REFEREE_FAILURE:
			return { ...state, updating: false };

		//remove Referee
		case REMOVE_REFEREE:
			return { ...state, deleting: true };
		case REMOVE_REFEREE_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: RefereeDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_REFEREE_FAILURE:
			return { ...state, deleting: false };
		default:
			return { ...state };
	}
};
