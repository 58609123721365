import { useEffect, useState } from 'react';
import { Card, Typography, Divider, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { setAuth } from 'Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import Animate from 'rc-animate';
import { LoginForm } from './LoginForm';
import { requestLogin } from 'Api/AuthApi';

const Login = () => {
	const auth = useSelector((state: any) => state.AuthReducer);
	const [authState] = useState('login');
	const dispatch = useDispatch();
	const history = useHistory();

	const preLoginHandler = (value: any) => {
		dispatch(setAuth({ requesting: true, error: '' }));
		notification.destroy();
		requestLogin(value)
			.then((data: any) => {
				localStorage.setItem('auths', JSON.stringify(data.data));
				dispatch(setAuth({ requesting: false, error: '', loggedIn: true, token: data.data }));
				localStorage.setItem('loggedIn', 'true');
			})
			.catch((error: any) => {
				const data = error?.response?.data;
				dispatch(setAuth({ requesting: false, error: data?.data[0].messages[0].message }));
				notification.error({
					message: 'Login Failed',
					description: auth.error
				});
			});
	};

	useEffect(
		() => {
			if (auth.error) {
				notification.error({
					message: 'Login Failed',
					description: auth.error
				});
			}
		},
		[auth.error]
	);

	useEffect(
		() => {
			if (auth.loggedIn) {
				history.push('/');
			}
		},
		[auth, history]
	);

	const getLoginView = (state: string) => {
		switch (state) {
			case 'login':
				return (
					<LoginForm
						error={auth.error}
						loginHandler={(e: any) => preLoginHandler(e)}
						requesting={auth.requesting}
					/>
				);
			default:
				return <LoginForm loginHandler={(e: any) => preLoginHandler(e)} requesting={auth.requesting} />;
		}
	};

	return (
		<Animate transitionName="fade" transitionAppear>
			<Card style={{ boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.03)', borderRadius: '10px', margin: '40px 0px' }}>
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Login
				</Typography.Title>
				<Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

				{getLoginView(authState)}
			</Card>
		</Animate>
	);
};

export default Login;
