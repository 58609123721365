import {
	CreateSessionParams,
	CREATE_SESSION,
	CREATE_SESSION_FAILURE,
	CREATE_SESSION_SUCCESS,
	FETCH_SESSIONS,
	FETCH_SESSIONS_FAILURE,
	FETCH_SESSIONS_SUCCESS,
	FETCH_SESSION_DETAILS,
	FETCH_SESSION_DETAILS_FAILURE,
	FETCH_SESSION_DETAILS_SUCCESS,
	REMOVE_SESSION,
	REMOVE_SESSION_FAILURE,
	REMOVE_SESSION_SUCCESS,
	UPDATE_SESSION,
	UPDATE_SESSION_FAILURE,
	UPDATE_SESSION_SUCCESS,
	SessionDetailsParams,
	SessionFetchParams
} from './SessionTypes';

//fetch Session List
export const fetchSessions = (params: SessionFetchParams) => ({
	type: FETCH_SESSIONS,
	payload: params
});

export const fetchSessionsSuccess = (response: any) => ({
	type: FETCH_SESSIONS_SUCCESS,
	payload: response
});

export const fetchSessionsFailure = (error: any) => ({
	type: FETCH_SESSIONS_FAILURE,
	payload: error
});

//fetch Session Details
export const fetchSession = (params: SessionDetailsParams) => ({
	type: FETCH_SESSION_DETAILS,
	payload: params
});

export const fetchSessionSuccess = (response: any) => ({
	type: FETCH_SESSION_DETAILS_SUCCESS,
	payload: response
});

export const fetchSessionFailure = (error: any) => ({
	type: FETCH_SESSION_DETAILS_FAILURE,
	payload: error
});

//create Session
export const createSession = (params: CreateSessionParams) => ({
	type: CREATE_SESSION,
	payload: params
});

export const createSessionSuccess = (response: CreateSessionParams) => ({
	type: CREATE_SESSION_SUCCESS,
	payload: response
});
export const createSessionFailure = (error: any) => ({
	type: CREATE_SESSION_FAILURE,
	payload: error
});

//update Session
export const updateSession = (params: CreateSessionParams) => ({
	type: UPDATE_SESSION,
	payload: params
});

export const updateSessionSuccess = (response: CreateSessionParams) => ({
	type: UPDATE_SESSION_SUCCESS,
	payload: response
});

export const updateSessionFailure = (error: any) => ({
	type: UPDATE_SESSION_FAILURE,
	payload: error
});

//delete Session
export const deleteSession = (params: SessionDetailsParams) => ({
	type: REMOVE_SESSION,
	payload: params
});
export const deleteSessionSuccess = (response: SessionDetailsParams) => ({
	type: REMOVE_SESSION_SUCCESS,
	payload: response
});
export const deleteSessionFailure = (error: any) => ({
	type: REMOVE_SESSION_FAILURE,
	payload: error
});
