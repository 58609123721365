import API from 'Api/Api';
import { CreatePlayerParams, PlayerDetailsParams, PlayerFetchParams } from './PlayerTypes';

//get Player
export const takePlayers = (data: PlayerFetchParams) => {
	if (data._where) {
		return API({
			url: `/players?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/players',
			method: 'GET',
			params: data
		});
	}
};

//get Player Details
export const takePlayer = (data: PlayerDetailsParams) => {
	return API({
		url: '/players',
		method: 'GET',
		params: data
	});
};

//create Player Details
export const makePlayer = (data: CreatePlayerParams) => {
	return API({
		url: '/players',
		method: 'POST',
		data: data
	});
};

//modify Player
export const modifyPlayer = (data: CreatePlayerParams) => {
	return API({
		url: `/players/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Player
export const cutPlayer = (data: PlayerDetailsParams) => {
	return API({
		url: '/players',
		method: 'DELETE',
		params: data
	});
};
