import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	createPerformanceFailure,
	createPerformanceSuccess,
	deletePerformanceFailure,
	deletePerformanceSuccess,
	fetchPerformanceFailure,
	fetchPerformancesFailure,
	fetchPerformancesSuccess,
	fetchPerformanceSuccess,
	updatePerformanceFailure,
	updatePerformanceSuccess
} from './PerformanceActions';
import {
	cutPerformance,
	makePerformance,
	modifyPerformance,
	takePerformance,
	takePerformances
} from './PerformanceApis';
import {
	CreatePerformanceParams,
	FETCH_PERFORMANCES,
	UPDATE_PERFORMANCE,
	PerformanceDetailsParams,
	PerformanceFetchParams,
	REMOVE_PERFORMANCE,
	CREATE_PERFORMANCE,
	FETCH_PERFORMANCE_DETAILS
} from './PerformanceTypes';

//get performances
const getPerformancesAsync = async (data: PerformanceFetchParams) => await takePerformances(data);

function* getPerformances(params: any) {
	const { payload } = params;
	try {
		const performances = yield call(getPerformancesAsync, payload);
		if (performances) {
			yield put(fetchPerformancesSuccess(performances.data));
		} else {
			yield put(fetchPerformancesFailure('Could not get list of performances'));
			notification.error({
				message: 'Get Performance List',
				description: 'Could not get list of performances'
			});
		}
	} catch (error) {
		yield put(fetchPerformancesFailure('Network Error while getting Performances'));
		notification.error({
			message: 'Get Performance List',
			description: 'Network Error while getting Performances'
		});
	}
}

function* watchGetPerformances() {
	yield takeLatest(FETCH_PERFORMANCES, getPerformances);
}

//get performance details
const getPerformanceAsync = async (data: PerformanceDetailsParams) => await takePerformance(data);

function* getPerformance(params: any) {
	const { payload } = params;
	try {
		const performances = yield call(getPerformanceAsync, payload);
		if (performances) {
			yield put(fetchPerformanceSuccess(performances.data));
		} else {
			yield put(fetchPerformanceFailure('Could not get list of performances'));
			notification.error({
				message: 'Get Performance',
				description: 'Could not get list of performances'
			});
		}
	} catch (error) {
		yield put(fetchPerformanceFailure('Network Error while getting Performances'));
		notification.error({
			message: 'Get Performance',
			description: 'Network Error while getting Performances'
		});
	}
}

function* watchGetPerformance() {
	yield takeLatest(FETCH_PERFORMANCE_DETAILS, getPerformance);
}

//create Performance
const generatePerformanceAsync = async (data: CreatePerformanceParams) => await makePerformance(data);

function* generatePerformance(params: any) {
	const { payload } = params;
	try {
		const performances = yield call(generatePerformanceAsync, payload);
		if (performances) {
			yield put(createPerformanceSuccess(performances.data));
			notification.success({
				message: 'Create Performance',
				description: 'Performance created successfully'
			});
		} else {
			const message = 'Could not create Performance';
			yield put(createPerformanceFailure(message));
			notification.error({
				message: 'Create Performance',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Performance';
		yield put(createPerformanceFailure(message));
		notification.error({
			message: 'Create Performance',
			description: message
		});
	}
}

function* watchGeneratePerformance() {
	yield takeLatest(CREATE_PERFORMANCE, generatePerformance);
}

//update Performance
const amendPerformanceAsync = async (data: CreatePerformanceParams) => await modifyPerformance(data);

function* amendPerformance(params: any) {
	const { payload } = params;
	try {
		const performances = yield call(amendPerformanceAsync, payload);
		if (performances) {
			yield put(updatePerformanceSuccess(performances.data));
			notification.success({
				message: 'Update Performance',
				description: 'Performance updated successfully'
			});
		} else {
			const message = 'Could not Update Performance';
			yield put(updatePerformanceFailure(message));
			notification.error({
				message: 'Update Performance',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Performance';
		yield put(updatePerformanceFailure(message));
		notification.error({
			message: 'Update Performance',
			description: message
		});
	}
}

function* watchAmendPerformance() {
	yield takeLatest(UPDATE_PERFORMANCE, amendPerformance);
}

//Delete Performance
const removePerformanceAsync = async (data: PerformanceDetailsParams) => await cutPerformance(data);

function* removePerformance(params: any) {
	const { payload } = params;
	try {
		const performances = yield call(removePerformanceAsync, payload);
		if (performances) {
			yield put(deletePerformanceSuccess(performances.data));
			notification.success({
				message: 'Performance Deleted',
				description: 'Performance deleted successfully'
			});
		} else {
			const message = 'Could not delete Performance';
			yield put(deletePerformanceFailure(message));
			notification.error({
				message: 'Performance Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Performance';
		yield put(deletePerformanceFailure(message));
		notification.error({
			message: 'Performance Deleted',
			description: message
		});
	}
}

function* watchRemovePerformance() {
	yield takeLatest(REMOVE_PERFORMANCE, removePerformance);
}

export default function* PerformanceSagas() {
	yield all([
		fork(watchGetPerformances),
		fork(watchGetPerformance),
		fork(watchGeneratePerformance),
		fork(watchAmendPerformance),
		fork(watchRemovePerformance)
	]);
}
