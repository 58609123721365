import API from 'Api/Api';
import { CreateActivityParams, ActivityDetailsParams, ActivityFetchParams } from './ActivityTypes';

//get Activity
export const takeActivitys = (data: ActivityFetchParams) => {
	const _where = data?._where;
	delete data?._where;
	if (_where) {
		return API({
			url: `/data-activity-logs?${_where}`,
			method: 'GET',
			params: data
		});
	} else {
		return API({
			url: '/data-activity-logs',
			method: 'GET',
			params: data
		});
	}
};

//get Activity Details
export const takeActivity = (data: ActivityDetailsParams) => {
	return API({
		url: '/data-activity-logs',
		method: 'GET',
		params: data
	});
};

//create Activity Details
export const makeActivity = (data: CreateActivityParams) => {
	return API({
		url: '/data-activity-logs',
		method: 'POST',
		data: data
	});
};

//modify Activity
export const modifyActivity = (data: CreateActivityParams) => {
	return API({
		url: `/data-activity-logs/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Activity
export const cutActivity = (data: ActivityDetailsParams) => {
	return API({
		url: '/data-activity-logs',
		method: 'DELETE',
		params: data
	});
};
