import FormList from "Components/FormList/FormList";
import { Route, RouteChildrenProps, Switch } from "react-router";
import ActivityLog from "Views/ActivityLog/ActivityLog";
import GIRReport from "Views/GIRReport/GIRReport";
import ActivityRoute from "./ActivityRoute";
import DashboardRoute from "./DashboardRoute";
import EventRoute from "./EventRoute";
import PlayerRoute from "./PlayerRoute";
import RefereeRoute from "./RefereeRoute";
import ReportRoute from "./ReportRoute";
import { RouteGuard } from "./RouteGuard";
import SchoolRoute from "./SchoolRoute";
import SessionRoute from "./SessionRoute";
import TournamentRoute from "./TournamentRoute";
import UserRoute from "./UserRoute";
import WorkshopRoute from "./WorkshopRoute";

export const ProtectedRoute = (props: RouteChildrenProps) => {
  return (
    <RouteGuard>
      <Switch>
        <Route path={`${props.match?.path}dashboard`} component={DashboardRoute} />
        {/* <Route path={`${props.match?.path}reports`} component={ReportRoute} /> */}
        <Route path={`${props.match?.path}gir-report`} component={GIRReport} />
        <Route path={`${props.match?.path}users`} component={UserRoute} />
        <Route path={`${props.match?.path}schools`} component={SchoolRoute} />
        <Route path={`${props.match?.path}players`} component={PlayerRoute} />
        <Route path={`${props.match?.path}activity/events`} component={EventRoute} />
        <Route path={`${props.match?.path}forms`} component={FormList} />
        <Route path={`${props.match?.path}session`} component={SessionRoute} />
        <Route path={`${props.match?.path}activity/activity`} component={ActivityRoute} />
        {/* <Route path={`${props.match?.path}activity`} component={ActivityRoute} /> */}
        <Route path={`${props.match?.path}activity/workshop`} component={WorkshopRoute} />
        <Route path={`${props.match?.path}referee`} component={RefereeRoute} />
        <Route path={`${props.match?.path}activity/tournaments`} component={TournamentRoute} />
        {/* <Route path={`${props.match?.path}teams`} component={TeamRoute} /> */}
        <Route component={DashboardRoute} />
      </Switch>
    </RouteGuard>
  );
};

export default ProtectedRoute;
