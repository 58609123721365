import { Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import AgeGroupChart from 'Components/DashboardWidgets/AgeGroupChart/AgeGroupChart';
import PlayersVsProvinces from 'Components/DashboardWidgets/PlayersVsProvinces/PlayerVsProvinces';
import ProvinceVsTraining from 'Components/DashboardWidgets/ProvinceVsTrainings/ProvinceVsTraining';
import PlayersVsGenderVsProvince from 'Components/DashboardWidgets/PlayerVsGenderVsProvince/PlayerVsGenderVsProvince';

import React, { useEffect } from 'react';
import './Stats.scss';
import { useDispatch } from 'react-redux';
import {
	fetchAgeVsProvince,
	fetchPlayerVsProvince,
	fetchPlayerVsProvinceVsGender,
	fetchTrainingVsProvince
} from 'Redux/AnalysisRedux/AnalysisActions';
export const Stats = (props: any) => {
	const dispatch = useDispatch();
	useEffect(
		() => {
			dispatch(fetchAgeVsProvince({}));
			dispatch(fetchPlayerVsProvinceVsGender({}));
			dispatch(fetchPlayerVsProvince({}));
			dispatch(fetchTrainingVsProvince({}));
		},
		[ 0 ]
	);
	return (
		<Row gutter={[ 20, 20 ]}>
			<Col lg={16}>
				<PlayersVsProvinces />
			</Col>
			<Col lg={8}>
				<AgeGroupChart />
			</Col>
			<Col lg={12}>
				<PlayersVsGenderVsProvince />
			</Col>
			<Col lg={12}>
				<ProvinceVsTraining />
			</Col>
		</Row>
	);
};

export default Stats;
