import { EnvironmentOutlined, MobileOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createGirevent } from "Redux/GIREventRedux/GireventActions";

const { Option } = Select;
export const GIREventForm = (props: any) => {
  const layout = useSelector((state: any) => state.LayoutReducer);
  const dispatch = useDispatch();
  const submitHandler = (values: any) => {
    if (!layout.update) {
      dispatch(createGirevent(values))
    }
  }

  return (
    <Content>
      <Typography.Title style={{ textAlign: 'center' }} level={4}>ADD GIR EVENT</Typography.Title>
      <Divider />
      <Form
        // {...layout}
        layout="vertical"
        name="basic"
        onFinish={submitHandler}
      // onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 20]} justify="center">
          <Col lg={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Title is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: "Location is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Start Date is required!" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "End Date is required!" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Status"
              name="status"
            // rules={[{ required: true, message: "End Date is required!" }]}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col lg={24}>
            <Form.Item
              label="Description"
              name="description"
            // rules={[{ required: true, message: "Address is Required!" }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default GIREventForm;
