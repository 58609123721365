import { EnvironmentOutlined, MobileOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import { getUserId } from "Api/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGirevents } from "Redux/GIREventRedux/GireventActions";
import { createGirreport } from "Redux/GIRReportRedux/GirreportActions";

const { Option } = Select;
export const GIRForm = (props: any) => {
  const dispatch = useDispatch();
  const gir = useSelector((state: any) => state.GireventReducer);
  const layout = useSelector((state: any) => state.LayoutReducer);
  useEffect(() => {
    if (!gir.loaded) {
      dispatch(fetchGirevents({}))
    }
  }, [])

  const submitHandler = (values: any) => {
    if (!layout.update) {
      dispatch(createGirreport({ ...values, createdBy: getUserId(), event: values.location }))
    }
  }
  return (
    <Content>
      <Typography.Title style={{ textAlign: 'center' }} level={4}>ACTIVITY LOG</Typography.Title>
      <Divider />
      <Form
        // {...layout}
        layout="horizontal"
        name="basic"
        onFinish={submitHandler}
      // onFinishFailed={onFinishFailed}
      >
        <Row gutter={[20, 20]} justify="center">
          <Col lg={13}>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: "First Name is required!" }]}
            >
              <Select>
                {gir.list && gir?.list?.map((l: any, i: number) => {
                  return <Option value={l.id}>{l.title}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Session Type"
              name="sessionType"
              rules={[{ required: true, message: "Session Type is Required!" }]}
            >
              <Radio.Group>
                <Radio value="Try">Try</Radio>
                <Radio value="Play">Play</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Main Activity"
              name="mainActivity"
              rules={[{ required: true, message: "Session Type is Required!" }]}
            >



              <Radio.Group>
                <Radio style={{ display: 'block' }} value="gir_programme">GIR Programme</Radio>
                <Radio style={{ display: 'block' }} value="festival_event">Festival Event</Radio>
                <Radio style={{ display: 'block' }} value="gir_programme_festival_event">GIR Programme & festival/event</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={13}>

            <Row gutter={[20, 20]} align="middle">
              <Col lg={6}>
                <Typography.Paragraph>Participants</Typography.Paragraph>
              </Col>
              <Col lg={9}>
                <Form.Item
                  label="Boys"
                  name="boysParticipant"
                  rules={[{ required: true, message: "Number is Required!" }]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col lg={9}>
                <Form.Item
                  label="Girls"
                  name="girlsParticipant"
                  rules={[{ required: true, message: "Number is Required!" }]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={13}>
            <Form.Item
              label="Event Date"
              name="eventDate"
              rules={[{ required: true, message: "Date is Required!" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col lg={13}>
            <Form.Item
              label="Remarks"
              name="summery"
              rules={[{ required: true, message: "Remars is Required!" }]}
            >
              <TextArea />
            </Form.Item>
          </Col>


          <Col lg={13} style={{ display: 'flex', justifyContent: 'center' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default GIRForm;
