import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	FETCH_AGE_GROUP_VS_PROVINCE,
	FETCH_PLAYER_GENDER_PROVINCE,
	FETCH_PROVINCE_PLAYER,
	FETCH_PROVINCE_TRAINING
} from './AnalysisTypes';
import {
	takeAgeVsProvince,
	takeGenderVsProvinceVsPlayer,
	takeProvinceVsPlayer,
	takeProvinceVsTraining
} from './AnalysisApis';
import {
	fetchAgeVsProvinceFailure,
	fetchAgeVsProvinceSuccess,
	fetchPlayerVsProvinceFailure,
	fetchPlayerVsProvinceSuccess,
	fetchPlayerVsProvinceVsGenderFailure,
	fetchPlayerVsProvinceVsGenderSuccess,
	fetchTrainingVsProvinceFailure,
	fetchTrainingVsProvinceSuccess
} from './AnalysisActions';

//get analysiss
const getAgeVsProvinceAsync = async (data: any) => await takeAgeVsProvince(data);

function* getAgeVsProvince(params: any) {
	const { payload } = params;
	try {
		const analysis: AxiosResponse = yield call(getAgeVsProvinceAsync, payload);
		if (analysis) {
			yield put(fetchAgeVsProvinceSuccess(analysis.data));
		} else {
			yield put(fetchAgeVsProvinceFailure('Could not get list of analysiss'));
			notification.error({
				message: 'Get Analysis List',
				description: 'Could not get list of analysiss'
			});
		}
	} catch (error) {
		yield put(fetchAgeVsProvinceFailure('Network Error while getting Analysiss'));
		notification.error({
			message: 'Get Analysis List',
			description: 'Network Error while getting Analysiss'
		});
	}
}

function* watchGetAgeVsProvince() {
	yield takeLatest(FETCH_AGE_GROUP_VS_PROVINCE, getAgeVsProvince);
}

//get analysiss
const getGenderVsProvincevsPlayerAsync = async (data: any) => await takeGenderVsProvinceVsPlayer(data);

function* getGenderVsProvinceVsPlayer(params: any) {
	const { payload } = params;
	try {
		const analysis: AxiosResponse = yield call(getGenderVsProvincevsPlayerAsync, payload);
		if (analysis) {
			yield put(fetchPlayerVsProvinceVsGenderSuccess(analysis.data));
		} else {
			yield put(fetchPlayerVsProvinceVsGenderFailure('Could not get list of analysiss'));
			notification.error({
				message: 'Get Analysis List',
				description: 'Could not get list of analysiss'
			});
		}
	} catch (error) {
		yield put(fetchPlayerVsProvinceVsGenderFailure('Network Error while getting Analysiss'));
		notification.error({
			message: 'Get Analysis List',
			description: 'Network Error while getting Analysiss'
		});
	}
}

function* watchGenderVsProvinceVsPlayer() {
	yield takeLatest(FETCH_PLAYER_GENDER_PROVINCE, getGenderVsProvinceVsPlayer);
}

//get analysiss
const getProvincevsPlayerAsync = async (data: any) => await takeProvinceVsPlayer(data);

function* getProvinceVsPlayer(params: any) {
	const { payload } = params;
	try {
		const analysis: AxiosResponse = yield call(getProvincevsPlayerAsync, payload);
		if (analysis) {
			yield put(fetchPlayerVsProvinceSuccess(analysis.data));
		} else {
			yield put(fetchPlayerVsProvinceFailure('Could not get list of analysiss'));
			notification.error({
				message: 'Get Analysis List',
				description: 'Could not get list of analysiss'
			});
		}
	} catch (error) {
		yield put(fetchPlayerVsProvinceFailure('Network Error while getting Analysiss'));
		notification.error({
			message: 'Get Analysis List',
			description: 'Network Error while getting Analysiss'
		});
	}
}

function* watchProvinceVsPlayer() {
	yield takeLatest(FETCH_PROVINCE_PLAYER, getProvinceVsPlayer);
}

//get analysiss
const getProvincevsTrainingAsync = async (data: any) => await takeProvinceVsTraining(data);

function* getProvinceVsTraining(params: any) {
	const { payload } = params;
	try {
		const analysis: AxiosResponse = yield call(getProvincevsTrainingAsync, payload);
		if (analysis) {
			yield put(fetchTrainingVsProvinceSuccess(analysis.data));
		} else {
			yield put(fetchTrainingVsProvinceFailure('Could not get list of analysiss'));
			notification.error({
				message: 'Get Analysis List',
				description: 'Could not get list of analysiss'
			});
		}
	} catch (error) {
		yield put(fetchTrainingVsProvinceFailure('Network Error while getting Analysiss'));
		notification.error({
			message: 'Get Analysis List',
			description: 'Network Error while getting Analysiss'
		});
	}
}

function* watchProvinceVsTraining() {
	yield takeLatest(FETCH_PROVINCE_TRAINING, getProvinceVsTraining);
}

export default function* AnalysisSagas() {
	yield all([
		fork(watchGetAgeVsProvince),
		fork(watchGenderVsProvinceVsPlayer),
		fork(watchProvinceVsPlayer),
		fork(watchProvinceVsTraining)
	]);
}
