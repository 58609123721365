import API from 'Api/Api';
import { CreateSessionParams, SessionDetailsParams, SessionFetchParams } from './SessionTypes';

//get Session
export const takeSessions = (data: SessionFetchParams) => {
	// return API({
	// 	url: '/session',
	// 	method: 'GET',
	// 	params: data
	// });

	if (data._where) {
		return API({
			url: `/sessions?${data._where}`,
			method: 'GET'
			// params: data
		});
	} else {
		return API({
			url: '/sessions',
			method: 'GET',
			params: data
		});
	}
};

//get Session Details
export const takeSession = (data: SessionDetailsParams) => {
	return API({
		url: `/sessions/${data?.id}`,
		method: 'GET',
	});
};

//create Session Details
export const makeSession = (data: CreateSessionParams) => {
	return API({
		url: '/sessions',
		method: 'POST',
		data: data
	});
};

//modify Session
export const modifySession = (data: CreateSessionParams) => {
	return API({
		url: `/sessions/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Session
export const cutSession = (data: SessionDetailsParams) => {
	return API({
		url: '/sessions',
		method: 'DELETE',
		params: data
	});
};
