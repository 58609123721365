import { Col, Descriptions, Divider, Modal, Row, Typography } from "antd"
import dayjs from "dayjs";
import React from "react";
import { defaultFunction } from "Types/Types";
import { GetUploadUrl } from "Utils/GetUploadResponse";
import { SplitPascal } from "Utils/SplitPascal";
import TournamentDetail from "Components/DetailsView/TournamentDetail";
import WorkshopDetails from "Components/DetailsView/WorkshopDetails";
import UserDetails from "Components/DetailsView/UserDetails";
import Refereedetails from "./Refereedetails";

interface DetailsViewProps {
    data: any;
    labels?: string[];
    onHide: (e: any) => void;
    show?: any
    module?: any;
}

const DateKeys = ['dob', 'sessionDate'];
const userKeys = ['coach', 'assistantCoach'];
const mediasKeys = ['media']

const modules = ['tournament', 'workshop', 'user', 'referee'];



export const DetailsView = (params: DetailsViewProps) => {
    const { data, labels, onHide = defaultFunction, module } = params;
    const SelectView = (form: string) => {
        switch (form) {
            case 'tournament':
                return <TournamentDetail data={data} />;
            case 'workshop':
                return <WorkshopDetails data={data} />;
            case 'user':
                return <UserDetails data={data} />;
            case 'referee':
                return <Refereedetails data={data} />;

        }
    };
    return (
        <Modal footer={false} visible={data} width={module === 'player' ? '50vw' : '50vw'} destroyOnClose onCancel={onHide}>
            <Row justify="end">
                <Col lg={4}>
                    {data?.image && <img width="100%" src={GetUploadUrl([data?.image])} />}
                </Col>
                <Col lg={2}></Col>
            </Row>
            {modules?.includes(module) &&
                SelectView(module)
            }

            {labels?.map((label: any) => {
                return (
                    <>
                        <Descriptions column={2} labelStyle={{ textTransform: 'capitalize' }} contentStyle={{ fontWeight: 'bold' }} title={<><Typography.Title level={5}>{`${label?.title?.toUpperCase()}`}</Typography.Title></>}>
                            {label && label?.keys?.map((key: any) => {
                                return (
                                    label?.keys?.includes(key) ? <Descriptions.Item label={SplitPascal(key)}>
                                        {DateKeys.includes(key) ? (
                                            dayjs(data[key]).format('LL')
                                        ) : (
                                            <>
                                                {userKeys.includes(key) ? (
                                                    data[key]?.name
                                                ) : (
                                                    <>{!mediasKeys.includes(key) && data[key]}</>
                                                )}
                                            </>
                                        )}

                                    </Descriptions.Item> : false
                                )
                            })}
                        </Descriptions>
                        <Divider />
                        {data?.media &&

                            <>
                                <Typography.Title style={{ textTransform: 'uppercase', fontWeight: 'bold' }} level={5}>MEDIAS</Typography.Title>
                                {data?.media?.map((d: any) => {
                                    return <img width="100%" src={GetUploadUrl([d])} />
                                })}
                            </>
                        }
                    </>
                )
            })}

            {data?.previousHealth &&
                <Descriptions layout="vertical">
                    <Descriptions.Item label="Previous health issue/injury/allergy/accident">{data?.previousHealth}</Descriptions.Item>
                </Descriptions>
            }
        </Modal>
    )
}

export default DetailsView;