import { Content } from 'antd/lib/layout/layout';

export const ShadowContent = (props: any) => {
	const styles = {
		background: 'white',
		borderRadius: 20,
		marginTop: 20,
		padding: 30,
		boxShadow: `0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)`
	};
	return <Content style={styles}>{props.children}</Content>;
};
