import dayjs from "dayjs";

export const AttendanceMapping = (data: any) => {
    const { players, sessions } = data;
    let attData: any[] = [];
    players.map((player: any) => {
        let attendance = {
            name: player.name,
            ...getAttType(player.id, sessions)
        }
        attData.push(attendance)
    })
    return attData;
};

const getAttType = (id: any, sessions: any) => {
    let attTypes: any = {};
    sessions.map((session: any) => {
        attTypes = { ...attTypes, [dayjs(session?.sessionDate).format('DD/MM')]: session?.attendance?.list?.filter((at: any) => at.id === id)[0]?.type || '-' }
    })

    return attTypes;
}
