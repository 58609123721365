import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	createReportFailure,
	createReportSuccess,
	deleteReportFailure,
	deleteReportSuccess,
	fetchReportFailure,
	fetchReportsFailure,
	fetchReportsSuccess,
	fetchReportSuccess,
	updateReportFailure,
	updateReportSuccess
} from './ReportActions';
import { cutReport, makeReport, modifyReport, takeReport, takeReports } from './ReportApis';
import {
	CreateReportParams,
	FETCH_REPORTS,
	UPDATE_REPORT,
	ReportDetailsParams,
	ReportFetchParams,
	REMOVE_REPORT,
	CREATE_REPORT,
	FETCH_REPORT_DETAILS
} from './ReportTypes';

//get reports
const getReportsAsync = async (data: ReportFetchParams) => await takeReports(data);

function* getReports(params: any) {
	const { payload } = params;
	try {
		const reports: AxiosResponse = yield call(getReportsAsync, payload);
		if (reports) {
			yield put(fetchReportsSuccess(reports.data));
		} else {
			yield put(fetchReportsFailure('Could not get list of reports'));
			notification.error({
				message: 'Get Report List',
				description: 'Could not get list of reports'
			});
		}
	} catch (error) {
		yield put(fetchReportsFailure('Network Error while getting Reports'));
		notification.error({
			message: 'Get Report List',
			description: 'Network Error while getting Reports'
		});
	}
}

function* watchGetReports() {
	yield takeLatest(FETCH_REPORTS, getReports);
}

//get report details
const getReportAsync = async (data: ReportDetailsParams) => await takeReport(data);

function* getReport(params: any) {
	const { payload } = params;
	try {
		const reports: AxiosResponse = yield call(getReportAsync, payload);
		if (reports) {
			yield put(fetchReportSuccess(reports.data));
		} else {
			yield put(fetchReportFailure('Could not get list of reports'));
			notification.error({
				message: 'Get Report',
				description: 'Could not get list of reports'
			});
		}
	} catch (error) {
		yield put(fetchReportFailure('Network Error while getting Reports'));
		notification.error({
			message: 'Get Report',
			description: 'Network Error while getting Reports'
		});
	}
}

function* watchGetReport() {
	yield takeLatest(FETCH_REPORT_DETAILS, getReport);
}

//create Report
const generateReportAsync = async (data: CreateReportParams) => await makeReport(data);

function* generateReport(params: any) {
	const { payload } = params;
	try {
		const reports: AxiosResponse = yield call(generateReportAsync, payload);
		if (reports) {
			yield put(createReportSuccess(reports.data));
			notification.success({
				message: 'Create Report',
				description: 'Report created successfully'
			});
		} else {
			const message = 'Could not create Report';
			yield put(createReportFailure(message));
			notification.error({
				message: 'Create Report',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Report';
		yield put(createReportFailure(message));
		notification.error({
			message: 'Create Report',
			description: message
		});
	}
}

function* watchGenerateReport() {
	yield takeLatest(CREATE_REPORT, generateReport);
}

//update Report
const amendReportAsync = async (data: CreateReportParams) => await modifyReport(data);

function* amendReport(params: any) {
	const { payload } = params;
	try {
		const reports: AxiosResponse = yield call(amendReportAsync, payload);
		if (reports) {
			yield put(updateReportSuccess(reports.data));
			notification.success({
				message: 'Update Report',
				description: 'Report updated successfully'
			});
		} else {
			const message = 'Could not Update Report';
			yield put(updateReportFailure(message));
			notification.error({
				message: 'Update Report',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Report';
		yield put(updateReportFailure(message));
		notification.error({
			message: 'Update Report',
			description: message
		});
	}
}

function* watchAmendReport() {
	yield takeLatest(UPDATE_REPORT, amendReport);
}

//Delete Report
const removeReportAsync = async (data: ReportDetailsParams) => await cutReport(data);

function* removeReport(params: any) {
	const { payload } = params;
	try {
		const reports: AxiosResponse = yield call(removeReportAsync, payload);
		if (reports) {
			yield put(deleteReportSuccess(reports.data));
			notification.success({
				message: 'Report Deleted',
				description: 'Report deleted successfully'
			});
		} else {
			const message = 'Could not delete Report';
			yield put(deleteReportFailure(message));
			notification.error({
				message: 'Report Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Report';
		yield put(deleteReportFailure(message));
		notification.error({
			message: 'Report Deleted',
			description: message
		});
	}
}

function* watchRemoveReport() {
	yield takeLatest(REMOVE_REPORT, removeReport);
}

export default function* ReportSagas() {
	yield all([
		fork(watchGetReports),
		fork(watchGetReport),
		fork(watchGenerateReport),
		fork(watchAmendReport),
		fork(watchRemoveReport)
	]);
}
