import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Row, Space, Tag, Typography } from 'antd';
import { useContext } from 'react';
import './MyHeader.scss';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAuth } from 'Redux/Actions/AuthActions';
import { getUserDetails } from 'Api/Api';
const ProfileHeader = () => {
	const theme = useContext(ThemeContext);
	return (
		<div
			className="profile-header"
			style={{
				background: theme.primary
			}}
		>
			<Row justify="space-between" align="middle" style={{ padding: '10px 0' }}>
				<Col>
					<Space style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 0 }}>
						<UserOutlined style={{ fontSize: 24, color: 'white' }} />
						<Typography.Title level={4} style={{ color: 'white', marginBottom: 0 }}>
							{getUserDetails()?.fullName || getUserDetails()?.email}
						</Typography.Title>
					</Space>
				</Col>
				<Col>
					<SettingOutlined key="setting" style={{ color: 'white', fontSize: 20 }} />
				</Col>
			</Row>
			<Tag>{getUserDetails()?.role?.name}</Tag>

		</div>
	);
};

// const data = [
//   {
//     title: "My Profile",
//     avatar: <SmileTwoTone style={{ fontSize: 24 }} />,
//     desc: "Personal Information",
//   },
//   {
//     title: "My Inbox",
//     avatar: <InboxOutlined style={{ fontSize: 24 }} />,
//     desc: "Messages & Latest News",
//   },
//   {
//     title: "My Projects",
//     avatar: <ProjectTwoTone style={{ fontSize: 24 }} />,
//     desc: "Tasks & Active Projects",
//   },
//   {
//     title: "Billing Information",
//     avatar: <DollarCircleTwoTone style={{ fontSize: 24 }} />,
//     desc: "Pricing & Current Plan",
//   },
// ];

export const MyHeader = (props: any) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const handleLogout = () => {
		localStorage.removeItem('auths');
		localStorage.removeItem('loggedIn');
		dispatch(setAuth({ loggedIn: false }));
		history.push('/');
		window.location.reload();
	};
	const ProfileMenu = () => {
		return (
			<div>
				<Card
					style={{ width: 300 }}
					cover={<ProfileHeader />}
					actions={[
						// <SettingOutlined key="setting" />,
						// <EditOutlined key="edit" />,
						<Button type="primary" onClick={() => handleLogout()} icon={<LogoutOutlined />} danger>
							Logout
						</Button>
					]}
				/>
			</div>
		);
	};
	return (
		<div
			className="my-header"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				paddingRight: 20
			}}
		>
			{/* <Dropdown overlay={ProfileMenu} trigger={["click"]}>
        <BellTwoTone twoToneColor="#eb2f96" style={{ fontSize: 26 }} />
      </Dropdown> */}
			<Dropdown overlay={ProfileMenu} trigger={['click']} className="my-profile">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: 20
					}}
				>
					<span>{getUserDetails()?.fullName || getUserDetails()?.email}</span>
					<UserOutlined
						style={{
							fontSize: 26,
							marginLeft: 10
						}}
					/>
				</div>
			</Dropdown>
		</div>
	);
};

export default MyHeader;
