import { getUserId } from 'Api/Api';
import qs from 'qs';

export const SelfEvents = () => {
	let query = {
		_where: {
			_or: [
				[ { 'coaches.id_in': getUserId() } ], // implicit AND
				[ { 'coaches.parent.id_eq': getUserId() } ] // implicit AND
			]
		}
	};

	return { _where: qs.stringify(query) };
};

export const SelfSessions = () => {
	let query = {
		_where: {
			_or: [
				[ { 'coach.id_eq': getUserId() } ], // implicit AND
				[ { 'coach.parent.id_eq': getUserId() } ] // implicit AND
			]
		}
	};

	return { _where: qs.stringify(query) };
};

export const SelfPlayers = () => {
	let query = {
		_where: {
			_or: [
				[ { 'coach.id_eq': getUserId() } ], // implicit AND
				[ { 'coach.parent.id_eq': getUserId() } ] // implicit AND
			]
		}
	};

	return { _where: qs.stringify(query) };
};

export const SelfCoaches = (searchText: any) => {
	let query = {
		_where: {
			_or: [
				// [ { 'coach.id_eq': getUserId() } ], // implicit AND
				[ { parent_eq: getUserId() }, { fullName_contains: searchText } ], // implicit AND
				[ { 'role.id_eq': '6076adac4fb3af290d64a807' }, { fullName_contains: searchText } ]
			]
		}
	};

	return { _where: qs.stringify(query) };
};

export const CoachesOnly = (searchText: any) => {
	let query = {
		_where: {
			_or: [
				// [ { 'coach.id_eq': getUserId() } ], // implicit AND
				[ { 'role.id_eq': '6076adac4fb3af290d64a807' }, { fullName_contains: searchText } ] // implicit AND,
			]
		}
	};

	return { _where: qs.stringify(query) };
};

export const EventSessions = (id: string) => {
	const eventSessions = {
		_where: {
			_or: [
				[ { 'training.id_eq': id } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(eventSessions) };
};

export const EventPlayers = (id: string) => {
	const eventPlayers = {
		_where: {
			_or: [
				[ { 'trainings.id_in': id } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(eventPlayers) };
};

export const EventAttendances = (id: string) => {
	const eventAttendances = {
		_where: {
			_or: [
				[ { 'session.training.id_eq': id } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(eventAttendances) };
};

export const SessionDetailsQuery = (id: string) => {
	const particularSession = {
		_where: {
			_or: [
				[ { 'performances.session_in': id } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(particularSession) };
};

export const SelfActivityLog = () => {
	const particularSession = {
		_where: {
			_or: [
				[ { submitedBy_eq: getUserId() } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(particularSession) };
};

export const SelfWorkshop = () => {
	const particularSession = {
		_where: {
			_or: [
				[ { submitedBy_eq: getUserId() } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(particularSession) };
};

export const SelfTournament = () => {
	const particularSession = {
		_where: {
			_or: [
				[ { submitedBy_eq: getUserId() } ] // implicit AND
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(particularSession) };
};

export const SelfSubmitted = () => {
	const particularSession = {
		_where: {
			_or: [
				[ { 'submittedBy.id_eq': getUserId() } ], // implicit AND
				[ { 'submittedBy.parent.id_eq': getUserId() } ]
				// [{ category: 'province_associations' }], // implicit AND
			]
		}
	};
	return { _where: qs.stringify(particularSession) };
};
