import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setLayout } from 'Redux/Actions/LayoutActions';
import {
	createFormFailure,
	createFormSuccess,
	deleteFormFailure,
	deleteFormSuccess,
	fetchFormFailure,
	fetchForms,
	fetchFormsFailure,
	fetchFormsSuccess,
	fetchFormSuccess,
	updateFormFailure,
	updateFormSuccess
} from './FormActions';
import { cutForm, makeForm, modifyForm, takeForm, takeForms } from './FormApis';
import {
	CreateFormParams,
	FETCH_FORMS,
	UPDATE_FORM,
	FormDetailsParams,
	FormFetchParams,
	FETCH_FORM_DETAILS,
	CREATE_FORM,
	REMOVE_FORM
} from './FormTypes';

//get forms
const getFormsAsync = async (data: FormFetchParams) => await takeForms(data);

function* getForms(params: any) {
	const { payload } = params;
	try {
		const forms: AxiosResponse = yield call(getFormsAsync, payload);
		if (forms) {
			yield put(fetchFormsSuccess(forms.data));
		} else {
			yield put(fetchFormsFailure('Could not get list of forms'));
			notification.error({
				message: 'Get Form List',
				description: 'Could not get list of forms'
			});
		}
	} catch (error) {
		yield put(fetchFormsFailure('Network Error while getting Forms'));
		notification.error({
			message: 'Get Form List',
			description: 'Network Error while getting Forms'
		});
	}
}

function* watchGetForms() {
	yield takeLatest(FETCH_FORMS, getForms);
}

//get form details
const getFormAsync = async (data: FormDetailsParams) => await takeForm(data);

function* getForm(params: any) {
	const { payload } = params;
	try {
		const forms: AxiosResponse = yield call(getFormAsync, payload);
		if (forms) {
			yield put(fetchFormSuccess(forms.data));
		} else {
			yield put(fetchFormFailure('Could not get list of forms'));
			notification.error({
				message: 'Get Form',
				description: 'Could not get list of forms'
			});
		}
	} catch (error) {
		yield put(fetchFormFailure('Network Error while getting Forms'));
		notification.error({
			message: 'Get Form',
			description: 'Network Error while getting Forms'
		});
	}
}

function* watchGetForm() {
	yield takeLatest(FETCH_FORM_DETAILS, getForm);
}

//create Form
const generateFormAsync = async (data: CreateFormParams) => await makeForm(data);

function* generateForm(params: any) {
	const { payload } = params;
	try {
		const forms: AxiosResponse = yield call(generateFormAsync, payload);
		if (forms) {
			yield put(createFormSuccess(forms.data));
			yield put(fetchForms({}));
			yield put(setLayout({ form: false, data: false, update: false }));
			notification.success({
				message: 'Create Form',
				description: 'Form created successfully'
			});
		} else {
			const message = 'Could not create Form';
			yield put(createFormFailure(message));
			notification.error({
				message: 'Create Form',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Form';
		yield put(createFormFailure(message));
		notification.error({
			message: 'Create Form',
			description: message
		});
	}
}

function* watchGenerateForm() {
	yield takeLatest(CREATE_FORM, generateForm);
}

//update Form
const amendFormAsync = async (data: CreateFormParams) => await modifyForm(data);

function* amendForm(params: any) {
	const { payload } = params;
	try {
		const forms: AxiosResponse = yield call(amendFormAsync, payload);
		if (forms) {
			yield put(updateFormSuccess(forms.data));
			notification.success({
				message: 'Update Form',
				description: 'Form updated successfully'
			});
		} else {
			const message = 'Could not Update Form';
			yield put(updateFormFailure(message));
			notification.error({
				message: 'Update Form',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Form';
		yield put(updateFormFailure(message));
		notification.error({
			message: 'Update Form',
			description: message
		});
	}
}

function* watchAmendForm() {
	yield takeLatest(UPDATE_FORM, amendForm);
}

//Delete Form
const removeFormAsync = async (data: FormDetailsParams) => await cutForm(data);

function* removeForm(params: any) {
	const { payload } = params;
	try {
		const forms: AxiosResponse = yield call(removeFormAsync, payload);
		if (forms) {
			yield put(deleteFormSuccess(forms.data));
			notification.success({
				message: 'Form Deleted',
				description: 'Form deleted successfully'
			});
		} else {
			const message = 'Could not delete Form';
			yield put(deleteFormFailure(message));
			notification.error({
				message: 'Form Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Form';
		yield put(deleteFormFailure(message));
		notification.error({
			message: 'Form Deleted',
			description: message
		});
	}
}

function* watchRemoveForm() {
	yield takeLatest(REMOVE_FORM, removeForm);
}

export default function* FormSagas() {
	yield all([
		fork(watchGetForms),
		fork(watchGetForm),
		fork(watchGenerateForm),
		fork(watchAmendForm),
		fork(watchRemoveForm)
	]);
}
