import { Col, Row } from 'antd';
import MyContent from 'Components/Content/Content';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSchools } from 'Redux/SchoolRedux/SchoolActions';
import { fetchUsers } from 'Redux/UserRedux/UserActions';
import SchoolList from './SchoolList';

export const Schools = (props: any) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchSchools({}));
	}, []);
	return (
		<Row gutter={[ 20, 20 ]}>
			<Col lg={18}>
				<SchoolList />
			</Col>
			{/* <Col lg={6}>
				<MyContent>
					<div>User List</div>
				</MyContent>
			</Col> */}
		</Row>
	);
};

export default Schools;
